import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	ListItemText,
	MenuItem,
	Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { useParams } from 'react-router-dom';
import { filterObjectByKeys } from '@/utils/qsHelper';
import { GridColDef } from '@mui/x-data-grid';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { UserType } from '@/api/enum/userType.enum';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import AddRelativeSummaryFilterSection from './AddRelativeSummaryFilterSection';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useAddRelativeOrgUserMutation } from '@/api/mockApiSlice';
import {
	FriendOrgListFilter,
	FriendOrgListItem,
} from '@/api/DTO/friend/friend.interface';
import {
	useAddFriendsMutation,
	useGetFriendOrgListQuery,
} from '@/api/friend/friendApiSlice';

const AddMemberRelativeListModal = () => {
	const { t } = useTranslation();
	const { userId } = useParams();
	const showModal = useModal();
	const [addFriends] = useAddFriendsMutation();

	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const { userRoles } = useGetUser<useGetUserResultType>({
		// shouldRefetch: true,
		roleObj: {
			read: ['read', 'user', 'user-list'],
			create: ['create', 'user'],
			send: ['send', 'user'],
		},
	});

	const [selectedUser, setSelectedUser] = useState<string[]>([]);
	const [selectedUserItem, setSelectedUserItem] = useState<FriendOrgListItem[]>(
		[]
	);

	const defaultOrgUserPageFilter: FriendOrgListFilter = {
		pagination: initialPagination,
		sortings: [],
		searchKey: '',
		userId: userId || '',
	};

	const defaultValues: DefaultValues<FriendOrgListFilter> =
		defaultOrgUserPageFilter;

	const [searchFilter, setSearchFilter] = useState<FriendOrgListFilter>(
		defaultOrgUserPageFilter
	);

	const methods = useForm<FriendOrgListFilter>({
		defaultValues,
	});

	const transformedSearchParams: FriendOrgListFilter = {
		...defaultOrgUserPageFilter,
		...filterObjectByKeys(searchFilter, [
			'pagination',
			'sortings',
			'searchKey',
			'userId',
		]),
	};

	const {
		data: orgUserPageSummary,
		isSuccess: isGetOrgUserPageSummarySuccess,
	} = useGetFriendOrgListQuery(transformedSearchParams, {
		// refetchOnMountOrArgChange: true,
		// skip: !userRoles.read,
	});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const propertyFilterFormResult = value as FriendOrgListFilter;

			setSearchFilter(propertyFilterFormResult);
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: FriendOrgListItem[]) => {
		if (response == null) return;
		const newResponse: FriendOrgListItem[] = [];
		response.forEach((item) => {
			newResponse.push({
				...item,
				id: item.id,
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'username',
			headerName: t('common.username'),
			flex: 20, // 20% width
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='body2'
						variant='regular'
					>
						{params.row.username}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.phone}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.email}
					</Typography>
				</Box>
			),
		},
		{
			field: 'district',
			headerName: t('application.district'),
			flex: 9, // 9% width
		},
		{
			field: 'userType',
			headerName: t('common.userCategory'),
			flex: 9, // 9% width
			renderCell: (params) => (
				<Chip
					label={
						params.row.userType === UserType.NORMAL_USER
							? t('common.normalMember')
							: t('common.programMember')
					}
					color={
						params.row.userType === UserType.NORMAL_USER
							? 'chipTertiary'
							: 'chipPrimary'
					}
					variant='outlined'
				/>
			),
		},
		{
			field: 'serviceNumber',
			headerName: t('common.serviceNumber'),
			flex: 11, // 11% width
		},
		{
			field: 'staff',
			headerName: t('common.staffInCharge'),
			flex: 10, // 10% width
		},
		// {
		// 	field: 'disabled',
		// 	headerName: t('common.status'),
		// 	flex: 10, // 10% width
		// 	renderCell: (params) => (
		// 		<Chip
		// 			label={params.row.disabled ? t('common.freezed') : t('common.normal')}
		// 			color={params.row.disabled ? 'chipRed' : 'chipGreen'}
		// 			variant='outlined'
		// 		/>
		// 	),
		// },
		// {
		// 	field: 'joinedAt',
		// 	headerName: t('common.membershipTime'),
		// 	flex: 11, // 11% width
		// 	renderCell: (params) => (
		// 		<Typography variant='regular'>
		// 			{params.row.joinedAt
		// 				? dayjs(params.row.joinedAt)
		// 						.locale('zh-hk-my-setting')
		// 						.format(DISPLAY_DATE_FORMAT)
		// 				: '-'}
		// 		</Typography>
		// 	),
		// },
	];

	const handleAddSurveyOrgUser = async () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('relative.addRelative')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('relative.addRelativeMsg')}
						</Typography>
						<ul>
							{selectedUserItem.map((user) => {
								return (
									<li key={user.userId}>
										<Typography variant='body1'>{user?.username}</Typography>
									</li>
								);
							})}
						</ul>
					</>
				}
				cancellationText={''}
				confirmationText={t('button.confirm')}
				confirmationButtonProps={{ variant: 'contained' }}
				onConfirm={async () => {
					const response = await addFriends({
						userId: userId || '',
						targetUserIds: selectedUser,
					});
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.addRelativeSuccessMsg'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						handleClose();
					}
				}}
			/>
		));
	};

	return (
		<>
			<MenuItem
				onClick={() => {
					setOpen(true);
				}}
			>
				<ListItemText>{t('common.memberUser')}</ListItemText>
			</MenuItem>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='lg'
				fullWidth
			>
				<DialogTitle variant='h5'>
					{t('relative.addRelative') + '：' + t('common.memberUser')}
				</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent
					sx={{
						minHeight: '765px',
					}}
				>
					<FormProvider {...methods}>
						<AddRelativeSummaryFilterSection />

						<Box marginTop={2} minWidth='800px'>
							<AppDataGrid
								loading={!isGetOrgUserPageSummarySuccess}
								rows={transformResponse(orgUserPageSummary?.data?.items) ?? []}
								columns={columns}
								rowCount={orgUserPageSummary?.data?.pagination.totalItems ?? 0}
								getRowId={(row) => row.userId}
								checkboxSelection
								selectionModel={selectedUser}
								onSelectionModelChange={(ids) => {
									const rows =
										transformResponse(orgUserPageSummary?.data?.items) ?? [];
									const selectedIDs = new Set(ids);
									const selectedRowData = rows.filter((row) =>
										selectedIDs.has(row.userId.toString())
									);

									// Update selectedUser
									setSelectedUser(ids.map((id) => String(id)));

									// Update selectedUserItem
									setSelectedUserItem((prevSelected) => {
										const newSelected = new Set(ids.map((id) => String(id)));
										const updatedSelected = prevSelected.filter((item) =>
											newSelected.has(item.userId)
										);

										// Add newly selected items
										selectedRowData.forEach((row) => {
											if (
												!updatedSelected.some(
													(item) => item.userId === row.userId
												)
											) {
												updatedSelected.push(row);
											}
										});

										// Remove unselected items
										const allSelectedIDs = new Set([
											...prevSelected.map((item) => item.userId),
											...ids.map((id) => String(id)),
										]);
										return updatedSelected.filter((item) =>
											allSelectedIDs.has(item.userId)
										);
									});
								}}
							/>
						</Box>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						color='primary'
						onClick={handleAddSurveyOrgUser}
						disabled={selectedUser.length === 0}
					>
						{t('button.adjunction')}
						{/* {selectedUser.length > 0 && `(${selectedUser.length})`} */}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AddMemberRelativeListModal;
