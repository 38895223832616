import { useTranslation } from 'react-i18next';

export function useResourcesListOptions(options: any[]) {
	const { t } = useTranslation();
	const listMap: Record<string, any> = {
		statusList: [
			{ id: 1, name: t('communityResources.draft') },
			{ id: 2, name: t('communityResources.directorApproval') },
			{ id: 3, name: t('communityResources.supervisorApproval') },
			{ id: 4, name: t('communityResources.toBeListed') },
			{ id: 5, name: t('communityResources.isListed') },
			{ id: 6, name: t('communityResources.isPulled') },
		],
		onlyTwoStatusList: [
			{ id: 5, name: t('communityResources.isListed') },
			{ id: 6, name: t('communityResources.isPulled') },
		],
		statusChipMap: {
			1: { color: 'chipGrey' },
			2: { color: 'chipGrey' },
			3: { color: 'chipPrimary' },
			4: { color: 'chipTertiary' },
			5: { color: 'chipGreen' },
			6: { color: 'chipGrey' },
		},
		typesChipMap: {
			1: { color: 'chipCloth' },
			2: { color: 'chipFood' },
			3: { color: 'chipLive' },
			4: { color: 'chipWalk' },
			5: { color: 'chipCommunityWelfareInfo' },
		},
	};
	return options.reduce((result: Record<string, any[]>, type) => {
		if (listMap[type]) {
			result[type] = listMap[type];
		}
		return result;
	}, {});
}
