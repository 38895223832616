import { useAppSelector } from '../../../redux-hooks';
import { SnackbarType, selectSnackbarState } from './snackbarSlice';
import AppSnackbar from './AppSnackbar';

const SnackbarRoot = () => {
	const { snackbarType, snackbarProps } = useAppSelector(selectSnackbarState);

	if (snackbarType === SnackbarType.NULL) {
		return null;
	}

	return <AppSnackbar {...snackbarProps} />;
};

export default SnackbarRoot;
