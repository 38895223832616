import './styles.css';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect, useState } from 'react';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import TableCellResizer from './plugins/TableCellResizer';
import TableCellActionMenuPlugin from './plugins/TableCellActionMenuPlugin';
import Placeholder from './ui/Placeholder';
import { TableContext } from './plugins/TablePlugin';
import ContentEditable from './ui/ContentEditable';
import YouTubePlugin from './plugins/YouTubePlugin';
import { YouTubeNode } from './nodes/YouTubeNode';
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import { isJson } from '../../../utils/helper';

interface EditorDisplayProps {
	editorStateString?: string;
	isTransparentBackground?: boolean;
}

export default function EditorDisplay({
	editorStateString,
	isTransparentBackground,
}: EditorDisplayProps) {
	function ReloadEditorStatePlugIn() {
		const [editor] = useLexicalComposerContext();

		useEffect(() => {
			setTimeout(() => {
				if (editorStateString && isJson(editorStateString)) {
					const editorState = editor.parseEditorState(editorStateString);
					editor.setEditorState(editorState);
					editor.setEditable(false);
				}
			}, 0);
		}, [editorStateString]);

		return null;
	}

	const placeholder = <Placeholder> - </Placeholder>;

	const [floatingAnchorElem, setFloatingAnchorElem] =
		useState<HTMLDivElement | null>(null);

	const onRef = (_floatingAnchorElem: HTMLDivElement) => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	const editorConfig = {
		editable: false,
		...(editorStateString &&
			(isJson(editorStateString)
				? {
						editorState: editorStateString,
				  }
				: {
						editorState: null,
				  })),
		theme: PlaygroundEditorTheme,
		onError(error: Error) {
			throw error;
		},
		nodes: [
			HeadingNode,
			ListNode,
			ListItemNode,
			TableNode,
			TableCellNode,
			TableRowNode,
			AutoLinkNode,
			LinkNode,
			HorizontalRuleNode,
			YouTubeNode,
		],
		namespace: 'Playground',
	};

	return (
		<>
			<LexicalComposer initialConfig={editorConfig}>
				<TableContext>
					<div className='editor-shell editor-shell-read-only'>
						<div
							className={
								(isTransparentBackground
									? 'editor-container-transparent '
									: 'editor-container ') + 'tree-view'
							}
						>
							<RichTextPlugin
								contentEditable={
									<div className='editor-scroller'>
										<div className='editor' ref={onRef}>
											<ContentEditable />
										</div>
									</div>
								}
								placeholder={placeholder}
								ErrorBoundary={LexicalErrorBoundary}
							/>
							<AutoFocusPlugin />
							<ListPlugin />
							<LinkPlugin />
							<AutoLinkPlugin />
							<ListMaxIndentLevelPlugin maxDepth={7} />
							<FloatingLinkEditorPlugin />
							<HorizontalRulePlugin />
							<TablePlugin hasCellMerge={true} hasCellBackgroundColor={true} />
							<TableCellResizer />
							{floatingAnchorElem && (
								<>
									<TableCellActionMenuPlugin
										anchorElem={floatingAnchorElem}
										cellMerge={true}
									/>
								</>
							)}
							<AutoEmbedPlugin />
							<YouTubePlugin />
							<ReloadEditorStatePlugIn />
						</div>
					</div>
				</TableContext>
			</LexicalComposer>
		</>
	);
}
