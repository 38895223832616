import { yupResolver } from '@hookform/resolvers/yup';
import Close from '@mui/icons-material/Close';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppNumberTextField from '../form-inputs/AppNumberTextField';
import { useAdjustUserCoinMutation } from '@/api/mission/missionApiSLice';
import { AdjustUserCoinReqDTO } from '@/api/DTO/mission/mission.interface';

interface EditNumOfCoinModalProps {
	userId: string;
	coins: number;
}

const EditNumOfCoinModal = (props: EditNumOfCoinModalProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const defaultValues: DefaultValues<AdjustUserCoinReqDTO> = {
		coin: 0,
	};

	const formSchema = Yup.object().shape({
		coin: Yup.number()
			.nullable()
			.transform((curr, orig) => (orig === '' ? null : curr))
			.max(99999 - props.coins, t('errorMessage.maxCoins99999')),
	});

	const methods = useForm<AdjustUserCoinReqDTO>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		methods.setValue('userId', props.userId);
	}, [props.userId]);

	const handleClose = () => {
		setOpen(false);
	};

	const watchCoin = methods.watch('coin');

	const [adjustUserCoin] = useAdjustUserCoinMutation();

	const onSubmit: SubmitHandler<AdjustUserCoinReqDTO> = async (data) => {
		const response = await adjustUserCoin({
			userId: props.userId,
			coin: Number(data.coin),
		});
		if ('data' in response) {
			handleClose();
			methods.reset();
		}
	};

	return (
		<>
			<IconButton color='primary' onClick={() => setOpen(true)}>
				<CreateOutlinedIcon sx={{ cursor: 'pointer' }} />
			</IconButton>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='sm'
				fullWidth
			>
				<DialogTitle variant='h5'>{t('stat.editNumOfCoins')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12} display='flex' alignItems='center'>
									<Box
										width='100%'
										display='flex'
										gap={1}
										flexDirection='column'
									>
										<Grid container>
											<Grid item width={'120px'} marginRight='16px'>
												<Typography variant='body2' color='common.neutral40'>
													{t('stat.originalNumOfCoins')}
												</Typography>
											</Grid>
											<Grid item xs>
												<Typography
													variant='body1'
													sx={{ wordBreak: 'break-word' }}
												>
													{props.coins}
												</Typography>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item width={'120px'} marginRight='16px'>
												<Typography variant='body2' color='common.neutral40'>
													{t('stat.editedNumOfCoins')}
												</Typography>
											</Grid>
											<Grid item xs>
												<Typography
													variant='body1'
													sx={{ wordBreak: 'break-word' }}
												>
													{Number(watchCoin) + Number(props.coins)}
												</Typography>
											</Grid>
										</Grid>
									</Box>
									<AppNumberTextField
										control={methods.control}
										name='coin'
										fieldLabel={t('stat.editNum')}
										type='number'
										size='small'
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.save')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EditNumOfCoinModal;
