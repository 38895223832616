import { Option } from '../DTO/common.interface';

export enum Centre {
	MHAHK = 'MHAHK',
	NLPRA = 'NLPRA',
	SHARE = 'SHARE',
}

export const CentreMap = {
	MHAHK: 1,
	NLPRA: 2,
	SHARE: 0,
};

export const centreOptions: Option[] = [
	{
		id: Centre.MHAHK,
		name: '香港心理衞生會',
	},
	{
		id: Centre.NLPRA,
		name: '新生精神康復會',
	},
];

export const centreList = {
	[Centre.MHAHK]: [
		{
			id: Centre.MHAHK,
			name: '香港心理衞生會',
		},
		{
			id: Centre.SHARE,
			name: '無機構用戶',
		},
	],
	[Centre.NLPRA]: [
		{
			id: Centre.NLPRA,
			name: '新生精神康復會',
		},
		{
			id: Centre.SHARE,
			name: '無機構用戶',
		},
	],
	[Centre.SHARE]: [
		{
			id: Centre.SHARE,
			name: '無機構用戶',
		},
	],
};
