import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Grid,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Close from '@mui/icons-material/Close';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { MemberType } from '@/api/enum/common.enum';
import { genderOptions } from '@/api/enum/gender.enum';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	useGetMemberDetailQuery,
	useGetNoMemberDetailQuery,
	useRecoverUserMutation,
	useDeleteUserMutation,
} from '@/api/user/removeUserApiSlice';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';

interface Props {
	detailObj: any;
	memberType: number | string;
}

const DetailDialog = (props: Props) => {
	const { detailObj, memberType } = props;
	const [isOpen, setIsOpen] = useState(false);
	const { t } = useTranslation();
	const showModal = useModal();
	const [recoverUser] = useRecoverUserMutation();
	const [deleteUser] = useDeleteUserMutation();

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			recover: ['recover', 'removeUser'],
			delete: ['delete', 'removeUser'],
		},
	});

	const userTypeOptions = [
		{
			value: 1,
			name: t('common.normalMember'),
		},
		{
			value: 2,
			name: t('common.programMember'),
		},
		{
			value: 3,
			name: t('common.communityMember'),
		},
	];

	const { data: resData } =
		memberType == MemberType.member
			? useGetMemberDetailQuery(detailObj.id, {
					refetchOnMountOrArgChange: true,
					skip: !isOpen,
			  })
			: useGetNoMemberDetailQuery(detailObj.id, {
					refetchOnMountOrArgChange: true,
					skip: !isOpen,
			  });

	const formData = {
		...resData?.data,
		genderLabel:
			genderOptions.find((option) => option.id == resData?.data.gender)?.name ??
			'',
		userTypeLabel: userTypeOptions.find(
			(item) => item.value == resData?.data.userType
		)?.name,
	};

	const handleClose = () => {
		setIsOpen(false);
	};
	const handleRecover = async () => {
		const response = await recoverUser(formData?.id || '');
		if ('data' in response) {
			enqueueSnackbar(`${formData?.username}${t('snackbar.recoverSuccess')}`, {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
		}
	};
	const handleDelete = async () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('removeUser.removeUser')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('removeUser.removeUserMessage')}
						</Typography>
					</>
				}
				cancellationText={''}
				confirmationText={t('button.sureDelete')}
				confirmationButtonProps={{ color: 'error', variant: 'outlined' }}
				onConfirm={async () => {
					const response = await deleteUser(formData?.id || '');
					if ('data' in response) {
						enqueueSnackbar(`${formData?.username}${t('snackbar.removed')}`, {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						handleClose();
					}
				}}
			/>
		));
	};

	return (
		<>
			<div onClick={() => setIsOpen(true)}>
				<IconButton color='primary'>
					<VisibilityOutlinedIcon />
				</IconButton>
			</div>
			<Dialog open={isOpen} onClose={handleClose} maxWidth='md' fullWidth>
				<DialogTitle variant='h5'>
					{t('removeUser.removeUserDetail')}
				</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<Card>
						<CardContent
							sx={{ padding: '16px', paddingBottom: '16px!important' }}
						>
							<Grid container>
								<Box
									sx={{
										backgroundColor: 'grey.400',
										width: '80px',
										height: '80px',
										borderRadius: '50%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										position: 'relative',
										marginRight: '100px',
									}}
								>
									<AccountCircleOutlinedIcon
										sx={{
											width: '60px',
											height: '60px',
											color: 'white',
										}}
									/>
									{formData?.userType && (
										<Chip
											label={formData?.userTypeLabel}
											color={
												formData?.userType == 1
													? 'chipFilledTertiary'
													: 'chipPIRUser'
											}
											sx={{
												position: 'absolute',
												top: 0,
												left: 45,
											}}
										/>
									)}
								</Box>
								<Grid xs item>
									<Grid container alignItems='center' sx={{ height: '100%' }}>
										<Grid item xs={2}>
											<Typography variant='body2' color='#5D5E61'>
												{t('removeUser.removeReason')}
											</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant='body2'>
												{formData?.reason || '-'}
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography variant='body2' color='#5D5E61'>
												{t('removeUser.removeDate')}
											</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant='body2'>
												{formData?.createdRemoveAt || '-'}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<Grid container marginTop={'16px'}>
						<Grid item xs={6}>
							<Typography variant='subtitle1'>
								{t('common.accountInfo')}
							</Typography>
							<Grid container marginTop={'16px'}>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.username')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.username || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.phoneNumber')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.phone || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.email')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.email || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.belongedDistrict')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.district || '-'}
									</Typography>
								</Grid>
								{memberType == MemberType.member && (
									<>
										<Grid item xs={3}>
											<Typography variant='body2' color='#5D5E61'>
												{t('common.userCategory')}
											</Typography>
										</Grid>
										<Grid item xs={9}>
											<Typography variant='body2'>
												{formData?.userTypeLabel || '-'}
											</Typography>
										</Grid>
									</>
								)}
								{memberType == MemberType.member && (
									<>
										<Grid item xs={3}>
											<Typography variant='body2' color='#5D5E61'>
												{t('common.historyServiceNumber')}
											</Typography>
										</Grid>
										<Grid item xs={9}>
											<Typography variant='body2'>
												{formData?.serviceNumber || '-'}
											</Typography>
										</Grid>
									</>
								)}
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Typography variant='subtitle1'>
								{t('common.personalInfo')}
							</Typography>
							<Grid container marginTop={'16px'}>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.nickname')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.nickname || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.gender')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.genderLabel || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.dateOfBirth')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.birthday || '-'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant='body2' color='#5D5E61'>
										{t('common.personalizedWords')}
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Typography variant='body2'>
										{formData?.signature || '-'}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ padding: '20px' }}>
					{userRoles.delete && (
						<Button
							color='error'
							onClick={handleDelete}
							startIcon={<DeleteIcon />}
						>
							{t('button.inRemove')}
						</Button>
					)}
					{userRoles.recover && (
						<Button
							color='success'
							variant='contained'
							onClick={handleRecover}
							startIcon={<RotateLeftIcon />}
						>
							{t('button.recover')}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DetailDialog;
