import {
	Button,
	Divider,
	FormControl,
	FormHelperText,
	Grid,
	InputAdornment,
	Stack,
	Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import AppTextField from '@/common/components/form-inputs/AppTextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller, useFieldArray } from 'react-hook-form';
import { useStudyCourseOptions } from '@/hooks/useStudyCourseOptions';
import AppDropDownMenu from '@/common/components/form-inputs/AppDropDownMenu';
import { ChapterType, SubjectType } from '@/api/enum/studyCourse.enum';
import AppNumberTextField from '@/common/components/form-inputs/AppNumberTextField';
import SettingAnswerModal from './SettingAnswerDialog';
import { SubjectsOptionsItem } from '@/api/DTO/studyCourse/studyCourseList.interface';
import { cloneDeep } from 'lodash';
import DoneIcon from '@mui/icons-material/Done';

interface Props {
	methods: any;
}

export default function SubjectForm(props: Props) {
	const { methods } = props;
	const { t } = useTranslation();
	const { questionTypeList } = useStudyCourseOptions(['questionTypeList']);

	const {
		fields: subjectsFields,
		append: subjectsAppend,
		remove: subjectsRemove,
		update: subjectsUpdate,
	}: any = useFieldArray({
		control: methods.control,
		name: 'subjects',
	});

	const {
		fields: optionsFields,
		append: optionsAppend,
		remove: optionsRemove,
	} = useFieldArray({
		control: methods.control,
		name: 'options',
	});

	const { remove: answerListRemove } = useFieldArray({
		control: methods.control,
		name: 'answerList',
	});

	const watchType = methods.watch('type');
	const watchSubjects = methods.watch('subjects');
	const watchOptions = methods.watch('options');
	const watchChapterType = methods.watch('type');
	const watchAnswerList = methods.watch('answerList');

	useEffect(() => {
		if (watchType != 1) {
			subjectsFields.map((item: any, index: any) => {
				methods.clearErrors(`subjects[${index}].isSetAnswer`);
			});
		}
	}, [watchType]);

	const isShowDone = (kitem: SubjectsOptionsItem) => {
		const ele = (
			<InputAdornment position='end'>
				<DoneIcon sx={{ color: '#2E7D32' }} />
			</InputAdornment>
		);
		if (kitem.type == SubjectType.Radio) {
			const obj = watchAnswerList.find(
				(item: any) => item.pcode == kitem.pcode
			);
			if (obj && obj.radioAnswer == kitem.code) return ele;
		}
		if (kitem.type == SubjectType.Multiple) {
			const obj = watchAnswerList.find(
				(item: any) => item.pcode == kitem.pcode
			);
			if (obj && obj.multipleAnswer[kitem.code]) return ele;
		}
		if (kitem.type == SubjectType.Grid) {
			const obj = watchAnswerList.find(
				(item: any) => item.pcode == kitem.pcode
			);
			if (obj && obj.gridAnswer[kitem.code]) return ele;
		}
	};

	const optionsElement = (
		item: SubjectsOptionsItem,
		type: string | number,
		itemIndex: number
	) => {
		const newOptions = (watchOptions || []).filter(
			(kitem: SubjectsOptionsItem) => kitem.pcode === item.code
		);

		return (newOptions || []).map(
			(kitem: SubjectsOptionsItem, index: number) => {
				const pindex = watchOptions.findIndex(
					(a: SubjectsOptionsItem) => a.code == kitem.code
				);
				const deleteButton = (
					<IconButton
						aria-label='delete'
						sx={{ color: '#D32F2F', marginLeft: '15px' }}
						onClick={() => {
							const list = cloneDeep(watchAnswerList);

							if (kitem.type == SubjectType.Radio) {
								const ii = watchAnswerList.findIndex(
									(tt: any) => tt.pcode == kitem.pcode
								);
								list[ii] && (list[ii].radioAnswer = '');
								subjectsUpdate(itemIndex, { ...item, isSetAnswer: false });
							}
							if (kitem.type == SubjectType.Multiple) {
								const ii = watchAnswerList.findIndex(
									(tt: any) => tt.pcode == kitem.pcode
								);
								list[ii] && delete list[ii].multipleAnswer[kitem.code];
								list[ii] && (list[ii].multipleAnswerNum = 0);
								subjectsUpdate(itemIndex, { ...item, isSetAnswer: false });
							}
							// if (kitem.type == SubjectType.Linear) {
							// 	const ii = watchAnswerList.findIndex((tt: any) => tt.pcode == kitem.pcode)
							// 	delete list[ii].multipleAnswer[kitem.code]
							// }
							if (kitem.type == SubjectType.Grid) {
								const ii = watchAnswerList.findIndex(
									(tt: any) => tt.pcode == kitem.pcode
								);
								list[ii] && delete list[ii].gridAnswer[kitem.code];
								list[ii] && (list[ii].gridAnswerNum = 0);
								subjectsUpdate(itemIndex, { ...item, isSetAnswer: false });
							}
							methods.setValue('answerList', list);
							optionsRemove(pindex);
						}}
					>
						<DeleteIcon />
					</IconButton>
				);
				if (type == SubjectType.Radio) {
					return (
						<Grid item xs={12} key={kitem.code}>
							<Stack direction={'row'} alignItems={'center'}>
								<AppTextField
									control={methods.control}
									name={`options[${pindex}].text`}
									label={`${t('common.option')} ${index + 1}`}
									maxLength={100}
									showMaxLength
									InputProps={{
										endAdornment: isShowDone(kitem),
									}}
								/>
								{newOptions.length > 1 && deleteButton}
							</Stack>
						</Grid>
					);
				}
				if (type == SubjectType.Multiple) {
					return (
						<Grid item xs={12} key={kitem.code}>
							<Stack direction={'row'} alignItems={'center'}>
								<AppTextField
									control={methods.control}
									name={`options[${pindex}].text`}
									label={`${t('common.option')} ${index + 1}`}
									maxLength={100}
									showMaxLength
									InputProps={{
										endAdornment: isShowDone(kitem),
									}}
								/>
								{newOptions.length > 1 && deleteButton}
							</Stack>
						</Grid>
					);
				}
				if (type == SubjectType.Linear) {
					return (
						<Grid item xs={12} key={`${kitem.code}`}>
							<Typography
								variant='body2'
								color='common.neutral40'
								marginBottom={'5px'}
							>
								{t('practiceRecord.linearDegrees')}
							</Typography>
							<Grid container marginTop={0} alignItems={'center'} spacing={2}>
								<Grid item xs={2.5}>
									<AppNumberTextField
										control={methods.control}
										name={`options[${pindex}].linearNumMin`}
										type='number'
										nonNegative={true}
										maxLength={10}
									/>
								</Grid>
								<Grid item xs={1} textAlign={'center'}>
									到
								</Grid>
								<Grid item xs={2.5}>
									<AppNumberTextField
										control={methods.control}
										name={`options[${pindex}].linearNumMax`}
										type='number'
										nonNegative={true}
										maxLength={10}
									/>
								</Grid>

								<Grid item xs={1}></Grid>

								<Grid item xs={6}>
									<AppTextField
										control={methods.control}
										name={`options[${pindex}].linearTagMin`}
										label={t('practiceRecord.tagMin')}
										maxLength={10}
										showMaxLength
									/>
								</Grid>
								<Grid item xs={6}>
									<AppTextField
										control={methods.control}
										name={`options[${pindex}].linearTagMax`}
										label={t('practiceRecord.tagMax')}
										maxLength={10}
										showMaxLength
									/>
								</Grid>
							</Grid>
						</Grid>
					);
				}
				if (type == SubjectType.Grid) {
					return (
						<Grid item xs={6} key={kitem.code}>
							<Stack direction={'row'} alignItems={'center'}>
								<AppTextField
									control={methods.control}
									name={`options[${pindex}].text`}
									label={`${t('common.option')} ${index + 1}`}
									maxLength={10}
									showMaxLength
									InputProps={{
										endAdornment: isShowDone(kitem),
									}}
								/>
								{newOptions.length > 1 && deleteButton}
							</Stack>
						</Grid>
					);
				}
				return null;
			}
		);
	};

	const addOptions = (item: SubjectsOptionsItem, type: number | string) => {
		optionsAppend({
			code: new Date().getTime(),
			pcode: item.code,
			text: '',
			type: type,
		});
	};

	const addSubjects = () => {
		const pcode = `p${new Date().getTime()}`;
		subjectsAppend({
			code: pcode,
			title: '',
			type: 1,
		});
		optionsAppend({
			code: new Date().getTime(),
			pcode: pcode,
			text: '',
			type: 1,
		});
	};

	const removeSubjects = (index: number, item: SubjectsOptionsItem) => {
		removeOptions(item);
		subjectsRemove(index);
	};

	const removeOptions = (item: SubjectsOptionsItem, index?: number) => {
		const indices = watchOptions
			.map((kitem: { pcode: string }, kindex: any) =>
				kitem.pcode === item.code ? kindex : -1
			)
			.filter((kindex: number) => kindex !== -1);
		optionsRemove(indices);
	};

	const handleChangeSubjects = (
		value: string,
		item: SubjectsOptionsItem,
		index: number
	) => {
		removeOptions(item);
		const answerIndex = watchAnswerList.findIndex(
			(i: { pcode: string }) => i.pcode == item.code
		);
		answerIndex > -1 && answerListRemove(answerIndex);
		optionsAppend({
			code: new Date().getTime(),
			pcode: item.code,
			text: '',
			type: value,
		});
		subjectsUpdate(index, { ...watchSubjects[index], isSetAnswer: false });
	};

	return (
		<>
			{subjectsFields.map((item: SubjectsOptionsItem, index: number) => (
				<Grid item xs={12} key={item.code}>
					<Grid container spacing={3} marginBottom={2.5}>
						<Grid item xs={1}>
							<Typography variant='body1'>
								{t('studyCourse.subject')}
								{index + 1}
							</Typography>
						</Grid>
						<Grid item xs={11}>
							<FormControl
								error={
									methods.formState.errors.subjects &&
									!!methods.formState.errors.subjects[index]?.isSetAnswer
										?.message
								}
								fullWidth
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Stack direction={'row'} alignItems={'center'}>
											<AppTextField
												control={methods.control}
												name={`subjects[${index}].title`}
												label={`${t('studyCourse.subject')} ${index + 1} `}
												maxLength={100}
												showMaxLength
											/>
											<IconButton
												aria-label='delete'
												sx={{ color: '#D32F2F', marginLeft: '15px' }}
												onClick={() => removeSubjects(index, item)}
											>
												<DeleteIcon />
											</IconButton>
										</Stack>
									</Grid>

									<Controller
										control={methods.control}
										name={`subjects[${index}].type`}
										render={({ field: { onChange, value, ref } }) => (
											<>
												<Grid item xs={12}>
													<AppDropDownMenu
														handleChange={(value: string) =>
															handleChangeSubjects(value, item, index)
														}
														control={methods.control}
														name={`subjects[${index}].type`}
														label={t('common.questionType')}
														options={questionTypeList}
														required
													/>
												</Grid>

												{optionsElement(item, value, index)}
												<Stack
													direction={'row'}
													alignItems={'center'}
													sx={{ width: '100%' }}
												>
													{value != SubjectType.Linear && (
														<Button
															sx={{ marginLeft: '16px' }}
															variant='outlined'
															startIcon={<AddIcon />}
															onClick={() => addOptions(item, value)}
														>
															{t('button.addOption')}
														</Button>
													)}

													{watchChapterType == ChapterType.KnowledgeTest && (
														<SettingAnswerModal
															answerList={watchAnswerList.find(
																(i: { pcode: string }) => i.pcode == item.code
															)}
															formMethods={methods}
															subjectsItem={item}
															subjectsUpdate={subjectsUpdate}
															subjectsItemIndex={index}
															subjectsItemOptions={watchOptions}
															optionType={value}
														/>
													)}
												</Stack>
											</>
										)}
									/>
								</Grid>
								<FormHelperText>
									{methods.formState.errors.subjects &&
										methods.formState.errors.subjects[index]?.isSetAnswer
											?.message}
								</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
					<Divider />
				</Grid>
			))}

			<Grid item xs={12}>
				<Button
					startIcon={<AddIcon />}
					size='large'
					style={{
						alignItems: 'center',
						width: '100%',
						backgroundColor: '#F7F9FF',
					}}
					onClick={addSubjects}
				>
					{t('studyCourse.addSubject')}
				</Button>
			</Grid>
		</>
	);
}
