import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import {
	Badge,
	BadgeProps,
	Button,
	Grid,
	Stack,
	Tab,
	Tabs,
	Typography,
	styled,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AppMultipleDropDownMenu from '@/common/components/form-inputs/AppMultipleDropDownMenu';
import { useRouteMatch } from '@/utils/routeHelper';
import { Link, useLocation } from 'react-router-dom';
import { Option } from '@/api/DTO/common.interface';
import {
	StudyCourseCount,
	StudyCourseFilter,
} from '@/api/DTO/studyCourse/studyCourseList.interface';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		position: 'relative',
		top: 12,
		backgroundColor: '#FFDAD6',
		color: '#FF5449',
	},
	'&.badge-active .MuiBadge-badge': {
		backgroundColor: '#FF5449',
		color: '#fff',
	},
}));

interface FilterSectionProps {
	setSelectedId: any;
	typesList: Option[];
	methods: any;
	pageCount: StudyCourseCount;
}

export default function StudyCourseSummaryFilter(prop: FilterSectionProps) {
	const { setSelectedId, typesList, methods, pageCount } = prop;
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { control, watch, resetField } = useFormContext<StudyCourseFilter>();

	const routeMatch = useRouteMatch(pathname, [
		'/study-course/study-course-list/drafts',
		'/study-course/study-course-list/director-approval',
		'/study-course/study-course-list/supervisor-approval',
		'/study-course/study-course-list/awaiting-release',
		'/study-course/study-course-list/released',
		'/study-course/study-course-list/removed',
	]);
	const currentTab = routeMatch?.pattern?.path;

	const clearAllFilter = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();
		resetField('categories');
	};

	// reset button
	const watchType = watch('categories');

	const isTypeDirty = Array.isArray(watchType) && watchType.length !== 0;
	const isFilterDirty = isTypeDirty;

	const tabList = [
		{
			name: 'draft',
			tabText: t('tabs.draft'),
			tabValue: `/study-course/study-course-list/drafts`,
			tabTo: `/study-course/study-course-list/drafts`,
			countKey: 'draftCount',
		},
		{
			name: 'director-approval',
			tabText: t('tabs.awaitingDirectorApproval'),
			tabValue: `/study-course/study-course-list/director-approval`,
			tabTo: `/study-course/study-course-list/director-approval`,
			countKey: 'secondDraftCount',
		},
		{
			name: 'supervisor-approval',
			tabText: t('tabs.awaitingSupervisorApproval'),
			tabValue: `/study-course/study-course-list/supervisor-approval`,
			tabTo: `/study-course/study-course-list/supervisor-approval`,
			countKey: 'thirdDraftCount',
		},
		{
			name: 'awaiting-release',
			tabText: t('tabs.awaitingRelease'),
			tabValue: `/study-course/study-course-list/awaiting-release`,
			tabTo: `/study-course/study-course-list/awaiting-release`,
			countKey: 'readyCount',
		},
		{
			name: 'released',
			tabText: t('tabs.released'),
			tabValue: `/study-course/study-course-list/released`,
			tabTo: `/study-course/study-course-list/released`,
			countKey: 'publishCount',
		},
		{
			name: 'removed',
			tabText: t('tabs.removed'),
			tabValue: `/study-course/study-course-list/removed`,
			tabTo: `/study-course/study-course-list/removed`,
			countKey: 'pullCount',
		},
	];

	const handleTabChange = () => {
		setSelectedId([]);
		methods.reset();
	};
	return (
		<Stack>
			<Tabs
				value={currentTab}
				sx={{ borderBottom: 1, borderColor: 'divider' }}
				onChange={handleTabChange}
			>
				{currentTab &&
					tabList.map((item) => (
						<Tab
							key={item.name}
							label={
								<>
									{Number(pageCount[item.countKey]) > 0 ? (
										<StyledBadge
											badgeContent={pageCount[item.countKey]}
											className={
												currentTab.indexOf(item.name) > -1 ? 'badge-active' : ''
											}
										>
											<Typography variant='body1'>
												&nbsp;{item.tabText}
											</Typography>
										</StyledBadge>
									) : (
										<Typography variant='body1'>{item.tabText}</Typography>
									)}
								</>
							}
							value={item.tabValue}
							to={item.tabTo}
							component={Link}
						/>
					))}
			</Tabs>

			<Grid container spacing={3} alignItems='center' marginTop={0}>
				<Grid item xs={2}>
					<AppMultipleDropDownMenu
						control={control}
						name='categories'
						label={t('common.classify')}
						options={typesList}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>

				<Grid item xs>
					<Button
						variant='text'
						startIcon={<RotateLeftOutlinedIcon />}
						disabled={!isFilterDirty}
						onClick={clearAllFilter}
					>
						{t('button.reset')}
					</Button>
				</Grid>
			</Grid>
		</Stack>
	);
}
