import { yupResolver } from '@hookform/resolvers/yup';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Chip,
	Divider,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppFieldView, {
	AppFormLabel,
} from '@/common/components/form-view/AppFieldView';
import { MentalHealthInfo } from '@/api/DTO/mentalHealthInfo/mentalHealthInfo.interface';
import { FileInfo } from '@/api/DTO/common.interface';
import { useMentalHealthOptions } from '@/hooks/useMentalHealthOptions';
import { useGetArticlesDistrictsQuery } from '@/api/common';
import { useEffect, useMemo, useState } from 'react';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useSaveArticlesMutation } from '@/api/mentalHealthInfo/mentalHealthApiSlice';
import { cloneDeep } from 'lodash';
import MentalHealthContentInfo from './MentalHealthContentInfo';
import { ArticleCategory } from '@/api/enum/common.enum';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';

interface CreateMentalHealthInfoPageProps {
	categoryId: string | number; // 對應左側菜單
}

export default function CreateMentalHealthInfoPage({
	categoryId,
}: CreateMentalHealthInfoPageProps) {
	const { t } = useTranslation();
	const [saveArticles] = useSaveArticlesMutation();
	const { articleTypeList } = useMentalHealthOptions(['articleTypeList']);
	const navigate = useNavigate();
	const showModal = useModal();
	const [pdfUrl, setPdfUrl] = useState<FileInfo | null>(null);
	const [audioUrl, setAudioUrl] = useState<FileInfo | null>(null);
	const [thumbnailUrl, setThumbnailUrl] = useState<FileInfo | null>(null);
	const [images, setImages] = useState<FileInfo[] | null>(null);
	const [needConfirm, setNeedConfirm] = useState(false);

	const { data: districtsData } = useGetArticlesDistrictsQuery(null);
	const districtsOptions = useMemo(() => {
		const arr = (districtsData?.data || []).map(
			(item: { type: any; desc: string }) => {
				return {
					id: item.type,
					name: item.desc,
				};
			}
		);
		return arr || [];
	}, [districtsData]);

	const formSchema = Yup.object().shape({
		category: Yup.string().required(t('errorMessage.pleaseSelect')),
		district: Yup.string().when('category', {
			is: String(ArticleCategory.latestNews),
			then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
		}),
		subcategory: Yup.string().when('category', {
			is: String(ArticleCategory.knowMentalHealth),
			then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
		}),
		pushTime: Yup.string()
			.nullable()
			.test('is-valid-date', t('errorMessage.pleaseSelect'), (value) => {
				if (value && typeof value === 'string') {
					const date = new Date(value);
					return !isNaN(date.getTime());
				}
				return true;
			}),
		pullTime: Yup.string()
			.nullable()
			.test('is-valid-date', t('errorMessage.pleaseSelect'), (value) => {
				if (value && typeof value === 'string') {
					const date = new Date(value);
					return !isNaN(date.getTime());
				}
				return true;
			}),
		title: Yup.string()
			.required(t('errorMessage.required'))
			.max(20, t('errorMessage.max20', { max: 20 })),
		registrationLink: Yup.string().when('linkAttached', (linkAttached) => {
			if (linkAttached[0]) {
				return Yup.string().required(t('errorMessage.required'));
			} else {
				return Yup.string().notRequired();
			}
		}),
		thumbnail: Yup.object()
			.nullable()
			.test(
				'required',
				t('errorMessage.pleaseUpload'),
				(value) => value !== undefined && value !== null
			),
		// tags: Yup.array()
		// 	.min(1, t('errorMessage.pleaseSelect'))
		// 	.required(t('errorMessage.pleaseSelect')),
		images: Yup.array()
			.nullable()
			.when('type', {
				is: '1',
				then: (schema) =>
					schema
						.min(1, t('errorMessage.pleaseUpload'))
						.required(t('errorMessage.pleaseUpload')),
			}),
		pdf: Yup.string()
			.nullable()
			.when('type', {
				is: '2',
				then: (schema) => schema.required(t('errorMessage.pleaseUpload')),
			}),
		videoLink: Yup.string().when('type', {
			is: '3',
			then: (schema) =>
				schema
					.required(t('errorMessage.required'))
					.matches(
						/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w-]*)(&(amp;)?[\w?=]*)?/,
						t('errorMessage.invalidYoutubeLink')
					),
		}),
		audio: Yup.string()
			.nullable()
			.when('type', {
				is: '4',
				then: (schema) => schema.required(t('errorMessage.pleaseUpload')),
			}),
		content: Yup.string().required(t('errorMessage.required')),
	});
	const defaultValues: DefaultValues<MentalHealthInfo> = {
		type: '0', // 格式
		category: categoryId, // 资讯类别，对应健康模块的左侧菜单
	};
	const methods = useForm<MentalHealthInfo>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});
	useEffect(() => {
		const subscription = methods.watch((value, { name }) => {
			if (name == 'pushTime') methods.setValue('pullTime', '');
			if (!needConfirm) setNeedConfirm(true);
		});

		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const onSubmit: SubmitHandler<MentalHealthInfo> = (data) => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('mentalHealthInfo.addMentalHealth')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('mentalHealthInfo.isAddMentalHealth')}
						</Typography>
					</>
				}
				cancellationText={t('button.cancel')}
				confirmationText={t('button.sure')}
				confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
				onConfirm={async () => {
					const newData = cloneDeep(data);
					newData.district = {
						type: data.district,
					};
					data.pdf && (newData.pdf = pdfUrl);
					data.audio && (newData.audio = audioUrl);
					const res = await saveArticles(newData);

					const tipTitle =
						categoryId == ArticleCategory.latestNews
							? t('snackbar.latestNewsAddSuccess')
							: categoryId == ArticleCategory.knowMentalHealth
							? t('snackbar.knowMentalHealthAddSuccess')
							: categoryId == ArticleCategory.storyShare
							? t('snackbar.lifeStorySharingAddSuccess')
							: categoryId == ArticleCategory.columnShare
							? t('snackbar.columnSharingAddSuccess')
							: '';
					if ('data' in res) {
						enqueueSnackbar(tipTitle, {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						navigate(-1);
					}
				}}
			/>
		));
	};

	const handleClose = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('communityResources.giveUpFix')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('communityResources.sureGiveUpFix')}
						</Typography>
						<Typography variant='body1'>
							{t('communityResources.fixAllReset')}
						</Typography>
					</>
				}
				cancellationText={t('button.giveUpFix')}
				confirmationText={t('button.saveAndLeave')}
				confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
				onConfirm={() => methods.handleSubmit(onSubmit)()}
				onCancel={() => navigate(-1)}
			/>
		));
	};

	return (
		<>
			<Typography variant='h4'>{t('common.addInfo')}</Typography>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid container alignItems='stretch' columnSpacing={3} marginTop={3}>
						<Grid item xs display='flex'>
							<Card sx={{ width: '100%' }}>
								<CardContent>
									<Stack>
										<Typography variant='h5'>{t('common.setup')}</Typography>
										<Grid container spacing={3} marginTop={0} marginBottom={3}>
											<Grid item xs={6}>
												<AppFieldView
													label={t('common.createDate')}
													value={'-'}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppFormLabel>{t('common.status')}</AppFormLabel>
												<Box>
													<Chip
														label={t('common.draft')}
														color='chipGrey'
														variant='outlined'
													/>
												</Box>
											</Grid>
										</Grid>

										<Divider sx={{ marginBottom: '20px' }} />

										<MentalHealthContentInfo
											categoryId={categoryId}
											methods={methods}
											articleTypeList={articleTypeList}
											pdfUrl={pdfUrl}
											setPdfUrl={setPdfUrl}
											audioUrl={audioUrl}
											setAudioUrl={setAudioUrl}
											thumbnailUrl={thumbnailUrl}
											setThumbnailUrl={setThumbnailUrl}
											images={images}
											setImages={setImages}
											districtsOptions={districtsOptions}
										/>
									</Stack>
								</CardContent>
								<CardActions sx={{ justifyContent: 'end' }}>
									<Button onClick={handleClose}>{t('button.cancel')}</Button>
									<Button
										type='submit'
										variant='contained'
										disabled={!needConfirm}
									>
										{t('button.add')}
									</Button>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</>
	);
}
