import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LocalDataGrid from '../../../common/components/dataGrid/LocalDataGrid';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useGetVouchersQuery } from '../../../api/shop/shopApiSlice';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';

export default function MemberRewardSummaryPage() {
	const { t } = useTranslation();
	const { data: vouchersData } = useGetVouchersQuery();

	const showModal = useModal();

	useEffect(() => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('common.confidentialContent')}
				titleProps={{ variant: 'h6' }}
				description={
					<Typography variant='body1'>
						{t('common.confidentialContentMsg')}
					</Typography>
				}
				cancellationText={''}
				confirmationText={t('button.knew')}
				confirmationButtonProps={{ variant: 'contained' }}
			/>
		));
	}, []);

	const transformResponse = useCallback((response?: any[]) => {
		if (response == null) return;
		return response.map((item, index) => ({
			id: index,
			name: item.name,
			voucherReward: item.description,
			price: item.priceTag,
			level: item.level,
		}));
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: t('item.upgradeReward'),
			flex: 25,
			sortable: false,
		},
		{
			field: 'voucherReward',
			headerName: t('item.voucherReward') + ' ' + t('item.notDisplayInApp'),
			flex: 25,
			sortable: false,
		},
		{
			field: 'price',
			headerName: t('item.value(HKD)'),
			flex: 25,
			sortable: false,
			renderCell: (params) => {
				return <Box>{params.row.price}</Box>;
			},
		},
		{
			field: 'level',
			headerName: t('item.rewardLevel'),
			flex: 25,
			sortable: false,
			renderCell: (params) => (
				<>
					{t('item.level')}
					{params.row.level}
				</>
			),
		},
	];

	return (
		<>
			<Typography variant='h4'>{t('item.programMemberReward')}</Typography>

			<Card sx={{ marginTop: 3 }}>
				<CardContent>
					<LocalDataGrid
						rows={transformResponse(vouchersData?.data) ?? []}
						columns={columns}
						rowCount={vouchersData?.data?.length ?? 0}
						getRowId={(row) => row.id}
						pagination={false}
					/>
				</CardContent>
			</Card>
		</>
	);
}
