import { useGetMeQuery } from '@/api/userApiSlice';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { selectMyInfo, setMyInfo } from '../pages/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const UserContext = createContext<any>(null);

const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const userInfo = useSelector(selectMyInfo);
	const dispatch = useDispatch();

	const {
		data: getMeResponse,
		isLoading,
		isSuccess,
		refetch: userInfoRefetch,
	} = useGetMeQuery(null, { refetchOnMountOrArgChange: true });
	useEffect(() => {
		if (!isLoading && getMeResponse) {
			dispatch(setMyInfo(getMeResponse.data));

			// setUserInfo({
			// 	nickname: '113',
			// 	role: 'ROLE_SUPPORTING_STAFF',
			// });
		}
	}, [isLoading, getMeResponse]);

	return (
		<UserContext.Provider
			value={{ userInfo, isLoading, isSuccess, userInfoRefetch }}
		>
			{children}
		</UserContext.Provider>
	);
};

export { UserContext, UserProvider };
