import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	Box,
	Chip,
	FormControl,
	FormHelperText,
	Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
// import AddRoomTagDialog from './AddRoomTagDialog';
import { Tag } from '@/api/DTO/mentalHealthInfo/mentalHealthInfo.interface';
import AddCustomTagModal from '../modal/AddCustomTagModal';
import { useGetArticlesTagsQuery } from '@/api/mentalHealthInfo/mentalHealthApiSlice';
import { AppFormLabel } from '../form-view/AppFieldView';

export interface AppCustomTagsProps {
	label?: string;
}

export default function AppCustomTags<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	...props
}: UseControllerProps<TFieldValues, TName> & AppCustomTagsProps) {
	const { t } = useTranslation();
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	const selectedTags: Tag[] = field.value ?? [];

	const onChange = field.onChange;

	const { data: getTagsResponse, refetch } = useGetArticlesTagsQuery(null);

	const selectedTagsId: string[] = [];

	selectedTags.map((tag) => {
		selectedTagsId.push(tag.id);
	});

	const handleAdd = (tag: Tag) => {
		const arr: Tag[] = selectedTags ?? [];
		const found = arr.some((el) => el.id === tag.id);
		if (!found) {
			arr.push(tag);
			onChange(arr);
		} else {
			onChange(arr.filter((item) => item.id !== tag.id));
		}
	};

	// add tag dialog

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (addedTag?: Tag) => {
		setOpen(false);
		if (addedTag) {
			handleAdd(addedTag);
		}
	};

	const handleClick = (addedTag?: Tag) => () => {
		if (addedTag) {
			handleAdd(addedTag);
		}
	};

	return (
		<FormControl error={!!fieldState.error?.message} fullWidth>
			<AppFormLabel>{label}</AppFormLabel>

			<Box
				sx={{
					paddingBottom: '20px',
				}}
			>
				{getTagsResponse?.data.map((option: Tag) => (
					<Chip
						key={option.id}
						label={option.name}
						onClick={handleClick(option)}
						size='medium'
						variant={selectedTagsId.includes(option.id) ? 'filled' : 'outlined'}
						color={
							selectedTagsId.includes(option.id)
								? 'chipFilledTertiary'
								: 'chipGrey'
						}
						sx={{
							marginRight: '6px',
						}}
						clickable
						{...field}
					/>
				))}

				<Chip
					label={
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<AddIcon />
							<Typography marginLeft='4px' fontSize='12px'>
								{t('button.add')}
							</Typography>
						</Box>
					}
					size='medium'
					variant='outlined'
					color='chipGrey'
					sx={{
						marginRight: '6px',
					}}
					onClick={handleClickOpen}
				/>
				<FormHelperText>{errorMessage}</FormHelperText>
			</Box>
			<AddCustomTagModal
				open={open}
				onClose={handleClose}
				refetchGetTags={refetch}
			/>
		</FormControl>
	);
}
