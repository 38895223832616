import { apiSlice } from '../apiSlice';
import { SuccessResponse, Paginated } from '../DTO/common.interface';
import {
	NoticePageFilter,
	NoticePageRes,
	NoticeSend,
	NoticeSendBatch,
} from '../DTO/notice/notice.interface';

export const noticeApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getNoticePage: builder.query<
			SuccessResponse<Paginated<NoticePageRes>>,
			NoticePageFilter
		>({
			query: (body) => ({
				url: `/web/notice/page`,
				method: 'POST',
				body,
			}),
		}),

		sendOrgNotice: builder.mutation<SuccessResponse<null>, NoticeSend>({
			query: (body) => ({
				url: `/web/notice/org/send`,
				method: 'POST',
				body,
			}),
		}),

		sendFreeNotice: builder.mutation<SuccessResponse<null>, NoticeSend>({
			query: (body) => ({
				url: `/web/notice/free/send`,
				method: 'POST',
				body,
			}),
		}),

		sendOrgNoticeBatch: builder.mutation<
			SuccessResponse<null>,
			NoticeSendBatch
		>({
			query: (body) => ({
				url: `/web/notice/org/send-batch`,
				method: 'POST',
				body,
			}),
		}),

		sendFreeNoticeBatch: builder.mutation<
			SuccessResponse<null>,
			NoticeSendBatch
		>({
			query: (body) => ({
				url: `/web/notice/free/send-batch`,
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const {
	useGetNoticePageQuery,
	useSendOrgNoticeMutation,
	useSendFreeNoticeMutation,
	useSendOrgNoticeBatchMutation,
	useSendFreeNoticeBatchMutation,
} = noticeApiSlice;
