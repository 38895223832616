import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { useAppDispatch } from '../../../redux-hooks';
import { hideModal } from './modalSlice';
import { useTranslation } from 'react-i18next';

interface ApiErrorModalProps {
	title: string;
	content: string;
}

const ApiErrorModal = (props: ApiErrorModalProps) => {
	const dispatch = useAppDispatch();

	const { t } = useTranslation();

	const handleClose = () => {
		dispatch(hideModal());
	};
	return (
		<Dialog
			open={props.title != null}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			fullWidth
		>
			<DialogTitle id='alert-dialog-title' variant='h6'>
				{t('common.systemError')}
			</DialogTitle>
			<DialogContent>{props.content}</DialogContent>
			<DialogActions>
				<Button type='submit' variant='contained' onClick={handleClose}>
					{t('button.back')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ApiErrorModal;
