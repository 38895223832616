import {
	Paginated,
	PaginationAndSorting,
	SuccessResponse,
} from './DTO/common.interface';
import { UserMissionListResDTO } from './DTO/shop/shop.interface';
import { apiSlice } from './apiSlice';

// temp
export const mockApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		filterUserMissionList: builder.query<
			SuccessResponse<Paginated<UserMissionListResDTO>>,
			PaginationAndSorting
		>({
			query: (body) => ({
				url: `/web/mission/page`,
				method: 'POST',
				body,
			}),
		}),
		filterUserRelativeList: builder.query<
			SuccessResponse<Paginated<any>>,
			PaginationAndSorting
		>({
			query: (body) => ({
				url: `http://192.168.1.148:3002/admin/web/user/relative-list`,
				method: 'POST',
				body,
			}),
		}),
		getRelativeDetail: builder.query<SuccessResponse<any>, string>({
			query: (userId) => ({
				url: `http://192.168.1.148:3002/admin/web/user/relative-list/${userId}`,
			}),
		}),
		deleteRelative: builder.mutation<SuccessResponse<any>, string>({
			query: (relativeId) => ({
				url: `http://192.168.1.148:3002/admin/web/user/relative-list/${relativeId}`,
				method: 'DELETE',
			}),
		}),
		blockRelative: builder.mutation<SuccessResponse<any>, string>({
			query: (relativeId) => ({
				url: `http://192.168.1.148:3002/admin/web/user/relative-list/${relativeId}/block`,
				method: 'PUT',
			}),
		}),
		unblockRelative: builder.mutation<SuccessResponse<any>, string>({
			query: (relativeId) => ({
				url: `http://192.168.1.148:3002/admin/web/user/relative-list/${relativeId}/unblock`,
				method: 'PUT',
			}),
		}),
		addRelativeOrgUser: builder.mutation<SuccessResponse<any>, any>({
			query: (body) => ({
				url: `http://192.168.1.148:3002/admin/web/relative/org-user`,
				method: 'POST',
				body,
			}),
		}),
		addRelativeFreeUser: builder.mutation<SuccessResponse<any>, any>({
			query: (body) => ({
				url: `http://192.168.1.148:3002/admin/web/relative/free-user`,
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const {
	useFilterUserRelativeListQuery,
	useGetRelativeDetailQuery,
	useDeleteRelativeMutation,
	useBlockRelativeMutation,
	useUnblockRelativeMutation,
	useAddRelativeOrgUserMutation,
	useAddRelativeFreeUserMutation,
	useFilterUserMissionListQuery,
} = mockApiSlice;
