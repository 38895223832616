import EditorDisplay from '../editor/EditorDisplay';
import { AppFormLabel } from './AppFieldView';

interface RichTextDisplayProps {
	label?: string;
	editorStateString?: string;
}

export default function RichTextDisplay({
	label,
	editorStateString: htmlString,
}: RichTextDisplayProps) {
	return (
		<>
			<AppFormLabel>{label}</AppFormLabel>
			<EditorDisplay editorStateString={htmlString} isTransparentBackground />
		</>
	);
}
