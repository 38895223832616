import Link, { LinkProps } from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from '../../utils/routeHelper';

// You need to provide the routes in descendant order.
// This means that if you have nested routes like:
// users, users/new, users/edit.
// Then the order should be ['users/add', 'users/edit', 'users'].
const breadcrumbNameMap: { [key: string]: string } = {
	'/reports/report-list': 'common.reportList',
	'/users/member-user/add': 'common.createMemberUser',
	'/users/member-user/:userId': 'common.userDetail',
	'/users/member-user/:userId/info': 'tabs.userInfo',
	'/users/member-user/:userId/stat': 'tabs.stat',
	'/users/member-user/:userId/noti-history': 'tabs.notificationHistory',
	'/users/member-user/:userId/practice-record': 'tabs.practiceRecord',
	'/users/member-user/:userId/relative-list': 'tabs.relativeList',
	'/users/member-user/:userId/practice-record/practice-record-form':
		'button.distributionExercise',
	'/users/member-user/:userId/practice-record/practice-record-detail':
		'practiceRecord.exerciseDetail',
	'/users/member-user': 'common.memberUser',
	'/staffs/staff-list': 'common.staffList',
	'/staffs/staff-list/add': 'common.addStaffAccount',
	'/staffs/staff-list/info/:userId': 'common.staffInfo',
	'/users/non-member-user/add': 'common.createNonMemberUser',
	'/users/non-member-user/:userId': 'common.userDetail',
	'/users/non-member-user': 'common.nonMemberUser',
	'/items/member-reward': 'item.programMemberReward',
	'/items/virtual-props-reward': 'item.virtualPropsReward',
	'/items/user-coupon-list': 'item.userListOfCashCoupons',
	'/items/user-mission-list': 'item.userMissionList',
	'/users/application': 'common.allApplications',
	'/surveys/survey-list': 'survey.surveyList',
	'/surveys/survey-list/add': 'survey.createSurvey',
	'/surveys/survey-list/info/:surveyId': 'survey.surveyDetail',
	'/resources/resources-list': 'communityResources.resourcesList',
	'/resources/resources-list/add':
		'communityResources.createCommunityResources',
	'/resources/resources-list/info':
		'communityResources.communityResourcesDetail',

	'/study-course/study-course-list': 'studyCourse.studyCourseList',
	'/study-course/study-course-list/:studyCourseId/info':
		'studyCourse.studyCourseListDetail',
	'/study-course/study-course-list/add': 'studyCourse.createStudyCourse',
	'/study-course/study-course-list/:studyCourseId/info/addChapter':
		'studyCourse.createChapter',
	'/study-course/study-course-list/:chapterId/info/chapterDetail':
		'studyCourse.chapterDetail',

	'/mental-health-info/latest-news': 'mentalHealthInfo.latestNews',
	'/mental-health-info/latest-news/add': 'common.addInfo',
	'/mental-health-info/latest-news/info': 'mentalHealthInfo.latestInfoDetails',
	'/mental-health-info/latest-news/order': 'mentalHealthInfo.infoOrder',

	'/mental-health-info/know-mental-health': 'mentalHealthInfo.knowMentalHealth',
	'/mental-health-info/know-mental-health/add': 'common.addInfo',
	'/mental-health-info/know-mental-health/info':
		'mentalHealthInfo.knowMentalHealthDetails',
	'/mental-health-info/know-mental-health/order': 'mentalHealthInfo.infoOrder',

	'/mental-health-info/story-share': 'mentalHealthInfo.lifeStorySharing',
	'/mental-health-info/story-share/add': 'common.addInfo',
	'/mental-health-info/story-share/info':
		'mentalHealthInfo.lifeStorySharingDetails',
	'/mental-health-info/story-share/order': 'mentalHealthInfo.infoOrder',

	'/mental-health-info/column-share': 'mentalHealthInfo.columnSharing',
	'/mental-health-info/column-share/add': 'common.addInfo',
	'/mental-health-info/column-share/info':
		'mentalHealthInfo.columnSharingDetails',
	'/mental-health-info/column-share/order': 'mentalHealthInfo.infoOrder',

	'/users/remove-user': 'removeUser.removeUserList',
};

interface LinkRouterProps extends LinkProps {
	to: string;
	replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
	return <Link {...props} component={RouterLink} />;
}

export default function RouterBreadcrumbs() {
	const { t } = useTranslation();
	const location = useLocation();
	const pathnames = location.pathname.split('/').filter((x) => x);

	let lastPath = '';

	for (let index = pathnames.length - 1; index >= 0; index--) {
		const to = `/${pathnames.slice(0, index + 1).join('/')}`;
		const pathMatch = useRouteMatch(to, Object.keys(breadcrumbNameMap));
		if (pathMatch) {
			lastPath = pathMatch?.pattern?.path;
			break;
		}
	}

	return (
		<Breadcrumbs aria-label='breadcrumb' sx={{ marginBottom: 3 }}>
			<LinkRouter underline='hover' color='inherit' to='/'>
				{t('common.homePage')}
			</LinkRouter>

			{pathnames.map((_, index) => {
				const to = `/${pathnames.slice(0, index + 1).join('/')}`;
				const pathMatch = useRouteMatch(to, Object.keys(breadcrumbNameMap));
				const last = pathMatch?.pattern?.path == lastPath;

				return (
					pathMatch &&
					(last ? (
						<Typography color='text.primary' key={to}>
							{t(breadcrumbNameMap[pathMatch?.pattern?.path])}
						</Typography>
					) : (
						<LinkRouter underline='hover' color='inherit' to={to} key={to}>
							{t(breadcrumbNameMap[pathMatch?.pattern?.path])}
						</LinkRouter>
					))
				);
			})}
		</Breadcrumbs>
	);
}
