import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';

interface SingleCheckboxProps {
	label: string;
}

export default function SingleCheckbox<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	...props
}: SingleCheckboxProps &
	UseControllerProps<TFieldValues, TName> &
	CheckboxProps) {
	const { field } = useController(props);

	return (
		<FormControlLabel
			key={field.value}
			control={<Checkbox {...field} checked={field.value} />}
			label={label}
		/>
	);
}
