import dayjs from 'dayjs';

type Valuable<T> = {
	[K in keyof T as T[K] extends null | undefined ? never : K]: T[K];
};

export function removeObjKeyWithEmptyValue<T extends {}, V = Valuable<T>>(
	obj: T
): V {
	return Object.fromEntries(
		Object.entries(obj).filter(([, v]) => {
			if (Array.isArray(v)) {
				return v.length > 0;
			}
			return v != null;
		})
	) as V;
}

export function toNullIfEmptyArray<T extends {}, V = Valuable<T>>(
	array: T
): V | undefined {
	if (!Array.isArray(array) || !array.length) {
		return undefined;
	}
	return array as V;
}

export function pluralize(
	count: number,
	noun: string,
	suffix = 's',
	specialNoun?: string
) {
	if (specialNoun) {
		return `${count} ${count > 1 ? specialNoun : noun}`;
	} else {
		return `${count} ${noun}${count > 1 ? suffix : ''}`;
	}
}

export function isJson(str: string) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export function getDatesInRange(
	startDateString?: string,
	endDateString?: string
) {
	if (
		startDateString &&
		dayjs(startDateString).isValid() &&
		endDateString &&
		dayjs(endDateString).isValid()
	) {
		const startDate = dayjs(startDateString);
		const endDate = dayjs(endDateString);

		const dates = [];

		let currentDate = startDate;
		while (
			currentDate.isBefore(endDate, 'day') ||
			currentDate.isSame(endDate, 'day')
		) {
			dates.push(currentDate);
			currentDate = currentDate.add(1, 'day');
		}
		return dates;
	} else return [];
}

export function getMonthsInRange(
	startDateString?: string,
	endDateString?: string
) {
	if (
		startDateString &&
		dayjs(startDateString).isValid() &&
		endDateString &&
		dayjs(endDateString).isValid()
	) {
		const startDate = dayjs(startDateString);
		const endDate = dayjs(endDateString);

		const monthes = [];

		let currentMonth = startDate;
		while (
			currentMonth.isBefore(endDate, 'month') ||
			currentMonth.isSame(endDate, 'month')
		) {
			if (
				startDateString &&
				dayjs(startDateString).isValid() &&
				endDateString &&
				dayjs(endDateString).isValid()
			) {
				const startDate = dayjs(startDateString);
				const endDate = dayjs(endDateString);

				const dates = [];

				let currentDate = startDate;
				while (
					currentDate.isBefore(endDate, 'day') ||
					currentDate.isSame(endDate, 'day')
				) {
					if (currentMonth.month() === currentDate.month())
						dates.push(currentDate);

					currentDate = currentDate.add(1, 'day');
				}

				monthes.push(dates);
			}
			currentMonth = currentMonth.add(1, 'month');
		}
		return monthes;
	} else return [];
}

export function showLabel(
	value: any,
	list: any = [],
	attrValue = 'id',
	attrLabel = 'name'
) {
	const obj = list.find(
		(item: { [x: string]: any }) => item[attrValue] === value
	);
	return obj ? obj[attrLabel] : value;
}
