import { useLazyGetUserCommunityMapImagesQuery } from '@/api/statistics/statApiSlice';
import ImageMapDropDownMenu from '@/pages/users/component/user-stat/components/ImageMapDropDownMenu';
import Close from '@mui/icons-material/Close';
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ViewUploadedImagesModalProps {
	userId: string;
	children: React.ReactNode;
}

const ViewUploadedImagesModal = (props: ViewUploadedImagesModalProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { userId } = props;

	const handleClose = () => {
		setOpen(false);
	};

	const [getUserCommunityMapImages, { data: userCommunityMapImages }] =
		useLazyGetUserCommunityMapImagesQuery();

	const handleChange = (value: string) => {
		getUserCommunityMapImages({
			userId,
			communityEnum: Number(value),
		});
	};

	return (
		<>
			<Box onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
				{props.children}
			</Box>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='sm'
				fullWidth
			>
				<DialogTitle variant='h5'>
					{t('stat.listOfUploadingPictures')}
				</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<Grid container spacing={3} marginTop={0}>
						<Grid item xs={12}>
							<ImageMapDropDownMenu
								userId={props.userId}
								label={t('common.district')}
								onChange={handleChange}
							/>
						</Grid>
						{userCommunityMapImages?.data?.map((image: any, index: number) => (
							<Grid item xs={6} key={index}>
								<img
									src={image.thumbnail}
									alt={image.thumbnail}
									width='100%'
									style={{
										maxHeight: '176px',
										borderRadius: '5px',
										objectFit: 'cover',
									}}
								/>
								<Typography variant='body2'>{image.name}</Typography>
							</Grid>
						))}
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ViewUploadedImagesModal;
