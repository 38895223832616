import App from './App';
import './i18n';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	redirect,
} from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import {
	ArticleCategory,
	ArticlestatusType,
	ArticleMenu,
	CommunityResourcesType,
	UserType,
	StudyCourseType,
	MemberType,
} from '@/api/enum/common.enum';
import { UserProvider } from '@/context/userContext';
import LoginPage from './pages/auth/LoginPage';
import NavigationDrawer from './common/components/NavigationDrawer';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import EmailVerifyPage from './pages/auth/EmailVerifyPage';
import RequireAuth from './pages/auth/RequireAuth';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import NavigateWithState from './common/components/NavigateWithState';
import MemberUserSummaryPage from './pages/users/member-users/MemberUserSummaryPage';
import CreateMemberUserPage from './pages/users/member-users/CreateMemberUserPage';
import MemberUserContainer from './pages/users/member-users/MemberUserContainer';
import StaffListSummaryPage from './pages/staffs/staff-list/staffListSummaryPage';
import CreateStaffPage from './pages/staffs/staff-list/createStaffPage';
import StaffInfoPage from './pages/staffs/staff-list/staffInfoPage';
import NonMemberUserSummaryPage from './pages/users/non-member-users/NonMemberUserSummaryPage';
import NonMemberUserContainer from './pages/users/non-member-users/NonMemberUserContainer';
import CreateNonMemberUserPage from './pages/users/non-member-users/CreateNonMemberUserPage';
import ViewUserNotiHistoryPage from './pages/users/component/noti-history/ViewUserNotiHistoryPage';
import ViewUserStatPage from './pages/users/component/user-stat/ViewUserStatPage';
import ViewUserInfoPage from './pages/users/component/user-info/ViewUserInfoPage';
import MemberRewardSummaryPage from './pages/items/member-reward/MemberRewardSummaryPage';
import VirtualPropsRewardSummaryPage from './pages/items/virtual-props-reward/VirtualPropsRewardSummaryPage';
import UserListOfCashCouponSummaryPage from './pages/items/user-coupon-list/UserListOfCashCouponSummaryPage';
import ApplicationTab from './pages/users/application/applicationTab';
import JoinMemberPage from './pages/users/application/joinMemberPage';
import OutMemberPage from './pages/users/application/outMemberPage';
import JoinCentrePage from './pages/users/application/joinCentrePage';
import OutCentrePage from './pages/users/application/outCentrePage';
import SurveyListSummaryPage from './pages/surveys/survey-list/SurveyListSummaryPage';
import CreateSurveyPage from './pages/surveys/survey-list/CreateSurveyPage';
import SurveyInfoPage from './pages/surveys/survey-list/SurveyInfoPage';
import CreateResourcesPage from './pages/resources/resources-list/createResourcesPage';
import ResourcesInfoPage from './pages/resources/resources-list/resourcesInfoPage';
import MentalHealthInfoSummaryPage from './pages/mental-health-info/components/MentalHealthInfoSummaryPage';
import CreateMentalHealthInfoPage from './pages/mental-health-info/components/CreateMentalHealthInfoPage';
import MentalHealthInfoPage from './pages/mental-health-info/components/MentalHealthInfoPage';
import ResourcesSummaryPage from './pages/resources/resources-list/resourcesSummaryPage';
import Page404 from './pages/404';
import MentalHealthInfoOrderPage from './pages/mental-health-info/components/MentalHealthInfoOrderPage';
import VouchersPage from './pages/app/VouchersPage';
import PracticeRecordPage from './pages/users/component/practice-record/PracticeRecordPage';
import PracticeRecordForm from './pages/users/component/practice-record/PracticeRecordForm';
import PracticeRecordDetail from './pages/users/component/practice-record/PracticeRecordDetal';
import StudyCourseSummaryPage from './pages/study-course/study-course-list/StudyCourseSummaryPage';
import CreateStudyCoursePage from './pages/study-course/study-course-list/CreateStudyCoursePage';
import StudyCourseInfoPage from './pages/study-course/study-course-list/StudyCourseInfoPage';
import CreateChapterPage from './pages/study-course/study-course-list/CreateChapterPage';
import ChapterInfoPage from './pages/study-course/study-course-list/ChapterInfoPage';
import RemoveUserSummarPage from './pages/users/remove-user/RemoveUserSummarPage';
import RelativeListSummaryPage from './pages/users/component/relative-list/RelativeListSummaryPage';
import ReportListSummaryPage from './pages/reports/report-list/ReportListSummaryPage';
import UserMissionListSummaryPage from './pages/items/user-mission-list/UserMissionListSummaryPage';

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route path='' element={<App />}>
				<Route path='*' element={<Page404 />} />
				<Route path='login' element={<Outlet />}>
					<Route index element={<LoginPage />} />
				</Route>
				<Route path='forgot-password' element={<Outlet />}>
					<Route index element={<ForgotPasswordPage />} />
					<Route path='verify' element={<EmailVerifyPage />} />
					<Route path='reset' element={<ResetPasswordPage />} />
				</Route>
				<Route element={<RequireAuth></RequireAuth>}>
					<Route path='app'>
						<Route index element={<NavigateWithState to='vouchers' />} />
						<Route path='vouchers' element={<VouchersPage />} />
					</Route>
					<Route
						path=''
						element={
							<UserProvider>
								<NavigationDrawer />
							</UserProvider>
						}
					>
						<Route path='reports'>
							<Route path='report-list'>
								<Route index element={<ReportListSummaryPage />} />
							</Route>
						</Route>
						<Route path='users'>
							<Route path='member-user'>
								<Route index element={<MemberUserSummaryPage />} />
								<Route path='add' element={<CreateMemberUserPage />} />
								<Route
									path=':userId'
									element={<NavigateWithState to='info' />}
								/>
								<Route path=':userId/*' element={<MemberUserContainer />}>
									<Route path='info' element={<ViewUserInfoPage />} />
									<Route
										path='stat'
										element={<ViewUserStatPage userType={UserType.member} />}
									/>
									<Route
										path='noti-history'
										element={<ViewUserNotiHistoryPage />}
									/>
									<Route
										path='relative-list'
										element={<RelativeListSummaryPage />}
									/>
									<Route
										path='practice-record/'
										element={<PracticeRecordPage />}
									/>

									<Route
										path='practice-record/practice-record-form/:templateId'
										element={<PracticeRecordForm />}
									/>
									<Route
										path='practice-record/practice-record-detail/:exerciseId'
										element={<PracticeRecordDetail />}
									/>
								</Route>
							</Route>
							<Route path='non-member-user'>
								<Route index element={<NonMemberUserSummaryPage />} />
								<Route path='add' element={<CreateNonMemberUserPage />} />
								<Route
									path=':userId'
									element={<NavigateWithState to='info' />}
								/>
								<Route path=':userId/*' element={<NonMemberUserContainer />}>
									<Route path='info' element={<ViewUserInfoPage />} />
									<Route
										path='stat'
										element={<ViewUserStatPage userType={UserType.nonMenber} />}
									/>
									<Route
										path='noti-history'
										element={<ViewUserNotiHistoryPage />}
									/>
									<Route
										path='relative-list'
										element={<RelativeListSummaryPage />}
									/>
								</Route>
							</Route>
							<Route path='application'>
								<Route
									index
									element={<NavigateWithState to='tab/join-member' />}
								/>
								<Route path='tab/*' element={<ApplicationTab />}>
									<Route path='join-member' element={<JoinMemberPage />} />
									<Route path='out-member' element={<OutMemberPage />} />
									<Route path='join-centre' element={<JoinCentrePage />} />
									<Route path='out-centre' element={<OutCentrePage />} />
								</Route>
							</Route>
							<Route path='remove-user' element={<Outlet />}>
								<Route index element={<NavigateWithState to='member' />} />
								<Route
									key='member'
									path='member'
									element={
										<RemoveUserSummarPage
											key='member'
											memberType={MemberType.member}
										/>
									}
								/>
								<Route
									key='no-member'
									path='no-member'
									element={
										<RemoveUserSummarPage
											key='no-member'
											memberType={MemberType.noMember}
										/>
									}
								/>
							</Route>
						</Route>
						<Route path='resources'>
							<Route path='resources-list' element={<Outlet />}>
								<Route index element={<NavigateWithState to='drafts' />} />
								<Route
									key='drafts'
									path='drafts'
									element={
										<ResourcesSummaryPage
											key='drafts'
											statusType={CommunityResourcesType.draft}
										/>
									}
								/>
								<Route
									key='director-approval'
									path='director-approval'
									element={
										<ResourcesSummaryPage
											key='director-approval'
											statusType={CommunityResourcesType.directorApproval}
										/>
									}
								/>
								<Route
									key='supervisor-approval'
									path='supervisor-approval'
									element={
										<ResourcesSummaryPage
											key='supervisor-approval'
											statusType={CommunityResourcesType.supervisorApproval}
										/>
									}
								/>
								<Route
									key='awaiting-release'
									path='awaiting-release'
									element={
										<ResourcesSummaryPage
											key='awaiting-release'
											statusType={CommunityResourcesType.awaitingRelease}
										/>
									}
								/>
								<Route
									key='released'
									path='released'
									element={
										<ResourcesSummaryPage
											key='released'
											statusType={CommunityResourcesType.released}
										/>
									}
								/>
								<Route
									key='removed'
									path='removed'
									element={
										<ResourcesSummaryPage
											key='removed'
											statusType={CommunityResourcesType.removed}
										/>
									}
								/>
								<Route path='add' element={<CreateResourcesPage />} />
								<Route path='info/:id' element={<ResourcesInfoPage />} />
							</Route>
						</Route>
						<Route path='study-course'>
							<Route path='study-course-list' element={<Outlet />}>
								<Route index element={<NavigateWithState to='drafts' />} />
								<Route
									key='drafts'
									path='drafts'
									element={
										<StudyCourseSummaryPage
											key='drafts'
											statusType={StudyCourseType.draft}
										/>
									}
								/>
								<Route
									key='director-approval'
									path='director-approval'
									element={
										<StudyCourseSummaryPage
											key='director-approval'
											statusType={StudyCourseType.directorApproval}
										/>
									}
								/>
								<Route
									key='supervisor-approval'
									path='supervisor-approval'
									element={
										<StudyCourseSummaryPage
											key='supervisor-approval'
											statusType={StudyCourseType.supervisorApproval}
										/>
									}
								/>
								<Route
									key='awaiting-release'
									path='awaiting-release'
									element={
										<StudyCourseSummaryPage
											key='awaiting-release'
											statusType={StudyCourseType.awaitingRelease}
										/>
									}
								/>
								<Route
									key='released'
									path='released'
									element={
										<StudyCourseSummaryPage
											key='released'
											statusType={StudyCourseType.released}
										/>
									}
								/>
								<Route
									key='removed'
									path='removed'
									element={
										<StudyCourseSummaryPage
											key='removed'
											statusType={StudyCourseType.removed}
										/>
									}
								/>
								<Route path='add' element={<CreateStudyCoursePage />} />
								<Route
									path=':studyCourseId'
									element={<NavigateWithState to='info' />}
								/>
								<Route path=':studyCourseId/*' element={<Outlet />}>
									<Route path='info' element={<Outlet />}>
										<Route index element={<StudyCourseInfoPage />} />
										<Route path='addChapter' element={<CreateChapterPage />} />
										<Route
											path='chapterDetail/:chapterId'
											element={<ChapterInfoPage />}
										/>
									</Route>
								</Route>
							</Route>
						</Route>
						<Route path='staffs'>
							<Route path='staff-list'>
								<Route index element={<StaffListSummaryPage />} />
								<Route path='add' element={<CreateStaffPage />} />
								<Route path='info/:id' element={<StaffInfoPage />} />
							</Route>
						</Route>
						<Route path='items'>
							<Route
								path='member-reward'
								element={<MemberRewardSummaryPage />}
							/>
							<Route
								path='virtual-props-reward'
								element={<VirtualPropsRewardSummaryPage />}
							/>
							<Route
								path='user-coupon-list'
								element={<UserListOfCashCouponSummaryPage />}
							/>
							<Route
								path='user-mission-list'
								element={<UserMissionListSummaryPage />}
							/>
						</Route>
						<Route path='mental-health-info'>
							<Route path='latest-news' element={<Outlet />}>
								<Route index element={<NavigateWithState to='drafts' />} />
								<Route
									key='drafts'
									path='drafts'
									element={
										<MentalHealthInfoSummaryPage
											key='drafts'
											statusType={ArticlestatusType.draft}
											categoryId={ArticleCategory.latestNews}
											menuCode={ArticleMenu.latestNews}
										/>
									}
								/>
								<Route
									key='director-approval'
									path='director-approval'
									element={
										<MentalHealthInfoSummaryPage
											key='director-approval'
											statusType={ArticlestatusType.directorApproval}
											categoryId={ArticleCategory.latestNews}
											menuCode={ArticleMenu.latestNews}
										/>
									}
								/>
								<Route
									key='supervisor-approval'
									path='supervisor-approval'
									element={
										<MentalHealthInfoSummaryPage
											key='supervisor-approval'
											statusType={ArticlestatusType.supervisorApproval}
											categoryId={ArticleCategory.latestNews}
											menuCode={ArticleMenu.latestNews}
										/>
									}
								/>
								<Route
									key='awaiting-release'
									path='awaiting-release'
									element={
										<MentalHealthInfoSummaryPage
											key='awaiting-release'
											statusType={ArticlestatusType.awaitingRelease}
											categoryId={ArticleCategory.latestNews}
											menuCode={ArticleMenu.latestNews}
										/>
									}
								/>
								<Route
									key='released'
									path='released'
									element={
										<MentalHealthInfoSummaryPage
											key='released'
											statusType={ArticlestatusType.released}
											categoryId={ArticleCategory.latestNews}
											menuCode={ArticleMenu.latestNews}
										/>
									}
								/>
								<Route
									key='removed'
									path='removed'
									element={
										<MentalHealthInfoSummaryPage
											key='removed'
											statusType={ArticlestatusType.removed}
											categoryId={ArticleCategory.latestNews}
											menuCode={ArticleMenu.latestNews}
										/>
									}
								/>
								<Route
									path='add'
									element={
										<CreateMentalHealthInfoPage
											categoryId={ArticleCategory.latestNews}
										/>
									}
								/>
								<Route
									path='order/:subcategoryId'
									element={
										<MentalHealthInfoOrderPage
											categoryId={ArticleCategory.latestNews}
										/>
									}
								/>
								<Route path='info/:id' element={<MentalHealthInfoPage />} />
							</Route>
							<Route path='know-mental-health' element={<Outlet />}>
								<Route index element={<NavigateWithState to='drafts' />} />
								<Route
									path='drafts'
									element={
										<MentalHealthInfoSummaryPage
											key='drafts'
											statusType={ArticlestatusType.draft}
											categoryId={ArticleCategory.knowMentalHealth}
											menuCode={ArticleMenu.knowMentalHealth}
										/>
									}
								/>
								<Route
									path='director-approval'
									element={
										<MentalHealthInfoSummaryPage
											key='director-approval'
											statusType={ArticlestatusType.directorApproval}
											categoryId={ArticleCategory.knowMentalHealth}
											menuCode={ArticleMenu.knowMentalHealth}
										/>
									}
								/>
								<Route
									path='supervisor-approval'
									element={
										<MentalHealthInfoSummaryPage
											key='supervisor-approval'
											statusType={ArticlestatusType.supervisorApproval}
											categoryId={ArticleCategory.knowMentalHealth}
											menuCode={ArticleMenu.knowMentalHealth}
										/>
									}
								/>
								<Route
									path='awaiting-release'
									element={
										<MentalHealthInfoSummaryPage
											key='awaiting-release'
											statusType={ArticlestatusType.awaitingRelease}
											categoryId={ArticleCategory.knowMentalHealth}
											menuCode={ArticleMenu.knowMentalHealth}
										/>
									}
								/>
								<Route
									path='released'
									element={
										<MentalHealthInfoSummaryPage
											key='released'
											statusType={ArticlestatusType.released}
											categoryId={ArticleCategory.knowMentalHealth}
											menuCode={ArticleMenu.knowMentalHealth}
										/>
									}
								/>
								<Route
									path='removed'
									element={
										<MentalHealthInfoSummaryPage
											key='removed'
											statusType={ArticlestatusType.removed}
											categoryId={ArticleCategory.knowMentalHealth}
											menuCode={ArticleMenu.knowMentalHealth}
										/>
									}
								/>
								<Route
									path='add'
									element={
										<CreateMentalHealthInfoPage
											categoryId={ArticleCategory.knowMentalHealth}
										/>
									}
								/>
								<Route
									path='order/:subcategoryId'
									element={
										<MentalHealthInfoOrderPage
											categoryId={ArticleCategory.knowMentalHealth}
										/>
									}
								/>
								<Route path='info/:id' element={<MentalHealthInfoPage />} />
							</Route>
							<Route path='story-share' element={<Outlet />}>
								<Route index element={<NavigateWithState to='drafts' />} />
								<Route
									path='drafts'
									element={
										<MentalHealthInfoSummaryPage
											key='drafts'
											statusType={ArticlestatusType.draft}
											categoryId={ArticleCategory.storyShare}
											menuCode={ArticleMenu.storyShare}
										/>
									}
								/>
								<Route
									path='director-approval'
									element={
										<MentalHealthInfoSummaryPage
											key='director-approval'
											statusType={ArticlestatusType.directorApproval}
											categoryId={ArticleCategory.storyShare}
											menuCode={ArticleMenu.storyShare}
										/>
									}
								/>
								<Route
									path='supervisor-approval'
									element={
										<MentalHealthInfoSummaryPage
											key='supervisor-approval'
											statusType={ArticlestatusType.supervisorApproval}
											categoryId={ArticleCategory.storyShare}
											menuCode={ArticleMenu.storyShare}
										/>
									}
								/>
								<Route
									path='awaiting-release'
									element={
										<MentalHealthInfoSummaryPage
											key='awaiting-release'
											statusType={ArticlestatusType.awaitingRelease}
											categoryId={ArticleCategory.storyShare}
											menuCode={ArticleMenu.storyShare}
										/>
									}
								/>
								<Route
									path='released'
									element={
										<MentalHealthInfoSummaryPage
											key='released'
											statusType={ArticlestatusType.released}
											categoryId={ArticleCategory.storyShare}
											menuCode={ArticleMenu.storyShare}
										/>
									}
								/>
								<Route
									path='removed'
									element={
										<MentalHealthInfoSummaryPage
											key='removed'
											statusType={ArticlestatusType.removed}
											categoryId={ArticleCategory.storyShare}
											menuCode={ArticleMenu.storyShare}
										/>
									}
								/>
								<Route
									path='add'
									element={
										<CreateMentalHealthInfoPage
											categoryId={ArticleCategory.storyShare}
										/>
									}
								/>
								<Route
									path='order/:subcategoryId'
									element={
										<MentalHealthInfoOrderPage
											categoryId={ArticleCategory.storyShare}
										/>
									}
								/>
								<Route path='info/:id' element={<MentalHealthInfoPage />} />
							</Route>
							<Route path='column-share' element={<Outlet />}>
								<Route index element={<NavigateWithState to='drafts' />} />
								<Route
									path='drafts'
									element={
										<MentalHealthInfoSummaryPage
											key='drafts'
											statusType={ArticlestatusType.draft}
											categoryId={ArticleCategory.columnShare}
											menuCode={ArticleMenu.columnShare}
										/>
									}
								/>
								<Route
									path='director-approval'
									element={
										<MentalHealthInfoSummaryPage
											key='director-approval'
											statusType={ArticlestatusType.directorApproval}
											categoryId={ArticleCategory.columnShare}
											menuCode={ArticleMenu.columnShare}
										/>
									}
								/>
								<Route
									path='supervisor-approval'
									element={
										<MentalHealthInfoSummaryPage
											key='supervisor-approval'
											statusType={ArticlestatusType.supervisorApproval}
											categoryId={ArticleCategory.columnShare}
											menuCode={ArticleMenu.columnShare}
										/>
									}
								/>
								<Route
									path='awaiting-release'
									element={
										<MentalHealthInfoSummaryPage
											statusType={ArticlestatusType.awaitingRelease}
											categoryId={ArticleCategory.columnShare}
											menuCode={ArticleMenu.columnShare}
										/>
									}
								/>
								<Route
									path='released'
									element={
										<MentalHealthInfoSummaryPage
											key='released'
											statusType={ArticlestatusType.released}
											categoryId={ArticleCategory.columnShare}
											menuCode={ArticleMenu.columnShare}
										/>
									}
								/>
								<Route
									path='removed'
									element={
										<MentalHealthInfoSummaryPage
											key='removed'
											statusType={ArticlestatusType.removed}
											categoryId={ArticleCategory.columnShare}
											menuCode={ArticleMenu.columnShare}
										/>
									}
								/>
								<Route
									path='add'
									element={
										<CreateMentalHealthInfoPage
											categoryId={ArticleCategory.columnShare}
										/>
									}
								/>
								<Route
									path='order/:subcategoryId'
									element={
										<MentalHealthInfoOrderPage
											categoryId={ArticleCategory.columnShare}
										/>
									}
								/>
								<Route path='info/:id' element={<MentalHealthInfoPage />} />
							</Route>
						</Route>
						<Route path='surveys'>
							<Route index element={<NavigateWithState to='survey-list' />} />
							<Route path='survey-list' element={<Outlet />}>
								<Route index element={<SurveyListSummaryPage />} />
								<Route path='add' element={<CreateSurveyPage />} />
								<Route path='info/:surveyId' element={<SurveyInfoPage />} />
							</Route>
						</Route>
					</Route>
				</Route>
			</Route>
		</>
	)
);

export default router;
