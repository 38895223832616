import { apiSlice } from '../apiSlice';
import { SuccessResponse, Paginated } from '../DTO/common.interface';
import {
	AddFreeUserDTO,
	AddUserDTO,
	AddUserResDTO,
	FreeUserPageDTO,
	FreeUserPageResDTO,
	OrgUserDetailRes,
	OrgUserOutCentre,
	OrgUserPageDTO,
	OrgUserPageResDTO,
	OrgUserUpdateEmail,
	OrgUserUpdatePhone,
	OrgUserUpdateUsername,
	UpdateUserPersonal,
	UpdateUserType,
	UserTypeRes,
} from '../DTO/user/user.interface';

export const userApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		addOrgUser: builder.mutation<SuccessResponse<AddUserResDTO>, AddUserDTO>({
			query: (body) => ({
				url: `/web/users/org`,
				method: 'POST',
				body,
			}),
		}),

		addFreeUser: builder.mutation<
			SuccessResponse<AddUserResDTO>,
			AddFreeUserDTO
		>({
			query: (body) => ({
				url: `/web/users/free`,
				method: 'POST',
				body,
			}),
		}),

		getOrgUserPage: builder.query<
			SuccessResponse<Paginated<OrgUserPageResDTO>>,
			OrgUserPageDTO
		>({
			query: (body) => ({
				url: `/web/users/page/org`,
				method: 'POST',
				body,
			}),
		}),

		getFreeUserPage: builder.query<
			SuccessResponse<Paginated<FreeUserPageResDTO>>,
			FreeUserPageDTO
		>({
			query: (body) => ({
				url: `/web/users/page/free`,
				method: 'POST',
				body,
			}),
		}),

		getUserDetail: builder.query<SuccessResponse<OrgUserDetailRes>, string>({
			query: (userId) => ({
				url: `/web/users/org/${userId}`,
			}),
			providesTags: ['UserFreeze', 'MemberUser'],
		}),

		getFreeUserDetail: builder.query<SuccessResponse<OrgUserDetailRes>, string>(
			{
				query: (userId) => ({
					url: `/web/users/free/${userId}`,
				}),
				providesTags: ['UserFreeze', 'NonMemberUser'],
			}
		),

		getUserType: builder.query<SuccessResponse<UserTypeRes[]>, void>({
			query: () => ({
				url: `/web/users/type`,
			}),
		}),

		editOrgUserEmail: builder.mutation<
			SuccessResponse<null>,
			OrgUserUpdateEmail
		>({
			query: (body) => ({
				url: `/web/users/org/email`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['MemberUser'],
		}),

		editFreeUserEmail: builder.mutation<
			SuccessResponse<null>,
			OrgUserUpdateEmail
		>({
			query: (body) => ({
				url: `/web/users/free/email`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['NonMemberUser'],
		}),

		editOrgUserPhone: builder.mutation<
			SuccessResponse<null>,
			OrgUserUpdatePhone
		>({
			query: (body) => ({
				url: `/web/users/org/phone`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['MemberUser'],
		}),

		editFreeUserPhone: builder.mutation<
			SuccessResponse<null>,
			OrgUserUpdatePhone
		>({
			query: (body) => ({
				url: `/web/users/free/phone`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['NonMemberUser'],
		}),

		editOrgUserUsername: builder.mutation<
			SuccessResponse<null>,
			OrgUserUpdateUsername
		>({
			query: (body) => ({
				url: `/web/users/org/username`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['MemberUser'],
		}),

		editFreeUserUsername: builder.mutation<
			SuccessResponse<null>,
			OrgUserUpdateUsername
		>({
			query: (body) => ({
				url: `/web/users/free/username`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['NonMemberUser'],
		}),

		orgOutCentre: builder.mutation<SuccessResponse<null>, OrgUserOutCentre>({
			query: (body) => ({
				url: `/web/users/org/out-centre`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['MemberUser'],
		}),

		freeJoinCentre: builder.mutation<SuccessResponse<null>, OrgUserOutCentre>({
			query: (body) => ({
				url: `/web/users/free/join-centre`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['NonMemberUser'],
		}),

		editUserType: builder.mutation<SuccessResponse<null>, UpdateUserType>({
			query: (body) => ({
				url: `/web/users/type`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['MemberUser', 'NonMemberUser'],
		}),

		editUserTypeInfo: builder.mutation<SuccessResponse<null>, UpdateUserType>({
			query: (body) => ({
				url: `/web/users/pir`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['MemberUser', 'NonMemberUser'],
		}),

		deleteOrgUserPassword: builder.mutation<SuccessResponse<null>, string>({
			query: (userId) => ({
				url: `/web/users/org/${userId}/password`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MemberUser'],
		}),

		deleteFreeUserPassword: builder.mutation<SuccessResponse<null>, string>({
			query: (userId) => ({
				url: `/web/users/free/${userId}/password`,
				method: 'DELETE',
			}),
			invalidatesTags: ['NonMemberUser'],
		}),

		deleteOrgUser: builder.mutation<SuccessResponse<null>, string>({
			query: (userId) => ({
				url: `/web/users/org/${userId}`,
				method: 'DELETE',
			}),
		}),

		deleteFreeUser: builder.mutation<SuccessResponse<null>, string>({
			query: (userId) => ({
				url: `/web/users/free/${userId}`,
				method: 'DELETE',
			}),
		}),

		freezeOrgUser: builder.mutation<SuccessResponse<null>, string>({
			query: (userId) => ({
				url: `/web/users/org/${userId}/freeze`,
				method: 'PUT',
			}),
			invalidatesTags: ['UserFreeze'],
		}),

		freezeFreeUser: builder.mutation<SuccessResponse<null>, string>({
			query: (userId) => ({
				url: `/web/users/free/${userId}/freeze`,
				method: 'PUT',
			}),
			invalidatesTags: ['UserFreeze'],
		}),

		unfreezeOrgUser: builder.mutation<SuccessResponse<null>, string>({
			query: (userId) => ({
				url: `/web/users/org/${userId}/unfreeze`,
				method: 'PUT',
			}),
			invalidatesTags: ['UserFreeze'],
		}),

		unfreezeFreeUser: builder.mutation<SuccessResponse<null>, string>({
			query: (userId) => ({
				url: `/web/users/free/${userId}/unfreeze`,
				method: 'PUT',
			}),
			invalidatesTags: ['UserFreeze'],
		}),

		editOrgPersonal: builder.mutation<
			SuccessResponse<null>,
			UpdateUserPersonal
		>({
			query: (body) => ({
				url: `/web/users/org/personal`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['MemberUser'],
		}),

		editFreePersonal: builder.mutation<
			SuccessResponse<null>,
			UpdateUserPersonal
		>({
			query: (body) => ({
				url: `/web/users/free/personal`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['NonMemberUser'],
		}),

		isApproving: builder.query<SuccessResponse<any>, string>({
			query: (id) => ({
				url: `/web/users/approving/${id}`,
			}),
		}),
	}),
});

export const {
	useAddOrgUserMutation,
	useAddFreeUserMutation,
	useGetOrgUserPageQuery,
	useGetFreeUserPageQuery,
	useGetUserDetailQuery,
	useGetFreeUserDetailQuery,
	useGetUserTypeQuery,
	useIsApprovingQuery,
	useEditOrgUserEmailMutation,
	useEditFreeUserEmailMutation,
	useEditOrgUserPhoneMutation,
	useEditFreeUserPhoneMutation,
	useEditOrgUserUsernameMutation,
	useEditFreeUserUsernameMutation,
	useOrgOutCentreMutation,
	useFreeJoinCentreMutation,
	useEditUserTypeMutation,
	useEditUserTypeInfoMutation,
	useDeleteOrgUserPasswordMutation,
	useDeleteFreeUserPasswordMutation,
	useDeleteOrgUserMutation,
	useDeleteFreeUserMutation,
	useFreezeOrgUserMutation,
	useFreezeFreeUserMutation,
	useUnfreezeOrgUserMutation,
	useUnfreezeFreeUserMutation,
	useEditOrgPersonalMutation,
	useEditFreePersonalMutation,
} = userApiSlice;
