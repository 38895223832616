import AppTextField from '@/common/components/form-inputs/AppTextField';
import SingleCheckbox from '@/common/components/form-inputs/SingleCheckbox';
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	Typography,
	styled,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AppRadioGroup from '@/common/components/form-inputs/AppRadioGroup';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AppRichTextEditor from '@/common/components/editor/AppRichTextEditor';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFileUploader } from '@/hooks/useFileUploader';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import LinkIcon from '@mui/icons-material/Link';
import { FileInfo } from '@/api/DTO/common.interface';
import { ArticleCategory } from '@/api/enum/common.enum';
import ImageCropper from '@/common/components/imageCropper/imageCropper';
import AppCustomTags from '@/common/components/form-inputs/AppCustomTags';
import AppDropDownMenu from '@/common/components/form-inputs/AppDropDownMenu';
import { useMentalHealthOptions } from '@/hooks/useMentalHealthOptions';
import AppDatePicker from '@/common/components/form-inputs/AppDatePicker';
import dayjs from 'dayjs';
import { API_DATE_TIME_FORMAT } from '@/utils/dateHelper';
import { AppFormLabel } from '@/common/components/form-view/AppFieldView';

const StyledImg = styled('img')({
	width: '200px',
	height: '137px',
	objectFit: 'contain',
});
const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

interface Props {
	methods: any;
	formData?: any;
	articleTypeList: { id: string; name: string }[] | undefined;
	districtsOptions: { id: string; name: string }[];
	pdfUrl: FileInfo | null;
	setPdfUrl: any;
	audioUrl: FileInfo | null;
	setAudioUrl: any;
	thumbnailUrl: FileInfo | null;
	setThumbnailUrl: any;
	images: FileInfo[] | null;
	setImages: any;
	categoryId: string | number;
}

const MentalHealthContentInfo = (props: Props) => {
	const {
		methods,
		articleTypeList,
		pdfUrl,
		setPdfUrl,
		audioUrl,
		setAudioUrl,
		thumbnailUrl,
		setThumbnailUrl,
		images,
		setImages,
		formData,
		categoryId,
		districtsOptions,
	} = props;
	const { t } = useTranslation();
	const { uploadFile } = useFileUploader();
	const [inputKey, setInputKey] = useState(0);
	const pdfInputRef = useRef<HTMLInputElement>(null);
	const audioInputRef = useRef<HTMLInputElement>(null);
	const thumbnailInputRef = useRef<HTMLInputElement>(null);
	const [showCropper, setShowCropper] = useState(false);
	const [thumbnailBase, setThumbnailBase] = useState<{
		base64: any;
		name: string;
	} | null>(null);
	const { categoryList, subCategoryList } = useMentalHealthOptions([
		'categoryList',
		'subCategoryList',
	]);

	const watchLinkAttached = methods.watch('linkAttached');
	const watchFormat = methods.watch('type');
	const watchPushTime = methods.watch('pushTime');

	useEffect(() => {
		if (formData?.registrationLink) methods.setValue('linkAttached', true);
		if (formData?.thumbnail) {
			setThumbnailUrl({ ...formData.thumbnail, name: formData.thumbnail.url });
		}
		if (formData?.images) {
			const arr = (formData.images || []).map((item: any) => {
				return {
					...item,
					name: item.url,
				};
			});
			setImages(arr);
		}
		if (formData?.pdf) {
			methods.setValue('pdf', formData.pdf.url);
			setPdfUrl({ ...formData.pdf, name: formData.pdf.url });
		}
		if (formData?.audio) {
			methods.setValue('audio', formData.audio.url);
			setAudioUrl({ ...formData.audio, name: formData.audio.url });
		}
	}, [formData]);

	const thumbnailField = useController({
		name: 'thumbnail',
		control: methods.control,
	});
	const imagesField = useController({
		name: 'images',
		control: methods.control,
	});

	const handleImagesChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setInputKey(inputKey + 1);
		const selectedFile = event.target.files?.[0];
		if (!selectedFile) return;
		const res = await uploadFile(selectedFile);
		if (res) {
			const formImages = methods.getValues('images') || [];
			formImages.push(res);
			methods.setValue('images', formImages);
			methods.trigger('images');
			const obj: { url: string; name: string } = res;
			setImages((prevImages: any) => [...(prevImages ?? []), obj]);

			enqueueSnackbar(t('snackbar.uploadSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
		}
	};
	const handleClearImages = (index: number) => {
		const formImages = methods.getValues('images') || [];
		formImages.splice(index, 1);
		methods.setValue('images', formImages);
		methods.trigger('images');

		setImages((prevImages: any) => {
			const updatedImages = [...(prevImages || [])];
			updatedImages.splice(index, 1);
			return updatedImages;
		});
	};

	const handleThumbnailChange = async (e: any) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const name = files[0].name;
		const reader = new FileReader();
		reader.onload = () => {
			setShowCropper(true);
			setThumbnailBase({
				base64: reader?.result,
				name: name,
			});

			thumbnailInputRef.current && (thumbnailInputRef.current.value = '');
		};
		reader.readAsDataURL(files[0]);
	};
	const handleCropper = async (file?: any) => {
		setShowCropper(false);
		if (!file) return;
		const res = await uploadFile(file);
		if (res) {
			methods.setValue('thumbnail', res);
			methods.trigger('thumbnail');

			setThumbnailUrl(res);

			enqueueSnackbar(t('snackbar.uploadSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
		}
	};
	const handleClearThumbnail = useCallback(() => {
		methods.setValue('thumbnail', null);
		methods.trigger('thumbnail');
		setThumbnailUrl(null);
	}, []);

	const handlePdfChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const selectedFile = event.target.files?.[0];
		if (!selectedFile) return;
		const res = await uploadFile(selectedFile);
		if (res) {
			methods.setValue('pdf', res.name);
			methods.trigger('pdf');

			setPdfUrl(res);

			enqueueSnackbar(t('snackbar.uploadSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
		}
	};
	const handleClearPdf = () => {
		methods.setValue('pdf', null);
		pdfInputRef.current && (pdfInputRef.current.value = '');
		setPdfUrl(null);
	};

	const handleAudioChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const selectedFile = event.target.files?.[0];
		if (!selectedFile) return;
		const res = await uploadFile(selectedFile);
		if (res) {
			methods.setValue('audio', res.name);
			methods.trigger('audio');

			setAudioUrl(res);

			enqueueSnackbar(t('snackbar.uploadSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
		}
	};
	const handleClearAudio = () => {
		methods.setValue('audio', null);
		audioInputRef.current && (audioInputRef.current.value = '');
		setAudioUrl(null);
	};
	return (
		<>
			<Typography variant='h5'>{t('common.content')}</Typography>
			<Grid container spacing={3} marginTop={0} marginBottom={3}>
				<Grid
					item
					xs={
						categoryId == ArticleCategory.latestNews
							? 6
							: categoryId == ArticleCategory.knowMentalHealth
							? 6
							: 12
					}
				>
					<AppDropDownMenu
						disabled
						control={methods.control}
						name='category'
						label={t('common.infoCategory')}
						options={categoryList}
					/>
				</Grid>
				{categoryId == ArticleCategory.latestNews && (
					<Grid item xs={6}>
						<AppDropDownMenu
							control={methods.control}
							name='district'
							label={t('common.district')}
							options={districtsOptions || []}
						/>
					</Grid>
				)}
				{categoryId == ArticleCategory.knowMentalHealth && (
					<Grid item xs={6}>
						<AppDropDownMenu
							control={methods.control}
							name='subcategory'
							label={t('common.subclassify')}
							options={subCategoryList || []}
						/>
					</Grid>
				)}
				<Grid item xs={6}>
					<AppDatePicker
						minimumDate={dayjs().add(1, 'day').format(API_DATE_TIME_FORMAT)}
						control={methods.control}
						name='pushTime'
						label={t('common.estimatedReleaseDate') + t('common.optional')}
						formatDate={API_DATE_TIME_FORMAT}
					/>
				</Grid>
				<Grid item xs={6}>
					<AppDatePicker
						minimumDate={watchPushTime}
						disabled={!watchPushTime}
						control={methods.control}
						name='pullTime'
						label={t('common.estimatedRemoveDate') + t('common.optional')}
						formatDate={API_DATE_TIME_FORMAT}
					/>
				</Grid>

				<Grid item xs={12}>
					<AppCustomTags
						control={methods.control}
						name='tags'
						label={t('common.customTags')}
					/>
				</Grid>
				<Grid item xs={12}>
					<AppTextField
						control={methods.control}
						name='title'
						label={t('common.title')}
						maxLength={20}
						showMaxLength
					/>
				</Grid>

				{categoryId == ArticleCategory.latestNews && (
					<>
						<Grid item xs={6}>
							<SingleCheckbox
								control={methods.control}
								name='linkAttached'
								label={t('mentalHealthInfo.registrationLinkAttached')}
							/>
						</Grid>
						{watchLinkAttached && (
							<Grid item xs={12}>
								<AppTextField
									control={methods.control}
									name='registrationLink'
									label={t('mentalHealthInfo.registrationLink')}
								/>
							</Grid>
						)}
					</>
				)}

				<Grid item xs={12}>
					<FormControl error={!!thumbnailField.fieldState.error?.message}>
						<AppFormLabel>{t('common.thumbnail')}</AppFormLabel>

						{thumbnailUrl ? (
							<Box sx={{ width: '200px' }}>
								<StyledImg src={thumbnailUrl.url} sx={{ height: 'auto' }} />
								<Grid
									container
									direction='row'
									justifyContent='space-between'
									alignItems='center'
								>
									<Typography variant='caption' noWrap sx={{ width: '170px' }}>
										{''}
										{/* Have not used thumbnailUrl.name because sometimes the name is a URL. */}
									</Typography>
									<IconButton
										color='error'
										size='small'
										onClick={handleClearThumbnail}
									>
										<DeleteIcon fontSize='inherit' />
									</IconButton>
								</Grid>
							</Box>
						) : (
							<Box>
								<Button
									component='label'
									variant='text'
									startIcon={<AddIcon />}
									style={{ alignItems: 'center' }}
								>
									{t('button.uploadImage')}
									<VisuallyHiddenInput
										type='file'
										name='thumbnail'
										accept='image/*'
										onChange={handleThumbnailChange}
										ref={thumbnailInputRef}
									/>
									<ImageCropper
										image={thumbnailBase}
										isOpen={showCropper}
										handleCropper={handleCropper}
										option={{
											zoomTo: 0.5,
											initialAspectRatio: 1,
											viewMode: 1,
											autoCropArea: 1,
											checkOrientation: false,
											guides: true,
											aspectRatio: 3 / 2,
										}}
									/>
								</Button>
							</Box>
						)}
						<FormHelperText>
							{thumbnailField.fieldState.error?.message}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<AppRadioGroup
						style={{ padding: 0 }}
						control={methods.control}
						name='type'
						label={t('common.format')}
						row
						options={articleTypeList || []}
					/>
				</Grid>
				{watchFormat == '1' && (
					<Grid item xs={12}>
						<FormControl
							error={!!imagesField.fieldState.error?.message}
							fullWidth
						>
							<AppFormLabel>
								{t('mentalHealthInfo.contentImagesMax10')}
							</AppFormLabel>
							<Grid container direction='row' alignItems='center' spacing={2}>
								{(images || []).map((item, index) => (
									<Grid item xs={2} key={item.url} sx={{ minWidth: '200px' }}>
										<StyledImg src={item.url}></StyledImg>
										<Grid
											container
											direction='row'
											justifyContent='space-between'
											alignItems='center'
											style={{ width: '200px' }}
										>
											<Typography
												variant='caption'
												noWrap
												sx={{ width: '155px' }}
											>
												{t('common.picture')}
												{index + 1}
											</Typography>
											<IconButton
												key={inputKey}
												color='error'
												size='small'
												onClick={() => {
													handleClearImages(index);
												}}
											>
												<DeleteIcon fontSize='inherit' />
											</IconButton>
										</Grid>
									</Grid>
								))}

								{(!images || images?.length < 10) && (
									<Grid item>
										<Button
											component='label'
											variant='text'
											startIcon={<AddIcon />}
											style={{ alignItems: 'center' }}
										>
											{t('button.uploadImage')}
											<VisuallyHiddenInput
												key={inputKey}
												type='file'
												accept='image/*'
												name='images'
												onChange={handleImagesChange}
											/>
										</Button>
									</Grid>
								)}
							</Grid>

							<FormHelperText>
								{imagesField.fieldState.error?.message}
							</FormHelperText>
						</FormControl>
					</Grid>
				)}
				{watchFormat == '2' && (
					<Grid item xs={6}>
						<AppTextField
							disabled
							control={methods.control}
							name='pdf'
							label={t('common.pdfDoc')}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										{pdfUrl?.url && (
											<IconButton type='button' onClick={handleClearPdf}>
												<CloseIcon />
											</IconButton>
										)}

										<IconButton component='label'>
											<AttachFileIcon />
											<VisuallyHiddenInput
												type='file'
												accept='application/pdf'
												onChange={handlePdfChange}
												ref={pdfInputRef}
											/>
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				)}
				{watchFormat == '3' && (
					<Grid item xs={6}>
						<AppTextField
							control={methods.control}
							name='videoLink'
							label={t('common.videoLink')}
							InputProps={{
								endAdornment: (
									<>
										<InputAdornment position='end'>
											<LinkIcon />
										</InputAdornment>
									</>
								),
							}}
						/>
					</Grid>
				)}
				{watchFormat == '4' && (
					<Grid item xs={6}>
						<AppTextField
							disabled
							control={methods.control}
							name='audio'
							label={t('common.audioFile')}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										{audioUrl?.url && (
											<IconButton type='button' onClick={handleClearAudio}>
												<CloseIcon />
											</IconButton>
										)}

										<IconButton component='label'>
											<AttachFileIcon />
											<VisuallyHiddenInput
												type='file'
												accept='.mp3'
												onChange={handleAudioChange}
												ref={audioInputRef}
											/>
										</IconButton>
									</InputAdornment>
								),
							}}
							helperText={t('common.audioHelperText')}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<AppRichTextEditor control={methods.control} name='content' />
				</Grid>
			</Grid>
		</>
	);
};

export default MentalHealthContentInfo;
