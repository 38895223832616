import { yupResolver } from '@hookform/resolvers/yup';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppDropDownMenu from '../form-inputs/AppDropDownMenu';
import Close from '@mui/icons-material/Close';
import { UserCategory } from '@/api/enum/userCategory.enum';
import { closeSnackbar, useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { selectMyInfo } from '@/pages/auth/authSlice';
import { useGetCentreDistrictsQuery } from '@/api/area/areaApiSlice';
import { UserCatContext } from '@/context/userCategoryContext';
import { Option } from '@/api/DTO/common.interface';
import {
	useFreeJoinCentreMutation,
	useOrgOutCentreMutation,
} from '@/api/user/userApiSlice';
import { OrgUserOutCentre } from '@/api/DTO/user/user.interface';
import CloseIcon from '@mui/icons-material/Close';
import { Centre } from '@/api/enum/centre.enum';

interface EditBelongedDistrictModalProps {
	userId: string;
	districtId: string;
}

const EditBelongedDistrictModal = (props: EditBelongedDistrictModalProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { userCat } = useContext(UserCatContext);

	const [districtsOptions, setDistrictsOptions] = useState<Option[]>([]);

	const myInfo = useSelector(selectMyInfo);

	const { userId, districtId } = props;

	const { data: centreDistricts, isSuccess: isGetCentreDistrictsSuccess } =
		useGetCentreDistrictsQuery(myInfo?.centre || '', {
			refetchOnMountOrArgChange: true,
			skip: !myInfo?.centre,
		});

	const { data: freeDistricts } = useGetCentreDistrictsQuery(Centre.SHARE, {
		refetchOnMountOrArgChange: true,
	});

	const [editOrgDistrict] = useOrgOutCentreMutation();
	const [editFreeDistrict] = useFreeJoinCentreMutation();

	const defaultValues: DefaultValues<OrgUserOutCentre> = {
		districtId: districtId,
	};

	const formSchema = Yup.object().shape({
		districtId: Yup.string().required(t('errorMessage.pleaseSelect')),
	});

	const methods = useForm<OrgUserOutCentre>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		methods.setValue('userId', userId);
	}, [userId]);

	useEffect(() => {
		if (userCat === UserCategory.ORG) {
			setDistrictsOptions(centreDistricts?.data ?? []);
		} else {
			setDistrictsOptions(freeDistricts?.data ?? []);
		}
	}, [myInfo, userCat, centreDistricts, freeDistricts]);

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit: SubmitHandler<OrgUserOutCentre> = async (data) => {
		if (userCat === UserCategory.ORG) {
			const response = await editOrgDistrict(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.editBelongedDistrictSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				handleClose();
			}
		} else {
			const response = await editFreeDistrict(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.editBelongedDistrictSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				handleClose();
			}
		}
	};

	return (
		<>
			<IconButton color='primary' onClick={() => setOpen(true)}>
				<CreateOutlinedIcon sx={{ cursor: 'pointer' }} />
			</IconButton>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='xs'
				fullWidth
			>
				<DialogTitle variant='h5'>
					{t('common.editBelongedDistrict')}
				</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12}>
									<AppDropDownMenu
										control={methods.control}
										name='districtId'
										label={t('common.belongedDistrict')}
										options={districtsOptions}
										required
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EditBelongedDistrictModal;
