import { CentreDistrictResDTO } from '../DTO/area/area.interface';
import { Option, SuccessResponse } from '../DTO/common.interface';
import { apiSlice } from '../apiSlice';

export const areaApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCentreDistricts: builder.query<SuccessResponse<Option[]>, string>({
			query: (centre) => ({
				url: `/web/areas/${centre}/districts`,
				method: 'GET',
			}),
		}),
		getOutToDistricts: builder.query<
			SuccessResponse<CentreDistrictResDTO[]>,
			void
		>({
			query: () => ({
				url: `/web/areas/out-to`,
				method: 'GET',
			}),
		}),
	}),
});

export const { useGetCentreDistrictsQuery, useGetOutToDistrictsQuery } =
	areaApiSlice;
