import { apiSlice } from './apiSlice';
import { User } from './DTO/auth.interface';
import { SuccessResponse } from './DTO/common.interface';
export const userApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getMe: builder.query<SuccessResponse<User>, null>({
			query: () => `/web/users/me`,
			providesTags: ['GetMe'],
		}),
	}),
});
export const { useGetMeQuery } = userApiSlice;
