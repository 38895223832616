import { Box, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StaffInfoForm from './components/staffInfoForm';
import { FormType } from '@/api/enum/formType.enum';
import { useState } from 'react';

export default function CreateStaffPage() {
	const { t } = useTranslation();
	const [type, setType] = useState<FormType>(FormType.create);
	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
				<Box sx={{ width: '768px' }}>
					<Typography variant='h4'>{t('common.addStaffAccount')}</Typography>
					<Card sx={{ marginTop: 3 }}>
						<StaffInfoForm type={type} setType={setType} />
					</Card>
				</Box>
			</Box>
		</>
	);
}
