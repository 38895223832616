import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	IconButton,
	Stack,
	Typography,
	styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import {
	MentalHealthInfoFilter,
	MentalHealthInfoSummaryInfoItem,
} from '@/api/DTO/mentalHealthInfo/mentalHealthInfo.interface';
import MentalHealthInfoSummaryFilterSection from './MentalHealthInfoSummaryFilterSection';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import qs from 'qs';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../../utils/qsHelper';
import {
	DISPLAY_CHINESE_DATE_TIME_FORMAT,
	DISPLAY_DATE_FORMAT,
} from '@/utils/dateHelper';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SettingStatusModal from './SettingStatusModal';
import {
	useArticleStatusQuery,
	useGetArticlesCountQuery,
	useGetArticlesListQuery,
} from '@/api/mentalHealthInfo/mentalHealthApiSlice';
import { useGetArticlesDistrictsQuery } from '@/api/common';
import { useMentalHealthOptions } from '@/hooks/useMentalHealthOptions';
import { ArticleCategory, ArticlestatusType } from '@/api/enum/common.enum';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { showLabel } from '@/utils/helper';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ReorderArticlesModal from './ReorderArticlesModal';

export interface MentalHealthInfoDataGrid {}

interface MentalHealthInfoSummaryPageProps {
	statusType: string | number;
	categoryId: string | number;
	menuCode: string;
}

const statusChipList: Record<number | string, { color: string }> = {
	1: { color: 'chipGrey' },
	2: { color: 'chipGrey' },
	3: { color: 'chipPrimary' },
	4: { color: 'chipTertiary' },
	5: { color: 'chipGreen' },
	6: { color: 'chipGrey' },
};

export default function MentalHealthInfoSummaryPage(
	prop: MentalHealthInfoSummaryPageProps
) {
	const { statusType, categoryId, menuCode } = prop;
	const { articleTypeList, subCategoryList, statusList } =
		useMentalHealthOptions([
			'articleTypeList',
			'subCategoryList',
			'statusList',
		]);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);
	const [selectedId, setSelectedId] = useState<string[]>([]);

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			create: ['create', 'mentalHealth'],
			setStatus: ['setStatus', 'mentalHealth', String(statusType)],
			reorder: ['reorder', 'mentalHealth'],
		},
	});

	const { data: pageCount } = useGetArticlesCountQuery(
		{ categoryId: categoryId },
		{
			refetchOnMountOrArgChange: true,
		}
	);

	// 当前角色可操作的状态
	const { data: articleStatus } = useArticleStatusQuery({ type: statusType });
	const articleStatusList = useMemo(() => {
		return (articleStatus?.data || []).map((item) => {
			return {
				id: item.type,
				name: item.desc,
			};
		});
	}, [articleStatus]);

	const defaultMentalHealthInfoFilter: MentalHealthInfoFilter = {
		pagination: initialPagination,
		sortings: [],
		searchKey: '',
		districts: [],
		statusType: statusType, // 狀態tab
		categoryId: categoryId, // 對應左側菜單
		types: [],
	};

	const defaultValues: DefaultValues<MentalHealthInfoFilter> =
		defaultMentalHealthInfoFilter;
	const methods = useForm<MentalHealthInfoFilter>({
		defaultValues,
	});
	const transformedSearchParams: MentalHealthInfoFilter = {
		...defaultMentalHealthInfoFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'searchKey',
			'districts',
			'categoryId',
			'statusType',
			'types',
		]),
	};

	const {
		data: propertySummary,
		isSuccess: isGetPropertySummarySuccess,
		isLoading: isGetPropertyLoading,
	} = useGetArticlesListQuery(
		{
			...transformedSearchParams,
			districts: (transformedSearchParams?.districts || []).map((item: any) => {
				return { type: item };
			}),
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);

	useEffect(() => {
		if (!isGetPropertyLoading) setSelectedId([]);
	}, [propertySummary]);

	const transformResponse = useCallback(
		(response?: MentalHealthInfoSummaryInfoItem[]) => {
			if (response == null) return;
			const newResponse: MentalHealthInfoDataGrid[] = [];
			response.forEach((item) => {
				const typeName = articleTypeList.find((k) => k.id == item.type);
				newResponse.push({
					...item,
					type: typeName.name || '',
					createdAt: !item.createdAt
						? '-'
						: dayjs(item.createdAt)
								.locale('zh-hk-my-setting')
								.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
					pushTime: !item.pushTime
						? '-'
						: dayjs(item.pushTime)
								.locale('zh-hk-my-setting')
								.format(DISPLAY_DATE_FORMAT),
					pullTime: !item.pullTime
						? '-'
						: dayjs(item.pullTime)
								.locale('zh-hk-my-setting')
								.format(DISPLAY_DATE_FORMAT),
				});
			});
			return newResponse;
		},
		[]
	);

	const { data: districtsData } = useGetArticlesDistrictsQuery(null);
	const districtsOptions = useMemo(() => {
		const arr = (districtsData?.data || []).map(
			(item: { type: any; desc: string }) => {
				return {
					id: item.type,
					name: item.desc,
				};
			}
		);
		return arr || [];
	}, [districtsData]);

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const MentalHealthInfoFilterFormResult = value as MentalHealthInfoFilter;

			// append to url to reload page
			setSearchParams(qs.stringify(MentalHealthInfoFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const columns: GridColDef[] = [
		{
			field: 'title',
			headerName: t('common.title'),
			flex: 1,
		},
		{
			field: 'district',
			headerName: t('common.district'),
			flex: 1,
			renderCell: (params) => params.row.district?.desc,
			hide: categoryId != ArticleCategory.latestNews,
		},
		{
			field: 'subcategory',
			headerName: t('common.subclassify'),
			flex: 1,
			renderCell: (params) =>
				showLabel(params.row.subcategory, subCategoryList),
			hide: categoryId != ArticleCategory.knowMentalHealth,
		},
		{
			field: 'type',
			headerName: t('common.format'),
			flex: 1,
		},
		{
			field: 'status',
			headerName: t('common.status'),
			flex: 1,
			renderCell: (params) => (
				<Chip
					variant='outlined'
					label={params.row.status.desc}
					color={
						(statusChipList[params.row.status.type || '1']
							?.color as 'chipGrey') || 'chipGrey'
					}
				/>
			),
		},
		{
			field: 'pushTime',
			headerName: t('common.estimatedReleaseDate'),
			flex: 1,
		},
		{
			field: 'pullTime',
			headerName: t('common.estimatedRemoveDate'),
			flex: 1,
		},
		{
			field: 'createdAt',
			headerName: t('common.createDate'),
			flex: 1,
		},
		{
			field: 'edit',
			headerName: '',
			width: 68,
			renderCell: (params) => (
				<Link
					to={`/mental-health-info/${menuCode}/info/${params.id}`}
					state={{
						propertySummarySearchParams: qs.stringify(transformedSearchParams),
					}}
				>
					<IconButton color='primary'>
						<VisibilityOutlinedIcon />
					</IconButton>
				</Link>
			),
		},
	];

	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>
					{categoryId == ArticleCategory.latestNews &&
						t('mentalHealthInfo.latestNews')}
					{categoryId == ArticleCategory.knowMentalHealth &&
						t('mentalHealthInfo.knowMentalHealth')}
					{categoryId == ArticleCategory.storyShare &&
						t('mentalHealthInfo.lifeStorySharing')}
					{categoryId == ArticleCategory.columnShare &&
						t('mentalHealthInfo.columnSharing')}
				</Typography>

				<Stack direction={'row'} alignItems={'center'} spacing={3}>
					{userRoles.reorder &&
						statusType == ArticlestatusType.released &&
						(categoryId == ArticleCategory.knowMentalHealth ? (
							<ReorderArticlesModal
								menuCode={menuCode}
								transformedSearchParams={transformedSearchParams}
							/>
						) : (
							<Button
								variant='outlined'
								startIcon={<SwapVertIcon />}
								onClick={() =>
									navigate(`/mental-health-info/${menuCode}/order/0`, {
										state: {
											propertySummarySearchParams: qs.stringify(
												transformedSearchParams
											),
										},
									})
								}
								style={{ alignItems: 'center' }}
							>
								{t('button.reorder')}
							</Button>
						))}

					{userRoles.setStatus && (
						<SettingStatusModal
							currentStatus={statusType}
							selectedId={selectedId}
							setSelectedId={setSelectedId}
							articleStatusList={articleStatusList}
						/>
					)}

					{userRoles.create && (
						<Button
							variant='contained'
							startIcon={<AddIcon />}
							onClick={() =>
								navigate(`/mental-health-info/${menuCode}/add`, {
									state: {
										propertySummarySearchParams: qs.stringify(
											transformedSearchParams
										),
									},
								})
							}
							style={{ alignItems: 'center' }}
						>
							{t('button.addInfo')}
						</Button>
					)}
				</Stack>
			</Box>
			<Card sx={{ marginTop: 3 }}>
				<CardContent>
					<FormProvider {...methods}>
						<MentalHealthInfoSummaryFilterSection
							districtsOptions={districtsOptions}
							articleTypeList={articleTypeList}
							menuCode={menuCode}
							setSelectedId={setSelectedId}
							statusType={statusType}
							statusList={statusList}
							pageCount={pageCount?.data || {}}
						/>

						<Box marginTop={2}>
							<AppDataGrid
								loading={!isGetPropertySummarySuccess}
								rows={transformResponse(propertySummary?.data?.items) ?? []}
								columns={columns}
								rowCount={propertySummary?.data?.pagination.totalItems ?? 0}
								getRowId={(row) => row.id}
								checkboxSelection
								selectionModel={selectedId}
								onSelectionModelChange={(ids) => {
									setSelectedId(ids.map((id) => String(id)));
								}}
							/>
						</Box>
					</FormProvider>
				</CardContent>
			</Card>
		</>
	);
}
