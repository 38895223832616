import {
	Badge,
	BadgeProps,
	Grid,
	Stack,
	Tab,
	Tabs,
	Typography,
	styled,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from '@/utils/routeHelper';
import { Link, useLocation } from 'react-router-dom';
import AppSummarySearchBar from '@/common/components/form-inputs/AppSummarySearchBar';
import {
	RemoveUserCountRes,
	RemoveUserFilter,
} from '@/api/DTO/user/removeUser.interface';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		position: 'relative',
		top: 12,
		backgroundColor: '#FFDAD6',
		color: '#FF5449',
	},
	'&.badge-active .MuiBadge-badge': {
		backgroundColor: '#FF5449',
		color: '#fff',
	},
}));

interface Props {
	methods: any;
	pageCount: RemoveUserCountRes;
}

export default function RemoveUserSummaryFilterSection(prop: Props) {
	const { methods, pageCount } = prop;
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { control } = useFormContext<RemoveUserFilter>();

	const routeMatch = useRouteMatch(pathname, [
		'/users/remove-user/member',
		'/users/remove-user/no-member',
	]);
	const currentTab = routeMatch?.pattern?.path;

	const tabList = [
		{
			name: 'member-user',
			tabText: t('tabs.memberUser'),
			tabValue: `/users/remove-user/member`,
			tabTo: `/users/remove-user/member`,
			countKey: 'orgUser',
		},
		{
			name: 'no-member-user',
			tabText: t('tabs.noMemberUser'),
			tabValue: `/users/remove-user/no-member`,
			tabTo: `/users/remove-user/no-member`,
			countKey: 'freeUser',
		},
	];

	const handleTabChange = () => {
		methods.reset();
	};
	return (
		<Stack>
			<Tabs
				value={currentTab}
				sx={{ borderBottom: 1, borderColor: 'divider' }}
				onChange={handleTabChange}
			>
				{currentTab &&
					tabList.map((item) => (
						<Tab
							key={item.name}
							label={
								<>
									{Number(pageCount[item.countKey]) > 0 ? (
										<StyledBadge
											badgeContent={pageCount[item.countKey]}
											className={
												currentTab.indexOf(item.name) > -1 ? 'badge-active' : ''
											}
										>
											<Typography variant='body1'>
												&nbsp;{item.tabText}
											</Typography>
										</StyledBadge>
									) : (
										<Typography variant='body1'>{item.tabText}</Typography>
									)}
								</>
							}
							value={item.tabValue}
							to={item.tabTo}
							component={Link}
						/>
					))}
			</Tabs>

			<Grid container spacing={3} alignItems='center' marginTop={0}>
				<Grid item xs={2.5}>
					<AppSummarySearchBar
						name='loginID'
						control={control}
						placeholder={t('common.pleaseEnterKeywordSearch')}
					/>
				</Grid>
			</Grid>
		</Stack>
	);
}
