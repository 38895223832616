import { RelativePageFilter } from '@/api/DTO/relative/relative.interface';
import AppSummarySearchBar from '@/common/components/form-inputs/AppSummarySearchBar';
import { Button, Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import { useUnblockFriendsMutation } from '@/api/friend/friendApiSlice';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
	selectedUserItem: any[];
	setSelectedUser: any;
	setSelectedUserItem: any;
}

export default function ClockRelativeSummaryFilterSection(props: Props) {
	const { selectedUserItem, setSelectedUser, setSelectedUserItem } = props;
	const { t } = useTranslation();
	const { control } = useFormContext<RelativePageFilter>();
	const [unblockFriends] = useUnblockFriendsMutation();
	const showModal = useModal();

	const handleUnclock = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('relative.unclockUser')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('relative.isUnclockUser')}
						</Typography>
						<ul>
							{selectedUserItem.map((user) => {
								return (
									<li key={user.userId}>
										<Typography variant='body1'>{user?.username}</Typography>
									</li>
								);
							})}
						</ul>
					</>
				}
				cancellationText={''}
				confirmationText={t('button.confirm')}
				confirmationButtonProps={{ variant: 'contained' }}
				onConfirm={async () => {
					const response = await unblockFriends({
						friendBlockIds: selectedUserItem.map((item) => item.friendBlockId),
					});
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.unclockUserSuccess'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});

						setSelectedUser([]);
						setSelectedUserItem([]);
					}
				}}
			/>
		));
	};

	return (
		<Grid container rowSpacing={2} columnSpacing={1}>
			<Grid item xs={3}>
				<AppSummarySearchBar
					name='searchKey'
					control={control}
					placeholder={t('common.pleaseEnterKeywordSearch')}
				/>
			</Grid>
			<Grid
				item
				xs={9}
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
				}}
			>
				<Button
					variant='contained'
					onClick={handleUnclock}
					disabled={selectedUserItem.length < 1}
				>
					<RotateLeftOutlinedIcon />
					{t('button.unclockFriend')}
				</Button>
			</Grid>
		</Grid>
	);
}
