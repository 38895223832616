import { Box, Card, CardContent, Chip, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ResourcesForm from './components/resourcesForm';
import { FormType } from '@/api/enum/formType.enum';
import { useState } from 'react';
import AppFieldView, {
	AppFormLabel,
} from '@/common/components/form-view/AppFieldView';
export default function CreateResourcesPage() {
	const { t } = useTranslation();
	const [type, setType] = useState<FormType>(FormType.create);
	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Box>
					<Typography variant='h4'>
						{t('communityResources.createCommunityResources')}
					</Typography>
					<Card sx={{ marginTop: 3 }}>
						<CardContent sx={{ padding: '24px', paddingTop: 0 }}>
							<Grid container spacing={3} marginTop={0} marginBottom={3}>
								<Grid item xs={6}>
									<AppFieldView label={t('common.creationTime')} value={'-'} />
								</Grid>
								<Grid item xs={6}>
									<AppFormLabel>{t('common.status')}</AppFormLabel>
									<Box display='flex' justifyContent='space-between'>
										<Chip
											variant='outlined'
											label={'草稿'}
											color={'chipGrey'}
										/>
									</Box>
								</Grid>
							</Grid>
							<ResourcesForm type={type} setType={setType} />
						</CardContent>
					</Card>
				</Box>
			</Box>
		</>
	);
}
