import Logo from '@/assets/images/Logo.png';
import { Box, styled } from '@mui/material';
interface AuthContainerProps {}

const ContentBox = styled(Box)({
	display: 'flex',
	marginTop: '150px',
	justifyContent: 'center',
	height: '100vh',
});

export default function AuthContainer(
	props: React.PropsWithChildren<AuthContainerProps>
) {
	return (
		<>
			<ContentBox sx={{ background: 'background.default' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						paddingY: '15px',
						position: 'absolute',
						top: 0,
						background: '#fff',
						width: '100%',
					}}
				>
					<img src={Logo} alt='logo' width='120px' />
				</Box>
				{props.children}
			</ContentBox>
		</>
	);
}
