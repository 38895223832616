import { RelativeStatus } from '@/api/enum/relatives.enum';
import { Chip } from '@mui/material';
import { TFunction } from 'i18next';

export default function getRelativeStatusChip(
	status: RelativeStatus,
	t: TFunction
): JSX.Element {
	switch (status) {
		case RelativeStatus.NORMAL:
			return (
				<Chip
					label={t('relative.normal')}
					color='chipGreen'
					variant='outlined'
				/>
			);
		case RelativeStatus.REQUESTED:
			return (
				<Chip
					label={t('relative.requested')}
					color='chipLightGrey'
					variant='outlined'
				/>
			);
		// case RelativeStatus.BLOCKED:
		// 	return (
		// 		<Chip
		// 			label={t('relative.blocked')}
		// 			color='chipRed'
		// 			variant='outlined'
		// 		/>
		// 	);
		default:
			return <Chip label='Unknown Status' color='default' variant='outlined' />;
	}
}
