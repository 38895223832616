import { apiSlice } from '../apiSlice';
import { SuccessResponse, Paginated } from '../DTO/common.interface';
import {
	RemoveUserCountRes,
	RemoveUserDetail,
	RemoveUserFilter,
	RemoveUserListItem,
} from '../DTO/user/removeUser.interface';
import { AddUserDTO, AddUserResDTO } from '../DTO/user/user.interface';

export const removeUserApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getMemberList: builder.query<
			SuccessResponse<Paginated<RemoveUserListItem>>,
			RemoveUserFilter
		>({
			query: (body) => ({
				url: `/web/remove/org/page`,
				method: 'POST',
				body,
			}),
			providesTags: ['MemberList'],
		}),

		getNoMemberList: builder.query<
			SuccessResponse<Paginated<RemoveUserListItem>>,
			RemoveUserFilter
		>({
			query: (body) => ({
				url: `/web/remove/free/page`,
				method: 'POST',
				body,
			}),
			providesTags: ['NoMemberList'],
		}),

		getRemveUserCount: builder.query<SuccessResponse<RemoveUserCountRes>, null>(
			{
				query: () => `/web/remove/user-total`,
				providesTags: ['RemoveUserCount'],
			}
		),

		getMemberDetail: builder.query<SuccessResponse<RemoveUserDetail>, string>({
			query: (id) => `/web/remove/org/detail/${id}`,
		}),

		getNoMemberDetail: builder.query<SuccessResponse<RemoveUserDetail>, string>(
			{
				query: (id) => `/web/remove/free/detail/${id}`,
			}
		),

		recoverUser: builder.mutation<SuccessResponse<any>, string>({
			query: (id) => ({
				url: `/web/remove/restore/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['RemoveUserCount', 'MemberList', 'NoMemberList'],
		}),

		deleteUser: builder.mutation<SuccessResponse<any>, string>({
			query: (id) => ({
				url: `/web/remove/delete/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['RemoveUserCount', 'MemberList', 'NoMemberList'],
		}),
	}),
});

export const {
	useRecoverUserMutation,
	useDeleteUserMutation,
	useGetMemberListQuery,
	useGetNoMemberListQuery,
	useGetRemveUserCountQuery,
	useGetMemberDetailQuery,
	useGetNoMemberDetailQuery,
} = removeUserApiSlice;
