import { UserRole } from '../api/enum/userRole.enum';

export const menuPermission: any = {
	[UserRole.ROLE_ROOT_ACCOUNT]: [
		'/',
		'/staffs',
		'/staffs/staff-list',
		'/staffs/staff-list/*',
		'/users/remove-user',
		'/users/remove-user/*',
	],
	[UserRole.ROLE_SUPERVISOR]: [
		'/',
		'/staffs',
		'/staffs/staff-list',
		'/staffs/staff-list/*',
		'/users',
		'/users/member-user',
		'/users/member-user/*',
		'/users/non-member-user',
		'/users/non-member-user/*',
		'/users/application',
		'/users/application/*',
		'/users/remove-user',
		'/users/remove-user/*',
		'/resources',
		'/resources/resources-list',
		'/resources/resources-list/*',
		'/study-course',
		'/study-course/study-course-list',
		'/study-course/study-course-list/*',
		'/items',
		'/items/member-reward',
		'/items/member-reward/*',
		'/items/virtual-props-reward',
		'/items/virtual-props-reward/*',
		'/items/user-coupon-list',
		'/items/user-coupon-list/*',
		'/items/user-mission-list',
		'/items/user-mission-list/*',
		'/mental-health-info',
		'/mental-health-info/latest-news',
		'/mental-health-info/latest-news/*',
		'/mental-health-info/know-mental-health',
		'/mental-health-info/know-mental-health/*',
		'/mental-health-info/story-share',
		'/mental-health-info/story-share/*',
		'/mental-health-info/column-share',
		'/mental-health-info/column-share/*',
		'/surveys',
		'/surveys/survey-list',
		'/surveys/survey-list/*',
		'/app',
		'/app/*',
		'/reports',
		'/reports/*',
		'/reports/report-list',
		'/reports/report-list/*',
		'/study-course',
		'/study-course/*',
		'/study-course/study-course-list',
		'/study-course/study-course-list/*',
	],
	[UserRole.ROLE_PROJECT_IN_CHARGE]: [
		'/',
		'/staffs',
		'/staffs/staff-list',
		'/staffs/staff-list/*',
		'/users',
		'/users/member-user',
		'/users/member-user/*',
		'/users/non-member-user',
		'/users/non-member-user/*',
		'/users/application',
		'/users/application/*',
		'/users/remove-user',
		'/users/remove-user/*',
		'/resources',
		'/resources/resources-list',
		'/resources/resources-list/*',
		'/items',
		'/items/member-reward',
		'/items/member-reward/*',
		'/items/virtual-props-reward',
		'/items/virtual-props-reward/*',
		'/items/user-coupon-list',
		'/items/user-coupon-list/*',
		'/items/user-mission-list',
		'/items/user-mission-list/*',
		'/mental-health-info',
		'/mental-health-info/latest-news',
		'/mental-health-info/latest-news/*',
		'/mental-health-info/know-mental-health',
		'/mental-health-info/know-mental-health/*',
		'/mental-health-info/story-share',
		'/mental-health-info/story-share/*',
		'/mental-health-info/column-share',
		'/mental-health-info/column-share/*',
		'/surveys',
		'/surveys/survey-list',
		'/surveys/survey-list/*',
		'/app',
		'/app/*',
		'/reports',
		'/reports/*',
		'/reports/report-list',
		'/reports/report-list/*',
		'/study-course',
		'/study-course/*',
		'/study-course/study-course-list',
		'/study-course/study-course-list/*',
	],
	[UserRole.ROLE_PROFESSIONAL_STAFF]: [
		'/',
		'/staffs',
		'/staffs/staff-list',
		'/staffs/staff-list/*',
		'/users',
		'/users/member-user',
		'/users/member-user/*',
		'/users/non-member-user',
		'/users/non-member-user/*',
		'/users/application',
		'/users/application/*',
		'/users/remove-user',
		'/users/remove-user/*',
		'/resources',
		'/resources/resources-list',
		'/resources/resources-list/*',
		'/items',
		'/items/member-reward',
		'/items/member-reward/*',
		'/items/virtual-props-reward',
		'/items/virtual-props-reward/*',
		'/items/user-coupon-list',
		'/items/user-coupon-list/*',
		'/items/user-mission-list',
		'/items/user-mission-list/*',
		'/mental-health-info',
		'/mental-health-info/latest-news',
		'/mental-health-info/latest-news/*',
		'/mental-health-info/know-mental-health',
		'/mental-health-info/know-mental-health/*',
		'/mental-health-info/story-share',
		'/mental-health-info/story-share/*',
		'/mental-health-info/column-share',
		'/mental-health-info/column-share/*',
		'/surveys',
		'/surveys/survey-list',
		'/surveys/survey-list/*',
		'/app',
		'/app/*',
		'/reports',
		'/reports/*',
		'/reports/report-list',
		'/reports/report-list/*',
		'/study-course',
		'/study-course/*',
		'/study-course/study-course-list',
		'/study-course/study-course-list/*',
	],
	[UserRole.ROLE_SUPPORTING_STAFF]: [
		'/',
		'/staffs',
		'/staffs/staff-list',
		'/staffs/staff-list/*',
		'/users',
		'/users/member-user',
		'/users/member-user/*',
		'/users/non-member-user',
		'/users/non-member-user/*',
		'/users/application',
		'/users/application/*',
		'/users/remove-user',
		'/users/remove-user/*',
		'/resources',
		'/resources/resources-list',
		'/resources/resources-list/*',
		'/items',
		'/items/member-reward',
		'/items/member-reward/*',
		'/items/virtual-props-reward',
		'/items/virtual-props-reward/*',
		'/items/user-coupon-list',
		'/items/user-coupon-list/*',
		'/items/user-mission-list',
		'/items/user-mission-list/*',
		'/mental-health-info',
		'/mental-health-info/latest-news',
		'/mental-health-info/latest-news/*',
		'/mental-health-info/know-mental-health',
		'/mental-health-info/know-mental-health/*',
		'/mental-health-info/story-share',
		'/mental-health-info/story-share/*',
		'/mental-health-info/column-share',
		'/mental-health-info/column-share/*',
		'/surveys',
		'/surveys/survey-list',
		'/surveys/survey-list/*',
		'/app',
		'/app/*',
		'/reports',
		'/reports/*',
		'/reports/report-list',
		'/reports/report-list/*',
		'/study-course',
		'/study-course/*',
		'/study-course/study-course-list',
		'/study-course/study-course-list/*',
	],
};
