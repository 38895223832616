import {
	ArticlestatusType,
	CommunityResourcesType,
	StudyCourseType,
} from '@/api/enum/common.enum';
import { UserRole } from '../api/enum/userRole.enum';
import { defineAbility } from '@casl/ability';
export default (role: string) =>
	defineAbility((can, cannot) => {
		staffListAbility(can, role);
		allApplicationAbility(can, role);
		userManagementAbility(can, role);
		mentalHealthAbility(can, role);
		communityResourcesAbility(can, role);
		surveyAbility(can, role);
		itemAbility(can, role);
		removeUserAbility(can, role);
		studyCourseAbility(can, role);
	});

const staffListAbility = (
	can: (arg0: string, arg1: string, arg2?: string) => void,
	role: any
) => {
	switch (role) {
		case UserRole.ROLE_ROOT_ACCOUNT: {
			can('create', 'staffList');
			can('edit', 'staffList', UserRole.ROLE_SUPERVISOR);
			can('edit', 'staffList', UserRole.ROLE_PROJECT_IN_CHARGE);
			can('edit', 'staffList', UserRole.ROLE_PROFESSIONAL_STAFF);
			can('edit', 'staffList', UserRole.ROLE_SUPPORTING_STAFF);
			can('delete', 'staffList', UserRole.ROLE_SUPERVISOR);
			can('delete', 'staffList', UserRole.ROLE_PROJECT_IN_CHARGE);
			can('delete', 'staffList', UserRole.ROLE_PROFESSIONAL_STAFF);
			can('delete', 'staffList', UserRole.ROLE_SUPPORTING_STAFF);
			break;
		}
		case UserRole.ROLE_SUPERVISOR: {
			can('create', 'staffList');
			can('edit', 'staffList', UserRole.ROLE_PROJECT_IN_CHARGE);
			can('edit', 'staffList', UserRole.ROLE_PROFESSIONAL_STAFF);
			can('edit', 'staffList', UserRole.ROLE_SUPPORTING_STAFF);
			can('delete', 'staffList', UserRole.ROLE_PROJECT_IN_CHARGE);
			can('delete', 'staffList', UserRole.ROLE_PROFESSIONAL_STAFF);
			can('delete', 'staffList', UserRole.ROLE_SUPPORTING_STAFF);
			break;
		}
		case UserRole.ROLE_PROJECT_IN_CHARGE: {
			can('create', 'staffList');
			can('edit', 'staffList', UserRole.ROLE_PROFESSIONAL_STAFF);
			can('edit', 'staffList', UserRole.ROLE_SUPPORTING_STAFF);
			can('delete', 'staffList', UserRole.ROLE_PROFESSIONAL_STAFF);
			can('delete', 'staffList', UserRole.ROLE_SUPPORTING_STAFF);
			break;
		}
		case UserRole.ROLE_PROFESSIONAL_STAFF: {
			break;
		}
		case UserRole.ROLE_SUPPORTING_STAFF: {
			break;
		}
	}
};

const allApplicationAbility = (
	can: (arg0: string, arg1: string, arg2?: string) => void,
	role: any
) => {
	switch (role) {
		case UserRole.ROLE_ROOT_ACCOUNT: {
			break;
		}
		case UserRole.ROLE_SUPERVISOR: {
			can('passJoinMember', 'allApplication');
			can('rejectJoinMember', 'allApplication');
			can('passOutMember', 'allApplication');
			can('rejectOutMember', 'allApplication');
			can('passJoinCentre', 'allApplication');
			can('rejectJoinCentre', 'allApplication');
			can('passOutCentre', 'allApplication');
			can('rejectOutCentre', 'allApplication');
			can('allApplicationNavBarCount', 'allApplication');
			break;
		}
		case UserRole.ROLE_PROJECT_IN_CHARGE: {
			can('passJoinMember', 'allApplication');
			can('rejectJoinMember', 'allApplication');
			can('passOutMember', 'allApplication');
			can('rejectOutMember', 'allApplication');
			can('passJoinCentre', 'allApplication');
			can('rejectJoinCentre', 'allApplication');
			can('passOutCentre', 'allApplication');
			can('rejectOutCentre', 'allApplication');
			can('allApplicationNavBarCount', 'allApplication');
			break;
		}
		case UserRole.ROLE_PROFESSIONAL_STAFF: {
			can('passJoinMember', 'allApplication');
			can('rejectJoinMember', 'allApplication');
			can('passOutMember', 'allApplication');
			can('rejectOutMember', 'allApplication');
			can('passJoinCentre', 'allApplication');
			can('rejectJoinCentre', 'allApplication');
			can('passOutCentre', 'allApplication');
			can('rejectOutCentre', 'allApplication');
			can('allApplicationNavBarCount', 'allApplication');
			break;
		}
		case UserRole.ROLE_SUPPORTING_STAFF: {
			can('passJoinMember', 'allApplication');
			can('rejectJoinMember', 'allApplication');
			can('passJoinCentre', 'allApplication');
			can('rejectJoinCentre', 'allApplication');
			can('passOutCentre', 'allApplication');
			can('rejectOutCentre', 'allApplication');
			can('allApplicationNavBarCount', 'allApplication');
			break;
		}
	}
};

const userManagementAbility = (
	can: (arg0: string, arg1: string, arg2?: string) => void,
	role: any
) => {
	switch (role) {
		case UserRole.ROLE_ROOT_ACCOUNT: {
			break;
		}
		case UserRole.ROLE_SUPERVISOR: {
			can('read', 'user', 'user-list');
			can('create', 'user');
			can('read', 'user', 'user-info');
			can('edit', 'user', 'user-info');
			can('edit', 'user', 'username');
			can('edit', 'user', 'phone');
			can('edit', 'user', 'email');
			can('edit', 'user', 'password');
			can('read', 'user', 'user-stat');
			can('export', 'user');
			can('edit', 'user', 'coin');
			can('edit', 'user', 'exp');
			can('edit', 'user', 'walk-step');
			can('send', 'user');
			can('centre', 'user');
			can('freeze', 'user');
			can('unfreeze', 'user');
			can('delete', 'user');
			can('read', 'relative-list');
			can('read', 'relative-application-list');
			can('read', 'relative-application-permission');
			can('create', 'relative');
			can('edit', 'relative-permission');
			can('delete', 'relative');
			can('block', 'relative');
			break;
		}
		case UserRole.ROLE_PROJECT_IN_CHARGE: {
			can('read', 'user', 'user-list');
			can('create', 'user');
			can('read', 'user', 'user-info');
			can('edit', 'user', 'user-info');
			can('edit', 'user', 'username');
			can('edit', 'user', 'phone');
			can('edit', 'user', 'email');
			can('edit', 'user', 'password');
			can('read', 'user', 'user-stat');
			can('export', 'user');
			can('edit', 'user', 'coin');
			can('edit', 'user', 'exp');
			can('edit', 'user', 'walk-step');
			can('send', 'user');
			can('centre', 'user');
			can('freeze', 'user');
			can('unfreeze', 'user');
			can('delete', 'user');
			can('read', 'relative-list');
			can('read', 'relative-application-list');
			can('read', 'relative-application-permission');
			can('create', 'relative');
			can('edit', 'relative-permission');
			can('delete', 'relative');
			can('block', 'relative');
			break;
		}
		case UserRole.ROLE_PROFESSIONAL_STAFF: {
			can('read', 'user', 'user-list');
			can('create', 'user');
			can('read', 'user', 'user-info');
			can('edit', 'user', 'user-info');
			can('edit', 'user', 'username');
			can('edit', 'user', 'phone');
			can('edit', 'user', 'email');
			can('edit', 'user', 'password');
			can('read', 'user', 'user-stat');
			can('export', 'user');
			can('edit', 'user', 'coin');
			can('edit', 'user', 'exp');
			can('edit', 'user', 'walk-step');
			can('send', 'user');
			can('centre', 'user');
			can('read', 'relative-list');
			can('read', 'relative-application-list');
			can('read', 'relative-application-permission');
			can('create', 'relative');
			can('edit', 'relative-permission');
			can('delete', 'relative');
			can('block', 'relative');
			break;
		}
		case UserRole.ROLE_SUPPORTING_STAFF: {
			can('read', 'user', 'user-list');
			can('create', 'user');
			can('read', 'user', 'user-info');
			can('edit', 'user', 'user-info');
			can('edit', 'user', 'username');
			can('edit', 'user', 'phone');
			can('edit', 'user', 'email');
			can('edit', 'user', 'password');
			can('read', 'user', 'user-stat');
			can('export', 'user');
			can('edit', 'user', 'coin');
			can('edit', 'user', 'exp');
			can('edit', 'user', 'walk-step');
			can('send', 'user');
			can('read', 'relative-list');
			can('read', 'relative-application-list');
			can('read', 'relative-application-permission');
			can('create', 'relative');
			can('edit', 'relative-permission');
			can('delete', 'relative');
			can('block', 'relative');
			break;
		}
	}
};

const mentalHealthAbility = (
	can: (arg0: string, arg1: string, arg2?: string) => void,
	role: any
) => {
	switch (role) {
		case UserRole.ROLE_ROOT_ACCOUNT: {
			break;
		}
		case UserRole.ROLE_SUPERVISOR: {
			can('knowMentalHealthNavBarCount', 'mentalHealth');
			can('storyShareNavBarCount', 'mentalHealth');
			can('columnShareNavBarCount', 'mentalHealth');
			can('create', 'mentalHealth');
			can('statusRemark', 'mentalHealth');
			can('reorder', 'mentalHealth');
			can('delete', 'mentalHealth', String(ArticlestatusType.draft));
			can('delete', 'mentalHealth', String(ArticlestatusType.directorApproval));
			can(
				'delete',
				'mentalHealth',
				String(ArticlestatusType.supervisorApproval)
			);
			can('delete', 'mentalHealth', String(ArticlestatusType.awaitingRelease));
			can('delete', 'mentalHealth', String(ArticlestatusType.released));
			can('edit', 'mentalHealth', String(ArticlestatusType.draft));
			can('edit', 'mentalHealth', String(ArticlestatusType.directorApproval));
			can('edit', 'mentalHealth', String(ArticlestatusType.supervisorApproval));
			can('edit', 'mentalHealth', String(ArticlestatusType.awaitingRelease));
			can('edit', 'mentalHealth', String(ArticlestatusType.released));
			can('setStatus', 'mentalHealth', String(ArticlestatusType.draft));
			can(
				'setStatus',
				'mentalHealth',
				String(ArticlestatusType.directorApproval)
			);
			can(
				'setStatus',
				'mentalHealth',
				String(ArticlestatusType.supervisorApproval)
			);
			can(
				'setStatus',
				'mentalHealth',
				String(ArticlestatusType.awaitingRelease)
			);
			can('setStatus', 'mentalHealth', String(ArticlestatusType.released));
			break;
		}
		case UserRole.ROLE_PROJECT_IN_CHARGE: {
			can('knowMentalHealthNavBarCount', 'mentalHealth');
			can('storyShareNavBarCount', 'mentalHealth');
			can('columnShareNavBarCount', 'mentalHealth');
			can('create', 'mentalHealth');
			can('statusRemark', 'mentalHealth');
			can('reorder', 'mentalHealth');
			can('delete', 'mentalHealth', String(ArticlestatusType.draft));
			can('delete', 'mentalHealth', String(ArticlestatusType.directorApproval));
			can(
				'delete',
				'mentalHealth',
				String(ArticlestatusType.supervisorApproval)
			);
			can('delete', 'mentalHealth', String(ArticlestatusType.awaitingRelease));
			can('delete', 'mentalHealth', String(ArticlestatusType.released));
			can('edit', 'mentalHealth', String(ArticlestatusType.draft));
			can('edit', 'mentalHealth', String(ArticlestatusType.directorApproval));
			can('setStatus', 'mentalHealth', String(ArticlestatusType.draft));
			can(
				'setStatus',
				'mentalHealth',
				String(ArticlestatusType.directorApproval)
			);
			can(
				'setStatus',
				'mentalHealth',
				String(ArticlestatusType.supervisorApproval)
			);
			can(
				'setStatus',
				'mentalHealth',
				String(ArticlestatusType.awaitingRelease)
			);
			can('setStatus', 'mentalHealth', String(ArticlestatusType.released));
			break;
		}
		case UserRole.ROLE_PROFESSIONAL_STAFF: {
			can('knowMentalHealthNavBarCount', 'mentalHealth');
			can('storyShareNavBarCount', 'mentalHealth');
			can('columnShareNavBarCount', 'mentalHealth');
			can('create', 'mentalHealth');
			can('reorder', 'mentalHealth');
			can('delete', 'mentalHealth', String(ArticlestatusType.draft));
			can('edit', 'mentalHealth', String(ArticlestatusType.draft));
			can('setStatus', 'mentalHealth', String(ArticlestatusType.draft));
			can(
				'setStatus',
				'mentalHealth',
				String(ArticlestatusType.directorApproval)
			);
			can(
				'setStatus',
				'mentalHealth',
				String(ArticlestatusType.awaitingRelease)
			);
			can('setStatus', 'mentalHealth', String(ArticlestatusType.released));
			break;
		}
		case UserRole.ROLE_SUPPORTING_STAFF: {
			can('knowMentalHealthNavBarCount', 'mentalHealth');
			can('storyShareNavBarCount', 'mentalHealth');
			can('columnShareNavBarCount', 'mentalHealth');
			can('create', 'mentalHealth');
			can('reorder', 'mentalHealth');
			can('delete', 'mentalHealth', String(ArticlestatusType.draft));
			can('edit', 'mentalHealth', String(ArticlestatusType.draft));
			can('setStatus', 'mentalHealth', String(ArticlestatusType.draft));
			can(
				'setStatus',
				'mentalHealth',
				String(ArticlestatusType.directorApproval)
			);
			can(
				'setStatus',
				'mentalHealth',
				String(ArticlestatusType.awaitingRelease)
			);
			can('setStatus', 'mentalHealth', String(ArticlestatusType.released));
			break;
		}
	}
};

const communityResourcesAbility = (
	can: (arg0: string, arg1: string, arg2?: string) => void,
	role: any
) => {
	switch (role) {
		case UserRole.ROLE_ROOT_ACCOUNT: {
			break;
		}
		case UserRole.ROLE_SUPERVISOR: {
			can('communityResourcesNavBarCount', 'communityResources');
			can('statusRemark', 'communityResources');
			can('create', 'communityResources');
			can('delete', 'communityResources', String(CommunityResourcesType.draft));
			can(
				'delete',
				'communityResources',
				String(CommunityResourcesType.directorApproval)
			);
			can(
				'delete',
				'communityResources',
				String(CommunityResourcesType.supervisorApproval)
			);
			can(
				'delete',
				'communityResources',
				String(CommunityResourcesType.awaitingRelease)
			);
			can(
				'delete',
				'communityResources',
				String(CommunityResourcesType.removed)
			);
			can(
				'delete',
				'communityResources',
				String(CommunityResourcesType.released)
			);
			can('edit', 'communityResources', String(CommunityResourcesType.draft));
			can(
				'edit',
				'communityResources',
				String(CommunityResourcesType.directorApproval)
			);
			can(
				'edit',
				'communityResources',
				String(CommunityResourcesType.supervisorApproval)
			);
			can(
				'edit',
				'communityResources',
				String(CommunityResourcesType.awaitingRelease)
			);
			can(
				'edit',
				'communityResources',
				String(CommunityResourcesType.released)
			);
			can('edit', 'communityResources', String(CommunityResourcesType.removed));
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.draft)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.directorApproval)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.supervisorApproval)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.awaitingRelease)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.released)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.removed)
			);
			break;
		}
		case UserRole.ROLE_PROJECT_IN_CHARGE: {
			can('communityResourcesNavBarCount', 'communityResources');
			can('statusRemark', 'communityResources');
			can('create', 'communityResources');
			can('delete', 'communityResources', String(CommunityResourcesType.draft));
			can(
				'delete',
				'communityResources',
				String(CommunityResourcesType.directorApproval)
			);
			can(
				'delete',
				'communityResources',
				String(CommunityResourcesType.supervisorApproval)
			);
			can(
				'delete',
				'communityResources',
				String(CommunityResourcesType.awaitingRelease)
			);
			can(
				'delete',
				'communityResources',
				String(CommunityResourcesType.released)
			);
			can(
				'delete',
				'communityResources',
				String(CommunityResourcesType.removed)
			);
			can('edit', 'communityResources', String(CommunityResourcesType.draft));
			can(
				'edit',
				'communityResources',
				String(CommunityResourcesType.directorApproval)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.draft)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.directorApproval)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.supervisorApproval)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.awaitingRelease)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.released)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.removed)
			);
			break;
		}
		case UserRole.ROLE_PROFESSIONAL_STAFF: {
			can('communityResourcesNavBarCount', 'communityResources');
			can('create', 'communityResources');
			can('delete', 'communityResources', String(CommunityResourcesType.draft));
			can('edit', 'communityResources', String(CommunityResourcesType.draft));
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.draft)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.directorApproval)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.awaitingRelease)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.released)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.removed)
			);
			break;
		}
		case UserRole.ROLE_SUPPORTING_STAFF: {
			can('communityResourcesNavBarCount', 'communityResources');
			can('create', 'communityResources');
			can('delete', 'communityResources', String(CommunityResourcesType.draft));
			can('edit', 'communityResources', String(CommunityResourcesType.draft));
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.draft)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.directorApproval)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.awaitingRelease)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.released)
			);
			can(
				'setStatus',
				'communityResources',
				String(CommunityResourcesType.removed)
			);
			break;
		}
	}
};

const surveyAbility = (
	can: (arg0: string, arg1: string, arg2?: string) => void,
	role: any
) => {
	switch (role) {
		case UserRole.ROLE_ROOT_ACCOUNT: {
			break;
		}
		case UserRole.ROLE_SUPERVISOR: {
			can('read', 'survey', 'survey-list');
			can('create', 'survey');
			can('read', 'survey', 'survey-info');
			can('edit', 'survey');
			can('delete', 'survey');
			can('send', 'survey');
			break;
		}
		case UserRole.ROLE_PROJECT_IN_CHARGE: {
			can('read', 'survey', 'survey-list');
			can('create', 'survey');
			can('read', 'survey', 'survey-info');
			can('edit', 'survey');
			can('delete', 'survey');
			can('send', 'survey');
			break;
		}
		case UserRole.ROLE_PROFESSIONAL_STAFF: {
			can('read', 'survey', 'survey-list');
			can('create', 'survey');
			can('read', 'survey', 'survey-info');
			can('edit', 'survey');
			can('delete', 'survey');
			can('send', 'survey');
			break;
		}
		case UserRole.ROLE_SUPPORTING_STAFF: {
			can('read', 'survey', 'survey-list');
			can('create', 'survey');
			can('read', 'survey', 'survey-info');
			can('edit', 'survey');
			can('delete', 'survey');
			can('send', 'survey');
			break;
		}
	}
};

const itemAbility = (
	can: (arg0: string, arg1: string, arg2?: string) => void,
	role: any
) => {
	switch (role) {
		case UserRole.ROLE_ROOT_ACCOUNT: {
			break;
		}
		case UserRole.ROLE_SUPERVISOR: {
			can('read', 'item-list');
			can('read', 'mission-list');
			can('couponListNavBarCount', 'coupon-list');
			break;
		}
		case UserRole.ROLE_PROJECT_IN_CHARGE: {
			can('read', 'item-list');
			can('read', 'mission-list');
			can('couponListNavBarCount', 'coupon-list');
			break;
		}
		case UserRole.ROLE_PROFESSIONAL_STAFF: {
			can('read', 'item-list');
			can('read', 'mission-list');
			can('couponListNavBarCount', 'coupon-list');
			break;
		}
		case UserRole.ROLE_SUPPORTING_STAFF: {
			can('read', 'item-list');
			can('read', 'mission-list');
			can('couponListNavBarCount', 'coupon-list');
			break;
		}
	}
};

const removeUserAbility = (
	can: (arg0: string, arg1: string, arg2?: string) => void,
	role: any
) => {
	switch (role) {
		case UserRole.ROLE_ROOT_ACCOUNT: {
			break;
		}
		case UserRole.ROLE_SUPERVISOR: {
			can('recover', 'removeUser');
			can('delete', 'removeUser');
			can('removeUserNavBarCount', 'removeUser');
			break;
		}
		case UserRole.ROLE_PROJECT_IN_CHARGE: {
			can('recover', 'removeUser');
			can('delete', 'removeUser');
			can('removeUserNavBarCount', 'removeUser');
			break;
		}
		case UserRole.ROLE_PROFESSIONAL_STAFF: {
			can('recover', 'removeUser');
			can('delete', 'removeUser');
			can('removeUserNavBarCount', 'removeUser');
			break;
		}
		case UserRole.ROLE_SUPPORTING_STAFF: {
			can('recover', 'removeUser');
			can('delete', 'removeUser');
			can('removeUserNavBarCount', 'removeUser');
			break;
		}
	}
};

const studyCourseAbility = (
	can: (arg0: string, arg1: string, arg2?: string) => void,
	role: any
) => {
	switch (role) {
		case UserRole.ROLE_ROOT_ACCOUNT: {
			break;
		}
		case UserRole.ROLE_SUPERVISOR: {
			can('studyCourseNavBarCount', 'studyCourse');
			can('studyCourseCreate', 'studyCourse');
			can('studyCourseDetail', 'studyCourse');
			can('editStudyCourse', 'studyCourse', String(StudyCourseType.draft));
			can(
				'editStudyCourse',
				'studyCourse',
				String(StudyCourseType.directorApproval)
			);
			can(
				'editStudyCourse',
				'studyCourse',
				String(StudyCourseType.supervisorApproval)
			);
			can(
				'editStudyCourse',
				'studyCourse',
				String(StudyCourseType.awaitingRelease)
			);
			can('editStudyCourse', 'studyCourse', String(StudyCourseType.released));
			can('editStudyCourse', 'studyCourse', String(StudyCourseType.removed));
			can('deleteStudyCourse', 'studyCourse', String(StudyCourseType.draft));
			can(
				'deleteStudyCourse',
				'studyCourse',
				String(StudyCourseType.directorApproval)
			);
			can(
				'deleteStudyCourse',
				'studyCourse',
				String(StudyCourseType.supervisorApproval)
			);
			can(
				'deleteStudyCourse',
				'studyCourse',
				String(StudyCourseType.awaitingRelease)
			);
			can('deleteStudyCourse', 'studyCourse', String(StudyCourseType.released));
			can('deleteStudyCourse', 'studyCourse', String(StudyCourseType.removed));
			can('chapterDetail', 'studyCourse');
			can('createChapter', 'studyCourse', String(StudyCourseType.draft));
			can(
				'createChapter',
				'studyCourse',
				String(StudyCourseType.directorApproval)
			);
			can(
				'createChapter',
				'studyCourse',
				String(StudyCourseType.supervisorApproval)
			);
			can('editChapter', 'studyCourse', String(StudyCourseType.draft));
			can(
				'editChapter',
				'studyCourse',
				String(StudyCourseType.directorApproval)
			);
			can(
				'editChapter',
				'studyCourse',
				String(StudyCourseType.supervisorApproval)
			);
			break;
		}
		case UserRole.ROLE_PROJECT_IN_CHARGE: {
			can('studyCourseNavBarCount', 'studyCourse');
			can('studyCourseCreate', 'studyCourse');
			can('studyCourseDetail', 'studyCourse');
			can('editStudyCourse', 'studyCourse', String(StudyCourseType.draft));
			can(
				'editStudyCourse',
				'studyCourse',
				String(StudyCourseType.directorApproval)
			);
			can('deleteStudyCourse', 'studyCourse', String(StudyCourseType.draft));
			can(
				'deleteStudyCourse',
				'studyCourse',
				String(StudyCourseType.directorApproval)
			);
			can(
				'deleteStudyCourse',
				'studyCourse',
				String(StudyCourseType.supervisorApproval)
			);
			can(
				'deleteStudyCourse',
				'studyCourse',
				String(StudyCourseType.awaitingRelease)
			);
			can('deleteStudyCourse', 'studyCourse', String(StudyCourseType.released));
			can('deleteStudyCourse', 'studyCourse', String(StudyCourseType.removed));
			can('chapterDetail', 'studyCourse');
			can('createChapter', 'studyCourse', String(StudyCourseType.draft));
			can(
				'createChapter',
				'studyCourse',
				String(StudyCourseType.directorApproval)
			);
			can('editChapter', 'studyCourse', String(StudyCourseType.draft));
			can(
				'editChapter',
				'studyCourse',
				String(StudyCourseType.directorApproval)
			);
			break;
		}
		case UserRole.ROLE_PROFESSIONAL_STAFF: {
			can('studyCourseNavBarCount', 'studyCourse');
			can('studyCourseCreate', 'studyCourse');
			can('studyCourseDetail', 'studyCourse');
			can('editStudyCourse', 'studyCourse', String(StudyCourseType.draft));
			can('chapterDetail', 'studyCourse');
			can('createChapter', 'studyCourse', String(StudyCourseType.draft));
			can('editChapter', 'studyCourse', String(StudyCourseType.draft));
			break;
		}
		case UserRole.ROLE_SUPPORTING_STAFF: {
			can('studyCourseNavBarCount', 'studyCourse');
			can('studyCourseCreate', 'studyCourse');
			can('studyCourseDetail', 'studyCourse');
			can('editStudyCourse', 'studyCourse', String(StudyCourseType.draft));
			can('chapterDetail', 'studyCourse');
			can('createChapter', 'studyCourse', String(StudyCourseType.draft));
			can('editChapter', 'studyCourse', String(StudyCourseType.draft));
			break;
		}
	}
};

// 以下代碼是上面簡化版，哪個簡單易懂使用哪個

// const permission: any = {
// 	[UserRole.ROLE_ROOT_ACCOUNT]: {
// 	  staff: {
// 		create: true,
// 		edit: [UserRole.ROLE_SUPERVISOR, UserRole.ROLE_PROJECT_IN_CHARGE, UserRole.ROLE_PROFESSIONAL_STAFF, UserRole.ROLE_SUPPORTING_STAFF],
// 		delete: [UserRole.ROLE_SUPERVISOR, UserRole.ROLE_PROJECT_IN_CHARGE, UserRole.ROLE_PROFESSIONAL_STAFF, UserRole.ROLE_SUPPORTING_STAFF],
// 	  },
// 	  application: {
// 		read: ['500', '400'],
// 	  },
// 	},
// 	[UserRole.ROLE_SUPERVISOR]: {
// 	  staff: {
// 		create: true,
// 		edit: [UserRole.ROLE_PROJECT_IN_CHARGE, UserRole.ROLE_PROFESSIONAL_STAFF, UserRole.ROLE_SUPPORTING_STAFF],
// 		delete: [UserRole.ROLE_PROJECT_IN_CHARGE, UserRole.ROLE_PROFESSIONAL_STAFF, UserRole.ROLE_SUPPORTING_STAFF],
// 	  },
// 	  application: {
// 		read: ['500'],
// 	  },
// 	},
// 	[UserRole.ROLE_PROJECT_IN_CHARGE]: {
// 	  staff: {
// 		create: true,
// 		edit: [UserRole.ROLE_PROFESSIONAL_STAFF, UserRole.ROLE_SUPPORTING_STAFF],
// 		delete: [UserRole.ROLE_PROFESSIONAL_STAFF, UserRole.ROLE_SUPPORTING_STAFF],
// 	  },
// 	},
// 	[UserRole.ROLE_PROFESSIONAL_STAFF]: {
// 	  // 专业人员的权限定义
// 	},
// 	[UserRole.ROLE_SUPPORTING_STAFF]: {
// 	  // 支持人员的权限定义
// 	},
//   };

//   export default (role: UserRole) =>
// 	defineAbility((can, cannot) => {
// 	  Object.keys(permissions[role] || {}).forEach((module) => {
// 		Object.keys(permissions[role][module]).forEach((action) => {
// 		  if (Array.isArray(permissions[role][module][action])) {
// 			permissions[role][module][action].forEach((target: any) => {
// 			  can(action, module, target);
// 			});
// 		  } else if (permissions[role][module][action]) {
// 			can(action, module);
// 		  }
// 		});
// 	  });
// 	});
