import {
	setCredentials,
	setResetSessionId,
	setResetPasswordToken,
	setOTPToken,
} from '../pages/auth/authSlice';
import { apiSlice } from './apiSlice';
import {
	EmailLoginDTO,
	AuthLoginResDTO,
	ConfirmPassword,
	ResetSession,
	ResetToken,
	VerifyCode,
	OtpResDTO,
	OtpReqDTO,
	OtpVerificationReqDTO,
	UserLoginResDTO,
	UserSetPasswordReqDTO,
} from './DTO/auth.interface';
import { SuccessResponse } from './DTO/common.interface';

export const authApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation<SuccessResponse<AuthLoginResDTO>, EmailLoginDTO>({
			query: (credentials) => ({
				url: `/web/auth/login`,
				method: 'POST',
				body: { ...credentials },
				credentials: 'include',
			}),
			invalidatesTags: ['GetMe'],
		}),
		refresh: builder.mutation<SuccessResponse<AuthLoginResDTO>, void>({
			query: () => ({
				url: `/web/auth/refresh`,
				method: 'GET',
				credentials: 'include',
			}),
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setCredentials({ accessToken: data.data.accessToken }));
				} catch (err) {
					console.log(err);
				}
			},
		}),
		logout: builder.mutation<SuccessResponse<null>, void>({
			query: () => ({
				url: `/web/auth/logout`,
				method: 'DELETE',
				credentials: 'include',
			}),
		}),
		sendOTP: builder.mutation<SuccessResponse<OtpResDTO>, OtpReqDTO>({
			query: (body) => ({
				url: `/web/auth/otp`,
				method: 'POST',
				body: body,
			}),
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setOTPToken(data.data.otpToken));
				} catch (err) {
					console.log(err);
				}
			},
		}),
		loginVerifyOTP: builder.mutation<
			SuccessResponse<UserLoginResDTO>,
			OtpVerificationReqDTO
		>({
			query: (body) => ({
				url: `/web/auth/login/verify`,
				method: 'POST',
				body: body,
			}),
		}),
		forgotPasswordVerifyOTP: builder.mutation<
			SuccessResponse<UserLoginResDTO>,
			OtpVerificationReqDTO
		>({
			query: (body) => ({
				url: `/web/auth/forgot-password/verify`,
				method: 'POST',
				body: body,
				credentials: 'include',
			}),
		}),
		forgotResetPassword: builder.mutation<
			SuccessResponse<UserSetPasswordReqDTO>,
			any
		>({
			query: (body) => ({
				url: `/web/auth/password`,
				method: 'PUT',
				body: body,
			}),
		}),

		resetPasswordInit: builder.mutation<SuccessResponse<ResetSession>, string>({
			query: (email) => ({
				url: `/admin/reset-password/init`,
				method: 'POST',
				params: { email },
			}),
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setResetSessionId(data.data.id));
				} catch (err) {
					console.log(err);
				}
			},
		}),
		resendEmail: builder.mutation<SuccessResponse<null>, string>({
			query: (id) => ({
				url: `/admin/reset-password/resend`,
				method: 'POST',
				params: { id },
			}),
		}),
		verifyCode: builder.mutation<
			SuccessResponse<ResetToken>,
			{ id: string; body: VerifyCode }
		>({
			query: ({ id, body }) => ({
				url: `/admin/reset-password/verify-code`,
				method: 'POST',
				params: { id },
				body: body,
			}),
			extraOptions: { showSnackbarError: true },
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setResetPasswordToken(data.data.token));
				} catch (err) {
					console.log(err);
				}
			},
		}),
		confirmPassword: builder.mutation<
			SuccessResponse<null>,
			{ id: string; body: ConfirmPassword }
		>({
			query: ({ id, body }) => ({
				url: `admin/reset-password/confirm-password`,
				method: 'POST',
				params: { id },
				body: body,
			}),
		}),

		changePassword: builder.mutation<
			SuccessResponse<null>,
			{ oldPassword: string; password: string }
		>({
			query: (body) => ({
				url: `/web/staffs/password`,
				method: 'PUT',
				body: body,
			}),
		}),
	}),
});

export const {
	useLoginMutation,
	useRefreshMutation,
	useLogoutMutation,
	useSendOTPMutation,
	useLoginVerifyOTPMutation,
	useForgotPasswordVerifyOTPMutation,
	useForgotResetPasswordMutation,
	useResetPasswordInitMutation,
	useResendEmailMutation,
	useVerifyCodeMutation,
	useConfirmPasswordMutation,
	useChangePasswordMutation,
} = authApiSlice;
