import { Box, Card, CardContent, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import qs from 'qs';
import { filterObjectByKeys, parseSummarySearchParams } from '@/utils/qsHelper';
import ResourcesSummaryFilterSection from './RemoveUserSummaryFilterSection';
import {
	RemoveUserFilter,
	RemoveUserListItem,
} from '@/api/DTO/user/removeUser.interface';
import DetailDialog from './components/DetailDialog';
import {
	useGetMemberListQuery,
	useGetNoMemberListQuery,
	useGetRemveUserCountQuery,
} from '@/api/user/removeUserApiSlice';
import { MemberType } from '@/api/enum/common.enum';
import { UserType } from '@/api/enum/userType.enum';

export interface RemoveUserDataGrid {}

interface Props {
	memberType: string | number;
}

export default function RemoveUserSummarPage(prop: Props) {
	const { memberType } = prop;

	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const { data: pageCount } = useGetRemveUserCountQuery(null, {
		refetchOnMountOrArgChange: true,
	});

	const defaultRemoveUserFilter: RemoveUserFilter = {
		pagination: initialPagination,
		sortings: [],
		loginID: '',
	};

	const defaultValues: DefaultValues<RemoveUserFilter> =
		defaultRemoveUserFilter;
	const methods = useForm<RemoveUserFilter>({
		defaultValues,
	});

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value, { name }) => {
			const RemoveUserFilterFormResult = value as RemoveUserFilter;
			setSearchParams(qs.stringify(RemoveUserFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformedSearchParams: RemoveUserFilter = {
		...defaultRemoveUserFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'loginID',
		]),
	};

	const { data: propertySummary, isSuccess: isGetPropertySummarySuccess } =
		memberType == MemberType.member
			? useGetMemberListQuery(transformedSearchParams, {
					refetchOnMountOrArgChange: true,
			  })
			: useGetNoMemberListQuery(transformedSearchParams, {
					refetchOnMountOrArgChange: true,
			  });

	const transformResponse = useCallback((response?: RemoveUserListItem[]) => {
		if (response == null) return;
		const newResponse: RemoveUserDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				...item,
			});
		});
		return newResponse;
	}, []);

	const getColumns = () => {
		const columns: GridColDef[] = [
			{
				field: 'username',
				headerName: t('common.username'),
				flex: 1,
				renderCell: (params) => (
					<Box>
						<Typography
							sx={{
								display: 'block',
							}}
							fontSize='body2'
							variant='regular'
						>
							{params.row.username}
						</Typography>
						<Typography
							sx={{
								display: 'block',
							}}
							fontSize='caption'
							color='common.neutral50'
							variant='regular'
						>
							{params.row.phone}
						</Typography>
						<Typography
							sx={{
								display: 'block',
							}}
							fontSize='caption'
							color='common.neutral50'
							variant='regular'
						>
							{params.row.email}
						</Typography>
					</Box>
				),
			},
			{
				field: 'district',
				headerName: t('common.belongedDistrict'),
				flex: 1,
				renderCell: (params) => params.row.district || '-',
			},
			{
				field: 'reason',
				headerName: t('removeUser.removeReason'),
				flex: 1,
				renderCell: (params) => params.row.reason || '-',
			},
			{
				field: 'createdRemoveAt',
				headerName: t('removeUser.removeDate'),
				flex: 1,
				renderCell: (params) => params.row.createdRemoveAt || '-',
			},
			{
				field: 'edit',
				headerName: '',
				width: 68,
				renderCell: (params) => (
					<DetailDialog detailObj={params.row} memberType={memberType} />
				),
			},
		];

		if (memberType == MemberType.member) {
			columns.splice(1, 0, {
				field: 'userType',
				headerName: t('common.userCategory'),
				flex: 1,
				renderCell: (params) => (
					<Chip
						label={
							params.row.userType === UserType.PROGRAM_USER
								? t('common.programMember')
								: t('common.normalMember')
						}
						color={
							params.row.userType === UserType.PROGRAM_USER
								? 'chipPrimary'
								: 'chipTertiary'
						}
						variant='outlined'
					/>
				),
			});
			columns.splice(3, 0, {
				field: 'serviceNumber',
				headerName: t('common.historyServiceNumber'),
				flex: 1,
				renderCell: (params) => params.row.serviceNumber || '-',
			});
		}

		return columns;
	};

	const columns = getColumns();

	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>{t('removeUser.removeUserList')}</Typography>
			</Box>
			<Card sx={{ marginTop: 3 }}>
				<CardContent>
					<FormProvider {...methods}>
						<ResourcesSummaryFilterSection
							methods={methods}
							pageCount={pageCount?.data || {}}
						/>

						<Box marginTop={2}>
							<AppDataGrid
								loading={!isGetPropertySummarySuccess}
								rows={transformResponse(propertySummary?.data?.items) ?? []}
								columns={columns}
								rowCount={propertySummary?.data?.pagination.totalItems ?? 0}
								getRowId={(row) => row.id}
							/>
						</Box>
					</FormProvider>
				</CardContent>
			</Card>
		</>
	);
}
