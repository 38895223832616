export enum optionType {
	Radio = '1',
	Multiple = '2',
	Linear = '3',
	MoodThermometer = '6',
	AnswerImage = '5',
}

export enum ChapterType {
	KnowledgeTest = 1,
	PerceptionAssessment = 2,
	EmpiricalReflection = 3,
}

export enum AnswerImageType {
	Image = 1,
	Answer = 2,
	ImageAndAnswer = 3,
	ImageOrAnswer = 4,
}
