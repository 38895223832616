import { useTranslation } from 'react-i18next';
import {
	Typography,
	Card,
	CardContent,
	Stack,
	Button,
	Box,
	IconButton,
} from '@mui/material';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SurveyListSummaryFilterSection from './components/SurveyListSummaryFilterSection';
import { useGetSurveyPageQuery } from '@/api/survey/surveyApiSlice';
import {
	SurveyDetailResDTO,
	SurveyFilterPageReqDTO,
} from '@/api/DTO/survey/survey.interface';
import { filterObjectByKeys, parseSummarySearchParams } from '@/utils/qsHelper';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utils/dateHelper';
import qs from 'qs';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';

export interface SurveyListDataGrid {
	id: string;
	title: string;
	link: string;
	createdAt: string;
}

export default function SurveyListSummaryPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			read: ['read', 'survey', 'survey-list'],
			create: ['create', 'survey'],
		},
	});

	const defaultSurveyFilter: SurveyFilterPageReqDTO = {
		pagination: initialPagination,
		searchKey: '',
		sortings: [],
	};
	const defaultValues: DefaultValues<SurveyFilterPageReqDTO> =
		defaultSurveyFilter;
	const methods = useForm<SurveyFilterPageReqDTO>({
		defaultValues,
	});

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const transformedSearchParams: SurveyFilterPageReqDTO = {
		...defaultSurveyFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'searchKey',
		]),
	};

	const { data: surveyPageSummary, isSuccess: isGetSurveyPageSummarySuccess } =
		useGetSurveyPageQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
			skip: !userRoles.read,
		});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const freeUserPageFilterFormResult = value as SurveyFilterPageReqDTO;

			// append to url to reload page
			setSearchParams(qs.stringify(freeUserPageFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: SurveyDetailResDTO[]) => {
		if (response == null) return;
		const newResponse: SurveyListDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				id: item.id,
				title: item.title,
				link: item.link,
				createdAt: item.createdAt
					? dayjs(item.createdAt).format(DISPLAY_DATE_FORMAT)
					: '-',
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'title',
			headerName: t('common.name'),
			flex: 34,
		},
		{
			field: 'link',
			headerName: t('survey.surveyLink'),
			flex: 34,
		},
		{
			field: 'createdAt',
			headerName: t('common.creationDate'),
			flex: 34,
		},
		{
			field: 'edit',
			headerName: '',
			width: 68,
			renderCell: (params) => (
				<Link
					to={`/surveys/survey-list/info/${params.id}`}
					state={{
						propertySummarySearchParams: qs.stringify(transformedSearchParams),
					}}
				>
					<IconButton color='primary'>
						<VisibilityOutlinedIcon />
					</IconButton>
				</Link>
			),
		},
	];

	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>{t('survey.surveyList')}</Typography>
				<Stack direction={'row'} alignItems={'center'} spacing={3}>
					{userRoles.create && (
						<Button
							variant='contained'
							startIcon={<AddIcon />}
							onClick={() =>
								navigate(`/surveys/survey-list/add`, {
									state: {
										propertySummarySearchParams: qs.stringify(
											transformedSearchParams
										),
									},
								})
							}
							style={{ alignItems: 'center' }}
						>
							{t('button.createSurvey')}
						</Button>
					)}
				</Stack>
			</Box>

			<Card sx={{ marginTop: 3 }}>
				{userRoles.read && (
					<CardContent>
						<FormProvider {...methods}>
							<SurveyListSummaryFilterSection />

							<Box marginTop={2}>
								<AppDataGrid
									loading={!isGetSurveyPageSummarySuccess}
									rows={transformResponse(surveyPageSummary?.data.items) ?? []}
									columns={columns}
									rowCount={surveyPageSummary?.data?.pagination.totalItems ?? 0}
									getRowId={(row) => row.id}
								/>
							</Box>
						</FormProvider>
					</CardContent>
				)}
			</Card>
		</>
	);
}
