import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { useParams } from 'react-router-dom';
import { filterObjectByKeys } from '@/utils/qsHelper';
import { GridColDef } from '@mui/x-data-grid';
import AddMemberTargetSummaryFilterSection from './AddMemberTargetSummaryFilterSection';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utils/dateHelper';
import { UserType } from '@/api/enum/userType.enum';
import {
	useAddSurveyOrgUserMutation,
	useGetSurveyOrgJoinPageQuery,
} from '@/api/survey/surveyApiSlice';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import {
	OrgUserPageReqDTO,
	OrgUserPageResDTO,
} from '@/api/DTO/survey/survey.interface';
import {
	getUserStatusChipColor,
	getUserStatusLabel,
	UserStatus,
} from '@/api/enum/userStatus.enum';

export interface OrgUserPageDataGrid {
	id: string;
	userId: string;
	email: string;
	lastLogin?: string;
	username?: string;
	phone?: string;
	district: string;
	userType: number;
	serviceNumber?: string;
	staff?: string;
	status?: UserStatus;
	joinedAt: string;
}

const AddMemberTargetListModal = () => {
	const { t } = useTranslation();
	const { surveyId } = useParams();

	const [open, setOpen] = useState(false);

	const [addSurveyOrgUser] = useAddSurveyOrgUserMutation();

	const handleClose = () => {
		setOpen(false);
	};

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			read: ['read', 'user', 'user-list'],
			create: ['create', 'user'],
			send: ['send', 'user'],
		},
	});

	const [selectedUser, setSelectedUser] = useState<string[]>([]);

	const defaultOrgUserPageFilter: OrgUserPageReqDTO = {
		pagination: initialPagination,
		sortings: [],
		loginID: '',
		surveyId: surveyId ?? '',
	};

	const defaultValues: DefaultValues<OrgUserPageReqDTO> =
		defaultOrgUserPageFilter;

	const [searchFilter, setSearchFilter] = useState<OrgUserPageReqDTO>(
		defaultOrgUserPageFilter
	);

	const methods = useForm<OrgUserPageReqDTO>({
		defaultValues,
	});

	const transformedSearchParams: OrgUserPageReqDTO = {
		...defaultOrgUserPageFilter,
		...filterObjectByKeys(searchFilter, [
			'pagination',
			'sortings',
			'loginID',
			'userTypes',
			'statuses',
			'districtIds',
			'staffIds',
			'surveyId',
		]),
	};

	const {
		data: orgUserPageSummary,
		isSuccess: isGetOrgUserPageSummarySuccess,
	} = useGetSurveyOrgJoinPageQuery(transformedSearchParams, {
		refetchOnMountOrArgChange: true,
		skip: !userRoles.read,
	});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const propertyFilterFormResult = value as OrgUserPageReqDTO;

			setSearchFilter(propertyFilterFormResult);
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: OrgUserPageResDTO[]) => {
		if (response == null) return;
		const newResponse: OrgUserPageDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				id: item.userId,
				userId: item.userId,
				email: item.email,
				lastLogin: item.lastLogin,
				username: item.username ?? '-',
				phone: item.phone,
				district: item.district,
				userType: item.userType,
				serviceNumber:
					!item.serviceNumber || item.serviceNumber == ''
						? '-'
						: item.serviceNumber,
				staff: !item.staff || item.staff == '' ? '-' : item.staff,
				status: item.status,
				joinedAt: item.joinedAt,
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'username',
			headerName: t('common.username'),
			flex: 20, // 20% width
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='body2'
						variant='regular'
					>
						{params.row.username}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.phone}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.email}
					</Typography>
				</Box>
			),
		},
		{
			field: 'district',
			headerName: t('application.district'),
			flex: 9, // 9% width
		},
		{
			field: 'userType',
			headerName: t('common.userCategory'),
			flex: 9, // 9% width
			renderCell: (params) => (
				<Chip
					label={
						params.row.userType === UserType.NORMAL_USER
							? t('common.normalMember')
							: t('common.programMember')
					}
					color={
						params.row.userType === UserType.NORMAL_USER
							? 'chipTertiary'
							: 'chipPrimary'
					}
					variant='outlined'
				/>
			),
		},
		{
			field: 'serviceNumber',
			headerName: t('common.serviceNumber'),
			flex: 11, // 11% width
		},
		{
			field: 'staff',
			headerName: t('common.staffInCharge'),
			flex: 10, // 10% width
		},
		{
			field: 'status',
			headerName: t('common.status'),
			flex: 10, // 10% width
			renderCell: (params) => (
				<Chip
					label={getUserStatusLabel(params.row.status)}
					color={getUserStatusChipColor(params.row.status)}
					variant='outlined'
				/>
			),
		},
		{
			field: 'joinedAt',
			headerName: t('common.membershipTime'),
			flex: 11, // 11% width
			renderCell: (params) => (
				<Typography variant='regular'>
					{params.row.joinedAt
						? dayjs(params.row.joinedAt)
								.locale('zh-hk-my-setting')
								.format(DISPLAY_DATE_FORMAT)
						: '-'}
				</Typography>
			),
		},
	];

	const handleAddSurveyOrgUser = async () => {
		const response = await addSurveyOrgUser({
			surveyId: surveyId ?? '',
			userIds: selectedUser,
		});

		if ('data' in response) {
			enqueueSnackbar(t('snackbar.addMemberTargetSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
			setSelectedUser([]);
		}
	};

	return (
		<>
			<Button
				variant='contained'
				startIcon={<AddIcon />}
				onClick={() => {
					setOpen(true);
				}}
			>
				{t('button.addMemberTarget')}
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='lg'
				fullWidth
			>
				<DialogTitle variant='h5'>
					{t('survey.addSurveyTarget') + t('common.memberUser')}
				</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent
					sx={{
						minHeight: '765px',
					}}
				>
					<Typography
						variant='body1'
						sx={{ whiteSpace: 'pre-line' }}
						marginBottom={2}
					>
						{t('survey.addSurveyTargetMsg')}
					</Typography>
					<FormProvider {...methods}>
						<AddMemberTargetSummaryFilterSection />

						<Box marginTop={2} minWidth='800px'>
							<AppDataGrid
								loading={!isGetOrgUserPageSummarySuccess}
								rows={transformResponse(orgUserPageSummary?.data?.items) ?? []}
								columns={columns}
								rowCount={orgUserPageSummary?.data?.pagination.totalItems ?? 0}
								getRowId={(row) => row.id}
								checkboxSelection
								selectionModel={selectedUser}
								onSelectionModelChange={(ids) => {
									setSelectedUser(ids.map((id) => String(id)));
								}}
							/>
						</Box>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						color='primary'
						onClick={handleAddSurveyOrgUser}
						disabled={selectedUser.length === 0}
					>
						{t('button.add')}
						{selectedUser.length > 0 && `(${selectedUser.length})`}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AddMemberTargetListModal;
