import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormType } from '@/api/enum/formType.enum';
import { useMemo, useState } from 'react';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import {
	useDeleteStudyCourseMutation,
	useGetCategoriesQuery,
	useGetStudyCourseDetailQuery,
} from '@/api/studyCourse/studyCourseListApiSlice';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import StudyCourseForm from './components/StudyCourseForm';
import AppFieldView from '@/common/components/form-view/AppFieldView';
import AppImageView from '@/common/components/form-view/AppImageView';
import ChapterList from './components/ChapterList';
import { StyledImg } from './components/Styled';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utils/dateHelper';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';

export default function StudyCourseInfoPage() {
	const { t } = useTranslation();
	const [type, setType] = useState<FormType>(FormType.view);
	const { studyCourseId } = useParams();
	const navigate = useNavigate();
	const showModal = useModal();
	const [deleteStudyCourse] = useDeleteStudyCourseMutation();

	const { data: studyCourseInfo } = useGetStudyCourseDetailQuery(
		studyCourseId || '',
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const { userRoles } = useGetUser<useGetUserResultType>({
		roleObj: {
			chapterDetail: ['chapterDetail', 'studyCourse'],
			editStudyCourse: [
				'editStudyCourse',
				'studyCourse',
				String(studyCourseInfo?.data.status),
			],
			deleteStudyCourse: [
				'deleteStudyCourse',
				'studyCourse',
				String(studyCourseInfo?.data.status),
			],
		},
	});

	const { data: categoriesData } = useGetCategoriesQuery(null);
	const typesList = useMemo(() => {
		return (categoriesData?.data || []).map(
			(item: { type: any; desc: any }) => {
				return {
					id: item.type,
					name: item.desc,
				};
			}
		);
	}, [categoriesData]);

	const handleDelete = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('studyCourse.deleteStudyCourse')}
				titleProps={{ variant: 'h6' }}
				description={
					<Typography variant='body1'>
						{t('studyCourse.sureDeleteStudyCourse')}
					</Typography>
				}
				cancellationText=''
				confirmationText={t('button.delete')}
				confirmationButtonProps={{ color: 'error', variant: 'outlined' }}
				onConfirm={async () => {
					const response = await deleteStudyCourse(studyCourseId || '');
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.deleteStudyCourseSuccess'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						navigate(-1);
					}
				}}
			/>
		));
	};

	return (
		<Box>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>
					{t('studyCourse.studyCourseListDetail')}
				</Typography>
				{userRoles.deleteStudyCourse && (
					<Button
						startIcon={<DeleteOutlined />}
						onClick={handleDelete}
						color='error'
						variant='outlined'
					>
						{t('button.deleteCourse')}
					</Button>
				)}
			</Box>
			<Card sx={{ marginTop: '35px' }}>
				<CardContent>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Typography variant='h6'>{t('studyCourse.baseInfo')}</Typography>
						{type == FormType.view && userRoles.editStudyCourse && (
							<Button
								startIcon={<CreateOutlinedIcon />}
								onClick={() => setType(FormType.edit)}
							>
								{t('button.edit')}
							</Button>
						)}
					</Box>
					{(() => {
						switch (type) {
							case FormType.view:
								return (
									<>
										<Grid container spacing={3} marginTop={0} marginBottom={3}>
											<Grid item xs={6}>
												<AppFieldView
													label={t('common.creationDate')}
													value={
														dayjs(studyCourseInfo?.data.createdAt).format(
															DISPLAY_DATE_FORMAT
														) || '-'
													}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppFieldView
													label={t('common.classify')}
													value={
														studyCourseInfo?.data.categories.join('，') || '-'
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<AppFieldView
													label={t('studyCourse.courseName')}
													value={studyCourseInfo?.data.name || '-'}
												/>
											</Grid>
											<Grid item xs={12}>
												<AppFieldView
													label={t('studyCourse.courseIntroduction')}
													value={studyCourseInfo?.data.introduce || '-'}
												/>
											</Grid>
											<Grid item xs={12}>
												<AppImageView
													label={t('common.thumbnail')}
													imageElement={
														<StyledImg
															src={studyCourseInfo?.data.pic.url}
															sx={{
																width: '200px',
															}}
														/>
													}
													text={''} // No filename store in system, so cuerrently no display name available
												/>
											</Grid>
										</Grid>
									</>
								);
							case FormType.edit:
								return (
									<StudyCourseForm
										type={type}
										setType={setType}
										studyCourseInfo={studyCourseInfo?.data}
										typesList={typesList}
									/>
								);
						}
					})()}
				</CardContent>
			</Card>
			{userRoles.chapterDetail && (
				<ChapterList studyCourseInfo={studyCourseInfo?.data} />
			)}
		</Box>
	);
}
