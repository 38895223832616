import {
	Box,
	Button,
	Card,
	CardContent,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import qs from 'qs';
import { filterObjectByKeys, parseSummarySearchParams } from '@/utils/qsHelper';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ResourcesInfoItem } from '@/api/DTO/resources/resourcesList.interface';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '@/utils/dateHelper';
import dayjs from 'dayjs';
import {
	ChapterFilter,
	StudyCourseInfo,
} from '@/api/DTO/studyCourse/studyCourseList.interface';
import { useGetChapterListQuery } from '@/api/studyCourse/studyCourseListApiSlice';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';

export interface ResourcesInfoDataGrid {}

interface Props {
	studyCourseInfo: StudyCourseInfo;
}

export default function ChapterList(prop: Props) {
	const { studyCourseInfo } = prop;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { studyCourseId } = useParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const { userRoles } = useGetUser<useGetUserResultType>({
		roleObj: {
			createChapter: [
				'createChapter',
				'studyCourse',
				String(studyCourseInfo?.status),
			],
		},
	});

	const defaultChapterFilter: ChapterFilter = {
		pagination: initialPagination,
		sortings: [],
		lessonId: studyCourseId || '',
	};

	const defaultValues: DefaultValues<ChapterFilter> = defaultChapterFilter;
	const methods = useForm<ChapterFilter>({
		defaultValues,
	});

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const defaultChapterFilterFormResult = value as ChapterFilter;
			setSearchParams(qs.stringify(defaultChapterFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformedSearchParams: ChapterFilter = {
		...defaultChapterFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'lessonId',
		]),
	};

	const {
		data: propertySummary,
		isSuccess: isGetPropertySummarySuccess,
		isLoading: isGetPropertyLoading,
	} = useGetChapterListQuery(transformedSearchParams, {
		refetchOnMountOrArgChange: true,
	});

	const transformResponse = useCallback((response?: ResourcesInfoItem[]) => {
		if (response == null) return;
		const newResponse: ResourcesInfoDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				...item,
				updatedAt: !item.updatedAt
					? '-'
					: dayjs(item.updatedAt)
							.locale('zh-hk-my-setting')
							.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'title',
			headerName: t('common.title'),
			flex: 1,
		},
		{
			field: 'typeShow',
			headerName: t('studyCourse.category'),
			flex: 1,
		},
		{
			field: 'questionTotal',
			headerName: t('studyCourse.subjectNum'),
			flex: 1,
		},
		{
			field: 'studyingTotal',
			headerName: t('studyCourse.studying'),
			flex: 1,
		},
		{
			field: 'finishedTotal',
			headerName: t('studyCourse.studied'),
			flex: 1,
		},
		{
			field: 'accuracy',
			headerName: t('studyCourse.accuracy'),
			flex: 1,
		},
		{
			field: 'updatedAt',
			headerName: t('studyCourse.lastUpdateTime'),
			flex: 1,
		},
		{
			field: 'edit',
			headerName: '',
			width: 68,
			renderCell: (params) => (
				<Link
					to={`/study-course/study-course-list/${studyCourseId}/info/chapterDetail/${params.id}`}
					state={{
						propertySummarySearchParams: qs.stringify(transformedSearchParams),
					}}
				>
					<IconButton color='primary'>
						<VisibilityOutlinedIcon />
					</IconButton>
				</Link>
			),
		},
	];

	return (
		<>
			<Card sx={{ marginTop: 3 }}>
				<CardContent>
					<Box display='flex' justifyContent='space-between'>
						<Typography variant='h6'>{t('studyCourse.chapter')}</Typography>

						<Stack direction={'row'} alignItems={'center'} spacing={3}>
							{userRoles.createChapter && (
								<Button
									variant='contained'
									startIcon={<AddIcon />}
									onClick={() =>
										navigate(
											`/study-course/study-course-list/${studyCourseId}/info/addChapter`,
											{
												state: {
													propertySummarySearchParams: qs.stringify(
														transformedSearchParams
													),
												},
											}
										)
									}
								>
									{t('button.createChapter')}
								</Button>
							)}
						</Stack>
					</Box>
					{propertySummary?.data.items &&
					propertySummary?.data.items.length > 0 ? (
						<FormProvider {...methods}>
							<Box marginTop={2}>
								<AppDataGrid
									loading={!isGetPropertySummarySuccess}
									rows={transformResponse(propertySummary?.data?.items) ?? []}
									columns={columns}
									rowCount={propertySummary?.data?.pagination.totalItems ?? 0}
									getRowId={(row) => row.id}
								/>
							</Box>
						</FormProvider>
					) : (
						<Typography
							variant='body2'
							color={'common.neutral50'}
							marginTop={'16px'}
						>
							{t('studyCourse.nothing')}
						</Typography>
					)}
				</CardContent>
			</Card>
		</>
	);
}
