import {
	Button,
	CardActions,
	Grid,
	InputAdornment,
	Typography,
	IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { ResourcesInfo } from '@/api/DTO/resources/resourcesList.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AppTextField, {
	InputType,
} from '@/common/components/form-inputs/AppTextField';
import AppDropDownMenu from '@/common/components/form-inputs/AppDropDownMenu';
import { useNavigate } from 'react-router-dom';
import { FormType } from '@/api/enum/formType.enum';
import {
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import AppRadioGroup from '@/common/components/form-inputs/AppRadioGroup';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import {
	useAddResourcesMutation,
	useGetDistrictsQuery,
	useGetTypesQuery,
	useUpdateResourcesMutation,
} from '@/api/communityResources/resourcesListApiSlice';
import { useFileUploader } from '@/hooks/useFileUploader';
import { cloneDeep } from 'lodash';
import { StyledImg, VisuallyHiddenInput } from './styled';
import ImageCropper from '@/common/components/imageCropper/imageCropper';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';

interface Props {
	type: FormType;
	setType: Dispatch<SetStateAction<FormType>>;
	resourcesInfo?: ResourcesInfo;
}

export default function ResourcesForm({ type, setType, resourcesInfo }: Props) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [needConfirm, setNeedConfirm] = useState(false);
	const [showCropper, setShowCropper] = useState(false);
	const [imageBase, setImageBase] = useState<{
		base64: any;
		name: string;
	} | null>(null);
	const [previewUrl, setPreviewUrl] = useState<{
		url: string;
		name: string;
		fileKey: string;
	} | null>(null);
	const { uploadFile } = useFileUploader();
	const imageInputRef = useRef<HTMLInputElement>(null);
	const [addResources] = useAddResourcesMutation();
	const [updateResources] = useUpdateResourcesMutation();
	const showModal = useModal();

	const { data: typesData } = useGetTypesQuery(null);
	const { typesList, subTypesMap } = useMemo(() => {
		const subTypesMap: Record<string, { id: string; name: string }[]> = {};
		const typesList = (typesData?.data || []).map((item: any) => {
			subTypesMap[String(item.type)] = (item.subtypes || []).map(
				(sub: { subTypeId: any; subTypeDesc: any }) => ({
					id: sub.subTypeId,
					name: sub.subTypeDesc,
				})
			);
			return {
				id: String(item.type),
				name: item.typeDesc,
			};
		});
		return { typesList, subTypesMap };
	}, [typesData]);

	const { data: districtsData } = useGetDistrictsQuery(null);
	const districtsList = useMemo(() => {
		const arr = (districtsData?.data || []).map(
			(item: { id: string; name: string }) => {
				return {
					id: item.id,
					name: item.name,
				};
			}
		);
		return arr || [];
	}, [districtsData]);

	const defaultValues: DefaultValues<ResourcesInfo> = useMemo(() => {
		if (resourcesInfo) {
			const newData = cloneDeep(resourcesInfo);
			newData.resourceId = resourcesInfo.subTypeId || '';
			newData.image = resourcesInfo.pic.url || '';
			newData.communityId = resourcesInfo.communityId || '';

			setPreviewUrl({
				...resourcesInfo.pic,
				name: resourcesInfo.pic.url || '',
			});
			return newData;
		}
		return {
			type: '',
			resourceId: '',
			name: '',
			communityId: '',
			phone: '',
			address: '',
			image: '',
			introduce: '',
		};
	}, [resourcesInfo]);

	const formSchema = Yup.object().shape({
		type: Yup.string().required(t('errorMessage.pleaseSelect')),
		resourceId: Yup.string().required(t('errorMessage.pleaseSelect')),
		name: Yup.string()
			.required(t('errorMessage.required'))
			.max(50, t('errorMessage.max50', { max: 50 })),
		communityId: Yup.string().required(t('errorMessage.pleaseSelect')),
		phone: Yup.string()
			.when('type', {
				is: '5',
				then: (schema) => schema.required(t('errorMessage.phone')),
			})
			.test(
				'phone',
				() => t('errorMessage.phone'),
				(phone) => phone?.length == 8 || phone?.length == 0
			),
		address: Yup.string().required(t('errorMessage.required')),
		image: Yup.string().required(t('errorMessage.pleaseUpload')),
		introduce: Yup.string().max(100, t('errorMessage.max100', { max: 100 })),
	});
	const methods = useForm<ResourcesInfo>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		const subscription = methods.watch((value, { name }) => {
			if (!needConfirm) setNeedConfirm(true);
			if (name == 'type') {
				if (methods.getValues('resourceId')) methods.setValue('resourceId', '');
			}
		});

		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const watchType = methods.watch('type');
	const subTypeList = useMemo(() => {
		return subTypesMap[watchType];
	}, [watchType]);

	const onSubmit: SubmitHandler<ResourcesInfo> = async (data) => {
		if (type === FormType.create) {
			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('communityResources.createCommunityResources')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('communityResources.isCreateCommunityResources')}
							</Typography>
						</>
					}
					cancellationText={t('button.cancel')}
					confirmationText={t('button.sure')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={async () => {
						const newData = cloneDeep(data);
						newData.fileKey = previewUrl?.fileKey;
						delete newData.image;

						const response = await addResources(newData);
						if ('data' in response) {
							enqueueSnackbar(t('snackbar.addCommunityResourcesSuccess'), {
								variant: 'success',
								anchorOrigin: { horizontal: 'center', vertical: 'top' },
								autoHideDuration: 3000,
								action: (key) => {
									return <CloseIcon onClick={() => closeSnackbar(key)} />;
								},
							});
							navigate(-1);
						}
					}}
				/>
			));
		} else {
			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('communityResources.editCommunityResources')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('communityResources.isEditCommunityResources')}
							</Typography>
						</>
					}
					cancellationText={t('button.cancel')}
					confirmationText={t('button.sure')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={async () => {
						const newData = {
							id: data.id,
							name: data.name,
							phone: data.phone,
							address: data.address,
							fileKey: previewUrl?.fileKey,
							type: data.type,
							resourceId: data.resourceId,
							communityId: data.communityId,
							introduce: data.introduce,
						};
						const res = await updateResources(newData);
						if ('data' in res) {
							enqueueSnackbar(t('snackbar.fixSuccess'), {
								variant: 'success',
								anchorOrigin: { horizontal: 'center', vertical: 'top' },
								autoHideDuration: 3000,
								action: (key) => {
									return <CloseIcon onClick={() => closeSnackbar(key)} />;
								},
							});
							setType(FormType.view);
						}
					}}
				/>
			));
		}
	};

	const handleClose = async () => {
		if (type === FormType.edit) {
			if (!needConfirm) return setType(FormType.view);

			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('communityResources.giveUpFix')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('communityResources.sureGiveUpFix')}
							</Typography>
							<Typography variant='body1'>
								{t('communityResources.fixAllReset')}
							</Typography>
						</>
					}
					cancellationText={t('button.giveUpFix')}
					confirmationText={t('button.saveAndLeave')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={() => methods.handleSubmit(onSubmit)()}
					onCancel={() => setType(FormType.view)}
				/>
			));
		} else {
			if (!needConfirm) return navigate(-1);

			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('communityResources.giveUpFix')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('communityResources.sureGiveUpFix')}
							</Typography>
							<Typography variant='body1'>
								{t('communityResources.fixAllReset')}
							</Typography>
						</>
					}
					cancellationText={t('button.giveUpFix')}
					confirmationText={t('button.saveAndLeave')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={() => methods.handleSubmit(onSubmit)()}
					onCancel={() => navigate(-1)}
				/>
			));
		}
	};

	const handleFileChange = async (e: any) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const name = files[0].name;
		const reader = new FileReader();
		reader.onload = () => {
			setShowCropper(true);
			setImageBase({
				base64: reader?.result,
				name: name,
			});

			imageInputRef.current && (imageInputRef.current.value = '');
		};
		reader.readAsDataURL(files[0]);
	};
	const handleCropper = async (file?: any) => {
		setShowCropper(false);
		if (!file) return;
		const res = await uploadFile(file);
		if (res) {
			methods.setValue('image', res.name);
			methods.trigger('image');

			setPreviewUrl(res);

			enqueueSnackbar(t('snackbar.uploadSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
		}
	};
	const handleClearPicture = () => {
		methods.setValue('image', '');
		imageInputRef.current && (imageInputRef.current.value = '');
		setPreviewUrl(null);
	};
	return (
		<>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<AppRadioGroup
								style={{ padding: 0 }}
								control={methods.control}
								name='type'
								label={t('common.classify')}
								row
								options={typesList || []}
							/>
						</Grid>
						<Grid item xs={6}>
							<AppDropDownMenu
								control={methods.control}
								name='resourceId'
								label={t('common.subclassify')}
								options={subTypeList || []}
							/>
						</Grid>

						<Grid item xs={6}>
							<AppTextField
								control={methods.control}
								name='name'
								label={t('common.name')}
								maxLength={50}
								showMaxLength
							/>
						</Grid>
						<Grid item xs={6}>
							<AppDropDownMenu
								control={methods.control}
								name='communityId'
								label={t('common.district')}
								options={districtsList || []}
							/>
						</Grid>

						<Grid item xs={6}>
							<AppTextField
								control={methods.control}
								name='phone'
								inputType={InputType.number}
								label={
									t('common.phoneNumber') +
									(watchType != '5' ? t('common.optional') : '')
								}
								maxLength={8}
							/>
						</Grid>
						<Grid item xs={6}>
							<AppTextField
								control={methods.control}
								name='address'
								label={t('common.address')}
								maxLength={255}
							/>
						</Grid>

						<Grid item xs={6}>
							<AppTextField
								disabled
								control={methods.control}
								name='image'
								label={t('common.picture')}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											{previewUrl && (
												<IconButton type='button' onClick={handleClearPicture}>
													<CloseIcon />
												</IconButton>
											)}

											<IconButton component='label'>
												<ImageOutlinedIcon />
												<VisuallyHiddenInput
													type='file'
													accept='image/*'
													onChange={handleFileChange}
													ref={imageInputRef}
												/>
												<ImageCropper
													image={imageBase}
													isOpen={showCropper}
													handleCropper={handleCropper}
													option={{
														zoomTo: 0.5,
														initialAspectRatio: 1,
														viewMode: 1,
														autoCropArea: 1,
														checkOrientation: false,
														guides: true,
														aspectRatio: 3 / 2,
													}}
												/>
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							{previewUrl && (
								<StyledImg
									src={previewUrl.url}
									sx={{
										width: '300px',
									}}
								></StyledImg>
							)}
						</Grid>

						<Grid item xs={6}>
							<AppTextField
								control={methods.control}
								name='introduce'
								label={t('communityResources.introduce')}
								multiline
								maxLength={100}
								showMaxLength
							/>
						</Grid>
					</Grid>
					<CardActions sx={{ justifyContent: 'end', padding: '24px' }}>
						<Button onClick={handleClose}>{t('button.cancel')}</Button>
						<Button
							type='submit'
							variant='contained'
							disabled={!needConfirm && type === FormType.create}
						>
							{type === FormType.edit ? t('button.reserve') : t('button.add')}
						</Button>
					</CardActions>
				</form>
			</FormProvider>
		</>
	);
}
