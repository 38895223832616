import { Box, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ChapterForm from './components/ChapterForm';
import { FormType } from '@/api/enum/formType.enum';
import { useState } from 'react';
export default function CreateChapterPage() {
	console.log();
	const { t } = useTranslation();
	const [type, setType] = useState<FormType>(FormType.create);
	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Box>
					<Typography variant='h4'>{t('studyCourse.createChapter')}</Typography>
					<Card sx={{ marginTop: 3 }}>
						<CardContent sx={{ padding: '24px', paddingTop: 0 }}>
							<ChapterForm type={type} setType={setType} />
						</CardContent>
					</Card>
				</Box>
			</Box>
		</>
	);
}
