import { FormType } from '@/api/enum/formType.enum';
import AppFieldView from '@/common/components/form-view/AppFieldView';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import MemberTargetListCard from './components/MemberTargetListCard';
import NonMemberTargetListCard from './components/NonMemberTargetListCard';
import SurveyForm from './components/SurveyInfoForm';
import {
	useDeleteSurveyMutation,
	useGetSurveyQuery,
} from '@/api/survey/surveyApiSlice';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utils/dateHelper';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';

export default function SurveyInfoPage() {
	const { t } = useTranslation();
	const [type, setType] = useState<FormType>(FormType.view);
	const { surveyId } = useParams();
	const showModal = useModal();

	const navigate = useNavigate();

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			read: ['read', 'survey', 'survey-info'],
			edit: ['edit', 'survey', 'survey-info'],
			delete: ['delete', 'survey'],
		},
	});

	const { data: getSurvey } = useGetSurveyQuery(surveyId ?? '', {
		refetchOnMountOrArgChange: true,
		skip: !userRoles.read,
	});

	const [deleteSurvey] = useDeleteSurveyMutation();

	const handleDelete = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('survey.deleteSurvey')}
				titleProps={{ variant: 'h6' }}
				description={
					<Typography variant='body1'>{t('survey.deleteSurveyMsg')}</Typography>
				}
				cancellationText={''}
				confirmationText={t('button.delete')}
				confirmationButtonProps={{ color: 'error', variant: 'outlined' }}
				onConfirm={async () => {
					const response = await deleteSurvey(surveyId || '');
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.deleteSurveySuccess'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						navigate(-1);
					}
				}}
			/>
		));
	};

	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>{t('survey.surveyDetail')}</Typography>

				{userRoles.delete && (
					<Button
						startIcon={<DeleteOutlined />}
						onClick={handleDelete}
						color='error'
						variant='outlined'
					>
						{t('button.deleteSurvey')}
					</Button>
				)}
			</Box>
			<Grid container alignItems='stretch' columnSpacing={3} marginTop={2}>
				<Grid item xs display='flex'>
					<Card sx={{ width: '100%' }}>
						<CardContent>
							{userRoles.read && (
								<>
									{(() => {
										switch (type) {
											case FormType.view:
												return (
													<>
														<Box display='flex' justifyContent='space-between'>
															<Typography variant='h5'>
																{t('survey.surveyInfo')}
															</Typography>
															{userRoles.edit && (
																<Button
																	startIcon={<CreateOutlinedIcon />}
																	onClick={() => setType(FormType.edit)}
																>
																	{t('button.edit')}
																</Button>
															)}
														</Box>
														<Grid container spacing={3} marginTop={0}>
															<Grid item xs={4}>
																<AppFieldView
																	label={t('common.name')}
																	value={getSurvey?.data?.title}
																/>
															</Grid>
															<Grid item xs={4}>
																<AppFieldView
																	label={t('survey.surveyLink')}
																	value={getSurvey?.data?.link}
																/>
															</Grid>
															<Grid item xs={4}>
																<AppFieldView
																	label={t('common.creationDate')}
																	value={dayjs(
																		getSurvey?.data?.createdAt
																	).format(DISPLAY_DATE_FORMAT)}
																/>
															</Grid>
														</Grid>
													</>
												);
											case FormType.edit:
												return (
													<SurveyForm
														type={type}
														setType={setType}
														surveyId={surveyId ?? ''}
														title={getSurvey?.data?.title ?? ''}
														link={getSurvey?.data.link ?? ''}
														createdAt={getSurvey?.data?.createdAt ?? ''}
													/>
												);
										}
									})()}
								</>
							)}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			<MemberTargetListCard />
			<NonMemberTargetListCard />
		</>
	);
}
