import { yupResolver } from '@hookform/resolvers/yup';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppTextField from '../../../common/components/form-inputs/AppTextField';
import { useAddSurveyMutation } from '@/api/survey/surveyApiSlice';
import { SurveyCreationReqDTO } from '@/api/DTO/survey/survey.interface';
import { useNavigate } from 'react-router-dom';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';

export default function CreateSurveyPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const showModal = useModal();

	const [needConfirm, setNeedConfirm] = useState(false);

	const defaultValues: DefaultValues<SurveyCreationReqDTO> = {};

	const formSchema = Yup.object().shape({
		title: Yup.string().required(t('errorMessage.required')),
		link: Yup.string().required(t('errorMessage.required')),
	});

	const methods = useForm<SurveyCreationReqDTO>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});
	useEffect(() => {
		const subscription = methods.watch((value, { name }) => {
			if (!needConfirm) setNeedConfirm(true);
		});

		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const [addSurvey] = useAddSurveyMutation();

	const handleClose = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('communityResources.giveUpFix')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('communityResources.sureGiveUpFix')}
						</Typography>
						<Typography variant='body1'>
							{t('communityResources.fixAllReset')}
						</Typography>
					</>
				}
				cancellationText={t('button.giveUpFix')}
				confirmationText={t('button.saveAndLeave')}
				confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
				onConfirm={() => methods.handleSubmit(onSubmit)()}
				onCancel={() => navigate(-1)}
			/>
		));
	};

	const onSubmit: SubmitHandler<SurveyCreationReqDTO> = async (data) => {
		const response = await addSurvey(data);
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.createSurveySuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});

			navigate(-1);
		}
	};

	return (
		<>
			<Typography variant='h4'>{t('survey.createSurvey')}</Typography>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid container alignItems='stretch' columnSpacing={3} marginTop={3}>
						<Grid item xs display='flex'>
							<Card sx={{ width: '100%' }}>
								<CardContent>
									<Stack>
										<Typography variant='h5'>
											{t('survey.surveyInfo')}
										</Typography>
										<Grid container spacing={3} marginTop={0} marginBottom={3}>
											<Grid item xs={6}>
												<AppTextField
													control={methods.control}
													name='title'
													label={t('common.name')}
													maxLength={50}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppTextField
													control={methods.control}
													name='link'
													label={t('survey.surveyLink')}
													maxLength={255}
												/>
											</Grid>
										</Grid>
									</Stack>
								</CardContent>
								<CardActions sx={{ justifyContent: 'end' }}>
									<Button onClick={handleClose}>{t('button.cancel')}</Button>

									<Button
										type='submit'
										variant='contained'
										disabled={!needConfirm}
									>
										{t('button.add')}
									</Button>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</>
	);
}
