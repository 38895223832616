import {
	Button,
	CardActions,
	Grid,
	InputAdornment,
	Typography,
	IconButton,
	Box,
	FormControl,
	FormHelperText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useController,
	useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { FormType } from '@/api/enum/formType.enum';
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useFileUploader } from '@/hooks/useFileUploader';
import { cloneDeep } from 'lodash';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import AppFieldView, {
	AppFormLabel,
} from '@/common/components/form-view/AppFieldView';
import AppMultipleDropDownMenu from '@/common/components/form-inputs/AppMultipleDropDownMenu';
import { StudyCourseInfo } from '@/api/DTO/studyCourse/studyCourseList.interface';
import { useStudyCourseOptions } from '@/hooks/useStudyCourseOptions';
import AppTextField from '@/common/components/form-inputs/AppTextField';
import { StyledImg, VisuallyHiddenInput } from './Styled';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageCropper from '@/common/components/imageCropper/imageCropper';
import { FileInfo } from '@/api/DTO/common.interface';
import {
	useAddStudyCourseMutation,
	useEditStudyCourseMutation,
	useGetCategoriesQuery,
} from '@/api/studyCourse/studyCourseListApiSlice';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utils/dateHelper';

interface Props {
	type: FormType;
	setType: Dispatch<SetStateAction<FormType>>;
	studyCourseInfo?: StudyCourseInfo;
	typesList: any;
}

export default function StudyCourseForm({
	type,
	setType,
	studyCourseInfo,
	typesList,
}: Props) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [needConfirm, setNeedConfirm] = useState(false);
	const showModal = useModal();
	const [thumbnailBase, setThumbnailBase] = useState<{
		base64: any;
		name: string;
	} | null>(null);
	const [thumbnailUrl, setThumbnailUrl] = useState<FileInfo | null>(null);
	const [showCropper, setShowCropper] = useState(false);
	const thumbnailInputRef = useRef<HTMLInputElement>(null);
	const { uploadFile } = useFileUploader();

	const [addStudyCourse] = useAddStudyCourseMutation();
	const [editStudyCourse] = useEditStudyCourseMutation();

	const defaultValues: DefaultValues<StudyCourseInfo> = useMemo(() => {
		if (studyCourseInfo) {
			const newData = cloneDeep(studyCourseInfo);
			setThumbnailUrl({ ...newData?.pic, name: t('common.thumbnail') + 1 });
			newData.categories = (studyCourseInfo.categories || []).map(
				(item: any) => {
					return typesList.find((i: { name: any }) => i.name == item)?.id;
				}
			);
			console.log(newData.categories);
			newData.fileKey = studyCourseInfo.pic.fileKey;
			return newData;
		}
		return {};
	}, [studyCourseInfo, typesList]);

	const formSchema = Yup.object().shape({
		name: Yup.string()
			.required(t('errorMessage.required'))
			.max(20, t('errorMessage.max20', { max: 20 })),
		categories: Yup.array()
			.nullable()
			.min(1, t('errorMessage.pleaseSelect'))
			.required(t('errorMessage.pleaseSelect')),
		introduce: Yup.string()
			.required(t('errorMessage.required'))
			.max(200, t('errorMessage.max200', { max: 200 })),
		fileKey: Yup.string().required(t('errorMessage.pleaseUpload')),
	});
	const methods = useForm<StudyCourseInfo>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});
	const thumbnailField = useController({
		name: 'fileKey',
		control: methods.control,
	});

	useEffect(() => {
		const subscription = methods.watch((value, { name }) => {
			if (!needConfirm) setNeedConfirm(true);
		});

		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const onSubmit: SubmitHandler<StudyCourseInfo> = (data) => {
		if (type === FormType.create) {
			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('studyCourse.createStudyCourse')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('studyCourse.sureAddStudyCourse')}
							</Typography>
						</>
					}
					cancellationText={t('button.cancel')}
					confirmationText={t('button.sure')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={async () => {
						const response = await addStudyCourse(data);
						if ('data' in response) {
							enqueueSnackbar(t('snackbar.addStudyCourseSuccess'), {
								variant: 'success',
								anchorOrigin: { horizontal: 'center', vertical: 'top' },
								autoHideDuration: 3000,
								action: (key) => {
									return <CloseIcon onClick={() => closeSnackbar(key)} />;
								},
							});

							navigate(-1);
						}
					}}
				/>
			));
		} else {
			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('studyCourse.editStudyCourse')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('studyCourse.sureEditStudyCourse')}
							</Typography>
						</>
					}
					cancellationText={t('button.cancel')}
					confirmationText={t('button.sure')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={async () => {
						const response = await editStudyCourse(data);
						if ('data' in response) {
							enqueueSnackbar(t('snackbar.editStudyCourseSuccess'), {
								variant: 'success',
								anchorOrigin: { horizontal: 'center', vertical: 'top' },
								autoHideDuration: 3000,
								action: (key) => {
									return <CloseIcon onClick={() => closeSnackbar(key)} />;
								},
							});

							setType(FormType.view);
						}
					}}
				/>
			));
		}
	};

	const handleClose = async () => {
		if (type === FormType.edit) {
			if (!needConfirm) return setType(FormType.view);

			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('communityResources.giveUpFix')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('communityResources.sureGiveUpFix')}
							</Typography>
							<Typography variant='body1'>
								{t('communityResources.fixAllReset')}
							</Typography>
						</>
					}
					cancellationText={t('button.giveUpFix')}
					confirmationText={t('button.saveAndLeave')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={() => methods.handleSubmit(onSubmit)()}
					onCancel={() => setType(FormType.view)}
				/>
			));
		} else {
			if (!needConfirm) return navigate(-1);

			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('communityResources.giveUpFix')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('communityResources.sureGiveUpFix')}
							</Typography>
							<Typography variant='body1'>
								{t('communityResources.fixAllReset')}
							</Typography>
						</>
					}
					cancellationText={t('button.giveUpFix')}
					confirmationText={t('button.saveAndLeave')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={() => methods.handleSubmit(onSubmit)()}
					onCancel={() => navigate(-1)}
				/>
			));
		}
	};

	const handleThumbnailChange = async (e: any) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const name = files[0].name;
		const reader = new FileReader();
		reader.onload = () => {
			setShowCropper(true);
			setThumbnailBase({
				base64: reader?.result,
				name: name,
			});

			thumbnailInputRef.current && (thumbnailInputRef.current.value = '');
		};
		reader.readAsDataURL(files[0]);
	};
	const handleCropper = async (file?: any) => {
		setShowCropper(false);
		if (!file) return;
		const res = await uploadFile(file);
		if (res) {
			methods.setValue('fileKey', res.fileKey);
			methods.trigger('fileKey');

			setThumbnailUrl(res);
		}
	};
	const handleClearThumbnail = useCallback(() => {
		methods.setValue('fileKey', '');
		methods.trigger('fileKey');
		setThumbnailUrl(null);
	}, []);

	return (
		<>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid container spacing={3} marginTop={0} marginBottom={3}>
						{type == FormType.create && (
							<Grid item xs={12}>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<Typography variant='h6'>
										{t('studyCourse.baseInfo')}
									</Typography>
								</Box>
							</Grid>
						)}

						<Grid item xs={6}>
							<AppFieldView
								label={t('common.creationDate')}
								value={
									dayjs(studyCourseInfo?.createdAt).format(
										DISPLAY_DATE_FORMAT
									) || '-'
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<AppMultipleDropDownMenu
								control={methods.control}
								name='categories'
								label={t('common.classify')}
								options={typesList}
								emptyArrayIfNoSelection
							/>
						</Grid>
						<Grid item xs={12}>
							<AppTextField
								control={methods.control}
								name='name'
								label={t('studyCourse.courseName')}
								maxLength={20}
								showMaxLength
							/>
						</Grid>
						<Grid item xs={12}>
							<AppTextField
								control={methods.control}
								name='introduce'
								label={t('studyCourse.courseIntroduction')}
								maxLength={200}
								showMaxLength
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl error={!!thumbnailField.fieldState.error?.message}>
								<AppFormLabel>{t('common.thumbnail')}</AppFormLabel>

								{thumbnailUrl ? (
									<Box sx={{ width: '200px' }}>
										<StyledImg src={thumbnailUrl.url} sx={{ width: '200px' }} />
										<Grid
											container
											direction='row'
											justifyContent='space-between'
											alignItems='center'
										>
											<Typography
												variant='caption'
												noWrap
												sx={{ width: '170px' }}
											>
												{''}
												{/* Have not used thumbnailUrl.name because sometimes the name is a URL. */}
											</Typography>
											<IconButton
												color='error'
												size='small'
												onClick={handleClearThumbnail}
											>
												<DeleteIcon fontSize='inherit' />
											</IconButton>
										</Grid>
									</Box>
								) : (
									<Box>
										<Button
											component='label'
											variant='text'
											startIcon={<AddIcon />}
											style={{ alignItems: 'center' }}
										>
											{t('button.uploadImage')}
											<VisuallyHiddenInput
												type='file'
												name='fileKey'
												accept='image/*'
												onChange={handleThumbnailChange}
												ref={thumbnailInputRef}
											/>
											<ImageCropper
												image={thumbnailBase}
												isOpen={showCropper}
												handleCropper={handleCropper}
												option={{
													zoomTo: 0.5,
													initialAspectRatio: 1,
													viewMode: 1,
													autoCropArea: 1,
													checkOrientation: false,
													guides: true,
													aspectRatio: 2 / 1,
												}}
											/>
										</Button>
									</Box>
								)}
								<FormHelperText>
									{thumbnailField.fieldState.error?.message}
								</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
					<CardActions sx={{ justifyContent: 'end', padding: '24px' }}>
						<Button onClick={handleClose}>{t('button.cancel')}</Button>
						<Button
							type='submit'
							variant='contained'
							disabled={!needConfirm && type === FormType.create}
						>
							{type === FormType.edit ? t('button.reserve') : t('button.add')}
						</Button>
					</CardActions>
				</form>
			</FormProvider>
		</>
	);
}
