import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
	open: boolean;
	showCloseIcon: boolean;
	onClose: () => void;
	onCancel: () => void;
	onConfirm: () => void;
	title: string;
	titleProps: any;
	description: string;
	confirmationText: string;
	confirmationButtonProps: any;
	cancellationText: string;
	cancellationButtonProps: any;
}

const ConfirmationDialog = ({
	open,
	showCloseIcon = false,
	onClose,
	onCancel,
	onConfirm,
	title = '',
	description = '',
	confirmationText = 'Ok',
	cancellationText = 'Cancel',
	confirmationButtonProps,
	cancellationButtonProps,
	titleProps,
}: Props) => {
	return (
		<Dialog fullWidth open={open} onClose={onClose}>
			<DialogTitle {...titleProps}>{title}</DialogTitle>
			{showCloseIcon && (
				<IconButton
					aria-label='close'
					onClick={onClose}
					style={{ position: 'absolute', right: 8, top: 8 }}
				>
					<CloseIcon />
				</IconButton>
			)}

			<DialogContent sx={{ paddingTop: 0 }}>{description}</DialogContent>
			<DialogActions>
				{cancellationText && (
					<Button
						onClick={() => {
							onCancel && onCancel();
							onClose();
						}}
						{...cancellationButtonProps}
					>
						{cancellationText}
					</Button>
				)}
				<Button
					color='primary'
					onClick={() => {
						onConfirm && onConfirm();
						onClose();
					}}
					{...confirmationButtonProps}
				>
					{confirmationText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationDialog;
