import { Box } from '@mui/material';
import React from 'react';
import { AppFormLabel } from './AppFieldView';

export interface AppElementView {
	label?: string;
	value?: React.ReactNode;
	editButton?: React.ReactNode;
}

export default function AppElementView({
	label,
	value,
	editButton,
}: AppElementView) {
	return (
		<>
			<AppFormLabel>{label}</AppFormLabel>
			<Box display='flex'>
				{value}
				{editButton && <>{editButton}</>}
			</Box>
		</>
	);
}
