import { apiSlice } from '../apiSlice';
import { SuccessResponse, Paginated } from '../DTO/common.interface';
import {
	ChapterFilter,
	ChapterInfo,
	ChapterItem,
	DistributionDto,
	StudyCourseCategories,
	StudyCourseCount,
	StudyCourseFilter,
	StudyCourseInfo,
	StudyCourseItem,
} from '../DTO/studyCourse/studyCourseList.interface';

export const studyCourseListApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getStudyCourseList: builder.query<
			SuccessResponse<Paginated<StudyCourseItem>>,
			StudyCourseFilter
		>({
			query: (body) => ({
				url: `/web/lesson/page`,
				method: 'POST',
				body,
			}),
			providesTags: ['StudyCourseList'],
		}),
		getCategories: builder.query<
			SuccessResponse<StudyCourseCategories[]>,
			null
		>({
			query: () => ({
				url: `/web/lesson/categories`,
				method: 'GET',
			}),
		}),
		getStudyCourseCount: builder.query<SuccessResponse<StudyCourseCount>, null>(
			{
				query: () => `/web/lesson/count`,
				providesTags: ['StudyCoursePageCount'],
			}
		),
		addStudyCourse: builder.mutation<SuccessResponse<any>, StudyCourseInfo>({
			query: (body) => ({
				url: `/web/lesson`,
				method: 'POST',
				body,
			}),
		}),
		deleteStudyCourse: builder.mutation<SuccessResponse<any>, string>({
			query: (id) => ({
				url: `/web/lesson/${id}`,
				method: 'DELETE',
			}),
		}),
		getStudyCourseDetail: builder.query<SuccessResponse<any>, string>({
			query: (id) => ({
				url: `/web/lesson/${id}`,
				method: 'GET',
			}),
			providesTags: ['StudyCourseDetail'],
		}),
		editStudyCourse: builder.mutation<SuccessResponse<any>, StudyCourseInfo>({
			query: (body) => ({
				url: `/web/lesson`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['StudyCourseDetail'],
		}),
		getStudyCourseStatusList: builder.query<
			SuccessResponse<any>,
			string | number
		>({
			query: (status) => ({
				url: `/web/lesson/status/${status}`,
				method: 'GET',
			}),
		}),
		setStudyCourseStatus: builder.mutation<SuccessResponse<any>, string[]>({
			query: (body) => ({
				url: `/web/lesson/batch-status`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['StudyCourseList', 'StudyCoursePageCount'],
		}),
		getChapterList: builder.query<
			SuccessResponse<Paginated<ChapterItem>>,
			ChapterFilter
		>({
			query: (body) => ({
				url: `/web/chapter/page`,
				method: 'POST',
				body,
			}),
		}),
		addChapter: builder.mutation<SuccessResponse<any>, ChapterInfo>({
			query: (body) => ({
				url: `/web/chapter`,
				method: 'POST',
				body,
			}),
		}),
		editChapter: builder.mutation<SuccessResponse<any>, ChapterInfo>({
			query: (body) => ({
				url: `/web/chapter`,
				method: 'PUT',
				body,
			}),
		}),
		getChapterDetail: builder.query<SuccessResponse<ChapterInfo>, string>({
			query: (id) => ({
				url: `/web/chapter/${id}`,
				method: 'GET',
			}),
		}),
		distributionExercise: builder.mutation<
			SuccessResponse<any>,
			DistributionDto
		>({
			query: (body) => ({
				url: `/web/exercise`,
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const {
	useGetStudyCourseListQuery,
	useGetCategoriesQuery,
	useGetStudyCourseDetailQuery,
	useGetStudyCourseCountQuery,
	useGetStudyCourseStatusListQuery,
	useGetChapterListQuery,
	useGetChapterDetailQuery,
	useAddStudyCourseMutation,
	useDeleteStudyCourseMutation,
	useEditStudyCourseMutation,
	useSetStudyCourseStatusMutation,
	useAddChapterMutation,
	useEditChapterMutation,
	useDistributionExerciseMutation,
} = studyCourseListApiSlice;
