import { Button, IconButton, InputAdornment } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { SubmitHandler, DefaultValues, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthContainer from './components/AuthContainer';
import { useSendOTPMutation } from '../../api/authApiSlice';
import AppTextField from '../../common/components/form-inputs/AppTextField';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Link from '@mui/material/Link';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { OtpNature } from '@/api/enum/otpNature.enum';
import { useAppDispatch } from '@/redux-hooks';
import { setOTPEmail } from './authSlice';
import AuthCard from './components/AuthCard';

export type FormValues = {
	email: string;
};

export const defaultValues: DefaultValues<FormValues> = {
	email: '',
};

export default function ForgotPasswordPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const formSchema = Yup.object().shape({
		email: Yup.string()
			.required(t('errorMessage.required'))
			.email(t('errorMessage.email')),
	});

	const { handleSubmit, control } = useForm<FormValues>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [sendOTP] = useSendOTPMutation();

	const onSubmit: SubmitHandler<FormValues> = async (data) => {
		const response = await sendOTP({
			nature: OtpNature.RESET_PASSWORD,
			loginID: data.email,
		});

		if ('data' in response) {
			dispatch(setOTPEmail(data.email));
			navigate(`/forgot-password/verify`);
		}
	};

	return (
		<>
			<AuthContainer>
				<AuthCard
					title={t('forgotPassword.forgotPasswordTitle')}
					message={t('forgotPassword.forgotPasswordMessage')}
				>
					<Link
						color='primary'
						component={RouterLink}
						to='/login'
						position='absolute'
						top='-50px'
						left='-20px'
						display='flex'
						alignItems='center'
						sx={{
							textDecoration: 'none',
						}}
					>
						<KeyboardArrowLeftIcon />
						{t('button.back')}
					</Link>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Stack
							width='382px'
							alignItems='center'
							spacing='24px'
							marginTop='40px'
						>
							<AppTextField
								control={control}
								name='email'
								label={t('common.email')}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												edge='end'
												sx={{ pointerEvents: 'none' }}
											>
												<EmailOutlinedIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Stack>

						<Button
							fullWidth
							type='submit'
							variant='contained'
							sx={{
								marginTop: '40px',
							}}
						>
							{t('button.sendVerificationEmail')}
						</Button>
					</form>
				</AuthCard>
			</AuthContainer>
		</>
	);
}
