import { apiSlice } from '../apiSlice';
import { SuccessResponse, Paginated } from '../DTO/common.interface';
import {
	practiceRecordDetailDto,
	PracticeFilter,
	PracticeRecordPageItem,
	PracticeMarkPageFilter,
	PracticeMarkPageItem,
	PracticeMarkDetailDto,
	PracticeMarkActionDto,
} from '../DTO/practiceRecord/practiceRecord.interface';

export const practiceRecordApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getPracticeRecordTemplateList: builder.query<SuccessResponse<any[]>, null>({
			query: () => ({
				url: `/web/practice/template/list`,
				method: 'GET',
			}),
		}),
		getPracticeRecordTemplateDetail: builder.query<
			SuccessResponse<practiceRecordDetailDto>,
			string
		>({
			query: (id) => ({
				url: `/web/practice/template/${id}`,
				method: 'GET',
			}),
		}),
		setPractice: builder.mutation<SuccessResponse<any>, any>({
			query: (body) => ({
				url: `/web/practice`,
				method: 'POST',
				body,
			}),
		}),
		getPracticePageList: builder.query<
			SuccessResponse<Paginated<PracticeRecordPageItem>>,
			PracticeFilter
		>({
			query: (body) => ({
				url: `/web/practice/assignment-page`,
				method: 'POST',
				body,
			}),
		}),
		getLessonList: builder.query<
			SuccessResponse<{ id: string; name: string }[]>,
			null
		>({
			query: () => ({
				url: `/web/lesson/list`,
				method: 'GET',
			}),
		}),
		getLessonChapterList: builder.query<
			SuccessResponse<{ id: string; title: string }[]>,
			string
		>({
			query: (lessonId) => ({
				url: `/web/chapter/list?lesson=${lessonId}`,
				method: 'GET',
			}),
		}),
		deletePractice: builder.mutation<SuccessResponse<any>, string>({
			query: (exerciseId) => ({
				url: `/web/practice/${exerciseId}`,
				method: 'DELETE',
			}),
		}),
		getPracticeDetail: builder.query<
			SuccessResponse<practiceRecordDetailDto>,
			string
		>({
			query: (exerciseId) => ({
				url: `/web/practice/${exerciseId}/detail`,
				method: 'GET',
			}),
			providesTags: ['PracticeDetail'],
		}),
		getPracticeMarkPageList: builder.query<
			SuccessResponse<Paginated<PracticeMarkPageItem>>,
			PracticeMarkPageFilter
		>({
			query: (body) => ({
				url: `/web/practice/marking-page`,
				method: 'POST',
				body,
			}),
			providesTags: ['PracticeMarkPageList'],
		}),
		getPracticeMarkDetail: builder.query<
			SuccessResponse<PracticeMarkDetailDto>,
			string
		>({
			query: (userExerciseId) => ({
				url: `/web/practice/answer/${userExerciseId}`,
				method: 'GET',
			}),
		}),
		handlePracticeMarkAction: builder.mutation<
			SuccessResponse<any>,
			PracticeMarkActionDto
		>({
			query: (body) => ({
				url: `/web/practice/answer/marking`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PracticeDetail', 'PracticeMarkPageList'],
		}),
	}),
});

export const {
	useGetPracticeRecordTemplateListQuery,
	useGetPracticeRecordTemplateDetailQuery,
	useGetPracticePageListQuery,
	useGetLessonListQuery,
	useGetLessonChapterListQuery,
	useGetPracticeDetailQuery,
	useGetPracticeMarkPageListQuery,
	useGetPracticeMarkDetailQuery,
	useSetPracticeMutation,
	useDeletePracticeMutation,
	useHandlePracticeMarkActionMutation,
} = practiceRecordApiSlice;
