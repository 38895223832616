import React from 'react';
import { useAppSelector } from '../../../redux-hooks';
import ApiErrorModal from './ApiErrorModal';
import { ModalType, selectModalState } from './modalSlice';

const MODAL_COMPONENTS: { [key in ModalType]: React.ElementType } = {
	[ModalType.API_ERROR]: ApiErrorModal,
};

const ModalRoot = () => {
	const { modalType, modalProps } = useAppSelector(selectModalState);

	if (!modalType) {
		return null;
	}

	const SpecificModal = MODAL_COMPONENTS[modalType];
	return <SpecificModal {...modalProps} />;
};

export default ModalRoot;
