import { Option } from '../DTO/common.interface';

export enum RelativeStatus {
	REQUESTED = 0,
	NORMAL = 1,
	// BLOCKED = 2,
}

export const relativeStatusOptions: Option[] = [
	{
		id: RelativeStatus.NORMAL,
		name: '正常',
	},
	{
		id: RelativeStatus.REQUESTED,
		name: '請求中',
	},
	// {
	// 	id: RelativeStatus.BLOCKED,
	// 	name: '被封鎖',
	// },
];
