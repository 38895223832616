import { useMemo } from 'react';
import { selectMyInfo } from '@/pages/auth/authSlice';
import { useSelector } from 'react-redux';
import { useGetPageCountQuery } from '@/api/user/applicationApiSlice';
import { useGetArticlesCountQuery } from '@/api/mentalHealthInfo/mentalHealthApiSlice';
import { useResourcesCountQuery } from '@/api/communityResources/resourcesListApiSlice';
import { useGetVouchersItemTotalQuery } from '@/api/shop/shopApiSlice';
import { useGetUser } from './useGetUser';
import { useGetRemveUserCountQuery } from '@/api/user/removeUserApiSlice';
import { useGetStudyCourseCountQuery } from '@/api/studyCourse/studyCourseListApiSlice';

interface Props {}
export function useGetPageCount(props: Props = {} as Props) {
	const { userRoles } = useGetUser({
		roleObj: {
			allApplicationNavBarCount: [
				'allApplicationNavBarCount',
				'allApplication',
			],
			couponListNavBarCount: ['couponListNavBarCount', 'coupon-list'],
			knowMentalHealthNavBarCount: [
				'knowMentalHealthNavBarCount',
				'mentalHealth',
			],
			storyShareNavBarCount: ['storyShareNavBarCount', 'mentalHealth'],
			columnShareNavBarCount: ['columnShareNavBarCount', 'mentalHealth'],
			communityResourcesNavBarCount: [
				'communityResourcesNavBarCount',
				'communityResources',
			],
			removeUserNavBarCount: ['removeUserNavBarCount', 'removeUser'],
			studyCourseNavBarCount: ['studyCourseNavBarCount', 'studyCourse'],
		},
	});
	const myInfo = useSelector(selectMyInfo);

	// console.log(userRoles.allApplicationNavBarCount);
	// 所有申请
	const { data: applicationPageCountRes } = useGetPageCountQuery(
		{
			centreEnum: myInfo?.centre,
		},
		{
			refetchOnMountOrArgChange: true,
			skip: !userRoles.allApplicationNavBarCount,
		}
	);
	const applicationPageCount = useMemo(() => {
		if (applicationPageCountRes) {
			return (
				(applicationPageCountRes.data.joinCentreCount || 0) +
				(applicationPageCountRes.data.joinMemberCount || 0) +
				(applicationPageCountRes.data.outCentreCount || 0) +
				(applicationPageCountRes.data.outMemberCount || 0)
			);
		}
		return 0;
	}, [applicationPageCountRes]);

	// 可兑换现金卷用户列表
	const { data: vouchersPageCountRes } = useGetVouchersItemTotalQuery(null, {
		refetchOnMountOrArgChange: true,

		skip: !userRoles.couponListNavBarCount,
	});
	const vouchersPageCount = useMemo(() => {
		if (vouchersPageCountRes) {
			return vouchersPageCountRes.data.purchased || 0;
		}
		return 0;
	}, [vouchersPageCountRes]);

	// 认识精神健康
	const { data: knowMentalHealthPageCountRes } = useGetArticlesCountQuery(
		{ categoryId: 2 },
		{
			refetchOnMountOrArgChange: true,
			skip: !userRoles.knowMentalHealthNavBarCount,
		}
	);
	const knowMentalHealthPageCount = useMemo(() => {
		if (knowMentalHealthPageCountRes) {
			return (
				(knowMentalHealthPageCountRes.data.draftCount || 0) +
				(knowMentalHealthPageCountRes.data.publishCount || 0) +
				(knowMentalHealthPageCountRes.data.readyCount || 0) +
				(knowMentalHealthPageCountRes.data.secondDraftCount || 0) +
				(knowMentalHealthPageCountRes.data.thirdDraftCount || 0) +
				(knowMentalHealthPageCountRes.data.pullCount || 0)
			);
		}
		return 0;
	}, [knowMentalHealthPageCountRes]);

	// 生命故事分享
	const { data: storySharePageCountRes } = useGetArticlesCountQuery(
		{ categoryId: 3 },
		{
			refetchOnMountOrArgChange: true,
			skip: !userRoles.storyShareNavBarCount,
		}
	);
	const storySharePageCount = useMemo(() => {
		if (storySharePageCountRes) {
			return (
				(storySharePageCountRes.data.draftCount || 0) +
				(storySharePageCountRes.data.publishCount || 0) +
				(storySharePageCountRes.data.readyCount || 0) +
				(storySharePageCountRes.data.secondDraftCount || 0) +
				(storySharePageCountRes.data.thirdDraftCount || 0) +
				(storySharePageCountRes.data.pullCount || 0)
			);
		}
		return 0;
	}, [storySharePageCountRes]);

	// 专栏分享
	const { data: columnSharePageCountRes } = useGetArticlesCountQuery(
		{ categoryId: 4 },
		{
			refetchOnMountOrArgChange: true,
			skip: !userRoles.columnShareNavBarCount,
		}
	);
	const columnSharePageCount = useMemo(() => {
		if (columnSharePageCountRes) {
			return (
				(columnSharePageCountRes.data.draftCount || 0) +
				(columnSharePageCountRes.data.publishCount || 0) +
				(columnSharePageCountRes.data.readyCount || 0) +
				(columnSharePageCountRes.data.secondDraftCount || 0) +
				(columnSharePageCountRes.data.thirdDraftCount || 0) +
				(columnSharePageCountRes.data.pullCount || 0)
			);
		}
		return 0;
	}, [columnSharePageCountRes]);

	// 社区资源列表
	const { data: resourcesPageCountRes } = useResourcesCountQuery(null, {
		refetchOnMountOrArgChange: true,
		skip: !userRoles.communityResourcesNavBarCount,
	});
	const resourcesPageCount = useMemo(() => {
		if (resourcesPageCountRes) {
			return (
				(resourcesPageCountRes.data.draftCount || 0) +
				(resourcesPageCountRes.data.publishCount || 0) +
				(resourcesPageCountRes.data.readyCount || 0) +
				(resourcesPageCountRes.data.secondDraftCount || 0) +
				(resourcesPageCountRes.data.thirdDraftCount || 0) +
				(resourcesPageCountRes.data.pullCount || 0)
			);
		}
		return 0;
	}, [resourcesPageCountRes]);

	// 移除用户列表
	const { data: removeUserPageCountRes } = useGetRemveUserCountQuery(null, {
		refetchOnMountOrArgChange: true,
		skip: !userRoles.removeUserNavBarCount,
	});
	const removeUserPageCount = useMemo(() => {
		if (removeUserPageCountRes) {
			return (
				(removeUserPageCountRes.data.freeUser || 0) +
				(removeUserPageCountRes.data.orgUser || 0)
			);
		}
		return 0;
	}, [removeUserPageCountRes]);

	// 學習課程列表
	const { data: studyCoursePageCountRes } = useGetStudyCourseCountQuery(null, {
		refetchOnMountOrArgChange: true,
		skip: !userRoles.studyCourseNavBarCount,
	});
	const studyCoursePageCount = useMemo(() => {
		if (resourcesPageCountRes) {
			return (
				(studyCoursePageCountRes?.data.draftCount || 0) +
				(studyCoursePageCountRes?.data.publishCount || 0) +
				(studyCoursePageCountRes?.data.readyCount || 0) +
				(studyCoursePageCountRes?.data.secondDraftCount || 0) +
				(studyCoursePageCountRes?.data.thirdDraftCount || 0) +
				(studyCoursePageCountRes?.data.pullCount || 0)
			);
		}
		return 0;
	}, [studyCoursePageCountRes]);

	return {
		applicationPageCount,
		knowMentalHealthPageCount,
		storySharePageCount,
		columnSharePageCount,
		resourcesPageCount,
		vouchersPageCount,
		removeUserPageCount,
		studyCoursePageCount,
	};
}
