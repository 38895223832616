import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import {
	Badge,
	BadgeProps,
	Button,
	Grid,
	Stack,
	Tab,
	Tabs,
	Typography,
	styled,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AppMultipleDropDownMenu from '@/common/components/form-inputs/AppMultipleDropDownMenu';
import { useRouteMatch } from '@/utils/routeHelper';
import { Link, useLocation } from 'react-router-dom';
import {
	ResourcesCountRes,
	ResourcesInfoFilter,
} from '@/api/DTO/resources/resourcesList.interface';
import { Option } from '@/api/DTO/common.interface';
import AppSummarySearchBar from '@/common/components/form-inputs/AppSummarySearchBar';
import { DistributionExerciseFilter } from '@/api/DTO/studyCourse/studyCourseList.interface';
import AddIcon from '@mui/icons-material/Add';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		position: 'relative',
		top: 12,
		backgroundColor: '#FFDAD6',
		color: '#FF5449',
	},
	'&.badge-active .MuiBadge-badge': {
		backgroundColor: '#FF5449',
		color: '#fff',
	},
}));

interface Props {
	methods: any;
}

export default function DistributionDialogFilter(prop: Props) {
	const { methods } = prop;
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { control, watch, resetField } =
		useFormContext<DistributionExerciseFilter>();

	const routeMatch = useRouteMatch(pathname, [
		'/study-course/study-course-list/drafts',
		'/study-course/study-course-list/director-approval',
		'/study-course/study-course-list/supervisor-approval',
		'/study-course/study-course-list/awaiting-release',
		'/study-course/study-course-list/released',
	]);
	const currentTab = routeMatch?.pattern?.path;

	const clearAllFilter = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();
		resetField('status');
	};

	// reset button
	const watchType = watch('status');

	const isTypeDirty = Array.isArray(watchType) && watchType.length !== 0;
	const isFilterDirty = isTypeDirty;

	const handleTabChange = () => {
		methods.reset();
	};
	return (
		<Stack>
			<Grid container spacing={3} alignItems='center' marginTop={0}>
				<Grid item xs={6}>
					<AppSummarySearchBar
						autoMinWidth={true}
						name='loginID'
						control={control}
						placeholder={t('common.pleaseEnterKeywordSearch')}
					/>
				</Grid>
			</Grid>
		</Stack>
	);
}
