import { Box, Typography } from '@mui/material';
import React from 'react';
import { AppFormLabel } from './AppFieldView';

export interface AppFieldViewProps {
	label?: string;
	imageElement?: React.ReactNode;
	text?: string;
}

export default function AppFieldView({
	label,
	imageElement,
	text,
}: AppFieldViewProps) {
	return (
		<>
			<AppFormLabel>{label}</AppFormLabel>
			{imageElement ? (
				<>
					<Box display='flex' justifyContent='space-between'>
						{imageElement}
					</Box>
					<Typography variant='caption' noWrap sx={{ width: '170px' }}>
						{text}
					</Typography>
				</>
			) : (
				<Typography variant='body1'>-</Typography>
			)}
		</>
	);
}
