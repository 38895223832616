import {
	MentalHealthInfoFilter,
	ReorderArticle,
} from '@/api/DTO/mentalHealthInfo/mentalHealthInfo.interface';
import AppDropDownMenu from '@/common/components/form-inputs/AppDropDownMenu';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { useMentalHealthOptions } from '@/hooks/useMentalHealthOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import Close from '@mui/icons-material/Close';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
} from '@mui/material';
import qs from 'qs';
import { useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

interface ReorderArticlesModalProps {
	menuCode: string;
	transformedSearchParams: MentalHealthInfoFilter;
}

const ReorderArticlesModal = (props: ReorderArticlesModalProps) => {
	const { menuCode, transformedSearchParams } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const { userRoles } = useGetUser<useGetUserResultType>({
		roleObj: {
			reorder: ['reorder', 'mentalHealth'],
		},
	});
	const { subCategoryList } = useMentalHealthOptions(['subCategoryList']);

	const defaultValues: DefaultValues<ReorderArticle> = {};

	const formSchema = Yup.object().shape({
		subcategoryId: Yup.string().required(t('errorMessage.pleaseSelect')),
	});

	const methods = useForm<ReorderArticle>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const handleClose = () => {
		methods.reset();
		setOpen(false);
	};

	const onSubmit: SubmitHandler<ReorderArticle> = async (data) => {
		navigate(`/mental-health-info/${menuCode}/order/${data.subcategoryId}`, {
			state: {
				propertySummarySearchParams: qs.stringify(transformedSearchParams),
			},
		});
		handleClose();
	};

	return (
		<>
			{userRoles.reorder && (
				<Button
					variant='outlined'
					startIcon={<SwapVertIcon />}
					onClick={() => setOpen(true)}
					style={{ alignItems: 'center' }}
				>
					{t('button.reorder')}
				</Button>
			)}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='xs'
				fullWidth
			>
				<DialogTitle variant='h5'>{t('common.pleaseSelect')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12}>
									<AppDropDownMenu
										control={methods.control}
										name='subcategoryId'
										label={t('common.subclassify')}
										options={subCategoryList}
										required
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ReorderArticlesModal;
