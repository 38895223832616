import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export enum ModalType {
	API_ERROR = 'API_ERROR',
}

interface ModalState {
	modalType: keyof typeof ModalType | null;
	modalProps: { [key: string]: any };
}

const initialState: ModalState = {
	modalType: null,
	modalProps: {},
};

export const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		showModal: (state, action: PayloadAction<ModalState>) => {
			const { modalType, modalProps } = action.payload;
			return { ...state, modalType, modalProps };
		},
		hideModal: () => {
			return initialState;
		},
	},
});

export const { showModal, hideModal } = modalSlice.actions;

export const selectModalState = (state: RootState) => state.modal;

export default modalSlice;
