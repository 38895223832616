import { apiSlice } from '../apiSlice';
import { SuccessResponse, Paginated } from '../DTO/common.interface';
import {
	FreeUserPageReqDTO,
	FreeUserPageResDTO,
	OrgUserPageReqDTO,
	OrgUserPageResDTO,
	SurveyBatchUserReqDTO,
	SurveyCreationReqDTO,
	SurveyDetailResDTO,
	SurveyFilterPageReqDTO,
	SurveyFreeUserDTO,
	SurveyOrgUserDTO,
	SurveyUpdateReqDTO,
	SurveyUserBatchReqDTO,
	SurveyUserPageFilterReqDTO,
} from '../DTO/survey/survey.interface';

export const surveyApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		addSurvey: builder.mutation<SuccessResponse<null>, SurveyCreationReqDTO>({
			query: (body) => ({
				url: `/web/survey`,
				method: 'POST',
				body,
			}),
		}),

		getSurvey: builder.query<SuccessResponse<SurveyDetailResDTO>, string>({
			query: (surveyId) => ({
				url: `/web/survey/${surveyId}`,
				method: 'GET',
			}),
			providesTags: ['Survey'],
		}),

		getSurveyPage: builder.query<
			SuccessResponse<Paginated<SurveyDetailResDTO>>,
			SurveyFilterPageReqDTO
		>({
			query: (body) => ({
				url: `/web/survey/page`,
				method: 'POST',
				body,
			}),
		}),

		editSurvey: builder.mutation<SuccessResponse<null>, SurveyUpdateReqDTO>({
			query: (body) => ({
				url: `/web/survey`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Survey'],
		}),

		deleteSurvey: builder.mutation<SuccessResponse<null>, string>({
			query: (surveyId) => ({
				url: `/web/survey/${surveyId}`,
				method: 'DELETE',
			}),
		}),

		addSurveyOrgUser: builder.mutation<
			SuccessResponse<null>,
			SurveyBatchUserReqDTO
		>({
			query: (body) => ({
				url: `/web/survey/org-user`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['MemberTarget'],
		}),

		addSurveyFreeUser: builder.mutation<
			SuccessResponse<null>,
			SurveyBatchUserReqDTO
		>({
			query: (body) => ({
				url: `/web/survey/free-user`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['NonMemberTarget'],
		}),

		getSurveyOrgUserPage: builder.query<
			SuccessResponse<Paginated<SurveyOrgUserDTO>>,
			SurveyUserPageFilterReqDTO
		>({
			query: (body) => ({
				url: `/web/survey/org-user/page`,
				method: 'POST',
				body,
			}),
			providesTags: ['MemberTarget'],
		}),

		getSurveyOrgJoinPage: builder.query<
			SuccessResponse<Paginated<OrgUserPageResDTO>>,
			OrgUserPageReqDTO
		>({
			query: (body) => ({
				url: `/web/survey/org-join/page`,
				method: 'POST',
				body,
			}),
			providesTags: ['MemberTarget'],
		}),

		getSurveyFreeUserPage: builder.query<
			SuccessResponse<Paginated<SurveyFreeUserDTO>>,
			SurveyUserPageFilterReqDTO
		>({
			query: (body) => ({
				url: `/web/survey/free-user/page`,
				method: 'POST',
				body,
			}),
			providesTags: ['NonMemberTarget'],
		}),

		getSurveyFreeJoinPage: builder.query<
			SuccessResponse<Paginated<FreeUserPageResDTO>>,
			FreeUserPageReqDTO
		>({
			query: (body) => ({
				url: `/web/survey/free-join/page`,
				method: 'POST',
				body,
			}),
			providesTags: ['NonMemberTarget'],
		}),

		addSendSurvey: builder.mutation<
			SuccessResponse<null>,
			SurveyUserBatchReqDTO
		>({
			query: (body) => ({
				url: `/web/survey/send`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['MemberTarget', 'NonMemberTarget'],
		}),

		deleteSurveyUser: builder.mutation<
			SuccessResponse<null>,
			SurveyUserBatchReqDTO
		>({
			query: (body) => ({
				url: `/web/survey/users`,
				method: 'DELETE',
				body,
			}),
			invalidatesTags: ['MemberTarget', 'NonMemberTarget'],
		}),
	}),
});

export const {
	useAddSurveyMutation,
	useGetSurveyQuery,
	useGetSurveyPageQuery,
	useEditSurveyMutation,
	useDeleteSurveyMutation,
	useAddSurveyOrgUserMutation,
	useAddSurveyFreeUserMutation,
	useGetSurveyOrgUserPageQuery,
	useGetSurveyOrgJoinPageQuery,
	useGetSurveyFreeUserPageQuery,
	useGetSurveyFreeJoinPageQuery,
	useAddSendSurveyMutation,
	useDeleteSurveyUserMutation,
} = surveyApiSlice;
