import { RelativePageFilter } from '@/api/DTO/relative/relative.interface';
import AppSummarySearchBar from '@/common/components/form-inputs/AppSummarySearchBar';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function AddRelativeSummaryFilterSection() {
	const { t } = useTranslation();
	const { control } = useFormContext<RelativePageFilter>();

	return (
		<Grid container rowSpacing={2} columnSpacing={1}>
			<Grid item xs={3}>
				<AppSummarySearchBar
					name='searchKey'
					control={control}
					placeholder={t('common.pleaseEnterKeywordSearch')}
				/>
			</Grid>
		</Grid>
	);
}
