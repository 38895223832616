import BasicSwitch from '@/common/components/BasicSwitch';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { Box, Grid, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { useSetFriendsPermissionsMutation } from '@/api/friend/friendApiSlice';
import { useParams } from 'react-router-dom';

interface formDataProps {
	formData: any;
}

const EditDetailTop = (props: formDataProps) => {
	const { formData } = props;
	console.log(formData);
	const { t } = useTranslation();
	const [setFriendsPermissions] = useSetFriendsPermissionsMutation();
	const { userId } = useParams();

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			edit: ['edit', 'relative-permission'],
		},
	});

	const handleChangePower = async (checked: boolean, key: string) => {
		const obj: {
			userId: string;
			actorId: string;
			stepView?: boolean;
			mapView?: boolean;
			mascotView?: boolean;
			avatarView?: boolean;
			[key: string]: any;
		} = {
			userId: userId || '',
			actorId: formData.id,
			stepView: formData.permission.stepView,
			mapView: formData.permission.mapView,
			mascotView: formData.permission.mascotView,
			avatarView: formData.permission.avatarView,
		};
		obj[key] = checked;
		const response = await setFriendsPermissions(obj);
		if ('data' in response) {
			enqueueSnackbar(`${t('snackbar.setPowerSuccess')}`, {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
		}
	};

	return (
		<Grid
			container
			alignItems='center'
			sx={{ height: '100%' }}
			columnSpacing={3}
			rowSpacing={1}
		>
			<Grid item xs={6}>
				<Typography variant='body2'>
					{t('relative.permissionSetting')}
				</Typography>
			</Grid>

			<Grid item xs={6} display='flex' justifyContent='flex-end'>
				<Grid item xs={8} textAlign='right' color='common.neutral40'>
					<Typography variant='body2'>
						{t('relative.becomeRelativeDate')}
					</Typography>
				</Grid>
				<Grid item xs={4} textAlign='right'>
					<Typography variant='body2'>{formData.friendAt}</Typography>
				</Grid>
			</Grid>
			<Grid item xs={6}>
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Typography variant='body2' color='common.neutral40'>
						{t('relative.walkingTarget')}
					</Typography>
					<Switch
						color='success'
						checked={formData.permission.stepView}
						inputProps={{ 'aria-label': 'controlled' }}
						onChange={(e) => {
							handleChangePower(e.target.checked, 'stepView');
						}}
					/>
				</Box>
			</Grid>
			<Grid item xs={6}>
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Typography variant='body2' color='common.neutral40'>
						{t('stat.regionalInfoRecord')}
					</Typography>
					<Switch
						color='success'
						checked={formData.permission.mapView}
						inputProps={{ 'aria-label': 'controlled' }}
						onChange={(e) => {
							handleChangePower(e.target.checked, 'mapView');
						}}
					/>
				</Box>
			</Grid>
			<Grid item xs={6}>
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Typography variant='body2' color='common.neutral40'>
						{t('relative.partnerCostume')}
					</Typography>
					<Switch
						color='success'
						checked={formData.permission.mascotView}
						inputProps={{ 'aria-label': 'controlled' }}
						onChange={(e) => {
							handleChangePower(e.target.checked, 'mascotView');
						}}
					/>
				</Box>
			</Grid>
			<Grid item xs={6}>
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Typography variant='body2' color='common.neutral40'>
						{t('relative.customAvatar')}
					</Typography>
					<Switch
						color='success'
						checked={formData.permission.avatarView}
						inputProps={{ 'aria-label': 'controlled' }}
						onChange={(e) => {
							handleChangePower(e.target.checked, 'avatarView');
						}}
					/>
				</Box>
			</Grid>
		</Grid>
	);
};

export default EditDetailTop;
