import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import { Button, Grid, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AppMultipleDropDownMenu from '../../../../common/components/form-inputs/AppMultipleDropDownMenu';
import { useGetCentreDistrictsQuery } from '@/api/area/areaApiSlice';
import { userDisabledOptions } from '@/api/enum/userStatus.enum';
import { FreeUserPageReqDTO } from '@/api/DTO/survey/survey.interface';
import { Centre } from '@/api/enum/centre.enum';

export default function AddNonMemberTargetSummaryFilterSection() {
	const { t } = useTranslation();
	const { control, watch, resetField } = useFormContext<FreeUserPageReqDTO>();

	const { data: freeDistricts } = useGetCentreDistrictsQuery(Centre.SHARE, {
		refetchOnMountOrArgChange: true,
	});

	const resetFilterRHFState = () => {
		resetField('statuses');
		resetField('districtIds');
	};

	const clearAllFilter = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();
		resetFilterRHFState();
	};

	// reset button
	const watchLoginId = watch('loginID');
	const watchDistrictIds = watch('districtIds');
	const watchStatuses = watch('statuses');

	const isLoginIdDirty = watchLoginId;
	const isDistrictIdsDirty = watchDistrictIds && watchDistrictIds.length > 0;
	const isStatusDirty = watchStatuses && watchStatuses.length > 0;
	const isFilterDirty = isLoginIdDirty || isDistrictIdsDirty || isStatusDirty;

	return (
		<Stack spacing={2}>
			<Grid container spacing={3} alignItems='center' minWidth={'800px'}>
				<Grid item xs>
					<AppMultipleDropDownMenu
						control={control}
						name='districtIds'
						label={t('common.belongedDistrict')}
						options={freeDistricts?.data ?? []}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>
				<Grid item xs>
					<AppMultipleDropDownMenu
						control={control}
						name='statuses'
						label={t('common.status')}
						options={userDisabledOptions}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>
				<Grid item xs>
					<Button
						variant='text'
						startIcon={<RotateLeftOutlinedIcon />}
						disabled={!isFilterDirty}
						onClick={clearAllFilter}
					>
						{t('button.reset')}
					</Button>
				</Grid>
			</Grid>
		</Stack>
	);
}
