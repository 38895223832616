import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import dayjs from 'dayjs';
import {
	API_DATE_FORMAT,
	DISPLAY_DATE_MONTH_FORMAT,
} from '../../../utils/dateHelper';

interface Props {
	label?: string;
	required?: boolean;
	minimumDate?: string;
	maximumDate?: string;
	placeholder?: string;
	removeErrorPadding?: boolean;
	large?: boolean;
	disabled?: boolean;
	formatDate?: string;
	minDate?: Date;
	maxDate?: Date;
}

export default function AppMonthDatePicker<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	required,
	minimumDate,
	maximumDate,
	placeholder,
	removeErrorPadding,
	large,
	disabled,
	minDate,
	maxDate,
	...props
}: Props & UseControllerProps<TFieldValues, TName>) {
	const { field, fieldState } = useController(props);
	const { formatDate } = props;
	const errorMessage = fieldState.error?.message;

	return (
		<DatePicker
			openTo='year'
			views={['year', 'month']}
			minDate={minDate || ''}
			maxDate={maxDate || ''}
			disabled={disabled}
			{...field}
			inputFormat={DISPLAY_DATE_MONTH_FORMAT}
			value={
				field.value === '' || field.value === undefined || field.value === null
					? null
					: dayjs(
							field.value,
							formatDate ? formatDate : API_DATE_FORMAT
					  ).format()
			}
			onChange={(value) => {
				if (value === null) {
					field.onChange(null);
				} else
					field.onChange(
						dayjs(value).format(formatDate ? formatDate : API_DATE_FORMAT)
					);
			}}
			components={{
				OpenPickerIcon: fieldState.error
					? EventBusyOutlinedIcon
					: CalendarTodayOutlinedIcon,
			}}
			renderInput={(params) => {
				const formatPlaceholder = params.inputProps?.placeholder;
				return (
					<FormControl fullWidth={true} error={!!errorMessage}>
						<TextField
							{...params}
							disabled
							inputProps={{
								...params.inputProps,
								disabled: true,
								placeholder: placeholder ? placeholder : formatPlaceholder,
							}}
							label={label}
							error={!!errorMessage}
							fullWidth
						/>
						<FormHelperText>{errorMessage}</FormHelperText>
					</FormControl>
				);
			}}
		/>
	);
}
