import { Option } from '../DTO/common.interface';

export enum UserType {
	NORMAL_USER = 1,
	PROGRAM_USER = 2,
}

export const userTypeOptions: Option[] = [
	{
		id: UserType.NORMAL_USER,
		name: '普通會員',
	},
	{
		id: UserType.PROGRAM_USER,
		name: '計劃會員',
	},
];

export const userTypeList: Option[] = [
	{
		id: 0,
		name: '普通會員',
	},
	{
		id: 1,
		name: '計劃會員 (Tier 1)',
	},
	{
		id: 2,
		name: '計劃會員 (Tier 2)',
	},
];

export const noMemberUserTypeList: Option[] = [
	{
		id: 0,
		name: '普通會員',
	},
];
