import {
	Button,
	CardActions,
	Grid,
	InputAdornment,
	Typography,
	IconButton,
	Box,
	FormControl,
	FormHelperText,
	Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useController,
	useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { FormType } from '@/api/enum/formType.enum';
import {
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useFileUploader } from '@/hooks/useFileUploader';
import { cloneDeep } from 'lodash';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import { AppFormLabel } from '@/common/components/form-view/AppFieldView';
import { ChapterInfo } from '@/api/DTO/studyCourse/studyCourseList.interface';
import AppRadioGroup from '@/common/components/form-inputs/AppRadioGroup';
import { useStudyCourseOptions } from '@/hooks/useStudyCourseOptions';
import AppTextField from '@/common/components/form-inputs/AppTextField';
import AppRichTextEditor from '@/common/components/editor/AppRichTextEditor';
import SingleCheckbox from '@/common/components/form-inputs/SingleCheckbox';
import CloseIcon from '@mui/icons-material/Close';
import { StyledImg, VisuallyHiddenInput } from './Styled';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { FileInfo } from '@/api/DTO/common.interface';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { ChapterType, SubjectType } from '@/api/enum/studyCourse.enum';
import SubjectForm from './SubjectForm';
import {
	useAddChapterMutation,
	useEditChapterMutation,
} from '@/api/studyCourse/studyCourseListApiSlice';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import LinkIcon from '@mui/icons-material/Link';

interface Props {
	type: FormType;
	setType: Dispatch<SetStateAction<FormType>>;
	chapterInfo?: ChapterInfo;
}

export default function ChapterForm({ type, setType, chapterInfo }: Props) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [needConfirm, setNeedConfirm] = useState(false);
	const showModal = useModal();
	const { chapterTypeList } = useStudyCourseOptions(['chapterTypeList']);
	const { uploadFile } = useFileUploader();
	const [audioFileKey, setAudioFileKey] = useState<FileInfo | null>(null);
	const [picFileKey, setPicFileKey] = useState<FileInfo[] | null>(null);
	const musicInputRef = useRef<HTMLInputElement>(null);
	const [inputKey, setInputKey] = useState(0);
	const { studyCourseId } = useParams();
	const [addChapter] = useAddChapterMutation();
	const [editChapter] = useEditChapterMutation();

	const defaultValues: DefaultValues<ChapterInfo> = useMemo(() => {
		if (chapterInfo) {
			const newData = cloneDeep(chapterInfo);
			const obj: any = {
				chapterId: newData.chapterId,
				lessonId: newData.lessonId,
				type: newData.type,
				title: newData.title,
				subTitle: newData.subTitle,
				content: newData.content,
				isMovie:
					newData.attachmentTypes && newData.attachmentTypes.indexOf(1) > -1
						? true
						: false,
				isImage:
					newData.attachmentTypes && newData.attachmentTypes.indexOf(2) > -1
						? true
						: false,
				isMusic:
					newData.attachmentTypes && newData.attachmentTypes.indexOf(3) > -1
						? true
						: false,
				videoUrl: newData.videoUrl,
				audioFileKey: '',
				picFileKeys: [],
			};
			if (obj.isMusic) {
				const audioFileKeyObj = newData.attachmentList?.find(
					(item) => item.type == 3
				);
				obj.audioFileKey = audioFileKeyObj?.url || '';
				audioFileKeyObj &&
					setAudioFileKey({ ...audioFileKeyObj, name: audioFileKeyObj.url });
			}
			if (obj.isImage) {
				const picFileKeyArr = newData.attachmentList?.filter(
					(item) => item.type == 2
				);
				obj.picFileKeys = picFileKeyArr?.map((item) => {
					return { fileKey: item.fileKey, url: item.url, name: item.url };
				});
				picFileKeyArr && setPicFileKey(obj.picFileKeys);
			}

			const subjects: any[] = [];
			const options: any[] = [];
			const answerList: any[] = [];
			(newData.qoList || []).map((item) => {
				if (newData.type == ChapterType.KnowledgeTest) {
					const answerObj = {
						falseAnswer: item.wrongFeedback,
						trueAnswer: item.rightFeedback,
						radioAnswer: '',
						multipleAnswer: {},
						linearNumMaxAnswer: 0,
						gridAnswer: {},
						gridAnswerNum: 0,
						multipleAnswerNum: 0,
						pcode: item.questionId,
					};
					if (item.type == SubjectType.Radio) {
						const obj = item.optionList.find((ktem: any[]) => {
							return ktem.sort == item.answerList[0];
						});
						answerObj.radioAnswer = obj.optionId;
					}
					if (item.type == SubjectType.Multiple) {
						const obj: { [key: string]: any } = {};
						let num = 0;
						item.optionList.map((ktem: any) => {
							if (item.answerList.indexOf(ktem.sort) > -1) {
								obj[ktem.optionId] = true;
								num = num + 1;
							} else {
								obj[ktem.optionId] = false;
							}
						});
						answerObj.multipleAnswer = obj;
						answerObj.multipleAnswerNum = num;
					}
					if (item.type == SubjectType.Linear) {
						const obj = item.optionList.find((ktem: any) => {
							return ktem.sort == item.answerList[0];
						});
						answerObj.linearNumMaxAnswer = Number(obj.sort);
					}
					if (item.type == SubjectType.Grid) {
						const obj: { [key: string]: any } = {};
						let num = 0;
						item.optionList.map((ktem: any) => {
							if (item.answerList.indexOf(ktem.sort) > -1) {
								obj[ktem.optionId] = true;
								num = num + 1;
							} else {
								obj[ktem.optionId] = false;
							}
						});
						answerObj.gridAnswer = obj;
						answerObj.gridAnswerNum = num;
					}
					answerList.push(answerObj);
				}
				subjects.push({
					code: item.questionId,
					title: item.title,
					type: item.type,
					isSetAnswer:
						!item.answerList || item.answerList.length <= 0 ? false : true,
				});
				item.optionList.map((ktem: any, index: number) => {
					if (item.type == SubjectType.Linear) {
						if (index + 1 == item.optionList.length) {
							options.push({
								code: ktem.optionId,
								pcode: item.questionId,
								text: ktem.content,
								type: item.type,
								linearNumMin: item.optionList[0].content,
								linearNumMax: ktem.content,
								linearTagMin: item.minLabel,
								linearTagMax: item.maxLabel,
							});
						}
					} else {
						options.push({
							code: ktem.optionId,
							pcode: item.questionId,
							text: ktem.content,
							type: item.type,
						});
					}
				});
			});
			obj.subjects = subjects;
			obj.options = options;
			obj.answerList = answerList;

			return obj;
		}
		const code = `p${new Date().getTime()}`;
		return {
			type: 1,
			subjects: [
				{
					code: code,
					title: '',
					type: SubjectType.Radio,
					isSetAnswer: false,
				},
			],
			options: [
				{
					code: new Date().getTime(),
					pcode: code,
					text: '',
					type: SubjectType.Radio,
				},
			],
			answerList: [],
		};
	}, [chapterInfo]);

	const formSchema = Yup.object().shape({
		type: Yup.string().required(t('errorMessage.pleaseSelect')),
		title: Yup.string()
			.required(t('errorMessage.required'))
			.max(20, t('errorMessage.max20', { max: 20 })),
		subTitle: Yup.string()
			.required(t('errorMessage.required'))
			.max(20, t('errorMessage.max20', { max: 20 })),
		videoUrl: Yup.string()
			.nullable()
			.when('isMovie', {
				is: true,
				then: (schema) =>
					schema
						.required(t('errorMessage.required'))
						.matches(
							/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w-]*)(&(amp;)?[\w?=]*)?/,
							t('errorMessage.invalidYoutubeLink')
						),
			}),
		audioFileKey: Yup.string().when('isMusic', {
			is: true,
			then: (schema) => schema.required(t('errorMessage.required')),
		}),
		picFileKeys: Yup.array()
			.nullable()
			.when('isImage', {
				is: true,
				then: (schema) => {
					return schema
						.min(1, t('errorMessage.pleaseUpload'))
						.required(t('errorMessage.pleaseUpload'));
				},
			}),
		subjects: Yup.array().of(
			Yup.object().shape({
				title: Yup.string().required(t('errorMessage.required')),
				isSetAnswer: Yup.boolean()
					.test(
						'isSetAnswer',
						t('errorMessage.pleaseSetAnswer'),
						(value, content) => {
							if ((content?.from || [])[1].value.type == 1) {
								return value === true;
							} else {
								return true;
							}
						}
					)
					.default(false),
			})
		),
		options: Yup.array().of(
			Yup.object().shape({
				text: Yup.string().when('type', {
					is: (type: string | number) => type == 1 || type == 2 || type == 4,
					then: (schema) => schema.required(t('errorMessage.required')),
				}),
				linearNumMax: Yup.string().when('type', {
					is: (type: string | number) => type == 3,
					then: (schema) => schema.required(t('errorMessage.required')),
				}),
				linearNumMin: Yup.string().when('type', {
					is: (type: string | number) => type == 3,
					then: (schema) => schema.required(t('errorMessage.required')),
				}),
				linearTagMax: Yup.string().when('type', {
					is: (type: string | number) => type == 3,
					then: (schema) => schema.required(t('errorMessage.required')),
				}),
				linearTagMin: Yup.string().when('type', {
					is: (type: string | number) => type == 3,
					then: (schema) => schema.required(t('errorMessage.required')),
				}),
			})
		),
	});

	const methods = useForm<ChapterInfo>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});
	const watchType = methods.watch('type');
	const watchIsMovie = methods.watch('isMovie');
	const watchIsImage = methods.watch('isImage');
	const watchIsMusic = methods.watch('isMusic');
	const watchAudioFileKey = methods.watch('audioFileKey');
	const watchVideoUrl = methods.watch('videoUrl');
	const imagesField = useController({
		name: 'picFileKeys',
		control: methods.control,
	});

	useEffect(() => {
		const subscription = methods.watch((value, { name }) => {
			if (!needConfirm) setNeedConfirm(true);
		});

		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const onSubmit: SubmitHandler<ChapterInfo> = async (data) => {
		const newData = cloneDeep(data);
		const requestObj: ChapterInfo = {
			chapterId: newData.chapterId,
			lessonId: studyCourseId || '',
			type: newData.type,
			title: newData.title,
			subTitle: newData.subTitle,
			content: newData.content,
			attachmentTypes: [],
		};
		if (newData.id) requestObj.id = newData.id;
		if (newData.isMovie) {
			requestObj.attachmentTypes.push(1);
			requestObj.videoUrl = newData.videoUrl;
		}
		if (newData.isMusic) {
			requestObj.attachmentTypes.push(3);
			requestObj.audioFileKey = audioFileKey?.fileKey;
		}
		if (newData.isImage) {
			requestObj.attachmentTypes.push(2);
			requestObj.picFileKeys = (newData.picFileKeys || []).map((item) => {
				return item.fileKey;
			});
		}
		requestObj.qaList = (newData.subjects || []).map(
			(item: any, index: number) => {
				// 知识测验才有答案
				let minLabel = '';
				let maxLabel = '';
				let minValue = '';
				let maxValue = '';
				const optionList = (newData.options || []).filter((ktem: any) => {
					if (ktem.pcode == item.code) {
						if (ktem.type == 3) {
							minLabel = ktem.linearTagMin;
							maxLabel = ktem.linearTagMax;
							minValue = ktem.linearNumMin;
							maxValue = ktem.linearNumMax;
						}
						return {
							content: ktem.text,
							code: ktem.code,
						};
					}
				});
				const newOptionList = optionList.map((ktem: any, kindex: any) => {
					return { ...ktem, sort: kindex + 1, content: ktem.text };
				});
				const otherOptionList = [
					{ content: minValue, sort: 1 },
					{ content: maxValue, sort: 2 },
				];

				let rightFeedback = '';
				let wrongFeedback = '';
				const type2answerList: number[] = [];
				const type4answerList: number[] = [];
				const list = (newData.answerList || []).filter(
					(ktem: any) => ktem.pcode == item.code
				);
				const answerList = (list || []).map((ktem: any) => {
					rightFeedback = ktem.trueAnswer;
					wrongFeedback = ktem.falseAnswer;
					let sort = null;
					if (item.type == SubjectType.Radio) {
						sort = newOptionList.find((jtem: any) => {
							if (jtem.code == ktem.radioAnswer) return { sort: jtem.sort };
						}).sort;
					}
					if (item.type == SubjectType.Multiple) {
						Object.keys(ktem.multipleAnswer).forEach((key, i) => {
							if (ktem.multipleAnswer[key]) {
								type2answerList.push(i + 1);
							}
						});
					}
					if (item.type == SubjectType.Linear) sort = ktem.linearNumMaxAnswer;
					if (item.type == SubjectType.Grid) {
						Object.keys(ktem.gridAnswer).forEach((key, i) => {
							if (ktem.gridAnswer[key]) {
								type4answerList.push(i + 1);
							}
						});
					}

					return sort;
				});
				const obj: any = {
					type: item.type,
					title: item.title,
					sort: index + 1,
					rightFeedback: rightFeedback,
					wrongFeedback: wrongFeedback,
					minLabel: minLabel,
					maxLabel: maxLabel,
					optionList:
						item.type == SubjectType.Linear ? otherOptionList : newOptionList,
				};
				if (requestObj.type == 1) {
					obj.answerList =
						item.type == SubjectType.Multiple
							? type2answerList
							: item.type == SubjectType.Grid
							? type4answerList
							: answerList;
				}
				return obj;
			}
		);
		if (type === FormType.create) {
			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('studyCourse.createChapter')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('studyCourse.isCreateChapter')}
							</Typography>
						</>
					}
					cancellationText={t('button.cancel')}
					confirmationText={t('button.sure')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={async () => {
						const response = await addChapter(requestObj);
						if ('data' in response) {
							enqueueSnackbar(t('snackbar.addChapterSuccess'), {
								variant: 'success',
								anchorOrigin: { horizontal: 'center', vertical: 'top' },
								autoHideDuration: 3000,
								action: (key) => {
									return <CloseIcon onClick={() => closeSnackbar(key)} />;
								},
							});
							navigate(`/study-course/study-course-list/${studyCourseId}/info`);
						}
					}}
				/>
			));
		} else {
			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('studyCourse.editChapter')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('studyCourse.isEditChapter')}
							</Typography>
						</>
					}
					cancellationText={t('button.cancel')}
					confirmationText={t('button.sure')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={async () => {
						const response = await editChapter(requestObj);
						if ('data' in response) {
							enqueueSnackbar(t('snackbar.editChapterSuccess'), {
								variant: 'success',
								anchorOrigin: { horizontal: 'center', vertical: 'top' },
								autoHideDuration: 3000,
								action: (key) => {
									return <CloseIcon onClick={() => closeSnackbar(key)} />;
								},
							});
							navigate(`/study-course/study-course-list/${studyCourseId}/info`);
						}
					}}
				/>
			));
		}
	};

	const handleClose = async () => {
		if (type === FormType.edit) {
			if (!needConfirm) return setType(FormType.view);

			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('communityResources.giveUpFix')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('communityResources.sureGiveUpFix')}
							</Typography>
							<Typography variant='body1'>
								{t('communityResources.fixAllReset')}
							</Typography>
						</>
					}
					cancellationText={t('button.giveUpFix')}
					confirmationText={t('button.saveAndLeave')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={() => methods.handleSubmit(onSubmit)()}
					onCancel={() => setType(FormType.view)}
				/>
			));
		} else {
			if (!needConfirm) return navigate(-1);

			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('communityResources.giveUpFix')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('communityResources.sureGiveUpFix')}
							</Typography>
							<Typography variant='body1'>
								{t('communityResources.fixAllReset')}
							</Typography>
						</>
					}
					cancellationText={t('button.giveUpFix')}
					confirmationText={t('button.saveAndLeave')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={() => methods.handleSubmit(onSubmit)()}
					onCancel={() => navigate(-1)}
				/>
			));
		}
	};

	const handleAudioChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const selectedFile = event.target.files?.[0];
		if (!selectedFile) return;
		const res = await uploadFile(selectedFile);
		if (res) {
			methods.setValue('audioFileKey', res.name);
			methods.trigger('audioFileKey');

			setAudioFileKey(res);
		}
	};

	const handleImagesChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setInputKey(inputKey + 1);
		const selectedFile = event.target.files?.[0];
		if (!selectedFile) return;
		const res = await uploadFile(selectedFile);
		if (res) {
			const formImages = methods.getValues('picFileKeys') || [];
			formImages.push(res);
			methods.setValue('picFileKeys', formImages);
			methods.trigger('picFileKeys');
			const obj: { url: string; name: string } = res;
			setPicFileKey((prevImages: any) => [...(prevImages ?? []), obj]);
		}
	};

	const handleCleaInformation = (field: any) => {
		if (field == 'audioFileKey')
			musicInputRef.current && (musicInputRef.current.value = '');
		methods.setValue(field, null);
	};

	const handleClearImages = (index: number) => {
		const formImages = methods.getValues('picFileKeys') || [];
		formImages.splice(index, 1);
		methods.setValue('picFileKeys', formImages);
		methods.trigger('picFileKeys');

		setPicFileKey((prevImages: any) => {
			const updatedImages = [...(prevImages || [])];
			updatedImages.splice(index, 1);
			return updatedImages;
		});
	};

	return (
		<>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid container spacing={3} marginTop={0} marginBottom={3}>
						<Grid item xs={12}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Typography variant='h6'>{t('common.setup')}</Typography>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='body2' color='common.neutral40'>
								{t('studyCourse.chapterType')}
							</Typography>
							<AppRadioGroup
								control={methods.control}
								name='type'
								row
								options={chapterTypeList}
							/>
						</Grid>
						<Grid item xs={12}>
							<AppTextField
								control={methods.control}
								name='title'
								label={t('studyCourse.chapterTitle')}
								maxLength={20}
								showMaxLength
							/>
						</Grid>
					</Grid>

					<Divider sx={{ marginBottom: '8px' }} />

					<Grid container spacing={3} marginTop={0} marginBottom={3}>
						<Grid item xs={12}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Typography variant='h6'>
									{t('studyCourse.paragraphContent')}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<AppTextField
								control={methods.control}
								name='subTitle'
								label={t('studyCourse.paragraphTitle')}
								maxLength={20}
								showMaxLength
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='body2' marginBottom={'16px'}>
								{t('studyCourse.introductoryText')}
							</Typography>
							<AppRichTextEditor control={methods.control} name='content' />
						</Grid>
						<Grid item xs={12}>
							<Typography variant='body2' color='common.neutral40'>
								{t('studyCourse.information')}
							</Typography>
							<SingleCheckbox
								control={methods.control}
								name='isMovie'
								label={t('studyCourse.movie')}
							/>
							<SingleCheckbox
								control={methods.control}
								name='isMusic'
								label={t('studyCourse.music')}
							/>
							<SingleCheckbox
								control={methods.control}
								name='isImage'
								label={t('studyCourse.image')}
							/>
						</Grid>
						{watchIsMovie && (
							<Grid item xs={12}>
								<AppTextField
									control={methods.control}
									name='videoUrl'
									label={t('studyCourse.movieLink')}
									InputProps={{
										endAdornment: (
											<>
												<InputAdornment position='end'>
													<LinkIcon />
												</InputAdornment>
											</>
										),
									}}
								/>
							</Grid>
						)}
						{watchIsMusic && (
							<Grid item xs={12}>
								<AppTextField
									disabled
									control={methods.control}
									name='audioFileKey'
									label={t('studyCourse.musicLink')}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												{watchAudioFileKey ? (
													<IconButton
														type='button'
														onClick={() =>
															handleCleaInformation('audioFileKey')
														}
													>
														<CloseIcon />
													</IconButton>
												) : (
													<IconButton component='label'>
														<AttachFileIcon />
														<VisuallyHiddenInput
															type='file'
															accept='audio/*'
															onChange={handleAudioChange}
															ref={musicInputRef}
														/>
													</IconButton>
												)}
											</InputAdornment>
										),
									}}
									helperText={t('common.newAudioHelperText')}
								/>
							</Grid>
						)}
						{watchIsImage && (
							<Grid item xs={12}>
								<FormControl
									error={!!imagesField.fieldState.error?.message}
									fullWidth
								>
									<AppFormLabel>{t('studyCourse.uploadImage')}</AppFormLabel>
									<Grid
										container
										direction='row'
										alignItems='center'
										spacing={2}
									>
										{(picFileKey || []).map((item, index) => (
											<Grid
												item
												xs={2}
												key={item.url}
												sx={{ minWidth: '200px' }}
											>
												<StyledImg
													src={item.url}
													sx={{
														width: '200px',
														height: '137px',
													}}
												></StyledImg>
												<Grid
													container
													direction='row'
													justifyContent='space-between'
													alignItems='center'
													style={{ width: '200px' }}
												>
													<Typography
														variant='caption'
														noWrap
														sx={{ width: '155px' }}
													>
														{t('common.picture')}
														{index + 1}
													</Typography>
													<IconButton
														key={inputKey}
														color='error'
														size='small'
														onClick={() => {
															handleClearImages(index);
														}}
													>
														<DeleteIcon fontSize='inherit' />
													</IconButton>
												</Grid>
											</Grid>
										))}

										{(!picFileKey || picFileKey?.length < 5) && (
											<Grid item>
												<Button
													component='label'
													variant='text'
													startIcon={<AddIcon />}
													style={{ alignItems: 'center' }}
												>
													{t('button.uploadImage')}
													<VisuallyHiddenInput
														key={inputKey}
														type='file'
														accept='image/*'
														name='picFileKeys'
														onChange={handleImagesChange}
													/>
												</Button>
											</Grid>
										)}
									</Grid>

									<FormHelperText>
										{imagesField.fieldState.error?.message}
									</FormHelperText>
								</FormControl>
							</Grid>
						)}

						<SubjectForm methods={methods} />
					</Grid>

					<CardActions sx={{ justifyContent: 'end', padding: '24px' }}>
						<Button onClick={handleClose}>{t('button.cancel')}</Button>
						<Button
							type='submit'
							variant='contained'
							disabled={!needConfirm && type === FormType.create}
						>
							{type === FormType.edit ? t('button.reserve') : t('button.add')}
						</Button>
					</CardActions>
				</form>
			</FormProvider>
		</>
	);
}
