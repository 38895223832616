import { FieldValues, FieldPath, UseControllerProps } from 'react-hook-form';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import AppTextField, { AppTextFieldProps } from './AppTextField';
import { useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

export default function AppPasswordTextField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
	props: AppTextFieldProps &
		UseControllerProps<TFieldValues, TName> &
		TextFieldProps
) {
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	return (
		<AppTextField
			{...props}
			type={showPassword ? 'text' : 'password'}
			maxLength={40}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<IconButton
							aria-label='toggle password visibility'
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge='end'
						>
							{showPassword ? (
								<VisibilityOffOutlinedIcon />
							) : (
								<VisibilityOutlinedIcon />
							)}
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	);
}
