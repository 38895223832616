import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import AppDatePicker from '../../../../../common/components/form-inputs/AppDatePicker';
import { NoticePageFilter } from '@/api/DTO/notice/notice.interface';

export default function UserNotiHistorySummaryFilterSection() {
	const { t } = useTranslation();
	const { control, watch } = useFormContext<NoticePageFilter>();

	const watchStartDate = watch('startDate');
	const watchEndDate = watch('endDate');

	return (
		<Grid container rowSpacing={2} columnSpacing={1}>
			<Grid item xs={5.5}>
				<AppDatePicker
					control={control}
					name='startDate'
					label={t('common.startDate')}
					maximumDate={watchEndDate}
				/>
			</Grid>
			<Grid item xs={1}>
				<Typography textAlign='center' alignContent='center' height='100%'>
					—
				</Typography>
			</Grid>
			<Grid item xs={5.5}>
				<AppDatePicker
					control={control}
					name='endDate'
					label={t('common.endDate')}
					minimumDate={watchStartDate}
				/>
			</Grid>
		</Grid>
	);
}
