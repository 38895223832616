import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from '@/utils/routeHelper';
import {
	Stack,
	Box,
	Tabs,
	Tab,
	Typography,
	Badge,
	styled,
	BadgeProps,
	Card,
	CardContent,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectMyInfo } from '@/pages/auth/authSlice';
import { useGetPageCountQuery } from '@/api/user/applicationApiSlice';
import { ApplicationContext } from '@/context/applicationContext';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { useGetPageCount } from '@/hooks/useGetPageCount';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		position: 'relative',
		top: 12,
		backgroundColor: '#FFDAD6',
		color: '#FF5449',
	},
	'&.badge-active .MuiBadge-badge': {
		backgroundColor: '#FF5449',
		color: '#fff',
	},
}));

export default function ApplicationTab() {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			passJoinMember: ['passJoinMember', 'allApplication'],
			rejectJoinMember: ['rejectJoinMember', 'allApplication'],
			passOutMember: ['passOutMember', 'allApplication'],
			rejectOutMember: ['rejectOutMember', 'allApplication'],
			passJoinCentre: ['passJoinCentre', 'allApplication'],
			rejectJoinCentre: ['rejectJoinCentre', 'allApplication'],
			passOutCentre: ['passOutCentre', 'allApplication'],
			rejectOutCentre: ['rejectOutCentre', 'allApplication'],
		},
	});

	const routeMatch = useRouteMatch(pathname, [
		'/users/application/tab/join-member',
		'/users/application/tab/out-member',
		'/users/application/tab/join-centre',
		'/users/application/tab/out-centre',
	]);

	const currentTab = routeMatch?.pattern?.path;
	const myInfo = useSelector(selectMyInfo);
	const { data: pageCount } = useGetPageCountQuery(
		{
			centreEnum: myInfo?.centre,
		},
		{
			refetchOnMountOrArgChange: true,
			skip: !myInfo?.centre,
		}
	);

	const tabList = [
		{
			name: 'joinMember',
			tabText: t('common.joinMemberApplicaion'),
			tabValue: '/users/application/tab/join-member',
			tabTo: '/users/application/tab/join-member',
			countKey: 'joinMemberCount',
		},
		{
			name: 'outMember',
			tabText: t('common.outMemberApplicaion'),
			tabValue: '/users/application/tab/out-member',
			tabTo: '/users/application/tab/out-member',
			countKey: 'outMemberCount',
		},
		{
			name: 'joinCentre',
			tabText: t('common.joinCentreApplicaion'),
			tabValue: '/users/application/tab/join-centre',
			tabTo: '/users/application/tab/join-centre',
			countKey: 'joinCentreCount',
		},
		{
			name: 'outCentre',
			tabText: t('common.outCentreApplicaion'),
			tabValue: '/users/application/tab/out-centre',
			tabTo: '/users/application/tab/out-centre',
			countKey: 'outCentreCount',
		},
	];

	return (
		<ApplicationContext.Provider value={{ userRoles }}>
			<Box sx={{ width: '100%' }}>
				<Stack direction={'row'} justifyContent={'space-between'} spacing={3}>
					<Typography variant='h4'>{t('common.allApplications')}</Typography>
				</Stack>
				<Card sx={{ marginTop: 3 }}>
					<CardContent>
						<Tabs
							value={currentTab}
							sx={{ borderBottom: 1, borderColor: 'divider' }}
						>
							{currentTab &&
								tabList.map((item) => (
									<Tab
										key={item.name}
										label={
											<>
												{Number(pageCount?.data[item.countKey]) > 0 ? (
													<StyledBadge
														badgeContent={pageCount?.data[item.countKey]}
														className={
															currentTab.indexOf(item.name) > -1
																? 'badge-active'
																: ''
														}
													>
														<Typography variant='body1'>
															&nbsp;{item.tabText}
														</Typography>
													</StyledBadge>
												) : (
													<Typography variant='body1'>
														{item.tabText}
													</Typography>
												)}
											</>
										}
										value={item.tabValue}
										to={item.tabTo}
										component={Link}
									/>
								))}
						</Tabs>

						<Outlet />
					</CardContent>
				</Card>
			</Box>
		</ApplicationContext.Provider>
	);
}
