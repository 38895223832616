import { Box, Card, CardContent, Chip, Grid } from '@mui/material';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import AppDataGrid, {
	initialPagination,
} from '../../../../common/components/dataGrid/AppDataGrid';
import AppDataGridHeaderWithSorting from '../../../../common/components/dataGrid/AppDataGridHeaderWithSorting';
import {
	API_DATE_FORMAT,
	DISPLAY_CHINESE_DATE_TIME_FORMAT,
	INVALID_DATE,
} from '../../../../utils/dateHelper';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../../../utils/qsHelper';
import UserNotiHistorySummaryFilterSection from './components/UserNotiHistorySummaryFilterSection';
import { useGetNoticePageQuery } from '@/api/notice/noticeApiSlice';
import { NoticePageFilter } from '@/api/DTO/notice/notice.interface';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export default function ViewUserNotiHistoryPage() {
	const { t } = useTranslation();
	const { userId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			send: ['send', 'user'],
		},
	});

	const defaultNoticePageFilter: NoticePageFilter = {
		pagination: initialPagination,
		sortings: [],
		userId: userId ?? '',
		startDate: dayjs().subtract(30, 'day').format(API_DATE_FORMAT),
		endDate: dayjs().format(API_DATE_FORMAT),
	};

	const defaultValues: DefaultValues<NoticePageFilter> =
		defaultNoticePageFilter;

	const formSchema = Yup.object().shape({
		startDate: Yup.string()
			.required(t('errorMessage.required'))
			.test(
				'required',
				t('errorMessage.date'),
				(value?: string) => value !== INVALID_DATE
			),
		endDate: Yup.string()
			.required(t('errorMessage.required'))
			.test(
				'required',
				t('errorMessage.date'),
				(value?: string) => value !== INVALID_DATE
			),
	});

	const methods = useForm<any>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const transformedSearchParams: NoticePageFilter = {
		...defaultNoticePageFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'startDate',
			'endDate',
		]),
	};

	const { data: noticePageSummary, isSuccess: isGetNoticePageSummarySuccess } =
		useGetNoticePageQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
			skip: !userRoles.send,
		});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const noticePageFilterFormResult = value as NoticePageFilter;

			// validate form and continue if form is valid
			methods.handleSubmit(() => {
				// append to url to reload page
				setSearchParams(qs.stringify(noticePageFilterFormResult));
			})();
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: any[]) => {
		if (response == null) return;
		const newResponse: any[] = [];
		response.forEach((item, index) => {
			newResponse.push({
				id: index,
				sendTime: dayjs(item.sendTime)
					.locale('zh-hk-my-setting')
					.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
				title: item.title,
				content: item.content,
				isRead: item.isRead,
			});
		});
		return newResponse;
	}, []);

	const DataGridHeader = (params: GridColumnHeaderParams) => {
		return (
			<AppDataGridHeaderWithSorting
				headerName={params.colDef.headerName}
				sortingsPropertyName={params.colDef.field}
			/>
		);
	};

	const columns: GridColDef[] = [
		{
			field: 'sendTime',
			headerName: t('common.sentTime'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'title',
			headerName: t('common.sentTitle'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'content',
			headerName: t('common.sentContent'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'isRead',
			headerName: t('common.messageStatus'),
			flex: 1,
			minWidth: 200,
			renderCell: (params) => (
				<Box padding='8px'>
					{params.row.isRead == false && (
						<Chip
							label={t('common.unread')}
							color='chipTertiary'
							variant='outlined'
						/>
					)}
					{params.row.isRead == true && (
						<Chip
							label={t('common.read')}
							color='chipGrey'
							variant='outlined'
						/>
					)}
				</Box>
			),
		},
	];

	const pageContent = (
		<>
			{userRoles.send && (
				<Grid container alignItems='stretch' columnSpacing={3} marginTop={2}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<FormProvider {...methods}>
									<form>
										<Grid
											container
											rowSpacing={2}
											columnSpacing={1}
											marginTop={0}
											marginBottom={4}
										>
											<Grid item xs={6}>
												<UserNotiHistorySummaryFilterSection />
											</Grid>
										</Grid>
										<AppDataGrid
											loading={!isGetNoticePageSummarySuccess}
											rows={
												transformResponse(noticePageSummary?.data?.items) ?? []
											}
											columns={columns}
											rowCount={
												noticePageSummary?.data?.pagination.totalItems ?? 0
											}
											getRowId={(row) => row.id}
										/>
									</form>
								</FormProvider>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			)}
		</>
	);

	return <div>{pageContent}</div>;
}
