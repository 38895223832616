import {
	DndContext,
	DragEndEvent,
	DragOverlay,
	PointerSensor,
	TouchSensor,
	closestCenter,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	styled,
} from '@mui/material';
import SortableArticlesItem from './SortableArticlesItem';
import { ArticleOrderSummaryItemDTO } from '@/api/DTO/mentalHealthInfo/mentalHealthInfo.interface';
import { useTranslation } from 'react-i18next';
import { ArticleCategory } from '@/api/enum/common.enum';

interface DndArticleListSectionProps {
	articleItems: ArticleOrderSummaryItemDTO[];
	handleRepositionItem: (activeIndex: number, overIndex: number) => void;
	categoryId: ArticleCategory;
}

export default function DndArticleListSection({
	articleItems,
	handleRepositionItem,
	categoryId,
}: DndArticleListSectionProps) {
	const { t } = useTranslation();

	// for input methods detection
	const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

	// triggered when dragging ends
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		if (!over) return;

		const activeItem = articleItems.find(
			(item: ArticleOrderSummaryItemDTO) => item.id === active.id
		);
		const overItem = articleItems.find(
			(item: ArticleOrderSummaryItemDTO) => item.id === over.id
		);

		if (!activeItem || !overItem) {
			return;
		}

		const activeIndex = articleItems.findIndex(
			(item: ArticleOrderSummaryItemDTO) => item.id === active.id
		);
		const overIndex = articleItems.findIndex(
			(item: ArticleOrderSummaryItemDTO) => item.id === over.id
		);

		if (activeIndex !== overIndex) {
			handleRepositionItem(activeIndex, overIndex);
		}
	};

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragEnd={handleDragEnd}
		>
			<SortableContext
				items={articleItems.map((i) => i.id)}
				strategy={rectSortingStrategy}
			>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow
								sx={{
									backgroundColor: '#EEF1F6',
								}}
							>
								<StyledTableCell>{t('common.title')}</StyledTableCell>
								{categoryId !== ArticleCategory.latestNews && (
									<StyledTableCell>{t('common.institution')}</StyledTableCell>
								)}
								<StyledTableCell>{t('common.district')}</StyledTableCell>
								<StyledTableCell>{t('common.format')}</StyledTableCell>
								<StyledTableCell>
									{t('common.estimatedReleaseDate')}
								</StyledTableCell>
								<StyledTableCell>
									{t('common.estimatedRemoveDate')}
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{articleItems?.map((item: ArticleOrderSummaryItemDTO) => (
								<SortableArticlesItem
									key={item.id}
									item={item}
									categoryId={categoryId}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<DragOverlay />
			</SortableContext>
		</DndContext>
	);
}

const StyledTableCell = styled(TableCell)({
	fontWeight: 'normal',
});
