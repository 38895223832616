import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import StaffInfoForm from './components/staffInfoForm';
import { FormType } from '@/api/enum/formType.enum';
import { useState } from 'react';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import AppFieldView from '@/common/components/form-view/AppFieldView';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useDeleteStaffMutation,
	useGetStaffInfoQuery,
} from '@/api/staff/staffApiSlice';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';

export default function StaffInfoPage() {
	const { t } = useTranslation();
	const [type, setType] = useState<FormType>(FormType.view);
	const { id } = useParams();
	const [deleteStaff] = useDeleteStaffMutation();
	const navigate = useNavigate();
	const showModal = useModal();

	const { data: staffInfo } = useGetStaffInfoQuery(id, {
		refetchOnMountOrArgChange: true,
	});

	const { userRoles } = useGetUser<useGetUserResultType>({
		roleObj: {
			edit: ['edit', 'staffList', staffInfo?.data.roleKey],
			delete: ['delete', 'staffList', staffInfo?.data.roleKey],
		},
	});

	const handleDelete = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('common.deleteStaff')}
				titleProps={{ variant: 'h6' }}
				description={
					<Typography variant='body1'>{t('staff.isDeleteStaff')}</Typography>
				}
				cancellationText=''
				confirmationText={t('button.delete')}
				confirmationButtonProps={{ color: 'error', variant: 'outlined' }}
				onConfirm={async () => {
					const response = await deleteStaff(id || '');
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.deleteStaffSuccess'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						navigate(-1);
					}
				}}
			/>
		));
	};
	return (
		<>
			<Grid container justifyContent='center' sx={{ width: 768 }}>
				<Grid item xs={12}></Grid>
			</Grid>
			<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
				<Box sx={{ width: '768px' }}>
					<Box display='flex' justifyContent='space-between'>
						<Typography variant='h4'>{t('common.staffInfo')}</Typography>
						{type === FormType.view && userRoles.delete && (
							<Button
								startIcon={<DeleteOutlined />}
								onClick={handleDelete}
								color='error'
								variant='outlined'
							>
								{t('button.deleteStaff')}
							</Button>
						)}
					</Box>
					<Card sx={{ marginTop: 3 }}>
						{(() => {
							switch (type) {
								case FormType.view:
									return (
										<>
											<CardContent sx={{ padding: '24px' }}>
												<Box display='flex' justifyContent='space-between'>
													<Typography variant='h6'>
														{t('common.accountInfo')}
													</Typography>
													{userRoles.edit && (
														<Button
															startIcon={<CreateOutlinedIcon />}
															onClick={() => setType(FormType.edit)}
														>
															{t('button.edit')}
														</Button>
													)}
												</Box>
												<Grid
													container
													spacing={3}
													marginTop={0}
													marginBottom={3}
												>
													<Grid item xs={6}>
														<AppFieldView
															label={t('common.staffName')}
															value={staffInfo?.data.name}
														/>
													</Grid>
													<Grid item xs={6}>
														<AppFieldView
															label={t('common.position')}
															value={staffInfo?.data.role}
														/>
													</Grid>
													<Grid item xs={6}>
														<AppFieldView
															label={t('common.email')}
															value={staffInfo?.data.email}
														/>
													</Grid>
												</Grid>
											</CardContent>
										</>
									);
								case FormType.edit:
									return (
										<StaffInfoForm
											id={id || ''}
											type={type}
											setType={setType}
											staffInfo={staffInfo?.data}
										/>
									);
							}
						})()}
					</Card>
				</Box>
			</Box>
		</>
	);
}
