import {
	Box,
	Button,
	Card,
	CardContent,
	Divider,
	Grid,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormType } from '@/api/enum/formType.enum';
import { useCallback, useState } from 'react';
import ChapterForm from './components/ChapterForm';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import AppFieldView, {
	AppFormLabel,
} from '@/common/components/form-view/AppFieldView';
import RichTextDisplay from '@/common/components/form-view/RichTextDisplay';
import { useParams } from 'react-router-dom';
import { useGetChapterDetailQuery } from '@/api/studyCourse/studyCourseListApiSlice';
import { StyledImg } from './components/Styled';
import DoneIcon from '@mui/icons-material/Done';
import DistributionExerciseDialog from './DistributionExerciseDialog/DistributionDialog';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { SubjectType } from '@/api/enum/studyCourse.enum';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import LinearScaleOutlinedIcon from '@mui/icons-material/LinearScaleOutlined';
import AppElementView from '@/common/components/form-view/AppElementView';
import { StudyCourseType } from '@/api/enum/common.enum';

export default function ChapterInfoPage() {
	console.log();
	const { t } = useTranslation();
	const [type, setType] = useState<FormType>(FormType.view);
	const { chapterId } = useParams();

	const { data: chapterInfo } = useGetChapterDetailQuery(chapterId || '', {
		refetchOnMountOrArgChange: true,
	});

	const { userRoles } = useGetUser<useGetUserResultType>({
		roleObj: {
			editChapter: [
				'editChapter',
				'studyCourse',
				String(chapterInfo?.data.lessonStatus),
			],
		},
	});

	const musicLink = (response: any) => {
		if (response == null) return;
		const obj = chapterInfo?.data.attachmentList?.find(
			(item) => item.type == 3
		);
		return decodeURIComponent(obj?.url || '');
	};

	const imageLink = (response: any) => {
		if (response == null) return;
		const arr = chapterInfo?.data.attachmentList?.filter(
			(item) => item.type == 2
		);
		return arr;
	};

	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>{t('studyCourse.chapterDetail')}</Typography>

				{chapterInfo?.data.lessonStatus == StudyCourseType.released && (
					<DistributionExerciseDialog />
				)}
			</Box>

			<Card sx={{ marginTop: '24px' }}>
				<CardContent sx={{ padding: '24px', paddingTop: 0 }}>
					{type == FormType.view && (
						<>
							<Box
								display='flex'
								justifyContent='space-between'
								paddingTop={'24px'}
							>
								<Typography variant='h6'>{t('common.setup')}</Typography>
								{userRoles.editChapter && (
									<Button
										startIcon={<CreateOutlinedIcon />}
										onClick={() => setType(FormType.edit)}
									>
										{t('button.edit')}
									</Button>
								)}
							</Box>
							<Grid container spacing={3} marginTop={0} marginBottom={3}>
								<Grid item xs={12}>
									<AppFieldView
										label={t('studyCourse.chapterType')}
										value={chapterInfo?.data.typeShow || '-'}
									/>
								</Grid>
								<Grid item xs={12}>
									<AppFieldView
										label={t('studyCourse.chapterTitle')}
										value={chapterInfo?.data.title || '-'}
									/>
								</Grid>
							</Grid>

							<Divider sx={{ marginBottom: '8px' }} />

							<Grid container spacing={3} marginTop={0} marginBottom={3}>
								<Grid item xs={12}>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<Typography variant='h6'>
											{t('studyCourse.paragraphContent')}
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<AppFieldView
										value={chapterInfo?.data.subTitle || '-'}
										label={t('studyCourse.paragraphTitle')}
									/>
								</Grid>
								<Grid item xs={12}>
									<RichTextDisplay
										label={t('studyCourse.introductoryText')}
										editorStateString={chapterInfo?.data.content}
									/>
								</Grid>
								{(chapterInfo?.data.attachmentTypes || []).includes(1) && (
									<Grid item xs={12}>
										<AppFieldView
											label={t('studyCourse.movieLink')}
											value={chapterInfo?.data.videoUrl}
											link={chapterInfo?.data.videoUrl}
										/>
									</Grid>
								)}
								{(chapterInfo?.data.attachmentTypes || []).includes(3) && (
									<Grid item xs={12}>
										<AppFieldView
											label={t('studyCourse.musicLink')}
											value={musicLink(chapterInfo)}
											link={musicLink(chapterInfo)}
										/>
									</Grid>
								)}
								{(chapterInfo?.data.attachmentTypes || []).includes(2) && (
									<Grid item xs={12}>
										<AppFormLabel>{t('studyCourse.uploadImage')}</AppFormLabel>
										<Grid container direction='row' spacing={2}>
											{(imageLink(chapterInfo) || []).map((item, index) => (
												<Grid
													item
													xs={2}
													sx={{ minWidth: '200px' }}
													key={item.fileKey}
												>
													<StyledImg
														src={item.url}
														sx={{
															width: '200px',
															height: '137px',
														}}
													></StyledImg>
													<Grid
														container
														direction='row'
														justifyContent='space-between'
														alignItems='center'
														style={{ width: '200px' }}
													>
														<Typography variant='caption' noWrap>
															{t('common.picture')}
															{index + 1}
														</Typography>
													</Grid>
												</Grid>
											))}
										</Grid>
									</Grid>
								)}
							</Grid>
							{(chapterInfo?.data.qoList || []).map((item, index) => (
								<Grid item xs={12} key={item.sort}>
									<Grid
										container
										spacing={3}
										marginBottom={'16px'}
										marginTop={'0px'}
									>
										<Grid item xs={1}>
											<Typography variant='h6'>
												{t('studyCourse.subject')}
												{index + 1}
											</Typography>
										</Grid>
										<Grid item xs={11}>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<AppFieldView
														label={t('studyCourse.subject') + (index + 1)}
														value={item.title}
													/>
												</Grid>
												<Grid item xs={12}>
													<AppElementView
														label={t('common.questionType')}
														value={
															<Stack
																direction='row'
																sx={{
																	justifyContent: 'flex-start',
																	alignItems: 'center',
																}}
															>
																{item.type == SubjectType.Radio ? (
																	<RadioButtonCheckedIcon />
																) : item.type == SubjectType.Multiple ? (
																	<CheckBoxOutlinedIcon />
																) : item.type == SubjectType.Linear ? (
																	<LinearScaleOutlinedIcon />
																) : item.type == SubjectType.Grid ? (
																	<Grid4x4Icon />
																) : (
																	''
																)}
																<span style={{ paddingLeft: '5px' }}>
																	{item.typeShow}
																</span>
															</Stack>
														}
													/>
												</Grid>
												{(item.type == SubjectType.Radio ||
													item.type == SubjectType.Multiple) &&
													item.optionList.map((ktem: any, index: number) => (
														<Grid item xs={12} key={ktem.sort}>
															<AppFieldView
																label={t('common.option') + (index + 1)}
																value={ktem.content}
																editButton={
																	(item.answerList || '').indexOf(ktem.sort) >
																		-1 && <DoneIcon sx={{ color: '#2E7D32' }} />
																}
															/>
														</Grid>
													))}
												{item.type == SubjectType.Linear && (
													<>
														<Grid item xs={6}>
															<AppFieldView
																label={t('practiceRecord.valueMin')}
																value={item.optionList[0].content}
															/>
														</Grid>
														<Grid item xs={6}>
															<AppFieldView
																label={t('practiceRecord.valueMax')}
																value={
																	item.optionList[item.optionList.length - 1]
																		.content
																}
															/>
														</Grid>
														<Grid item xs={6}>
															<AppFieldView
																label={t('practiceRecord.tagMin')}
																value={item.minLabel}
															/>
														</Grid>
														<Grid item xs={6}>
															<AppFieldView
																label={t('practiceRecord.tagMax')}
																value={item.maxLabel}
															/>
														</Grid>
														<Grid item xs={6}>
															<AppFieldView
																label={t('studyCourse.trueAnswer')}
																value={
																	item.answerList &&
																	item.optionList.find(
																		(s: any[]) => s.sort == item.answerList[0]
																	).content
																}
															/>
														</Grid>
													</>
												)}
												{item.type == SubjectType.Grid &&
													item.optionList.map((ktem: any, index: number) => (
														<Grid item xs={6} key={ktem.sort}>
															<AppFieldView
																label={t('common.option') + (index + 1)}
																value={ktem.content}
																editButton={
																	(item.answerList || '').indexOf(ktem.sort) >
																		-1 && (
																		<DoneIcon
																			sx={{
																				color: '#2E7D32',
																				marginRight: '50px',
																			}}
																		/>
																	)
																}
															/>
														</Grid>
													))}
												<Grid item xs={12}>
													<Divider />
												</Grid>
												<Grid item xs={12}>
													<Typography variant='body1'>
														{t('studyCourse.answerFeedback')}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<AppFieldView
														label={t('studyCourse.rightAnswer')}
														value={item.rightFeedback || '-'}
													/>
												</Grid>
												<Grid item xs={12}>
													<AppFieldView
														label={t('studyCourse.wrongAnswer')}
														value={item.wrongFeedback || '-'}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Divider />
								</Grid>
							))}
						</>
					)}
					{type == FormType.edit && (
						<ChapterForm
							type={type}
							setType={setType}
							chapterInfo={chapterInfo?.data}
						/>
					)}
				</CardContent>
			</Card>
		</>
	);
}
