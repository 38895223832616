/* eslint-disable no-mixed-spaces-and-tabs */
import { getCommunityName } from '@/api/enum/community.enum';
import { useGetUserCommunityMapQuery } from '@/api/statistics/statApiSlice';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';

interface ImageMapDropDownMenuProps {
	onChange: (value: string) => void;
	userId: string;
	removeErrorPadding?: boolean;
	disabled?: boolean;
	label: string;
}

export default function ImageMapDropDownMenu({
	label,
	userId,
	onChange,
	...props
}: ImageMapDropDownMenuProps) {
	const [communityEnum, setCommunityEnum] = useState('');

	const handleChange = (event: SelectChangeEvent) => {
		setCommunityEnum(event.target.value as string);
		onChange(event.target.value as string);
	};

	const { data: userCommunityMap } = useGetUserCommunityMapQuery(userId);

	return (
		<>
			<FormControl fullWidth>
				<InputLabel>{label}</InputLabel>

				<Select value={communityEnum} label={label} onChange={handleChange}>
					{userCommunityMap?.data.map((option) => (
						<MenuItem key={option.code} value={option.code}>
							{` ${getCommunityName(Number(option.code))} (${
								option.checkInCount
							}/${option.buildingCount})`}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	);
}
