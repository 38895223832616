import {
	FormControl,
	FormHelperText,
	Grid,
	TextField,
	TextFieldProps,
} from '@mui/material';
import {
	FieldPath,
	FieldValues,
	UseControllerProps,
	useController,
} from 'react-hook-form';

export enum InputType {
	normal,
	number,
	amount, // amount of money
	english,
	lastName,
}

export interface AppTextFieldProps {
	inputType?: InputType;
	maxLength?: number;
	showMaxLength?: boolean;
	slotProps?: any;
}

export default function AppTextField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	inputType,
	maxLength,
	showMaxLength,
	...props
}: AppTextFieldProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;
	const amountRegex = /^\d+(\.\d{0,2})?$/;
	const englishRegex = /^[a-zA-Z\s]*$/;

	return (
		<FormControl fullWidth={true} error={!!errorMessage}>
			<TextField
				{...props}
				{...field}
				value={field.value ?? ''}
				onChange={(e) => {
					if (inputType === InputType.number) {
						// extract number from string
						field.onChange(e.target.value.replace(/[^0-9]/g, ''));
					} else if (inputType === InputType.amount) {
						if (e.target.value === '' || amountRegex.test(e.target.value)) {
							field.onChange(e.target.value);
						}
					} else if (inputType === InputType.english) {
						if (e.target.value === '' || englishRegex.test(e.target.value)) {
							field.onChange(e.target.value);
						}
					} else if (inputType === InputType.lastName) {
						if (e.target.value === '' || englishRegex.test(e.target.value)) {
							field.onChange(e.target.value.toUpperCase());
						}
					} else field.onChange(e.target.value);
				}}
				error={!!errorMessage}
				inputProps={{ maxLength: maxLength, row: 5 }}
			/>
			{maxLength && showMaxLength && (
				<FormHelperText sx={{ textAlign: 'right' }}>
					{field.value?.length ?? 0}/{maxLength}
				</FormHelperText>
			)}
			<FormHelperText>{errorMessage}</FormHelperText>
		</FormControl>
	);
}
