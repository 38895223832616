import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import { ResourcesInfoDataGrid } from '../StudyCourseSummaryPage';
import { ResourcesInfoItem } from '@/api/DTO/resources/resourcesList.interface';
import {
	DistributionDto,
	DistributionExerciseFilter,
} from '@/api/DTO/studyCourse/studyCourseList.interface';
import { filterObjectByKeys, parseSummarySearchParams } from '@/utils/qsHelper';
import DistributionDialogFilter from './DistributionDialogFilter';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetOrgUserPageQuery } from '@/api/user/userApiSlice';
import { OrgUserPageItem } from '@/api/DTO/user/user.interface';
import {
	UserStatus,
	getUserStatusChipColor,
	getUserStatusLabel,
} from '@/api/enum/userStatus.enum';
import qs from 'qs';
import AppDataGridHeaderWithSorting from '@/common/components/dataGrid/AppDataGridHeaderWithSorting';
import { UserType } from '@/api/enum/userType.enum';
import {
	API_DATE_TIME_FORMAT,
	DISPLAY_CHINESE_DATE_TIME_FORMAT,
	replaceTimeTo235959,
} from '@/utils/dateHelper';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AppNumberTextField from '@/common/components/form-inputs/AppNumberTextField';
import AppDatePicker from '@/common/components/form-inputs/AppDatePicker';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useDistributionExerciseMutation } from '@/api/studyCourse/studyCourseListApiSlice';

import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import AppTextField from '@/common/components/form-inputs/AppTextField';

interface Props {}

export interface OrgUserPageDataGrid {
	id: string;
	userId: string;
	email: string;
	lastLogin: string;
	username: string;
	phone: string;
	district: string;
	userType: number;
	serviceNumber?: string;
	staff?: string;
	status?: UserStatus;
	joinedAt: string;
}

const DistributionDialog = (props: Props) => {
	// const {} = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [selectedId, setSelectedId] = useState<string[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);
	const { chapterId } = useParams();
	const [distributionExercise] = useDistributionExerciseMutation();
	const showModal = useModal();

	const defaultDistributionExerciseFilter: DistributionExerciseFilter = {
		pagination: initialPagination,
		sortings: [],
		status: [],
		loginID: '',
		userTypes: [2],
	};

	const defaultValues: DefaultValues<DistributionExerciseFilter> =
		defaultDistributionExerciseFilter;
	const methods = useForm<DistributionExerciseFilter>({
		defaultValues,
	});

	const distributiondefaultValues: DefaultValues<DistributionDto> = {
		chapterId: chapterId,
		coin: '',
		experience: '',
		deadline: '',
		userIds: [],
		title: '',
	};
	const formSchema = Yup.object().shape({
		coin: Yup.string().required(t('errorMessage.required')),
		experience: Yup.string().required(t('errorMessage.required')),
		deadline: Yup.string().required(t('errorMessage.pleaseSelect')),
		title: Yup.string().required(t('errorMessage.required')),
	});
	const distributionMethods = useForm<DistributionDto>({
		defaultValues: distributiondefaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const transformedSearchParams: DistributionExerciseFilter = {
		...defaultDistributionExerciseFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'loginID',
		]),
	};
	const {
		data: orgUserPageSummary,
		isLoading: isGetOrgUserPageSummaryLoading,
		isSuccess: isGetOrgUserPageSummarySuccess,
	} = useGetOrgUserPageQuery(transformedSearchParams, {
		refetchOnMountOrArgChange: true,
	});

	useEffect(() => {
		const subscription = methods.watch((value) => {
			const filterFormResult = value as DistributionExerciseFilter;
			setSearchParams(qs.stringify(filterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	useEffect(() => {
		if (!isGetOrgUserPageSummaryLoading) setSelectedId([]);
	}, [orgUserPageSummary]);

	const transformResponse = useCallback((response?: OrgUserPageItem[]) => {
		if (response == null) return;
		const newResponse: OrgUserPageDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				...item,
			});
		});
		return newResponse;
	}, []);

	const handleClose = () => {
		distributionMethods.reset();
		setSelectedId([]);
		setOpen(false);
	};

	const DataGridHeader = (params: GridColumnHeaderParams) => {
		return (
			<AppDataGridHeaderWithSorting
				headerName={params.colDef.headerName}
				sortingsPropertyName={params.colDef.field}
			/>
		);
	};

	const columns: GridColDef[] = [
		{
			field: 'username',
			headerName: t('common.username'),
			flex: 1,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='body2'
						variant='regular'
					>
						{params.row.username}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.phone}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.email}
					</Typography>
				</Box>
			),
		},
		{
			field: 'district',
			headerName: t('common.belongedDistrict'),
			flex: 1,
		},
		{
			field: 'userType',
			headerName: t('common.userCategory'),
			flex: 1,
			renderCell: (params) => (
				<Chip
					label={
						params.row.userType === UserType.NORMAL_USER
							? t('common.normalMember')
							: t('common.programMember')
					}
					color={
						params.row.userType === UserType.NORMAL_USER
							? 'chipTertiary'
							: 'chipPrimary'
					}
					variant='outlined'
				/>
			),
		},
		{
			field: 'serviceNumber',
			headerName: t('common.serviceNumber'),
			flex: 1,
		},
		{
			field: 'staff',
			headerName: t('common.staffInCharge'),
			flex: 1,
		},
		{
			field: 'status',
			headerName: t('common.status'),
			flex: 1,
			renderCell: (params) => (
				<Chip
					label={getUserStatusLabel(params.row.status)}
					color={getUserStatusChipColor(params.row.status)}
					variant='outlined'
				/>
			),
		},
		{
			field: 'joinedAt',
			headerName: t('common.membershipTime'),
			flex: 1,
			renderCell: (params) => (
				<Typography variant='regular'>
					{params.row.joinedAt
						? dayjs(params.row.joinedAt)
								.locale('zh-hk-my-setting')
								.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)
						: '-'}
				</Typography>
			),
		},
	];

	const onSubmit: SubmitHandler<DistributionDto> = async (data) => {
		if (selectedId.length <= 0) {
			enqueueSnackbar(t('snackbar.pleaseSelectUser'), {
				variant: 'warning',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			return;
		}
		const userArr = selectedId.map((item) => {
			return orgUserPageSummary?.data.items.find((ktem) => {
				return ktem.userId == item;
			});
		});
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('button.distributionExercise')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('studyCourse.sureDistributionExercises')}
						</Typography>
						<br />
						{userArr.map((item) => (
							<Typography variant='body1' key={item?.userId}>
								<span
									style={{
										padding: '0 10px',
										fontWeight: 'bold',
										fontSize: '20px',
									}}
								>
									·
								</span>
								{item?.username}
								{item?.serviceNumber && `(${item.serviceNumber})`}
							</Typography>
						))}
					</>
				}
				cancellationText={''}
				confirmationText={t('button.sureDistribute')}
				confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
				onConfirm={async () => {
					const response = await distributionExercise({
						...data,
						deadline: replaceTimeTo235959(data.deadline),
						userIds: selectedId,
					});
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.btachDistributionSuccess'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});

						handleClose();
					}
				}}
			/>
		));
	};

	return (
		<>
			<Button
				type='submit'
				variant='contained'
				startIcon={<AddIcon />}
				onClick={() => setOpen(true)}
			>
				{t('button.batchDistributionExercise')}
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='lg'
				fullWidth
			>
				<DialogTitle variant='h5'>
					{t('studyCourse.batchDistributionExerciseMemberUser')}
				</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<Typography variant='body1'>
						{t('practiceRecord.completionReward')}
					</Typography>
					<FormProvider {...distributionMethods}>
						<Grid container spacing={3} marginTop={0} marginBottom={3}>
							<Grid item xs={6}>
								<AppNumberTextField
									control={distributionMethods.control}
									name={`coin`}
									type='number'
									nonNegative={true}
									fieldLabel={t('practiceRecord.goldNum')}
								/>
							</Grid>
							<Grid item xs={6}>
								<AppNumberTextField
									control={distributionMethods.control}
									name={`experience`}
									type='number'
									nonNegative={true}
									fieldLabel={t('practiceRecord.addEmpirical')}
								/>
							</Grid>
							<Grid item xs={6}>
								<AppTextField
									control={distributionMethods.control}
									name='title'
									label={t('practiceRecord.exerciseTitle')}
								/>
							</Grid>
							<Grid item xs={6}>
								<AppDatePicker
									minimumDate={dayjs()
										.add(0, 'day')
										.format(API_DATE_TIME_FORMAT)}
									control={distributionMethods.control}
									name='deadline'
									label={t('practiceRecord.expirationDate')}
									formatDate={API_DATE_TIME_FORMAT}
								/>
							</Grid>
						</Grid>
					</FormProvider>
					<Divider />
					<FormProvider {...methods}>
						<DistributionDialogFilter methods={methods} />

						<Box marginTop={2}>
							<AppDataGrid
								loading={!isGetOrgUserPageSummarySuccess}
								rows={transformResponse(orgUserPageSummary?.data?.items) ?? []}
								columns={columns}
								rowCount={orgUserPageSummary?.data?.pagination.totalItems ?? 0}
								getRowId={(row) => row.userId}
								checkboxSelection
								selectionModel={selectedId}
								onSelectionModelChange={(ids) => {
									setSelectedId(ids.map((id) => String(id)));
								}}
							/>
						</Box>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Typography variant='body2' color='#C2841A' marginRight={'8px'}>
						<InfoOutlinedIcon
							sx={{
								fontSize: '16px',
								position: 'relative',
								top: '3px',
							}}
						/>
						{t('studyCourse.exercisesAutoDistribution')}
					</Typography>
					<Button
						type='submit'
						variant='contained'
						onClick={distributionMethods.handleSubmit(onSubmit)}
					>
						{t('button.distribution')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DistributionDialog;
