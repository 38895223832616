import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface formDataProps {
	formData: any;
}

const JoinMemberDetailTop = (props: formDataProps) => {
	const { formData } = props;
	const { t } = useTranslation();
	return (
		<Grid container alignItems='center' sx={{ height: '100%' }}>
			<Grid item xs={2}>
				<Typography variant='body2' color='#5D5E61'>
					{t('common.serviceNumber')}
				</Typography>
			</Grid>
			<Grid item xs={10}>
				<Typography variant='body2'>
					{formData?.serviceNumber || '-'}
				</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography variant='body2' color='#5D5E61'>
					{t('common.applyTime')}
				</Typography>
			</Grid>
			<Grid item xs={10}>
				<Typography variant='body2'>{formData?.applyDate || '-'}</Typography>
			</Grid>
		</Grid>
	);
};

export default JoinMemberDetailTop;
