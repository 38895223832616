// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
export const PASSWORD_PATTERN = new RegExp(/.{6,}/);
export const ADMIN_PASSWORD_PATTERN = new RegExp(
	/^(?=.*[A-Za-z])(?=.*\d).{8,}$/
);

// export const PASSWORD_PATTERN = new RegExp(
// 	/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]).{6,}$/
// );
export const VERIFICATION_CODE_PATTERN = new RegExp(/^\d{4}$/);

export const NONE_INVALID_DATE_PATTERN = new RegExp(/^((?!Invalid Date).)*$/);
