/* eslint-disable no-mixed-spaces-and-tabs */
import { ReactNode } from 'react';
import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextFieldProps,
} from '@mui/material';
import { Option } from '../../../api/DTO/common.interface';

interface AppDropDownMenuProps {
	passObjectValue?: boolean;
	options: Option[];
	infoPopUp?: ReactNode;
	removeErrorPadding?: boolean;
	disabled?: boolean;
	disabledItem?: string | number | undefined;
	handleChange?: any;
}

export default function AppDropDownMenu<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	passObjectValue,
	label,
	options,
	handleChange,
	...props
}: AppDropDownMenuProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { disabled, disabledItem } = props;
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<FormControl error={!!fieldState.error?.message} fullWidth>
			<InputLabel>{label}</InputLabel>
			<Select
				disabled={disabled}
				{...field}
				variant='outlined'
				label={label}
				onChange={(e) => {
					field.onChange(
						passObjectValue
							? options.find((i) => i.id === e.target.value)
							: e.target.value
					);
					handleChange && handleChange(e.target.value);
				}}
				value={
					field.value == null
						? ''
						: passObjectValue
						? (field.value as Option).id
						: field.value
				}
				error={!!errorMessage}
			>
				{options.map((option) => (
					<MenuItem
						key={option.id}
						value={option.id}
						disabled={disabledItem == option.id}
					>
						<Stack
							direction='row'
							sx={{
								justifyContent: 'flex-start',
								alignItems: 'center',
							}}
						>
							{option.icon && option.icon}&nbsp;{option.name}
						</Stack>
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{errorMessage}</FormHelperText>
		</FormControl>
	);
}
