import { apiSlice } from '../apiSlice';
import { SuccessResponse } from '../DTO/common.interface';
import {
	AdjustUserStepReqDTO,
	FetchUserStepReqDTO,
	FetchUserStepResDTO,
} from '../DTO/health/heath.interface';

export const staffApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		fetchUserSteps: builder.query<
			SuccessResponse<FetchUserStepResDTO>,
			FetchUserStepReqDTO
		>({
			query: (body) => ({
				url: `/web/health/steps/fetch`,
				method: 'POST',
				body,
			}),
			providesTags: ['UserSteps'],
		}),

		adjustUserSteps: builder.mutation<
			SuccessResponse<null>,
			AdjustUserStepReqDTO
		>({
			query: (body) => ({
				url: `/web/health/steps/adjust`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['UserSteps'],
		}),
	}),
});

export const { useFetchUserStepsQuery, useAdjustUserStepsMutation } =
	staffApiSlice;
