import { yupResolver } from '@hookform/resolvers/yup';
import Close from '@mui/icons-material/Close';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppNumberTextField from '../form-inputs/AppNumberTextField';
import { AdjustUserStepReqDTO } from '@/api/DTO/health/heath.interface';
import { useAdjustUserStepsMutation } from '@/api/health/healthApiSlice';
import { closeSnackbar, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

interface EditNumOfStepModalProps {
	userId: string;
	originalSteps: number;
	date: string;
}

const EditNumOfStepModal = (props: EditNumOfStepModalProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [adjustUserSteps] = useAdjustUserStepsMutation();

	const defaultValues: DefaultValues<AdjustUserStepReqDTO> = {
		userId: props.userId,
		steps: 0,
		date: props.date,
	};

	const formSchema = Yup.object().shape({
		steps: Yup.number()
			.nullable()
			.transform((curr, orig) => (orig === '' ? null : curr))
			.min(0, t('errorMessage.min0Max100000'))
			.max(100000, t('errorMessage.min0Max100000'))
			.test(
				'maxTotalSteps',
				t('errorMessage.maxSteps1000000'),
				function (value) {
					return value == null || value + props.originalSteps <= 1000000;
				}
			),
	});

	const methods = useForm<AdjustUserStepReqDTO>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		methods.setValue('userId', props.userId);
	}, [props.userId]);

	const handleClose = () => {
		setOpen(false);
		methods.reset();
	};

	const onSubmit: SubmitHandler<AdjustUserStepReqDTO> = async (data) => {
		const response = await adjustUserSteps(data);
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.editNumOfStepSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
		}
	};

	return (
		<>
			<IconButton color='primary' onClick={() => setOpen(true)}>
				<CreateOutlinedIcon sx={{ cursor: 'pointer' }} />
			</IconButton>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='sm'
				fullWidth
			>
				<DialogTitle variant='h5'>{t('stat.editNumOfSteps')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12} display='flex' alignItems='center'>
									<Box
										width='100%'
										display='flex'
										gap={1}
										flexDirection='column'
									>
										<Grid container>
											<Grid item width={'120px'} marginRight='16px'>
												<Typography variant='body2' color='common.neutral40'>
													{t('common.date')}
												</Typography>
											</Grid>
											<Grid item xs>
												<Typography
													variant='body1'
													sx={{ wordBreak: 'break-word' }}
												>
													{props.date}
												</Typography>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item width={'120px'} marginRight='16px'>
												<Typography variant='body2' color='common.neutral40'>
													{t('stat.originalSteps')}
												</Typography>
											</Grid>
											<Grid item xs>
												<Typography
													variant='body1'
													sx={{ wordBreak: 'break-word' }}
												>
													{props.originalSteps}
												</Typography>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item width={'120px'} marginRight='16px'>
												<Typography variant='body2' color='common.neutral40'>
													{t('stat.editedSteps')}
												</Typography>
											</Grid>
											<Grid item xs>
												<Typography
													variant='body1'
													sx={{ wordBreak: 'break-word' }}
												>
													{props.originalSteps + Number(methods.watch('steps'))}
												</Typography>
											</Grid>
										</Grid>
									</Box>
									<AppNumberTextField
										control={methods.control}
										name='steps'
										fieldLabel={t('stat.editNum')}
										type='number'
										size='small'
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.save')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EditNumOfStepModal;
