import { Option } from '../DTO/common.interface';

export enum FurnitureSubCategory {
	Wallpaper = 1,
	Floor = 2,
	WindowObject = 3,
	FloorToy = 4,
	Plate = 5,
	Food = 6,
	BedAndLargeObject = 7,
}

export const furnitureSubCategoryOptions: Option[] = [
	{ id: FurnitureSubCategory.Wallpaper, name: '牆紙' },
	{ id: FurnitureSubCategory.Floor, name: '地板' },
	{ id: FurnitureSubCategory.WindowObject, name: '窗口物件' },
	{ id: FurnitureSubCategory.FloorToy, name: '地上玩具' },
	{ id: FurnitureSubCategory.Plate, name: '碟' },
	{ id: FurnitureSubCategory.Food, name: '食物' },
	{ id: FurnitureSubCategory.BedAndLargeObject, name: '床+大型物件' },
];
