import { UserMissionListResDTO } from '@/api/DTO/shop/shop.interface';
import { useFilterUserMissionListQuery } from '@/api/mockApiSlice';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../../utils/qsHelper';
import { PaginationAndSorting } from '@/api/DTO/common.interface';

export interface UserMissionListDataGrid {
	id: string;
	name: string;
	type: string;
	coin: string;
	exp: string;
	level: string;
}

export default function UserMissionListSummaryPage() {
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			read: ['read', 'mission-list'],
		},
	});

	const defaultUserMissionListFilter: PaginationAndSorting = {
		pagination: initialPagination,
		sortings: [],
	};

	const defaultValues: DefaultValues<PaginationAndSorting> =
		defaultUserMissionListFilter;

	const methods = useForm<PaginationAndSorting>({
		defaultValues,
	});

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const transformedSearchParams: PaginationAndSorting = {
		...defaultUserMissionListFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'searchKey',
			'subcategoryIds',
		]),
	};

	const { data: userMissionListData, isSuccess: isGetUserMissionListSuccess } =
		useFilterUserMissionListQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
			skip: !userRoles.read,
		});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const UserMissionListFilterFormResult = value as PaginationAndSorting;

			// append to url to reload page
			setSearchParams(qs.stringify(UserMissionListFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback(
		(response?: UserMissionListResDTO[]) => {
			if (response == null) return;
			const newResponse: UserMissionListDataGrid[] = [];
			response.forEach((item, index) => {
				newResponse.push({
					id: index.toString(),
					name: item.name,
					type: item.type,
					coin: item.coin ?? '-',
					exp: item.exp ?? '-',
					level: item.level ?? '-',
				});
			});
			return newResponse;
		},
		[]
	);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: t('item.mission'),
			flex: 20,
		},
		{
			field: 'type',
			headerName: t('item.cycle'),
			flex: 20,
		},
		{
			field: 'coin',
			headerName: t('item.coinReward'),
			flex: 20,
		},
		{
			field: 'exp',
			headerName: t('item.expReward'),
			flex: 20,
		},
		{
			field: 'level',
			headerName: t('item.levelLimit'),
			flex: 20,
		},
	];

	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>{t('item.userMissionList')}</Typography>
			</Box>
			<Card sx={{ marginTop: 3 }}>
				<CardContent>
					<FormProvider {...methods}>
						<Box marginTop={2}>
							<AppDataGrid
								loading={!isGetUserMissionListSuccess}
								rows={transformResponse(userMissionListData?.data.items) ?? []}
								columns={columns}
								rowCount={userMissionListData?.data.pagination?.totalItems ?? 0}
								getRowId={(row) => row.id}
							/>
						</Box>
					</FormProvider>
				</CardContent>
			</Card>
		</>
	);
}
