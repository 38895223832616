export const userDisabledOptions = [
	{
		id: false,
		name: '正常',
	},
	{
		id: true,
		name: '已凍結',
	},
];

export enum UserApplyStatus {
	JOIN_MEMBER = 1,
	QUIT_MEMBER = 2,
	JOIN_CENTRE = 3,
	QUIT_CENTRE = 4,
}

export enum UserStatus {
	FREEZED = 0,
	NORMAL = 1,
	WAITING_FOR_REMOVE = 2,
}

export function getUserStatusLabel(status?: UserStatus): string {
	switch (status) {
		case UserStatus.FREEZED:
			return '已凍結';
		case UserStatus.NORMAL:
			return '正常';
		case UserStatus.WAITING_FOR_REMOVE:
			return '等待移除';
		default:
			return '';
	}
}

export function getUserStatusChipColor(
	status?: UserStatus
): 'chipRed' | 'chipGreen' | 'chipTertiary' | undefined {
	switch (status) {
		case UserStatus.FREEZED:
			return 'chipRed';
		case UserStatus.NORMAL:
			return 'chipGreen';
		case UserStatus.WAITING_FOR_REMOVE:
			return 'chipTertiary';
		default:
			return undefined;
	}
}
