import { matchPath } from 'react-router-dom';

export function useRouteMatch(pathname: string, patterns: readonly string[]) {
	for (let i = 0; i < patterns.length; i += 1) {
		const pattern = patterns[i];
		const possibleMatch = matchPath(pattern, pathname);
		if (possibleMatch !== null) {
			return possibleMatch;
		}
	}

	return null;
}
