import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from './authSlice';
import { useRefreshMutation } from '../../api/authApiSlice';
import FullScreenLoader from '../../common/components/FullScreenLoader';

const RequireAuth = () => {
	const location = useLocation();

	const token = useSelector(selectCurrentToken);
	const effectRan = useRef(false);

	const [refresh, { isSuccess, isLoading, isError }] = useRefreshMutation();
	useEffect(() => {
		if (effectRan.current === true || process.env.NODE_ENV !== 'development') {
			// React 18 Strict Mode
			const verifyRefreshToken = async () => {
				console.log('verifying refresh token');
				try {
					await refresh();
				} catch (err) {
					console.error(222, err);
				}
			};

			if (!token) {
				verifyRefreshToken();
			}
		}

		return () => {
			effectRan.current = true;
		};

		// eslint-disable-next-line
	}, [token]);
	if (isLoading) {
		return <FullScreenLoader />;
	} else if (isError) {
		return <Navigate to='/login' state={{ from: location }} replace />;
	} else if (isSuccess || token) {
		return <Outlet />;
	} else {
		return <></>;
	}
};

export default RequireAuth;
