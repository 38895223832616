import { yupResolver } from '@hookform/resolvers/yup';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppTextField from '../form-inputs/AppTextField';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Close from '@mui/icons-material/Close';
import { UserCategory } from '@/api/enum/userCategory.enum';
import { OrgUserUpdateUsername } from '@/api/DTO/user/user.interface';
import {
	useEditFreeUserUsernameMutation,
	useEditOrgUserUsernameMutation,
} from '@/api/user/userApiSlice';
import { closeSnackbar, useSnackbar } from 'notistack';
import { UserCatContext } from '@/context/userCategoryContext';
import CloseIcon from '@mui/icons-material/Close';

interface EditUsernameModalProps {
	userId: string;
	username: string;
}

const EditUsernameModal = (props: EditUsernameModalProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { userCat } = useContext(UserCatContext);

	const { userId, username } = props;

	const [editOrgUsername] = useEditOrgUserUsernameMutation();
	const [editFreeUsername] = useEditFreeUserUsernameMutation();

	const defaultValues: DefaultValues<OrgUserUpdateUsername> = {
		username: username,
	};

	const formSchema = Yup.object().shape({
		username: Yup.string()
			.required(t('errorMessage.required'))
			.min(4, t('errorMessage.usernameMin4'))
			.matches(/^[a-zA-Z0-9_.-]*$/, t('errorMessage.usernameOnlyAllow')),
	});

	const methods = useForm<OrgUserUpdateUsername>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		methods.setValue('userId', userId);
	}, [userId]);

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit: SubmitHandler<OrgUserUpdateUsername> = async (data) => {
		if (userCat === UserCategory.ORG) {
			const response = await editOrgUsername(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.editUsernameSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				handleClose();
			}
		} else {
			const response = await editFreeUsername(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.editUsernameSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				handleClose();
			}
		}
	};

	return (
		<>
			<IconButton color='primary' onClick={() => setOpen(true)}>
				<CreateOutlinedIcon sx={{ cursor: 'pointer' }} />
			</IconButton>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='xs'
				fullWidth
			>
				<DialogTitle variant='h5'>{t('common.editUsername')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12}>
									<AppTextField
										control={methods.control}
										name='username'
										label={t('common.username')}
										maxLength={20}
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EditUsernameModal;
