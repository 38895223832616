import { Card, CardContent, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import LocalDataGrid from '../../../common/components/dataGrid/LocalDataGrid';
import ExportReportModal from './components/ExportReportModal';

export default function ReportListSummaryPage() {
	const { t } = useTranslation();

	const reportNameList = [
		{
			id: 1,
			name: t('report.applicationDataReport'),
		},
		{
			id: 3,
			name: t('report.dailyLoginTimeReport'),
		},
		{
			id: 5,
			name: t('report.dailyLoginNumReport'),
		},
		{
			id: 7,
			name: t('report.userDailyUsageDataList'),
		},
		// {
		// 	id: 8,
		// 	name: t('report.mentalHealthInfoReport'),
		// },
		{
			id: 9,
			name: t('report.regionalInfoReport'),
		},
		// {
		// 	id: 10,
		// 	name: t('report.communityResourceReport'),
		// },
		// {
		// 	id: 11,
		// 	name: t('report.exchangeReport'),
		// },
		{
			id: 12,
			name: t('report.entityRewardReport'),
		},
		// {
		// 	id: 13,
		// 	name: t('report.virtualRewardReport'),
		// },

		// {
		// 	id: 14,
		// 	name: t('report.userReport'),
		// },
		// {
		// 	id: 15,
		// 	name: t('report.inactiveUserReport'),
		// },
	];

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: t('report.reportName'),
			flex: 50,
			sortable: false,
		},
		{
			field: 'download',
			headerName: '',
			width: 68,
			renderCell: (params) => (
				<ExportReportModal rowId={params.row.id} rowName={params.row.name} />
			),
		},
	];

	return (
		<>
			<Typography variant='h4'>{t('report.reportList')}</Typography>

			<Card sx={{ marginTop: 3 }}>
				<CardContent>
					<LocalDataGrid
						rows={reportNameList}
						columns={columns}
						rowCount={0}
						getRowId={(row) => row.id}
						pagination={false}
					/>
				</CardContent>
			</Card>
		</>
	);
}
