import { Box, FormLabel, Link, Tooltip, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

export interface AppFieldViewProps {
	label?: string;
	labelElement?: React.ReactNode;
	value?: string;
	subValue?: string;
	editButton?: React.ReactNode;
	link?: string;
}

export default function AppFieldView({
	label,
	labelElement,
	value,
	subValue,
	link,
	editButton,
}: AppFieldViewProps) {
	return (
		<>
			<AppFormLabel>
				{label}
				{labelElement && labelElement}
			</AppFormLabel>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
					{link ? (
						<Link href={value} target='_blank' color='inherit'>
							{value == undefined || value == '' ? '-' : value}
						</Link>
					) : (
						<>{value == undefined || value == '' ? '-' : value}</>
					)}
					{subValue && (
						<Typography
							variant='body2'
							color='common.neutral50'
							display='inline-block'
						>
							{subValue}
						</Typography>
					)}
				</Typography>

				{editButton && <>{editButton}</>}
			</Box>
		</>
	);
}

export function AppFormLabel({ children }: { children: ReactNode }) {
	return <FormLabel sx={{ fontSize: '12px' }}>{children}</FormLabel>;
}
