import {
	Button,
	CardActions,
	CardContent,
	Grid,
	InputAdornment,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import {
	AccountInfo,
	StaffInfoResDto,
} from '@/api/DTO/staff/staffList.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AppTextField from '@/common/components/form-inputs/AppTextField';
import AppDropDownMenu from '@/common/components/form-inputs/AppDropDownMenu';
import { useNavigate } from 'react-router-dom';
import { FormType } from '@/api/enum/formType.enum';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { useGetRolesQuery } from '@/api/common';
import {
	useAddStaffMutation,
	useEditStaffMutation,
} from '@/api/staff/staffApiSlice';
import MailOutline from '@mui/icons-material/MailOutline';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';

interface StallInfoFormProps {
	type: FormType;
	setType: Dispatch<SetStateAction<FormType>>;
	staffInfo?: StaffInfoResDto;
	id?: string;
}

export default function StaffInfoForm({
	type,
	setType,
	staffInfo,
	id,
}: StallInfoFormProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [addStaff] = useAddStaffMutation();
	const [editStaff] = useEditStaffMutation();
	const [needConfirm, setNeedConfirm] = useState(false);
	const showModal = useModal();

	const { data: rolesData, isLoading: getRolesLoading } =
		useGetRolesQuery(null);

	const rolesList = useMemo(() => {
		return rolesData?.data ?? [];
	}, [rolesData]);

	const roleId = useMemo(() => {
		if (!staffInfo || !rolesList) return undefined;
		return rolesList.find((item) => item.name === staffInfo.role)?.id;
	}, [staffInfo, rolesList]);

	useEffect(() => {
		if (roleId && !getRolesLoading) {
			methods.reset({
				name: staffInfo?.name,
				email: staffInfo?.email,
				roleId,
			});
		}
	}, [getRolesLoading, roleId, staffInfo]);

	const defaultValues: DefaultValues<AccountInfo> = useMemo(
		() => ({
			name: staffInfo?.name || '',
			email: staffInfo?.email || '',
			roleId,
		}),
		[staffInfo, roleId]
	);

	const formSchema = Yup.object().shape({
		name: Yup.string()
			.required(t('errorMessage.required'))
			.min(3, t('errorMessage.min3'))
			.max(100, t('errorMessage.max100')),
		roleId: Yup.string().required(t('errorMessage.pleaseSelect')),
		email: Yup.string()
			.required(t('errorMessage.required'))
			.email(t('errorMessage.email')),
	});
	const methods = useForm<AccountInfo>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});
	useEffect(() => {
		const subscription = methods.watch((value) => {
			if (!needConfirm) setNeedConfirm(true);
		});

		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const onSubmit: SubmitHandler<AccountInfo> = async (data) => {
		if (type === FormType.create) {
			const response = await addStaff(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.createSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				navigate(-1);
			}
		} else {
			data.id = id || '';
			const response = await editStaff(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.editSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				// navigate(-1);
				setType(FormType.view);
			}
		}
	};

	const handleClose = () => {
		if (type === FormType.edit) {
			if (!needConfirm) return setType(FormType.view);
			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('staff.giveFix')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('staff.sureGiveUpFix')}
							</Typography>
							<Typography variant='body1'>{t('staff.fixAllReset')}</Typography>
						</>
					}
					cancellationText={''}
					confirmationText={t('button.confirm')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={() => setType(FormType.view)}
				/>
			));
		} else {
			if (!needConfirm) return navigate(-1);
			showModal((props: any) => (
				<ConfirmationDialog
					{...props}
					showCloseIcon={true}
					title={t('staff.giveUpCreate')}
					titleProps={{ variant: 'h6' }}
					description={
						<>
							<Typography variant='body1'>
								{t('staff.sureGiveUpCreate')}
							</Typography>
							<Typography variant='body1'>{t('staff.allReset')}</Typography>
						</>
					}
					cancellationText={''}
					confirmationText={t('button.confirm')}
					confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
					onConfirm={() => navigate(-1)}
				/>
			));
		}
	};
	return (
		<>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<CardContent sx={{ padding: '24px' }}>
						<Typography variant='h6'>{t('common.accountInfo')}</Typography>
						{type === FormType.create && (
							<Typography
								variant='body2'
								sx={{
									display: 'flex',
									alignItems: 'center',
									marginTop: '5px',
								}}
							>
								<ErrorOutline fontSize='small' sx={{ marginRight: '5px' }} />
								{t('staff.willSendEmail')}
							</Typography>
						)}

						<Grid container spacing={3} marginTop={0} marginBottom={3}>
							<Grid item xs={6}>
								<AppTextField
									control={methods.control}
									name='name'
									label={t('common.staffName')}
									maxLength={100}
								/>
							</Grid>
							<Grid item xs={6}>
								{!getRolesLoading && (
									<AppDropDownMenu
										control={methods.control}
										name='roleId'
										label={t('common.position')}
										options={rolesList}
										required
									/>
								)}
							</Grid>
							<Grid item xs={6}>
								<AppTextField
									control={methods.control}
									name='email'
									label={t('common.email')}
									maxLength={50}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<MailOutline />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>
					</CardContent>
					<CardActions sx={{ justifyContent: 'end', padding: '24px' }}>
						<Button onClick={handleClose}>{t('button.cancel')}</Button>
						<Button
							type='submit'
							variant='contained'
							disabled={!needConfirm && type === FormType.create}
						>
							{type === FormType.edit ? t('button.saveUp') : t('button.create')}
						</Button>
					</CardActions>
				</form>
			</FormProvider>
		</>
	);
}
