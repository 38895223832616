import { Option } from '../DTO/common.interface';

export enum Gender {
	FEMALE = 0,
	MALE = 1,
	OTHER = 2,
}

export const genderOptions: Option[] = [
	{
		id: Gender.MALE,
		name: '男',
	},
	{
		id: Gender.FEMALE,
		name: '女',
	},
	{
		id: Gender.OTHER,
		name: '其他',
	},
];
