import { apiSlice } from '../apiSlice';
import { SuccessResponse, Paginated } from '../DTO/common.interface';
import {
	ResourcesCountRes,
	ResourcesInfo,
	ResourcesInfoDto,
	ResourcesInfoFilter,
	ResourcesInfoItem,
	ResourcesLogsFilter,
	ResourcesLogsRes,
	ResourcesUpdateStatus,
} from '../DTO/resources/resourcesList.interface';

export const resourcesListApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getResourcesList: builder.query<
			SuccessResponse<Paginated<ResourcesInfoItem>>,
			ResourcesInfoFilter
		>({
			query: (body) => ({
				url: `/web/community-resource/page`,
				method: 'POST',
				body,
			}),
			providesTags: ['CommunityResourcesList'],
		}),
		getDistricts: builder.query<
			SuccessResponse<{ id: string; name: string }[]>,
			null
		>({
			query: () => ({
				url: `/web/community-resource/districts`,
			}),
		}),
		getTypes: builder.query<
			SuccessResponse<
				{ type: string | number; typeDesc: string; subtypes: any }[]
			>,
			null
		>({
			query: () => ({
				url: `/web/community-resource/types`,
			}),
		}),
		addResources: builder.mutation<SuccessResponse<any>, ResourcesInfo>({
			query: (body) => ({
				url: `/web/community-resource`,
				method: 'POST',
				body,
			}),
		}),
		getLogsList: builder.query<
			SuccessResponse<Paginated<ResourcesLogsRes>>,
			ResourcesLogsFilter
		>({
			query: (body) => ({
				url: `/web/community-resource/${body.id}/logs`,
				method: 'POST',
				body,
			}),
			providesTags: ['CommunityResourcesLogsList'],
		}),
		getDetail: builder.query<
			SuccessResponse<ResourcesInfo>,
			string | undefined
		>({
			query: (id) => ({
				url: `/web/community-resource/${id}`,
			}),
			providesTags: ['CommunityResourcesInfo'],
		}),
		updateResources: builder.mutation<SuccessResponse<any>, ResourcesInfoDto>({
			query: (body) => ({
				url: `/web/community-resource`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: [
				'CommunityResourcesInfo',
				'CommunityResourcesLogsCount',
			],
		}),
		resourceStatus: builder.query<
			SuccessResponse<{ status: string | number; desc: string }[]>,
			string | number | undefined
		>({
			query: (status) => ({
				url: `/web/community-resource/status/${status}`,
			}),
		}),
		updateResourcesStatus: builder.mutation<
			SuccessResponse<any>,
			ResourcesUpdateStatus
		>({
			query: (body) => ({
				url: `/web/community-resource/batch-status`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: [
				'CommunityResourcesInfo',
				'CommunityResourcesList',
				'CommunityResourcesCount',
				'CommunityResourcesLogsCount',
				'CommunityResourcesLogsList',
			],
		}),
		deleteResources: builder.mutation<SuccessResponse<any>, string | undefined>(
			{
				query: (id) => ({
					url: `/web/community-resource/${id}`,
					method: 'DELETE',
				}),
			}
		),
		resourcesCount: builder.query<SuccessResponse<ResourcesCountRes>, null>({
			query: () => ({
				url: `/web/community-resource/count`,
			}),
			providesTags: ['CommunityResourcesCount'],
		}),
		resourcesLogsCount: builder.query<
			SuccessResponse<{ [key: string]: string }>,
			string | undefined
		>({
			query: (id) => ({
				url: `/web/community-resource/${id}/log-count`,
			}),
			providesTags: ['CommunityResourcesLogsCount'],
		}),
	}),
});

export const {
	useGetResourcesListQuery,
	useGetDistrictsQuery,
	useGetTypesQuery,
	useGetLogsListQuery,
	useGetDetailQuery,
	useAddResourcesMutation,
	useUpdateResourcesMutation,
	useUpdateResourcesStatusMutation,
	useDeleteResourcesMutation,
	useResourceStatusQuery,
	useResourcesCountQuery,
	useResourcesLogsCountQuery,
} = resourcesListApiSlice;
