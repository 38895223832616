import { ArticleOrderSummaryItemDTO } from '@/api/DTO/mentalHealthInfo/mentalHealthInfo.interface';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, TableCell, TableRow } from '@mui/material';
import { HTMLAttributes } from 'react';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utils/dateHelper';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { useMentalHealthOptions } from '@/hooks/useMentalHealthOptions';
import { showLabel } from '@/utils/helper';
import { ArticleCategory } from '@/api/enum/common.enum';

type Props = {
	item: ArticleOrderSummaryItemDTO;
	categoryId: ArticleCategory;
} & HTMLAttributes<HTMLDivElement>;

const SortableArticlesItem = ({ item, categoryId }: Props) => {
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({
		id: item.id,
	});

	const { articleTypeList } = useMentalHealthOptions(['articleTypeList']);

	const styles = {
		transform: CSS.Transform.toString(transform),
		transition: transition || undefined,
		opacity: isDragging ? '0.4' : '1',
		backgroundColor: isDragging ? '#F5F5F5' : '',
	};

	return (
		<>
			<TableRow ref={setNodeRef} style={styles}>
				<TableCell component='th' scope='row'>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
						<DehazeIcon cursor={'pointer'} {...listeners} {...attributes} />
						{`${item.title}`}
					</Box>
				</TableCell>
				{categoryId !== ArticleCategory.latestNews && (
					<TableCell component='th' scope='row'>
						{`${item?.centre}`}
					</TableCell>
				)}
				<TableCell component='th' scope='row'>
					{`${item?.district?.desc}`}
				</TableCell>
				<TableCell component='th' scope='row'>
					{`${showLabel(item.type, articleTypeList)}`}
				</TableCell>
				<TableCell component='th' scope='row'>
					{item.pushTime
						? dayjs(item.pushTime).format(DISPLAY_DATE_FORMAT)
						: '-'}
				</TableCell>
				<TableCell component='th' scope='row'>
					{item.pullTime
						? dayjs(item.pullTime).format(DISPLAY_DATE_FORMAT)
						: '-'}
				</TableCell>
			</TableRow>
		</>
	);
};

export default SortableArticlesItem;
