import { apiSlice } from '../apiSlice';
import {
	AddStaffDTO,
	StaffInfoResDto,
	StaffListSummaryItem,
	EditStaffDTO,
	StaffResDto,
	StaffFilter,
} from '../DTO/staff/staffList.interface';
import { SuccessResponse, Paginated, Option } from '../DTO/common.interface';

export const staffApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getStaffInfo: builder.query<
			SuccessResponse<StaffInfoResDto>,
			string | undefined
		>({
			query: (staffId) => ({
				url: `/web/staffs/${staffId}`,
			}),
			providesTags: ['StaffInfo'],
		}),

		getStaffList: builder.query<SuccessResponse<Option[]>, void>({
			query: () => ({
				url: `/web/staffs/list`,
			}),
		}),

		getStaffPage: builder.query<
			SuccessResponse<Paginated<StaffListSummaryItem>>,
			StaffFilter
		>({
			query: (body) => ({
				url: `/web/staffs/page`,
				method: 'POST',
				body,
			}),
		}),

		addStaff: builder.mutation<SuccessResponse<StaffResDto>, AddStaffDTO>({
			query: (body) => ({
				url: `web/staffs`,
				method: 'POST',
				body,
			}),
		}),

		deleteStaff: builder.mutation<SuccessResponse<StaffResDto>, string>({
			query: (userId) => ({
				url: `web/staffs/${userId}`,
				method: 'DELETE',
			}),
		}),

		editStaff: builder.mutation<
			SuccessResponse<StaffListSummaryItem>,
			EditStaffDTO
		>({
			query: (body) => ({
				url: `/web/staffs`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['StaffInfo'],
		}),
	}),
});

export const {
	useGetStaffPageQuery,
	useGetStaffListQuery,
	useGetStaffInfoQuery,
	useAddStaffMutation,
	useEditStaffMutation,
	useDeleteStaffMutation,
} = staffApiSlice;
