import { useUploadMediaMutation } from '@/api/common';

export const useFileUploader = () => {
	const [upload] = useUploadMediaMutation();

	const uploadFile = async (
		file: File
	): Promise<{ url: string; name: string; fileKey: string } | null> => {
		const formData = new FormData();
		formData.append('files', file);
		const response = await upload(formData);
		if ('data' in response && response.data.data.length > 0) {
			return {
				url: response.data.data[0].url,
				name: file.name,
				fileKey: response.data.data[0].fileKey,
			};
		}
		return null;
	};

	return { uploadFile };
};
