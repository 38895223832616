import { yupResolver } from '@hookform/resolvers/yup';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppTextField from '../form-inputs/AppTextField';
import Close from '@mui/icons-material/Close';
import { closeSnackbar, useSnackbar } from 'notistack';
import { OrgUserUpdateEmail } from '@/api/DTO/user/user.interface';
import {
	useEditFreeUserEmailMutation,
	useEditOrgUserEmailMutation,
} from '@/api/user/userApiSlice';
import { UserCategory } from '@/api/enum/userCategory.enum';
import { UserCatContext } from '@/context/userCategoryContext';
import CloseIcon from '@mui/icons-material/Close';

interface EditEmailModalProps {
	userId: string;
	email: string;
}

const EditEmailModal = (props: EditEmailModalProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { userCat } = useContext(UserCatContext);

	const { userId, email } = props;

	const [editOrgEmail] = useEditOrgUserEmailMutation();
	const [editFreeEmail] = useEditFreeUserEmailMutation();

	const defaultValues: DefaultValues<OrgUserUpdateEmail> = {
		email: email,
	};

	const formSchema = Yup.object().shape({
		email: Yup.string()
			.required(t('errorMessage.required'))
			.email(t('errorMessage.email')),
	});

	const methods = useForm<OrgUserUpdateEmail>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		methods.setValue('userId', userId);
	}, [userId]);

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit: SubmitHandler<OrgUserUpdateEmail> = async (data) => {
		if (userCat === UserCategory.ORG) {
			const response = await editOrgEmail(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.editEmailSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				handleClose();
			}
		} else {
			const response = await editFreeEmail(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.editEmailSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				handleClose();
			}
		}
	};

	return (
		<>
			<IconButton color='primary' onClick={() => setOpen(true)}>
				<CreateOutlinedIcon sx={{ cursor: 'pointer' }} />
			</IconButton>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='xs'
				fullWidth
			>
				<DialogTitle variant='h5'>{t('common.editEmail')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12}>
									<AppTextField
										control={methods.control}
										name='email'
										label={t('common.email')}
										maxLength={50}
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EditEmailModal;
