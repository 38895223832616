export enum SubjectType {
	Radio = 1,
	Multiple = 2,
	Linear = 3,
	Grid = 4,
}

export enum ChapterType {
	KnowledgeTest = 1,
	PerceptionAssessment = 2,
	EmpiricalReflection = 3,
}
