import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import AppDatePicker from '../../../../../common/components/form-inputs/AppDatePicker';
import { FetchUserStepReqDTO } from '@/api/DTO/health/heath.interface';
import dayjs from 'dayjs';
import { API_DATE_FORMAT } from '@/utils/dateHelper';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(minMax);

export default function UserStatSummaryFilterSection() {
	const { t } = useTranslation();
	const { control, watch } = useFormContext<FetchUserStepReqDTO>();

	const watchDatePeriodStart = watch('start');
	const watchDatePeriodEnd = watch('end');

	const minDate = dayjs.min(dayjs(watchDatePeriodEnd), dayjs());

	return (
		<Grid container rowSpacing={2} columnSpacing={1}>
			<Grid item xs={5.5}>
				<AppDatePicker
					control={control}
					name='start'
					label={t('common.startDate')}
					maximumDate={minDate?.format(API_DATE_FORMAT)}
				/>
			</Grid>
			<Grid item xs={1}>
				<Typography textAlign='center' alignContent='center' height='100%'>
					—
				</Typography>
			</Grid>
			<Grid item xs={5.5}>
				<AppDatePicker
					control={control}
					name='end'
					label={t('common.endDate')}
					minimumDate={watchDatePeriodStart}
					maximumDate={dayjs().format(API_DATE_FORMAT)}
				/>
			</Grid>
		</Grid>
	);
}
