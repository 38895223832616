import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useController,
	useFieldArray,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AppFieldView, {
	AppFormLabel,
} from '@/common/components/form-view/AppFieldView';
import AppRadioGroup from '@/common/components/form-inputs/AppRadioGroup';
import { usePracticeRecordOptions } from '@/hooks/usePracticeRecordOptions';
import AppTextField from '@/common/components/form-inputs/AppTextField';
import AppDatePicker from '@/common/components/form-inputs/AppDatePicker';
import dayjs from 'dayjs';
import {
	API_DATE_TIME_FORMAT,
	getFutureDate,
	replaceTimeTo235959,
} from '@/utils/dateHelper';
import AppRichTextEditor from '@/common/components/editor/AppRichTextEditor';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { FileInfo } from '@/api/DTO/common.interface';
import { useFileUploader } from '@/hooks/useFileUploader';
import AppDropDownMenu from '@/common/components/form-inputs/AppDropDownMenu';
import AppNumberTextField from '@/common/components/form-inputs/AppNumberTextField';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useGetLessonChapterListQuery,
	useGetLessonListQuery,
	useGetPracticeRecordTemplateDetailQuery,
	useSetPracticeMutation,
} from '@/api/practiceRecord/practiceRecordApiSlice';
import { PracticeRecordInfo } from '@/api/DTO/practiceRecord/practiceRecord.interface';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import { cloneDeep } from 'lodash';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { AnswerImageType, optionType } from '@/api/enum/exercise.enum';
import { StyledImg, VisuallyHiddenInput } from './components/Styled';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { useGetUserDetailQuery } from '@/api/user/userApiSlice';

export default function PracticeRecordForm() {
	const { t } = useTranslation();
	const [inputKey, setInputKey] = useState(0);
	const [images, setImages] = useState<FileInfo[] | null>(null);
	const { uploadFile } = useFileUploader();
	const { templateId, userId } = useParams();
	const [needConfirm, setNeedConfirm] = useState(false);
	const showModal = useModal();
	const navigate = useNavigate();
	const [setPractice] = useSetPracticeMutation();
	const [contentToBePasted, setContentToBePasted] = useState<
		string | undefined
	>(undefined);

	const { data: getUserDetailResponse } = useGetUserDetailQuery(userId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	const { userInfo } = useGetUser<useGetUserResultType>({
		shouldRefetch: false,
	});

	const { data: practiceTemplateDetail } =
		useGetPracticeRecordTemplateDetailQuery(templateId || '', {
			refetchOnMountOrArgChange: true,
			skip: templateId === undefined || Number(templateId) < 0,
		});

	const { data: lessonListData } = useGetLessonListQuery(null, {
		refetchOnMountOrArgChange: true,
	});

	const {
		exerciseCategoryList,
		questionTypeList,
		correctionMethodList,
		answerImageOptionList,
	} = usePracticeRecordOptions([
		'exerciseCategoryList',
		'questionTypeList',
		'correctionMethodList',
		'answerImageOptionList',
	]);

	const defaultValues: DefaultValues<PracticeRecordInfo> = {
		exerciseCategory: '1',
	};

	const formSchema = Yup.object().shape({
		deadline: Yup.string().required(t('errorMessage.required')),
		title: Yup.string().required(t('errorMessage.required')),
		subTitle: Yup.string().when('exerciseCategory', {
			is: '1',
			then: (schema) => schema.required(t('errorMessage.required')),
		}),
		type: Yup.string().when('exerciseCategory', {
			is: '1',
			then: (schema) => schema.required(t('errorMessage.required')),
		}),
		markingMethod: Yup.string().when('exerciseCategory', {
			is: '2',
			then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
		}),
		lessonId: Yup.string().when('exerciseCategory', {
			is: '2',
			then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
		}),
		chapterId: Yup.string().when('exerciseCategory', {
			is: '2',
			then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
		}),
		userResponseSelection: Yup.string().when('type', {
			is: (type: string) => type == optionType.AnswerImage,
			then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
		}),
		updatePicTotal: Yup.string().when('userResponseSelection', {
			is: (userResponseSelection: number) => {
				return (
					userResponseSelection == AnswerImageType.Image ||
					userResponseSelection == AnswerImageType.ImageAndAnswer ||
					userResponseSelection == AnswerImageType.ImageOrAnswer
				);
			},
			then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
		}),
		optionTotal: Yup.string().when('userResponseSelection', {
			is: (userResponseSelection: number) => {
				return (
					userResponseSelection == AnswerImageType.Answer ||
					userResponseSelection == AnswerImageType.ImageAndAnswer ||
					userResponseSelection == AnswerImageType.ImageOrAnswer
				);
			},
			then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
		}),
		answerTitle: Yup.array().of(
			Yup.string().test(
				'answerTitle',
				t('errorMessage.required'),
				(value, content) => {
					if (
						[
							AnswerImageType.Answer,
							AnswerImageType.ImageAndAnswer,
							AnswerImageType.ImageOrAnswer,
						].includes((content?.from || [])[0].value.userResponseSelection)
					) {
						if (!value) {
							return false;
						} else {
							return true;
						}
					} else {
						return true;
					}
				}
			)
		),
		singleOptions: Yup.array().when('type', {
			is: (type: string) => type == optionType.Radio,
			then: (schema) =>
				schema
					.min(1, t('errorMessage.pleaseSelect'))
					.of(
						Yup.object().shape({
							text: Yup.string().required(t('errorMessage.required')),
						})
					)
					.required(),
			otherwise: (schema) => schema.notRequired(),
		}),
		multipleOptions: Yup.array().when('type', {
			is: (type: string) => type == optionType.Multiple,
			then: (schema) =>
				schema
					.min(1, t('errorMessage.pleaseSelect'))
					.of(
						Yup.object().shape({
							text: Yup.string().required(t('errorMessage.required')),
						})
					)
					.required(),
			otherwise: (schema) => schema.notRequired(),
		}),
		linearDegreesMin: Yup.string().when('type', {
			is: optionType.Linear,
			then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
		}),
		linearDegreesMax: Yup.string().when('type', {
			is: optionType.Linear,
			then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
		}),
		minLabel: Yup.string().when('type', {
			is: optionType.Linear,
			then: (schema) => schema.required(t('errorMessage.required')),
		}),
		maxLabel: Yup.string().when('type', {
			is: optionType.Linear,
			then: (schema) => schema.required(t('errorMessage.required')),
		}),
		coin: Yup.string().required(t('errorMessage.required')),
		experience: Yup.string().required(t('errorMessage.required')),
	});
	const methods = useForm<PracticeRecordInfo>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		const subscription = methods.watch((value, { name }) => {
			if (!needConfirm) setNeedConfirm(true);
		});

		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const {
		fields: singleOptionsFields,
		append: singleOptionsAppend,
		remove: singleOptionsRemove,
	} = useFieldArray({
		control: methods.control,
		name: 'singleOptions',
	});
	const {
		fields: multipleOptionsFields,
		append: multipleOptionsAppend,
		remove: multipleOptionsRemove,
	} = useFieldArray({
		control: methods.control,
		name: 'multipleOptions',
	});
	const watchQuestionType = methods.watch('type');
	const watchOptionTotal = methods.watch('optionTotal');
	const watchExerciseCategory = methods.watch('exerciseCategory');
	const imagesField = useController({
		name: 'fileKeys',
		control: methods.control,
	});
	const watchLessonId = methods.watch('lessonId');
	const watchUserResponseSelection = methods.watch('userResponseSelection');

	const { data: chapterListData } = useGetLessonChapterListQuery(
		watchLessonId || '',
		{
			refetchOnMountOrArgChange: true,
			skip: !watchLessonId,
		}
	);
	useEffect(() => {
		methods.setValue('chapterId', '');
	}, [watchLessonId]);

	useEffect(() => {}, [watchOptionTotal]);

	useEffect(() => {
		const data = practiceTemplateDetail?.data;
		if (data) {
			methods.setValue('content', data?.content);
			setContentToBePasted(data?.content); // AppRichTextEditor的state不會隨form value改變，需要以此方式來修改AppRichTextEditor的State

			methods.setValue('title', data?.titleShow);
			methods.setValue('subTitle', data?.subTitle);
			methods.setValue(
				'deadline',
				getFutureDate(new Date(), Number(data?.deadline))
			);
			methods.setValue('type', data?.type);
			if (data?.pic) {
				methods.setValue('fileKeys', [data?.pic]);
				setImages([data?.pic]);
			}

			data?.coin && methods.setValue('coin', data?.coin);
			data?.experience && methods.setValue('experience', data?.experience);
			data?.minLabel && methods.setValue('minLabel', data?.minLabel);
			data?.maxLabel && methods.setValue('maxLabel', data?.maxLabel);
			if (data?.optionTotal) {
				methods.setValue('linearDegreesMax', data?.optionTotal);
				methods.setValue('linearDegreesMin', 0);
			}

			if (data.type == optionType.AnswerImage) {
				if (!data.picRequired && !data.answerRequired) {
					methods.setValue(
						'userResponseSelection',
						AnswerImageType.ImageOrAnswer
					);
				} else if (data.picRequired == true && data.answerRequired == true) {
					methods.setValue(
						'userResponseSelection',
						AnswerImageType.ImageAndAnswer
					);
				} else if (!data.picRequired && data.answerRequired == true) {
					methods.setValue('userResponseSelection', AnswerImageType.Answer);
				} else if (data.picRequired == true && !data.answerRequired) {
					methods.setValue('userResponseSelection', AnswerImageType.Image);
				}
				methods.setValue('updatePicTotal', data.updatePicTotal);
				methods.setValue('optionTotal', data.optionTotal);
				const arr = [
					data.option1,
					data.option2,
					data.option3,
					data.option4,
					data.option5,
				].filter((item) => {
					if (item && item != null) return item;
				});
				methods.setValue('answerTitle', arr);
			}
		}
	}, [practiceTemplateDetail]);

	const onSubmit: SubmitHandler<PracticeRecordInfo> = async (data) => {
		console.log(data);
		const newData = cloneDeep(data);
		const obj: PracticeRecordInfo = {
			userId: userId,
			exerciseCategory: newData.exerciseCategory,
			deadline: replaceTimeTo235959(newData.deadline),
			title: newData.title,
			content: newData.content,
			coin: newData.coin,
			experience: newData.experience,
		};
		if (newData.exerciseCategory == 1) {
			obj.type = newData.type;
			obj.subTitle = newData.subTitle;
			obj.fileKeys = (newData.fileKeys || []).map((item: any) => item.fileKey);

			if (newData.type == optionType.Radio) {
				newData.singleOptions.map((item: any, index: number) => {
					(obj as any)[`option${index + 1}`] = item.text;
				});
			}
			if (newData.type == optionType.Multiple) {
				newData.multipleOptions.map((item: any, index: number) => {
					(obj as any)[`option${index + 1}`] = item.text;
				});
			}

			if (newData.type == optionType.Linear) {
				obj.minLabel = newData.minLabel;
				obj.maxLabel = newData.maxLabel;
				const min = Math.min(
					newData.linearDegreesMin || 0,
					newData.linearDegreesMax || 0
				);
				const max = Math.max(
					newData.linearDegreesMin || 0,
					newData.linearDegreesMax || 0
				);
				const allNumbers = [];
				for (let i = min; i <= max; i++) {
					allNumbers.push(i);
				}
				if (allNumbers.length == 1) allNumbers.push(allNumbers[0]);
				allNumbers.map((item: any, index: number) => {
					(obj as any)[`option${index + 1}`] = item;
				});
			}

			if (newData.type == optionType.AnswerImage) {
				if (newData.userResponseSelection == AnswerImageType.Image) {
					obj.picRequired = true;
					obj.updatePicTotal = newData.updatePicTotal;
				}
				if (newData.userResponseSelection == AnswerImageType.Answer) {
					obj.answerRequired = true;
					obj.optionTotal = newData.optionTotal;
					newData.answerTitle.map((item: any, index: number) => {
						if (index + 1 <= Number(obj.optionTotal)) {
							(obj as any)[`answerTitle${index + 1}`] = item;
						}
					});
				}
				if (newData.userResponseSelection == AnswerImageType.ImageAndAnswer) {
					obj.answerRequired = true;
					obj.picRequired = true;
					obj.updatePicTotal = newData.updatePicTotal;
					obj.optionTotal = newData.optionTotal;
					newData.answerTitle.map((item: any, index: number) => {
						if (index + 1 <= Number(obj.optionTotal)) {
							(obj as any)[`answerTitle${index + 1}`] = item;
						}
					});
				}
				if (newData.userResponseSelection == AnswerImageType.ImageOrAnswer) {
					obj.updatePicTotal = newData.updatePicTotal;
					obj.optionTotal = newData.optionTotal;
					newData.answerTitle.map((item: any, index: number) => {
						if (index + 1 <= Number(obj.optionTotal)) {
							(obj as any)[`answerTitle${index + 1}`] = item;
						}
					});
				}
			}
		}

		if (newData.exerciseCategory == 2) {
			obj.markingMethod = newData.markingMethod;
			obj.lessonId = newData.lessonId;
			obj.chapterId = newData.chapterId;
		}

		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('practiceRecord.distributionExercise')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('practiceRecord.isDistributionExercise')}
						</Typography>
					</>
				}
				cancellationText={t('button.cancel')}
				confirmationText={t('button.sure')}
				confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
				onConfirm={async () => {
					const response = await setPractice(obj);
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.distributionSuccess'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						navigate(-1);
					}
				}}
			/>
		));
	};

	const handleClose = async () => {
		if (!needConfirm) return navigate(-1);

		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('communityResources.giveUpFix')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('communityResources.sureGiveUpFix')}
						</Typography>
						<Typography variant='body1'>
							{t('communityResources.fixAllReset')}
						</Typography>
					</>
				}
				cancellationText={t('button.giveUpFix')}
				confirmationText={t('button.saveAndLeave')}
				confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
				onConfirm={() => methods.handleSubmit(onSubmit)()}
				onCancel={() => navigate(-1)}
			/>
		));
	};

	const handleImagesChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setInputKey(inputKey + 1);
		const selectedFile = event.target.files?.[0];
		if (!selectedFile) return;
		const res = await uploadFile(selectedFile);
		if (res) {
			const formImages = methods.getValues('fileKeys') || [];
			formImages.push(res);
			methods.setValue('fileKeys', formImages);
			methods.trigger('fileKeys');
			const obj: { url: string; name: string } = res;
			setImages((prevImages: any) => [...(prevImages ?? []), obj]);
		}
	};
	const handleClearImages = (index: number) => {
		const formImages = methods.getValues('fileKeys') || [];
		formImages.splice(index, 1);
		methods.setValue('fileKeys', formImages);
		methods.trigger('fileKeys');
		setImages((prevImages: any) => {
			const updatedImages = [...(prevImages || [])];
			updatedImages.splice(index, 1);
			return updatedImages;
		});
	};

	const handleChangeType = (value: string) => {
		if (value == optionType.Radio && singleOptionsFields.length < 1) {
			singleOptionsAppend({
				id: new Date().getTime(),
				text: '',
			});
		}
		if (value == optionType.Multiple && multipleOptionsFields.length < 1) {
			multipleOptionsAppend({
				id: new Date().getTime(),
				text: '',
			});
		}
	};

	return (
		<>
			<FormProvider {...methods}>
				<form
					style={{ marginTop: '24px' }}
					onSubmit={methods.handleSubmit(onSubmit)}
				>
					<Card>
						<CardContent>
							<Stack>
								<Typography variant='h6'>{t('common.setup')}</Typography>
								<Grid container spacing={3} marginTop={0} marginBottom={3}>
									<Grid item xs={6}>
										<AppFieldView label={t('common.createDate')} value={'-'} />
									</Grid>
									<Grid item xs={6}>
										<AppFieldView
											label={t('practiceRecord.assignedUsers')}
											value={
												`${getUserDetailResponse?.data.username}` +
												(getUserDetailResponse?.data.nickname
													? `(${getUserDetailResponse?.data.nickname})`
													: '')
											}
										/>
									</Grid>
								</Grid>

								<Divider sx={{ marginBottom: '20px' }} />

								<Typography variant='h6'>
									{t('practiceRecord.exerciseContent')}
								</Typography>
								<Grid container spacing={2} marginTop={0}>
									<Grid item xs={6}>
										<Typography variant='body2' color='common.neutral40'>
											{t('practiceRecord.exerciseCategory')}
										</Typography>
										<AppRadioGroup
											control={methods.control}
											name='exerciseCategory'
											row
											options={exerciseCategoryList}
										/>
									</Grid>
									{watchExerciseCategory == 1 && (
										<Grid item xs={6}>
											<AppDatePicker
												minimumDate={dayjs()
													.add(0, 'day')
													.format(API_DATE_TIME_FORMAT)}
												control={methods.control}
												name='deadline'
												label={t('practiceRecord.expirationDate')}
												formatDate={API_DATE_TIME_FORMAT}
											/>
										</Grid>
									)}
									{watchExerciseCategory == 2 && (
										<>
											<Grid item xs={6}>
												<Typography variant='body2' color='common.neutral40'>
													{t('practiceRecord.correctionMethod')}
													<Tooltip
														title={
															<>
																{t('practiceRecord.autoTips')}
																<br />
																{t('practiceRecord.manualTips')}
															</>
														}
														arrow
														placement='top'
													>
														<InfoOutlinedIcon
															sx={{
																fontSize: '16px',
																position: 'relative',
																top: '3px',
																left: '3px',
															}}
														/>
													</Tooltip>
												</Typography>

												<AppRadioGroup
													control={methods.control}
													name='markingMethod'
													row
													options={correctionMethodList}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppDatePicker
													minimumDate={dayjs()
														.add(0, 'day')
														.format(API_DATE_TIME_FORMAT)}
													control={methods.control}
													name='deadline'
													label={t('practiceRecord.expirationDate')}
													formatDate={API_DATE_TIME_FORMAT}
												/>
											</Grid>
										</>
									)}

									<Grid item xs={12}>
										<AppTextField
											control={methods.control}
											name='title'
											label={t('practiceRecord.exerciseTitle')}
											maxLength={20}
											showMaxLength
										/>
									</Grid>
									{watchExerciseCategory == 1 && (
										<>
											<Grid item xs={12}>
												<AppTextField
													control={methods.control}
													name='subTitle'
													label={t('practiceRecord.questionTitle')}
													maxLength={50}
													showMaxLength
												/>
											</Grid>
											<Grid item xs={12}>
												<Typography variant='body2' color='common.neutral40'>
													{t('practiceRecord.promptText') +
														t('common.optional')}
												</Typography>
												<AppRichTextEditor
													control={methods.control}
													name='content'
													editorStateToBePasted={contentToBePasted}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControl
													error={!!imagesField.fieldState.error?.message}
													fullWidth
												>
													<AppFormLabel>
														{t('practiceRecord.additionalImage') +
															t('common.optional')}
													</AppFormLabel>
													<Grid
														container
														direction='row'
														alignItems='center'
														spacing={2}
													>
														{(images || []).map((item, index) => (
															<Grid
																item
																xs={2}
																key={item.url}
																sx={{ minWidth: '200px' }}
															>
																<StyledImg
																	src={item.url}
																	sx={{
																		width: '200px',
																		height: '137px',
																	}}
																></StyledImg>
																<Grid
																	container
																	direction='row'
																	justifyContent='space-between'
																	alignItems='center'
																	style={{ width: '200px' }}
																>
																	<Typography
																		variant='caption'
																		noWrap
																		sx={{ width: '155px' }}
																	>
																		{item.name}
																	</Typography>
																	<IconButton
																		key={inputKey}
																		color='error'
																		size='small'
																		onClick={() => {
																			handleClearImages(index);
																		}}
																	>
																		<DeleteIcon fontSize='inherit' />
																	</IconButton>
																</Grid>
															</Grid>
														))}

														{(!images || images?.length < 10) && (
															<Grid item>
																<Button
																	component='label'
																	variant='text'
																	startIcon={<AddIcon />}
																	style={{ alignItems: 'center' }}
																>
																	{t('button.uploadImage')}
																	<VisuallyHiddenInput
																		key={inputKey}
																		type='file'
																		accept='image/*'
																		name='fileKeys'
																		onChange={handleImagesChange}
																	/>
																</Button>
															</Grid>
														)}
													</Grid>

													<FormHelperText>
														{imagesField.fieldState.error?.message}
													</FormHelperText>
												</FormControl>
											</Grid>
											<Grid item xs={12}>
												<AppDropDownMenu
													handleChange={(value: string) =>
														handleChangeType(value)
													}
													control={methods.control}
													name='type'
													label={t('practiceRecord.questionType')}
													options={questionTypeList}
												/>
											</Grid>
											{watchQuestionType == optionType.AnswerImage && (
												<>
													<Grid item xs={12}>
														<AppDropDownMenu
															control={methods.control}
															name='userResponseSelection'
															label={t('practiceRecord.userResponseSelection')}
															options={answerImageOptionList}
														/>
													</Grid>
													{watchUserResponseSelection ==
														AnswerImageType.Image && (
														<Grid item xs={6}>
															<AppDropDownMenu
																control={methods.control}
																name='updatePicTotal'
																label={t('practiceRecord.imageTotal')}
																options={(() => {
																	return Array.from(
																		{ length: 6 },
																		(_, index) => ({
																			id: index + 1,
																			name: String(index + 1),
																		})
																	);
																})()}
															/>
														</Grid>
													)}

													{watchUserResponseSelection ==
														AnswerImageType.Answer && (
														<>
															<Grid item xs={6}>
																<AppDropDownMenu
																	control={methods.control}
																	name='optionTotal'
																	label={t('practiceRecord.answerNum')}
																	options={(() => {
																		return Array.from(
																			{ length: 5 },
																			(_, index) => ({
																				id: index + 1,
																				name: String(index + 1),
																			})
																		);
																	})()}
																	onChange={() => {
																		if (
																			watchUserResponseSelection ==
																				AnswerImageType.Answer ||
																			watchUserResponseSelection ==
																				AnswerImageType.ImageAndAnswer ||
																			watchUserResponseSelection ==
																				AnswerImageType.ImageOrAnswer
																		) {
																			methods.setValue(
																				'answerTitle',
																				Array.from(
																					{
																						length:
																							Number(watchOptionTotal) || 0,
																					},
																					() => ''
																				)
																			);
																		}
																	}}
																/>
															</Grid>
															<Grid item xs={12}>
																<Typography
																	variant='body1'
																	marginBottom={'5px'}
																>
																	{t('practiceRecord.answerTitle')}
																</Typography>
																{Array.from(
																	{ length: Number(watchOptionTotal) },
																	(_, index) => (
																		<AppTextField
																			key={new Date().getTime() + index}
																			control={methods.control}
																			name={`answerTitle[${index}]`}
																			label={`${t('practiceRecord.ranking')} ${
																				index + 1
																			} ${t('practiceRecord.subject')}`}
																			maxLength={10}
																			showMaxLength
																		/>
																	)
																)}
															</Grid>
														</>
													)}
													{watchUserResponseSelection ==
														AnswerImageType.ImageAndAnswer && (
														<>
															<Grid item xs={12}>
																<Typography variant='body2'>
																	<InfoOutlinedIcon
																		sx={{
																			fontSize: '16px',
																			position: 'relative',
																			top: '3px',
																			marginRight: '5px',
																			// left: '3px',
																		}}
																	/>
																	{t('practiceRecord.isFinishAll')}
																</Typography>
															</Grid>
															<Grid item xs={6}>
																<AppDropDownMenu
																	control={methods.control}
																	name='updatePicTotal'
																	label={t('practiceRecord.imageTotal')}
																	options={(() => {
																		return Array.from(
																			{ length: 6 },
																			(_, index) => ({
																				id: index + 1,
																				name: String(index + 1),
																			})
																		);
																	})()}
																/>
															</Grid>
															<Grid item xs={6}>
																<AppDropDownMenu
																	control={methods.control}
																	name='optionTotal'
																	label={t('practiceRecord.answerNum')}
																	options={(() => {
																		return Array.from(
																			{ length: 5 },
																			(_, index) => ({
																				id: index + 1,
																				name: String(index + 1),
																			})
																		);
																	})()}
																/>
															</Grid>
															<Grid item xs={12}>
																<Typography
																	variant='body1'
																	marginBottom={'5px'}
																>
																	{t('practiceRecord.answerTitle')}
																</Typography>
																{Array.from(
																	{ length: Number(watchOptionTotal) },
																	(_, index) => (
																		<AppTextField
																			key={new Date().getTime() + index}
																			control={methods.control}
																			name={`answerTitle[${index}]`}
																			label={`${t('practiceRecord.ranking')} ${
																				index + 1
																			} ${t('practiceRecord.subject')}`}
																			maxLength={10}
																			showMaxLength
																		/>
																	)
																)}
															</Grid>
														</>
													)}
													{watchUserResponseSelection ==
														AnswerImageType.ImageOrAnswer && (
														<>
															<Grid item xs={12}>
																<Typography variant='body2'>
																	<InfoOutlinedIcon
																		sx={{
																			fontSize: '16px',
																			position: 'relative',
																			top: '3px',
																			marginRight: '5px',
																		}}
																	/>
																	{t('practiceRecord.isFinishChoice')}
																</Typography>
															</Grid>
															<Grid item xs={6}>
																<AppDropDownMenu
																	control={methods.control}
																	name='updatePicTotal'
																	label={t('practiceRecord.imageTotal')}
																	options={(() => {
																		return Array.from(
																			{ length: 6 },
																			(_, index) => ({
																				id: index + 1,
																				name: String(index + 1),
																			})
																		);
																	})()}
																/>
															</Grid>
															<Grid item xs={6}>
																<AppDropDownMenu
																	control={methods.control}
																	name='optionTotal'
																	label={t('practiceRecord.answerNum')}
																	options={(() => {
																		return Array.from(
																			{ length: 5 },
																			(_, index) => ({
																				id: index + 1,
																				name: String(index + 1),
																			})
																		);
																	})()}
																/>
															</Grid>
															<Grid item xs={12}>
																<Typography
																	variant='body1'
																	marginBottom={'5px'}
																>
																	{t('practiceRecord.answerTitle')}
																</Typography>
																{Array.from(
																	{ length: Number(watchOptionTotal) },
																	(_, index) => (
																		<AppTextField
																			key={new Date().getTime() + index}
																			control={methods.control}
																			name={`answerTitle[${index}]`}
																			label={`${t('practiceRecord.ranking')} ${
																				index + 1
																			} ${t('practiceRecord.subject')}`}
																			maxLength={10}
																			showMaxLength
																		/>
																	)
																)}
															</Grid>
														</>
													)}
												</>
											)}
											{watchQuestionType == optionType.Radio && (
												<>
													<Grid item xs={12}>
														<Typography
															variant='body2'
															color='common.neutral40'
															marginBottom={'5px'}
														>
															{t('practiceRecord.option')}
														</Typography>
														{singleOptionsFields.map((item, index) => (
															<Stack
																direction={'row'}
																alignItems={'center'}
																key={item.id}
															>
																<AppTextField
																	key={index}
																	control={methods.control}
																	name={`singleOptions[${index}].text`}
																	label={`${t('practiceRecord.option')} ${
																		index + 1
																	} `}
																	maxLength={100}
																	showMaxLength
																/>
																{singleOptionsFields.length != 1 && (
																	<IconButton
																		aria-label='delete'
																		sx={{
																			color: '#D32F2F',
																			marginLeft: '15px',
																		}}
																		onClick={() => singleOptionsRemove(index)}
																	>
																		<DeleteIcon />
																	</IconButton>
																)}
															</Stack>
														))}
														{singleOptionsFields.length < 10 && (
															<Button
																component='label'
																variant='text'
																startIcon={<AddIcon />}
																style={{ alignItems: 'center' }}
																onClick={() =>
																	singleOptionsAppend({
																		id: new Date().getTime(),
																		text: '',
																	})
																}
															>
																{t('practiceRecord.addOption')}
															</Button>
														)}
													</Grid>
												</>
											)}
											{watchQuestionType == optionType.Multiple && (
												<>
													<Grid item xs={12}>
														<Typography
															variant='body2'
															color='common.neutral40'
															marginBottom={'5px'}
														>
															{t('practiceRecord.option')}
														</Typography>
														{multipleOptionsFields.map((item, index) => (
															<Stack
																direction={'row'}
																alignItems={'center'}
																key={item.id}
															>
																<AppTextField
																	key={index}
																	control={methods.control}
																	name={`multipleOptions[${index}].text`}
																	label={`${t('practiceRecord.option')} ${
																		index + 1
																	} `}
																	maxLength={100}
																	showMaxLength
																/>
																{multipleOptionsFields.length != 1 && (
																	<IconButton
																		aria-label='delete'
																		sx={{
																			color: '#D32F2F',
																			marginLeft: '15px',
																		}}
																		onClick={() => multipleOptionsRemove(index)}
																	>
																		<DeleteIcon />
																	</IconButton>
																)}
															</Stack>
														))}
														{multipleOptionsFields.length < 10 && (
															<Button
																component='label'
																variant='text'
																startIcon={<AddIcon />}
																style={{ alignItems: 'center' }}
																onClick={() =>
																	multipleOptionsAppend({
																		id: new Date().getTime(),
																		text: '',
																	})
																}
															>
																{t('practiceRecord.addOption')}
															</Button>
														)}
													</Grid>
												</>
											)}
											{watchQuestionType == optionType.Linear && (
												<>
													<Grid item xs={12}>
														<Typography
															variant='body2'
															color='common.neutral40'
															marginBottom={'5px'}
														>
															{t('practiceRecord.linearDegrees')}
														</Typography>
														<Grid container marginTop={0} alignItems={'center'}>
															<Grid item xs={2.5}>
																<AppDropDownMenu
																	control={methods.control}
																	name='linearDegreesMin'
																	options={Array.from(
																		{ length: 11 },
																		(_, i) => ({
																			id: i,
																			name: String(i),
																		})
																	)}
																/>
															</Grid>
															<Grid item xs={1} textAlign={'center'}>
																到
															</Grid>
															<Grid item xs={2.5}>
																<AppDropDownMenu
																	control={methods.control}
																	name='linearDegreesMax'
																	options={Array.from(
																		{ length: 11 },
																		(_, i) => ({
																			id: i,
																			name: String(i),
																		})
																	)}
																/>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6}>
														<AppTextField
															control={methods.control}
															name='minLabel'
															label={t('practiceRecord.tagMin')}
															maxLength={10}
															showMaxLength
														/>
													</Grid>
													<Grid item xs={6}>
														<AppTextField
															control={methods.control}
															name='maxLabel'
															label={t('practiceRecord.tagMax')}
															maxLength={10}
															showMaxLength
														/>
													</Grid>
												</>
											)}
										</>
									)}
									{watchExerciseCategory == 2 && (
										<>
											<Grid item xs={12}>
												<AppDropDownMenu
													control={methods.control}
													name='lessonId'
													label={t('practiceRecord.allocationCourse')}
													options={lessonListData?.data || []}
												/>
											</Grid>
											<Grid item xs={12}>
												<AppDropDownMenu
													control={methods.control}
													name='chapterId'
													label={t('practiceRecord.selectChapter')}
													options={(chapterListData?.data || []).map(
														(chapter) => {
															return {
																id: chapter.id,
																name: chapter.title,
															};
														}
													)}
												/>
											</Grid>
										</>
									)}
								</Grid>

								<Divider sx={{ marginBottom: '20px', marginTop: '20px' }} />

								<Typography variant='h6'>
									{t('practiceRecord.completionReward')}
								</Typography>
								<Grid container spacing={2} marginTop={0}>
									<Grid item xs={6}>
										<AppNumberTextField
											control={methods.control}
											nonNegative={true}
											name='coin'
											fieldLabel={t('practiceRecord.goldNum')}
											type='number'
										/>
									</Grid>
									<Grid item xs={6}>
										<AppNumberTextField
											control={methods.control}
											nonNegative={true}
											name='experience'
											fieldLabel={t('practiceRecord.addEmpirical')}
											type='number'
										/>
									</Grid>
								</Grid>
							</Stack>
						</CardContent>
						<CardActions sx={{ justifyContent: 'end' }}>
							<Button onClick={handleClose}>{t('button.cancel')}</Button>
							<Button type='submit' variant='contained'>
								{t('button.distribution')}
							</Button>
						</CardActions>
					</Card>
				</form>
			</FormProvider>
		</>
	);
}
