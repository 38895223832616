import {
	Badge,
	BadgeProps,
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Divider,
	Grid,
	Tab,
	Tabs,
	Typography,
	styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { GridColDef } from '@mui/x-data-grid';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import {
	useArticleStatusQuery,
	useDeleteArticlesMutation,
	useGetArticlesInfoQuery,
	useGetArticlesLogsCountQuery,
	useGetArticlesLogsListQuery,
} from '@/api/mentalHealthInfo/mentalHealthApiSlice';
import CloseIcon from '@mui/icons-material/Close';
import AppFieldView, {
	AppFormLabel,
} from '@/common/components/form-view/AppFieldView';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import dayjs from 'dayjs';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '@/utils/dateHelper';
import { useMentalHealthOptions } from '@/hooks/useMentalHealthOptions';
import { showLabel } from '@/utils/helper';
import AppImageView from '@/common/components/form-view/AppImageView';
import SettingStatusModal from './SettingStatusModal';
import { useGetArticlesDistrictsQuery } from '@/api/common';
import { FormType } from '@/api/enum/formType.enum';
import SettingMentalHealthContentInfo from './SettingMentalHealthContentInfo';
import { ArticleCategory } from '@/api/enum/common.enum';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import {
	LogsFilter,
	LogsRes,
	MentalHealthInfoRes,
} from '@/api/DTO/mentalHealthInfo/mentalHealthInfo.interface';
import AppElementView from '@/common/components/form-view/AppElementView';
import RichTextDisplay from '@/common/components/form-view/RichTextDisplay';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import { cloneDeep } from 'lodash';
import { SuccessResponse } from '@/api/DTO/common.interface';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		position: 'relative',
		top: 12,
		backgroundColor: '#FFDAD6',
		color: '#FF5449',
	},
	'&.badge-active .MuiBadge-badge': {
		backgroundColor: '#FF5449',
		color: '#fff',
	},
}));

const StyledImg = styled('img')({
	objectFit: 'contain',
});

const statusChipList: Record<number | string, { color: string }> = {
	1: { color: 'chipGrey' },
	2: { color: 'chipGrey' },
	3: { color: 'chipPrimary' },
	4: { color: 'chipTertiary' },
	5: { color: 'chipGreen' },
	6: { color: 'chipGrey' },
};

export default function MentalHealthInfoPage() {
	const { t } = useTranslation();
	const [type, setType] = useState<FormType>(FormType.view);
	const { id } = useParams();
	const showModal = useModal();
	const [deleteArticles] = useDeleteArticlesMutation();
	const navigate = useNavigate();
	const [currentTab, setCurrentTab] = useState('allInfo');
	const { categoryList, articleTypeList, subCategoryList } =
		useMentalHealthOptions([
			'categoryList',
			'articleTypeList',
			'subCategoryList',
		]);

	const defaultPropertyFilter: LogsFilter = {
		pagination: initialPagination,
		sortings: [],
		id,
	};
	const [transformedSearchParams, setTransformedSearchParams] =
		useState<LogsFilter>(defaultPropertyFilter);

	const tabList = [
		{
			name: 'allInfo',
			tabText: t('mentalHealthInfo.allInfo'),
			countKey: '',
		},
		{
			name: 'updateRecord',
			tabText: t('mentalHealthInfo.updateRecord'),
			countKey: 'logCount',
		},
	];

	const { data: logsCount } = useGetArticlesLogsCountQuery(id, {
		refetchOnMountOrArgChange: true,
	});

	const { data: articlesInfoData } = useGetArticlesInfoQuery(id || '', {
		refetchOnMountOrArgChange: true,
	});
	const articlesInfoObj = useMemo(() => {
		if (!articlesInfoData) {
			return null;
		}

		const newData: MentalHealthInfoRes = cloneDeep(
			articlesInfoData.data
		) as MentalHealthInfoRes;
		if (
			typeof newData.district === 'object' &&
			newData.district !== null &&
			'type' in newData.district
		) {
			newData.districtObj = newData.district;
			newData.district = newData.district.type; // 现在这是安全的
		}

		return newData;
	}, [articlesInfoData]);

	const { userRoles } = useGetUser<useGetUserResultType>({
		roleObj: {
			delete: ['delete', 'mentalHealth', String(articlesInfoObj?.status.type)],
			edit: ['edit', 'mentalHealth', String(articlesInfoObj?.status.type)],
		},
	});

	// 当前角色可操作的状态
	const { data: articleStatus } = useArticleStatusQuery(
		{ type: articlesInfoObj?.status.type },
		{
			refetchOnMountOrArgChange: true,
			skip: !articlesInfoObj?.status.type,
		}
	);
	const articleStatusList = useMemo(() => {
		return (articleStatus?.data || []).map((item) => {
			return {
				id: item.type,
				name: item.desc,
			};
		});
	}, [articleStatus]);

	// 獲取地區list
	const { data: districtsData } = useGetArticlesDistrictsQuery(null);
	const districtsOptions = useMemo(() => {
		const arr = (districtsData?.data || []).map(
			(item: { type: any; desc: string }) => {
				return {
					id: item.type,
					name: item.desc,
				};
			}
		);
		return arr || [];
	}, [districtsData]);

	const handleDelete = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('mentalHealthInfo.deleteInfo')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('mentalHealthInfo.isDeleteInfo')}
						</Typography>
					</>
				}
				cancellationText={''}
				confirmationText={t('button.delete')}
				confirmationButtonProps={{ color: 'error', variant: 'outlined' }}
				onConfirm={async () => {
					const response = await deleteArticles(id || '');
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.deleteArticlesSuccess'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						navigate(-1);
					}
				}}
			/>
		));
	};
	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setCurrentTab(newValue);
	};

	const { data: propertySummary, isSuccess: isGetPropertySummarySuccess } =
		useGetArticlesLogsListQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
		});
	const transformResponse = useCallback((response?: LogsRes[]) => {
		if (response == null) return;
		const newResponse: LogsRes[] = [];
		response.forEach((item, index) => {
			newResponse.push({
				...item,
				index: index,
				updatedAt: !item.updatedAt
					? '-'
					: dayjs(item.updatedAt)
							.locale('zh-hk-my-setting')
							.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
			});
		});
		return newResponse;
	}, []);
	const defaultValues: DefaultValues<LogsFilter> = defaultPropertyFilter;
	const methods = useForm<LogsFilter>({
		defaultValues,
	});
	useEffect(() => {
		const subscription = methods.watch((value) => {
			setTransformedSearchParams(value as LogsFilter);
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const columns: GridColDef[] = [
		{
			field: 'updatedAt',
			headerName: t('common.updatedDate'),
			flex: 1,
		},
		{
			field: 'content',
			headerName: t('common.content'),
			flex: 1,
		},
		{
			field: 'mark',
			headerName: t('common.remark'),
			flex: 1,
			renderCell: (params) => params.row.mark || '-',
		},
	];

	return (
		<Box>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>{t('common.infoDetails')}</Typography>
				{userRoles.delete && (
					<Button
						startIcon={<DeleteOutlined />}
						onClick={handleDelete}
						color='error'
						variant='outlined'
					>
						{t('button.delete')}
					</Button>
				)}
			</Box>
			<Tabs
				value={currentTab}
				sx={{ marginTop: '24px' }}
				onChange={handleTabChange}
			>
				{tabList.map((item) => (
					<Tab
						value={item.name}
						key={item.name}
						label={
							<>
								{Number(logsCount?.data[item.countKey]) > 0 ? (
									<StyledBadge
										badgeContent={logsCount?.data[item.countKey]}
										className={
											currentTab.indexOf(item.name) > -1 ? 'badge-active' : ''
										}
									>
										<Typography variant='body1'>
											&nbsp;{item.tabText}
										</Typography>
									</StyledBadge>
								) : (
									<Typography variant='body1'>{item.tabText}</Typography>
								)}
							</>
						}
					/>
				))}
			</Tabs>
			{currentTab == 'allInfo' && (
				<Card sx={{ marginTop: '10px' }}>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant='h5'>
									{t('mentalHealthInfo.setting')}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<AppFieldView
									label={t('common.creationTime')}
									value={
										!articlesInfoObj?.createAt
											? '-'
											: dayjs(articlesInfoObj?.createAt)
													.locale('zh-hk-my-setting')
													.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<AppFormLabel>{t('common.status')}</AppFormLabel>
								<Box display='flex' justifyContent='space-between'>
									<Chip
										variant='outlined'
										label={articlesInfoObj?.status.desc}
										color={
											(statusChipList[articlesInfoObj?.status.type || '1']
												?.color as 'default') || 'default'
										}
									/>
									<SettingStatusModal
										setStatusButton={true}
										selectedId={[id || '']}
										articleStatusList={articleStatusList}
										currentStatus={articlesInfoObj?.status.type}
									/>
								</Box>
							</Grid>
						</Grid>

						<Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />

						{(() => {
							switch (type) {
								case FormType.view:
									return (
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Box
													sx={{
														display: 'flex',
														justifyContent: 'space-between',
													}}
												>
													<Typography variant='h5'>
														{t('mentalHealthInfo.contents')}
													</Typography>
													{userRoles.edit && (
														<Button
															startIcon={<CreateOutlinedIcon />}
															onClick={() => setType(FormType.edit)}
														>
															{t('button.edit')}
														</Button>
													)}
												</Box>
											</Grid>
											<Grid
												item
												xs={
													articlesInfoObj?.category ==
													ArticleCategory.latestNews
														? 6
														: articlesInfoObj?.category ==
														  ArticleCategory.knowMentalHealth
														? 6
														: 12
												}
											>
												<AppFieldView
													label={t('common.infoCategory')}
													value={showLabel(
														articlesInfoObj?.category,
														categoryList
													)}
												/>
											</Grid>
											{articlesInfoObj?.category ==
												ArticleCategory.latestNews && (
												<Grid item xs={6}>
													<AppFieldView
														label={t('common.district')}
														value={articlesInfoObj?.districtObj.desc}
													/>
												</Grid>
											)}

											{articlesInfoObj?.category ==
												ArticleCategory.knowMentalHealth && (
												<Grid item xs={6}>
													<AppFieldView
														label={t('common.subclassify')}
														value={showLabel(
															articlesInfoObj?.subcategory,
															subCategoryList
														)}
													/>
												</Grid>
											)}
											<Grid item xs={6}>
												<AppFieldView
													label={t('common.estimatedReleaseDate')}
													value={
														!articlesInfoObj?.pushTime
															? '-'
															: dayjs(articlesInfoObj?.pushTime)
																	.locale('zh-hk-my-setting')
																	.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)
													}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppFieldView
													label={t('common.estimatedRemoveDate')}
													value={
														!articlesInfoObj?.pullTime
															? '-'
															: dayjs(articlesInfoObj?.pullTime)
																	.locale('zh-hk-my-setting')
																	.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<AppElementView
													label={t('common.customTags')}
													value={
														<>
															{articlesInfoObj?.tags &&
															articlesInfoObj?.tags.length > 0
																? articlesInfoObj?.tags.map((item) => (
																		<Chip
																			key={item.id}
																			label={item.name}
																			size='medium'
																			color='chipFilledTertiary'
																			sx={{ marginRight: '5px' }}
																		/>
																  ))
																: '-'}
														</>
													}
												/>
											</Grid>

											<Grid item xs={6}>
												<AppFieldView
													label={t('common.title')}
													value={articlesInfoObj?.title}
												/>
											</Grid>
											<Grid item xs={6}>
												{articlesInfoObj?.category ==
													ArticleCategory.latestNews && (
													<AppFieldView
														label={t('mentalHealthInfo.registrationLink')}
														value={articlesInfoObj?.registrationLink}
													/>
												)}
											</Grid>

											<Grid item xs={6}>
												<AppImageView
													label={t('common.thumbnail')}
													imageElement={
														articlesInfoObj?.thumbnail && (
															<StyledImg
																src={articlesInfoObj?.thumbnail.url}
																sx={{
																	width: '320px',
																}}
															/>
														)
													}
												/>
											</Grid>
											<Grid item xs={6}>
												<AppFieldView
													label={t('common.format')}
													value={showLabel(
														articlesInfoObj?.type,
														articleTypeList
													)}
												/>
											</Grid>
											{(() => {
												switch (String(articlesInfoObj?.type)) {
													case '0':
														return <></>;
													case '1':
														return (
															<Grid
																item
																xs={12}
																container
																direction='row'
																alignItems='center'
																spacing={2}
															>
																<Grid item xs={12}>
																	<AppFormLabel>
																		{t('mentalHealthInfo.contentImagesMax10')}
																	</AppFormLabel>
																	<Grid container direction='row' spacing={2}>
																		{(articlesInfoObj?.images || []).map(
																			(item, index) => (
																				<Grid
																					item
																					xs={2}
																					sx={{ minWidth: '200px' }}
																					key={item.fileKey}
																				>
																					<StyledImg
																						src={item.url}
																						sx={{
																							width: '200px',
																							height: '137px',
																						}}
																					></StyledImg>
																					<Grid
																						container
																						direction='row'
																						justifyContent='space-between'
																						alignItems='center'
																						style={{ width: '200px' }}
																					>
																						<Typography
																							variant='caption'
																							noWrap
																						>
																							{t('common.picture')}
																							{index + 1}
																						</Typography>
																					</Grid>
																				</Grid>
																			)
																		)}
																	</Grid>
																</Grid>
															</Grid>
														);
													case '2':
														return (
															<Grid item xs={12}>
																<AppFieldView
																	label={t('mentalHealthInfo.pdfFile')}
																	value={articlesInfoObj?.pdf.url}
																	link={articlesInfoObj?.pdf.url}
																/>
															</Grid>
														);
													case '3':
														return (
															<Grid item xs={12}>
																<AppFieldView
																	label={t('common.videoLink')}
																	value={articlesInfoObj?.videoLink}
																	link={articlesInfoObj?.videoLink}
																/>
															</Grid>
														);
													case '4':
														return (
															<Grid item xs={12}>
																<AppFieldView
																	label={t('common.audioFile')}
																	value={articlesInfoObj?.audio.url}
																	link={articlesInfoObj?.audio.url}
																/>
															</Grid>
														);
													default:
														return <></>;
												}
											})()}
											<Grid item xs={12}>
												<RichTextDisplay
													label={t('mentalHealthInfo.inContent')}
													editorStateString={articlesInfoObj?.content}
												/>
											</Grid>
										</Grid>
									);
								case FormType.edit:
									return (
										<SettingMentalHealthContentInfo
											formData={articlesInfoObj}
											articleTypeList={articleTypeList}
											districtsOptions={districtsOptions}
											setType={setType}
										/>
									);
							}
						})()}
					</CardContent>
				</Card>
			)}
			{currentTab == 'updateRecord' && (
				<Card sx={{ marginTop: '10px' }}>
					<CardContent>
						<FormProvider {...methods}>
							<Box marginTop={2}>
								<AppDataGrid
									loading={!isGetPropertySummarySuccess}
									rows={transformResponse(propertySummary?.data?.items) ?? []}
									columns={columns}
									rowCount={propertySummary?.data?.pagination.totalItems ?? 0}
									getRowId={(row) => row.index}
								/>
							</Box>
						</FormProvider>
					</CardContent>
				</Card>
			)}
		</Box>
	);
}
