import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import qs from 'qs';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../../utils/qsHelper';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ResourcesSummaryFilterSection from './resourcesSummaryFilterSection';
import {
	ResourcesInfoFilter,
	ResourcesInfoItem,
} from '@/api/DTO/resources/resourcesList.interface';
import {
	useGetDistrictsQuery,
	useGetResourcesListQuery,
	useGetTypesQuery,
	useResourceStatusQuery,
	useResourcesCountQuery,
} from '@/api/communityResources/resourcesListApiSlice';
import { showLabel } from '@/utils/helper';
import { useResourcesListOptions } from '@/hooks/useResourcesListOptions';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '@/utils/dateHelper';
import dayjs from 'dayjs';
import SettingStatusModal from './components/setStatusDialog';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';

export interface ResourcesInfoDataGrid {}

interface ResourcesSummaryPageProps {
	statusType: string | number;
}

export default function ResourcesSummaryPage(prop: ResourcesSummaryPageProps) {
	const { statusType } = prop;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);
	const [selectedId, setSelectedId] = useState<string[]>([]);
	const { statusList, statusChipMap, typesChipMap } = useResourcesListOptions([
		'statusList',
		'statusChipMap',
		'typesChipMap',
	]);
	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			create: ['create', 'communityResources'],
			setStatus: ['setStatus', 'communityResources', String(statusType)],
		},
	});

	// 当前角色可操作的状态
	const { data: resourcesStatus } = useResourceStatusQuery(statusType);
	const resourcesStatusList = useMemo(() => {
		return (resourcesStatus?.data || []).map((item) => {
			return {
				id: item.status,
				name: item.desc,
			};
		});
	}, [resourcesStatus]);

	const { data: districtsData } = useGetDistrictsQuery(null);
	const districtsList = useMemo(() => {
		const arr = (districtsData?.data || []).map(
			(item: { id: string; name: string }) => {
				return {
					id: item.id,
					name: item.name,
				};
			}
		);
		return arr || [];
	}, [districtsData]);

	const { data: typesData } = useGetTypesQuery(null);
	const { typesList, subTypesMap } = useMemo(() => {
		const subTypesMap: any = {};
		const typesList = (typesData?.data || []).map((item: any) => {
			subTypesMap[item.type] = (item.subtypes || []).map((sub: any) => {
				return {
					id: sub.subTypeId,
					name: sub.subTypeDesc,
				};
			});
			return {
				id: item.type,
				name: item.typeDesc,
			};
		});
		return { typesList, subTypesMap };
	}, [typesData]);

	const { data: pageCount } = useResourcesCountQuery(null, {
		refetchOnMountOrArgChange: true,
	});

	const defaultResourcesInfoFilter: ResourcesInfoFilter = {
		pagination: initialPagination,
		sortings: [],
		status: statusType,
		communityIds: [],
		subTypeIds: [],
		types: [],
	};

	const defaultValues: DefaultValues<ResourcesInfoFilter> =
		defaultResourcesInfoFilter;
	const methods = useForm<ResourcesInfoFilter>({
		defaultValues,
	});

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value, { name }) => {
			if (name == 'types') {
				methods.setValue('subTypeIds', []);
				value.subTypeIds = [];
			}
			const ResourcesInfoFilterFormResult = value as ResourcesInfoFilter;
			setSearchParams(qs.stringify(ResourcesInfoFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformedSearchParams: ResourcesInfoFilter = {
		...defaultResourcesInfoFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'status',
			'communityIds',
			'subTypeIds',
			'types',
		]),
	};

	const {
		data: propertySummary,
		isSuccess: isGetPropertySummarySuccess,
		isLoading: isGetPropertyLoading,
	} = useGetResourcesListQuery(transformedSearchParams, {
		refetchOnMountOrArgChange: true,
	});

	useEffect(() => {
		if (!isGetPropertyLoading) {
			setSelectedId([]);
		}
	}, [propertySummary]);

	const transformResponse = useCallback((response?: ResourcesInfoItem[]) => {
		if (response == null) return;
		const newResponse: ResourcesInfoDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				...item,
				updatedAt: !item.updatedAt
					? '-'
					: dayjs(item.updatedAt)
							.locale('zh-hk-my-setting')
							.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: t('common.name'),
			flex: 1,
		},
		{
			field: 'communityDistrict',
			headerName: t('common.district'),
			flex: 1,
		},
		{
			field: 'type',
			headerName: t('common.classify'),
			flex: 1,
			renderCell: (params) => (
				<Chip
					variant='filled'
					label={showLabel(params.row.type, typesList)}
					color={
						(typesChipMap[params.row.type || '1']?.color as 'default') ||
						'default'
					}
				/>
			),
		},
		{
			field: 'subType',
			headerName: t('common.subclassify'),
			flex: 1,
		},
		{
			field: 'phone',
			headerName: t('common.contactNumber'),
			flex: 1,
		},
		{
			field: 'status',
			headerName: t('common.status'),
			flex: 1,
			renderCell: (params) => (
				<Chip
					variant='outlined'
					label={showLabel(params.row.status, statusList)}
					color={
						(statusChipMap[params.row.status || '1']?.color as 'chipGrey') ||
						'chipGrey'
					}
				/>
			),
		},
		{
			field: 'updatedAt',
			headerName: t('common.lastModifiedTime'),
			flex: 1,
		},
		{
			field: 'edit',
			headerName: '',
			width: 68,
			renderCell: (params) => (
				<Link
					to={`/resources/resources-list/info/${params.id}`}
					state={{
						propertySummarySearchParams: qs.stringify(transformedSearchParams),
					}}
				>
					<IconButton color='primary'>
						<VisibilityOutlinedIcon />
					</IconButton>
				</Link>
			),
		},
	];

	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>
					{t('communityResources.resourcesList')}
				</Typography>

				<Stack direction={'row'} alignItems={'center'} spacing={3}>
					{userRoles.setStatus && (
						<SettingStatusModal
							currentStatus={statusType}
							selectedId={selectedId}
							setSelectedId={setSelectedId}
							resourcesStatusList={resourcesStatusList}
						/>
					)}

					{userRoles.create && (
						<Button
							variant='contained'
							startIcon={<AddIcon />}
							onClick={() =>
								navigate(`/resources/resources-list/add`, {
									state: {
										propertySummarySearchParams: qs.stringify(
											transformedSearchParams
										),
									},
								})
							}
						>
							{t('button.addCommunityResources')}
						</Button>
					)}
				</Stack>
			</Box>
			<Card sx={{ marginTop: 3 }}>
				<CardContent>
					<FormProvider {...methods}>
						<ResourcesSummaryFilterSection
							districtsList={districtsList}
							setSelectedId={setSelectedId}
							typesList={typesList}
							subTypesMap={subTypesMap}
							methods={methods}
							pageCount={pageCount?.data || {}}
						/>

						<Box marginTop={2}>
							<AppDataGrid
								loading={!isGetPropertySummarySuccess}
								rows={transformResponse(propertySummary?.data?.items) ?? []}
								columns={columns}
								rowCount={propertySummary?.data?.pagination.totalItems ?? 0}
								getRowId={(row) => row.id}
								checkboxSelection
								selectionModel={selectedId}
								onSelectionModelChange={(ids) => {
									setSelectedId(ids.map((id) => String(id)));
								}}
							/>
						</Box>
					</FormProvider>
				</CardContent>
			</Card>
		</>
	);
}
