import { UserType } from '@/api/enum/userType.enum';
import { useFilterUserRelativeListQuery } from '@/api/mockApiSlice';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Grid,
	IconButton,
	Menu,
	Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import qs from 'qs';
import { useCallback, useEffect, useState, useRef } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import AppDataGrid, {
	initialPagination,
} from '../../../../common/components/dataGrid/AppDataGrid';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '../../../../utils/dateHelper';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../../../utils/qsHelper';
import RelativeListSummaryFilterSection from './components/RelativeListSummaryFilterSection';
import getRelativeStatusChip from './components/RelativeStatusChip';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddMemberRelativeListModal from './components/AddMemberRelativeListModal';
import AddNonMemberRelativeListModal from './components/AddNonMemberRelativeListModal';
import DetailDialog from './components/detailDialog';
import { useGetFriendListQuery } from '@/api/friend/friendApiSlice';
import { FriendListFilter } from '@/api/DTO/friend/friend.interface';

export default function RelativeListSummaryPage() {
	const { t } = useTranslation();
	const { userId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);
	const [showDetailDialog, setShowDetailDialog] = useState(false);
	const [detailObj, setDetailObj] = useState<{
		relativeId: string;
		friendStatus: number;
		friendId: string;
		userId: string;
		friendRequestId: string;
	}>({
		relativeId: '',
		friendStatus: 0,
		friendId: '',
		userId: '',
		friendRequestId: '',
	});

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			readRelativeList: ['read', 'relative-list'],
			addRelative: ['create', 'relative'],
		},
	});

	const handleDetail = (obj: {
		friendId: string;
		id: string;
		friendStatus: number;
		userId: string;
		friendRequestId: string;
	}) => {
		setShowDetailDialog(true);
		setDetailObj({
			relativeId: obj.id,
			friendStatus: obj.friendStatus,
			friendId: obj.friendId,
			userId: obj.userId,
			friendRequestId: obj.friendRequestId,
		});
	};

	const defaultFriendListFilter: FriendListFilter = {
		pagination: initialPagination,
		sortings: [],
		searchKey: '',
		friendStatuses: [],
		userId: userId || '',
	};

	const defaultValues: DefaultValues<FriendListFilter> =
		defaultFriendListFilter;

	const formSchema = Yup.object().shape({});

	const methods = useForm<any>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const transformedSearchParams: FriendListFilter = {
		...defaultFriendListFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'searchKey',
			'friendStatuses',
			'userId',
		]),
	};

	const {
		data: userRelativeListPageSummary,
		isSuccess: isGetUserRelativeListPageSummarySuccess,
	} = useGetFriendListQuery(transformedSearchParams, {
		refetchOnMountOrArgChange: true,
		skip: !userRoles.readRelativeList,
	});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const friendListFilterFormResult = value as FriendListFilter;

			// validate form and continue if form is valid
			methods.handleSubmit(() => {
				// append to url to reload page
				setSearchParams(qs.stringify(friendListFilterFormResult));
			})();
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: any[]) => {
		if (response == null) return;
		const newResponse: any[] = [];
		response.forEach((item, index) => {
			newResponse.push({
				...item,
				friendAt: dayjs(item.friendAt)
					.locale('zh-hk-my-setting')
					.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'username',
			headerName: t('common.userName'),
			flex: 28,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='body2'
						variant='regular'
					>
						{params.row.username}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.phone}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.email}
					</Typography>
				</Box>
			),
		},
		{
			field: 'district',
			headerName: t('common.belongedDistrict'),
			flex: 14,
		},
		{
			field: 'friendStatus',
			headerName: t('relative.friendStatus'),
			flex: 14,
			renderCell: (params) => (
				<Box>{getRelativeStatusChip(params.row.friendStatus, t)}</Box>
			),
		},
		{
			field: 'userType',
			headerName: t('common.userCategory'),
			flex: 14,
			renderCell: (params) => (
				<Chip
					label={
						params.row.userType === UserType.NORMAL_USER
							? t('common.normalMember')
							: t('common.programMember')
					}
					color={
						params.row.userType === UserType.NORMAL_USER
							? 'chipTertiary'
							: 'chipPrimary'
					}
					variant='outlined'
				/>
			),
		},
		{
			field: 'serviceNumber',
			headerName: t('common.serviceNumber'),
			flex: 14,
		},
		{
			field: 'friendAt',
			headerName: t('common.relativeTime'),
			flex: 16,
		},
		{
			field: 'edit',
			headerName: '',
			width: 68,
			renderCell: (params) => (
				<div onClick={() => handleDetail(params.row)}>
					<IconButton color='primary'>
						<VisibilityOutlinedIcon />
					</IconButton>
				</div>
			),
		},
	];

	// add relatives menu

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const pageContent = (
		<>
			{userRoles.readRelativeList && (
				<Grid container alignItems='stretch' columnSpacing={3} marginTop={2}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<FormProvider {...methods}>
									<form>
										<Grid
											container
											rowSpacing={2}
											columnSpacing={1}
											marginTop={0}
											marginBottom={4}
										>
											<Grid item xs={6}>
												<RelativeListSummaryFilterSection />
											</Grid>
											<Grid
												item
												xs={6}
												sx={{
													display: 'flex',
													justifyContent: 'flex-end',
												}}
											>
												<Button
													variant='text'
													onClick={handleClick}
													sx={{ gap: '5px' }}
													ref={buttonRef}
													disabled={!userRoles.addRelative}
												>
													<AddIcon />
													{t('button.addRelative')}
													{open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
												</Button>
												<Menu
													anchorEl={anchorEl}
													open={open}
													onClose={handleClose}
													PaperProps={{
														style: {
															width: buttonRef.current
																? buttonRef.current.offsetWidth
																: 'auto',
														},
													}}
												>
													<AddMemberRelativeListModal />
													<AddNonMemberRelativeListModal />
												</Menu>
											</Grid>
										</Grid>
										<AppDataGrid
											loading={!isGetUserRelativeListPageSummarySuccess}
											rows={
												transformResponse(
													userRelativeListPageSummary?.data?.items
												) ?? []
											}
											columns={columns}
											rowCount={
												userRelativeListPageSummary?.data?.pagination
													.totalItems ?? 0
											}
											getRowId={(row) => row.userId}
										/>
									</form>
								</FormProvider>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			)}
			{showDetailDialog && (
				<DetailDialog
					isOpen={showDetailDialog}
					setIsOpen={setShowDetailDialog}
					status={detailObj.friendStatus}
					detailObj={detailObj}
				/>
			)}
		</>
	);

	return <div>{pageContent}</div>;
}
