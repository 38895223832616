export enum ReportName {
	userCurrentData = -1, // 用戶當前資料
	userData = -2, // 用戶數據
	userWalkTargetData = -3, // 用戶步行目標數據
	dailyUserUsageData = -4, // 用戶每日使用數據
	userEntityRewardsRedemptionData = -5, // 用戶實體獎勵兌換數據
	userVirtualRewardRedemptionData = -6, // 用戶虛擬獎勵兌換數據

	memberApplicationDataReport = 1, // 應用程式數據報表-機構
	noMemberApplicationDataReport = 2, // 應用程式數據報表-無機構
	memberDailyLoginTimeReport = 3, // 每日登入人次報表-機構
	noMemberDailyLoginTimeReport = 4, // 每日登入人次報表-無機構
	memberDailyLoginNumReport = 5, //每日登入人數報表-機構
	noMemberDailyLoginNumReport = 6, //每日登入人數報表-無機構
	userDailyUsageDataList = 7, // 用戶每日使用量數據名單
	mentalHealthInfoReport = 8, //精神健康資訊閱讀數據報表
	regionalInfoReport = 9, // 地區資訊數據報表
	communityResourceReport = 10, // 社區資源數據報表
	exchangeReport = 11, // 兌換數據報表
	entityRewardReport = 12, // 實體獎勵用戶兌換名單
	virtualRewardReport = 13, // 虛擬獎勵用戶兌換名單
	userReport = 14, // 用戶數據報表
	inactiveUserReport = 15, // 非活躍用戶數據列表
}
