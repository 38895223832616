import {
	ArticleOrderSummaryItemDTO,
	UpdateArticleOrderDTO,
} from '@/api/DTO/mentalHealthInfo/mentalHealthInfo.interface';

import {
	useGetArticleOrderMutation,
	useUpdateArticleOrderMutation,
} from '@/api/mentalHealthInfo/mentalHealthApiSlice';
import { arrayMove } from '@dnd-kit/sortable';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import { closeSnackbar, useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import DndArticleListSection from './info-order/DndArticleListSection';
import { ArticleCategory } from '@/api/enum/common.enum';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { useMentalHealthOptions } from '@/hooks/useMentalHealthOptions';
import CloseIcon from '@mui/icons-material/Close';

interface MentalHealthInfoOrderPageProps {
	categoryId: ArticleCategory;
}

export default function MentalHealthInfoOrderPage({
	categoryId,
}: MentalHealthInfoOrderPageProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { subcategoryId } = useParams();

	const { userRoles } = useGetUser<useGetUserResultType>({
		roleObj: {
			reorder: ['reorder', 'mentalHealth'],
		},
	});
	const { subCategoryList } = useMentalHealthOptions(['subCategoryList']);

	const [
		getArticleOrder,
		{ data: articleOrderSummary, isLoading: isLoadingArticleOrderSummary },
	] = useGetArticleOrderMutation();

	const [updateArticleOrder] = useUpdateArticleOrderMutation();

	const [articleItems, setArticleItems] = useState<
		ArticleOrderSummaryItemDTO[]
	>([]);

	const { handleSubmit, setValue } = useForm<UpdateArticleOrderDTO>({
		defaultValues: { articles: articleOrderSummary?.data ?? [] },
	});

	useEffect(() => {
		getArticleOrder({
			categoryId: categoryId,
			subcategoryId: Number(subcategoryId),
		});
	}, []);

	const articleList = useMemo(() => {
		return articleOrderSummary?.data ?? [];
	}, [articleOrderSummary]);

	useEffect(() => {
		if (articleList && !isLoadingArticleOrderSummary) {
			setArticleItems(articleList);
			setValue('articles', articleList);
		}
	}, [articleList, isLoadingArticleOrderSummary]);

	const handleRepositionItem = (activeIndex: number, overIndex: number) => {
		setArticleItems((prev) => arrayMove(prev, activeIndex, overIndex));
		const newArray = arrayMove(articleItems, activeIndex, overIndex);
		setValue('articles', newArray);
	};

	const onSubmit: SubmitHandler<UpdateArticleOrderDTO> = async (data) => {
		const result = data.articles.map(
			(article: ArticleOrderSummaryItemDTO, index: number) => ({
				...article,
				ordering: index + 1,
			})
		);
		const response = await updateArticleOrder({
			articles: result,
			categoryId: categoryId,
			subcategoryId:
				subCategoryList.find((option) => option.id == subcategoryId)?.id ?? 0,
		});
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.reorderInfoSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			navigate(-1);
		} else {
			enqueueSnackbar(t('snackbar.reorderInfoFail'), {
				variant: 'error',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			navigate(-1);
		}
	};

	const formContent = (
		<>
			<Typography variant='h4'>{t('mentalHealthInfo.infoOrder')}</Typography>
			{userRoles.reorder && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container alignItems='stretch' columnSpacing={3} marginTop={3}>
						<Grid item xs display='flex'>
							<Card sx={{ width: '100%' }}>
								<CardContent>
									<Stack>
										{articleItems && (
											<DndArticleListSection
												articleItems={articleItems}
												handleRepositionItem={handleRepositionItem}
												categoryId={categoryId}
											/>
										)}
									</Stack>
								</CardContent>
								<CardActions sx={{ justifyContent: 'end' }}>
									<Button onClick={() => navigate(-1)}>
										{t('button.cancel')}
									</Button>

									<Button type='submit' variant='contained'>
										{t('button.save')}
									</Button>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
				</form>
			)}
		</>
	);
	return <>{!isLoadingArticleOrderSummary && formContent}</>;
}
