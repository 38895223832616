import { yupResolver } from '@hookform/resolvers/yup';
import Close from '@mui/icons-material/Close';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	styled,
	TableContainer,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppNumberTextField from '../form-inputs/AppNumberTextField';
import { useAdjustUserExpMutation } from '@/api/mission/missionApiSLice';
import { AdjustUserExpReqDTO } from '@/api/DTO/mission/mission.interface';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useGetUserStatisticsExpQuery } from '@/api/statistics/statApiSlice';
import { ExpChartResDTO } from '@/api/DTO/statistics/statistics.interface';
interface EditCurrentExpModalProps {
	userId: string;
}

const EditCurrentExpModal = (props: EditCurrentExpModalProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	// get data
	const { data: userStatExp } = useGetUserStatisticsExpQuery(props.userId);

	// form
	const defaultValues: DefaultValues<AdjustUserExpReqDTO> = {
		exp: 0,
	};

	const formSchema = Yup.object().shape({});

	const methods = useForm<AdjustUserExpReqDTO>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		methods.setValue('userId', props.userId);
	}, [props.userId]);

	const handleClose = () => {
		setOpen(false);
	};
	const watchExp = methods.watch('exp');

	const [adjustUserExp] = useAdjustUserExpMutation();

	const onSubmit: SubmitHandler<AdjustUserExpReqDTO> = async (data) => {
		const response = await adjustUserExp({
			userId: props.userId,
			exp: Number(data.exp),
		});
		if ('data' in response) {
			handleClose();
			methods.reset();
		}
	};

	// calculation for display adjusted exp

	const adjustedExp = Number(watchExp) + (userStatExp?.data.exp ?? 0); // 調整後總經驗值
	const expChart = userStatExp?.data.expChart ?? [];

	function isReachedSysMaxExp(): boolean {
		const maxExp = expChart[expChart.length - 1]?.expRange.max;
		return adjustedExp >= maxExp;
	}

	function getReachedLevelRowIndex(): number | undefined {
		// If adjusted exp reached the system maximum exp, return the last row of expChart(=the system maximum level row)
		if (isReachedSysMaxExp()) {
			return expChart.length - 1;
		} else {
			// else find the corresponding level
			return expChart.findIndex(
				(row) =>
					adjustedExp >= row.expRange.min && adjustedExp <= row.expRange.max
			);
		}
	}

	function getReachedLevelRow(): ExpChartResDTO | undefined {
		const reachedLevelRowIndex = getReachedLevelRowIndex();
		return reachedLevelRowIndex != undefined
			? expChart[reachedLevelRowIndex]
			: undefined;
	}

	function getAdjustedLevel(): number {
		return getReachedLevelRow()?.level ?? 0;
	}

	function getAdjustedExpAll(): number {
		// If reached the system max exp, use the last available expAll
		if (isReachedSysMaxExp()) return expChart[expChart.length - 1 - 1].up ?? 0;
		else return getReachedLevelRow()?.up ?? 0;
	}

	function getAdjustedExpPortion(): number {
		// If reached the system max exp, use the last available expPortion
		const adjustedLevelExpMin = isReachedSysMaxExp()
			? expChart[(getReachedLevelRowIndex() ?? 0) - 1]?.expRange.min
			: getReachedLevelRow()?.expRange.min ?? 0;

		return adjustedExp - adjustedLevelExpMin;
	}

	return (
		<>
			<IconButton color='primary' onClick={() => setOpen(true)}>
				<CreateOutlinedIcon sx={{ cursor: 'pointer' }} />
			</IconButton>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='sm'
				fullWidth
			>
				<DialogTitle variant='h5'>{t('stat.editExp')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							{/* <Grid container marginBottom='5px'>
								<Grid item width={'120px'} marginRight='16px'>
									<Typography variant='body2' color='common.neutral40'>
										{t('stat.referenceLevel')}(±1)
									</Typography>
								</Grid>
								<Grid item xs>
									<Typography variant='body2' color='common.neutral40'>
										test
									</Typography>
								</Grid>
							</Grid>
							<Divider /> */}
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12} display='flex' alignItems='center'>
									<Box
										width='100%'
										display='flex'
										gap={1}
										flexDirection='column'
									>
										<Grid container>
											<Grid item width={'120px'} marginRight='16px'>
												<Typography variant='body2' color='common.neutral40'>
													{t('stat.currentExp')}
												</Typography>
											</Grid>
											<Grid item xs>
												<Typography
													variant='body2'
													sx={{ wordBreak: 'break-word' }}
												>
													{userStatExp?.data.expPortion.toLocaleString()}{' '}
													<Typography
														variant='body2'
														color='common.neutral50'
														display='inline-block'
													>
														{` / ${userStatExp?.data.expAll.toLocaleString()} (等級${
															userStatExp?.data.level
														})`}
													</Typography>
												</Typography>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item width={'120px'} marginRight='16px'>
												<Typography variant='body2' color='common.neutral40'>
													{t('stat.editedExp')}
												</Typography>
											</Grid>
											<Grid item xs>
												<Typography
													variant='body2'
													sx={{ wordBreak: 'break-word' }}
												>
													{getAdjustedExpPortion().toLocaleString()}{' '}
													<Typography
														variant='body2'
														color='common.neutral50'
														display='inline-block'
													>
														{`/ ${getAdjustedExpAll().toLocaleString()} (等級${getAdjustedLevel()})`}
													</Typography>
												</Typography>
											</Grid>
										</Grid>
									</Box>
									<AppNumberTextField
										control={methods.control}
										name='exp'
										fieldLabel={t('stat.increaseNum')}
										type='number'
										size='small'
										nonNegative
									/>
								</Grid>
							</Grid>
						</form>
						<Accordion
							sx={{
								boxShadow: 'none',
								'&:before': { display: 'none' },
								margin: 0,
							}}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon sx={{ color: '#006495' }} />}
								aria-controls='panel1a-content'
								id='panel1a-header'
								sx={{
									width: '132px',
									border: 'none',
									padding: '0px',
									color: '#006495',
									margin: '0 0',
									'& .MuiAccordionSummary-content.Mui-expanded': {
										margin: '10px 0',
									},
									'&.Mui-expanded': {
										minHeight: 'unset',
									},
								}}
							>
								<Typography fontSize='16px' fontWeight={400} margin='0'>
									{t('stat.viewLevelList')}
								</Typography>
							</AccordionSummary>
							<AccordionDetails sx={{ padding: 0 }}>
								<TableContainer sx={{ maxHeight: 561, overflowY: 'auto' }}>
									<Table
										stickyHeader
										style={{ tableLayout: 'fixed' }}
										sx={{
											'& td, & th': { border: 'none' },
										}}
									>
										<TableHead>
											<TableRow
												sx={{
													backgroundColor: '#F1F0F3',
												}}
											>
												<StyledTableCell>{t('stat.level')}</StyledTableCell>
												<StyledTableCell>{t('stat.totalExp')}</StyledTableCell>
												<StyledTableCell>
													{t('stat.nextLevelExp')}
												</StyledTableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{userStatExp?.data.expChart.map((row) => (
												<TableRow key={`row_${row.level}`}>
													<StyledTableCell>{row.level}</StyledTableCell>
													<StyledTableCell>{row.expRange.min}</StyledTableCell>
													<StyledTableCell>
														{row.up ? `+${row.up}` : '-'}
													</StyledTableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</AccordionDetails>
						</Accordion>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.save')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EditCurrentExpModal;

const StyledTableCell = styled(TableCell)({
	fontWeight: 'normal',
	height: '33px',
	padding: '8px',
});
