import { apiSlice } from '../apiSlice';
import { SuccessResponse, Paginated } from '../DTO/common.interface';
import {
	ArticleOrderSummaryItemDTO,
	ArticlesCountRes,
	GetArticleOrderDTO,
	LogsFilter,
	LogsRes,
	MentalHealthInfo,
	MentalHealthInfoDto,
	MentalHealthInfoRes,
	MentalHealthInfoSummaryInfoItem,
	Tag,
	UpdateArticleOrderDTO,
} from '../DTO/mentalHealthInfo/mentalHealthInfo.interface';

export const mentalHealthApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getArticlesList: builder.query<
			SuccessResponse<Paginated<MentalHealthInfoSummaryInfoItem>>,
			MentalHealthInfoDto
		>({
			query: (body) => ({
				url: `/web/articles/page`,
				method: 'POST',
				body,
			}),
			providesTags: ['MentalHealthList'],
		}),
		getArticlesTags: builder.query<SuccessResponse<Tag[]>, null>({
			query: () => ({
				url: `/web/articles/tags`,
			}),
		}),
		saveArticlesTags: builder.mutation<SuccessResponse<any>, { name: string }>({
			query: (body) => ({
				url: `/web/articles/tags`,
				method: 'POST',
				body,
			}),
		}),
		saveArticles: builder.mutation<SuccessResponse<any>, MentalHealthInfo>({
			query: (body) => ({
				url: `/web/articles`,
				method: 'POST',
				body,
			}),
		}),
		articleStatus: builder.query<
			SuccessResponse<{ type: number | string; desc: string }[]>,
			{ type: string | number | undefined }
		>({
			query: (body) => ({
				url: `/web/articles/statuses/list`,
				method: 'POST',
				body,
			}),
		}),
		batchUpdateStatus: builder.mutation<
			SuccessResponse<any>,
			{ articleIds: string[]; status: any; remark?: string }
		>({
			query: (body) => ({
				url: `/web/articles/statuses/batch`,
				method: 'POST',
				body,
			}),
			invalidatesTags: [
				'MentalHealthList',
				'MentalHealthInfo',
				'MentalHealthLogsList',
				'MentalHealthCount',
				'MentalHealthLogsCount',
			],
		}),
		getArticlesInfo: builder.query<
			SuccessResponse<MentalHealthInfoRes>,
			string
		>({
			query: (id) => ({
				url: `/web/articles/${id}`,
			}),
			providesTags: ['MentalHealthInfo'],
		}),
		deleteArticles: builder.mutation<SuccessResponse<any>, string | undefined>({
			query: (id) => ({
				url: `/web/articles/${id}`,
				method: 'DELETE',
			}),
		}),
		updateArticles: builder.mutation<
			SuccessResponse<any>,
			{ id: string; pushTime: string }
		>({
			query: (body) => ({
				url: `/web/articles/${body.id}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['MentalHealthInfo', 'MentalHealthLogsCount'],
		}),
		getArticlesLogsList: builder.query<
			SuccessResponse<Paginated<LogsRes>>,
			LogsFilter
		>({
			query: (body) => ({
				url: `/web/articles/${body.id}/logs`,
				method: 'POST',
				body,
			}),
			providesTags: ['MentalHealthLogsList'],
		}),
		getArticleOrder: builder.mutation<
			SuccessResponse<ArticleOrderSummaryItemDTO[]>,
			GetArticleOrderDTO
		>({
			query: (body) => ({
				url: `/web/articles/order/list`,
				method: 'POST',
				body,
			}),
		}),
		updateArticleOrder: builder.mutation<
			SuccessResponse<ArticleOrderSummaryItemDTO[]>,
			UpdateArticleOrderDTO
		>({
			query: (body) => ({
				url: `/web/articles/order`,
				method: 'POST',
				body,
			}),
		}),
		getArticlesCount: builder.query<
			SuccessResponse<ArticlesCountRes>,
			{ categoryId: string | number }
		>({
			query: (body) => ({
				url: `/web/articles/count`,
				method: 'POST',
				body,
			}),
			providesTags: ['MentalHealthCount'],
		}),
		getArticlesLogsCount: builder.query<
			SuccessResponse<{ [key: string]: string }>,
			string | undefined
		>({
			query: (id) => ({
				url: `/web/articles/${id}/log-count`,
			}),
			providesTags: ['MentalHealthLogsCount'],
		}),
	}),
});

export const {
	useGetArticlesListQuery,
	useGetArticlesTagsQuery,
	useSaveArticlesTagsMutation,
	useSaveArticlesMutation,
	useBatchUpdateStatusMutation,
	useDeleteArticlesMutation,
	useUpdateArticlesMutation,
	useArticleStatusQuery,
	useGetArticlesInfoQuery,
	useGetArticlesLogsListQuery,
	useGetArticlesCountQuery,
	useGetArticlesLogsCountQuery,
	useGetArticleOrderMutation,
	useUpdateArticleOrderMutation,
} = mentalHealthApiSlice;
