import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	IconButton,
	Snackbar,
	Stack,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import AppDataGridHeaderWithSorting from '../../../common/components/dataGrid/AppDataGridHeaderWithSorting';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import MemberUserSummaryFilterSection from './components/MemberUserSummaryFilterSection';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import qs from 'qs';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../../utils/qsHelper';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '../../../utils/dateHelper';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SendNotificationModal from '../../../common/components/modal/SendNotificationModal';
import { useGetOrgUserPageQuery } from '@/api/user/userApiSlice';
import { OrgUserPageDTO, OrgUserPageItem } from '@/api/DTO/user/user.interface';
import { UserType } from '@/api/enum/userType.enum';
import { UserCategory } from '@/api/enum/userCategory.enum';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import {
	getUserStatusChipColor,
	getUserStatusLabel,
	UserStatus,
} from '@/api/enum/userStatus.enum';

export interface OrgUserPageDataGrid {
	id: string;
	userId: string;
	email: string;
	lastLogin: string;
	username: string;
	phone: string;
	district: string;
	userType: number;
	serviceNumber?: string;
	staff?: string;
	status?: UserStatus;
	joinedAt: string;
}

export default function MemberUserSummaryPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const [selectedUser, setSelectedUser] = useState<string[]>([]);

	const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			read: ['read', 'user', 'user-list'],
			create: ['create', 'user'],
			send: ['send', 'user'],
		},
	});

	const defaultOrgUserPageFilter: OrgUserPageDTO = {
		pagination: initialPagination,
		sortings: [],
		loginID: '',
	};

	const defaultValues: DefaultValues<OrgUserPageDTO> = defaultOrgUserPageFilter;

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const methods = useForm<OrgUserPageDTO>({
		defaultValues,
	});

	const transformedSearchParams: OrgUserPageDTO = {
		...defaultOrgUserPageFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'loginID',
			'userTypes',
			'statuses',
			'districtIds',
			'staffIds',
		]),
	};

	const {
		data: orgUserPageSummary,
		isSuccess: isGetOrgUserPageSummarySuccess,
	} = useGetOrgUserPageQuery(transformedSearchParams, {
		refetchOnMountOrArgChange: true,
		skip: !userRoles.read,
	});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const orgUserPageFilterFormResult = value as OrgUserPageDTO;

			// append to url to reload page
			setSearchParams(qs.stringify(orgUserPageFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const handleSnackbarClose = () => {
		setIsSnackbarOpen(false);
	};

	const transformResponse = useCallback((response?: OrgUserPageItem[]) => {
		if (response == null) return;
		const newResponse: OrgUserPageDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				id: item.userId,
				userId: item.userId,
				email: item.email,
				lastLogin: item.lastLogin,
				username: item.username,
				phone: item.phone,
				district: item.district,
				userType: item.userType,
				serviceNumber:
					!item.serviceNumber || item.serviceNumber == ''
						? '-'
						: item.serviceNumber,
				staff: !item.staff || item.staff == '' ? '-' : item.staff,
				status: item.status,
				joinedAt: item.joinedAt,
			});
		});
		return newResponse;
	}, []);

	const DataGridHeader = (params: GridColumnHeaderParams) => {
		return (
			<AppDataGridHeaderWithSorting
				headerName={params.colDef.headerName}
				sortingsPropertyName={params.colDef.field}
			/>
		);
	};

	const columns: GridColDef[] = [
		{
			field: 'username',
			headerName: t('common.username'),
			flex: 20, // 20% width
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='body2'
						variant='regular'
					>
						{params.row.username}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.phone}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.email}
					</Typography>
				</Box>
			),
		},
		{
			field: 'district',
			headerName: t('application.district'),
			flex: 9, // 10% width
		},
		{
			field: 'userType',
			headerName: t('common.userCategory'),
			flex: 9, // 10% width
			renderCell: (params) => (
				<Chip
					label={
						params.row.userType === UserType.NORMAL_USER
							? t('common.normalMember')
							: t('common.programMember')
					}
					color={
						params.row.userType === UserType.NORMAL_USER
							? 'chipTertiary'
							: 'chipPrimary'
					}
					variant='outlined'
				/>
			),
		},
		{
			field: 'serviceNumber',
			headerName: t('common.serviceNumber'),
			flex: 11, // 12% width
		},
		{
			field: 'staff',
			headerName: t('common.staffInCharge'),
			flex: 10, // 10% width
		},
		{
			field: 'status',
			headerName: t('common.status'),
			flex: 10, // 10% width
			renderCell: (params) => (
				<Chip
					label={getUserStatusLabel(params.row.status)}
					color={getUserStatusChipColor(params.row.status)}
					variant='outlined'
				/>
			),
		},
		{
			field: 'joinedAt',
			headerName: t('common.membershipTime'),
			flex: 11, // 11% width
			renderCell: (params) => (
				<Typography variant='regular'>
					{params.row.joinedAt
						? dayjs(params.row.joinedAt)
								.locale('zh-hk-my-setting')
								.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)
						: '-'}
				</Typography>
			),
		},
		{
			field: 'lastLogin',
			headerName: t('common.lastOnlineTime'),
			flex: 10, // 10% width
			renderCell: (params) => (
				<Typography variant='regular'>
					{params.row.lastLogin
						? dayjs(params.row.lastLogin)
								.locale('zh-hk-my-setting')
								.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)
						: '-'}
				</Typography>
			),
		},
		{
			field: 'edit',
			headerName: '',
			width: 68,
			renderCell: (params) => (
				<Link
					to={`/users/member-user/${params.id}`}
					state={{
						orgUserPageSummarySearchParams: qs.stringify(
							transformedSearchParams
						),
					}}
				>
					<IconButton color='primary'>
						<VisibilityOutlinedIcon />
					</IconButton>
				</Link>
			),
		},
	];

	return (
		<>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={isSnackbarOpen}
			>
				<Alert
					severity='success'
					action={<CloseIcon onClick={handleSnackbarClose} />}
				>
					{t('snackbar.createMemberUserSuccess')}
				</Alert>
			</Snackbar>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>{t('common.memberUser')}</Typography>
				<Stack direction={'row'} alignItems={'center'} spacing={3}>
					{userRoles.send && (
						<SendNotificationModal
							userCat={UserCategory.ORG}
							selectedUser={selectedUser}
						/>
					)}

					{userRoles.create && (
						<Button
							variant='contained'
							startIcon={<AddIcon />}
							onClick={() =>
								navigate(`/users/member-user/add`, {
									state: {
										orgUserPageSummarySearchParams: qs.stringify(
											transformedSearchParams
										),
									},
								})
							}
							style={{ alignItems: 'center' }}
						>
							{t('button.addMemberUser')}
						</Button>
					)}
				</Stack>
			</Box>
			<Card sx={{ marginTop: 3 }}>
				{userRoles.read && (
					<CardContent>
						<FormProvider {...methods}>
							<MemberUserSummaryFilterSection />

							<Box marginTop={2}>
								<AppDataGrid
									loading={!isGetOrgUserPageSummarySuccess}
									rows={
										transformResponse(orgUserPageSummary?.data?.items) ?? []
									}
									columns={columns}
									rowCount={
										orgUserPageSummary?.data?.pagination.totalItems ?? 0
									}
									getRowId={(row) => row.id}
									checkboxSelection
									onSelectionModelChange={(ids) => {
										setSelectedUser(ids.map((id) => String(id)));
									}}
								/>
							</Box>
						</FormProvider>
					</CardContent>
				)}
			</Card>
		</>
	);
}
