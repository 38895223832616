import { yupResolver } from '@hookform/resolvers/yup';
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { NoticeSendBatch } from '@/api/DTO/notice/notice.interface';
import AppTextField from '../form-inputs/AppTextField';
import Close from '@mui/icons-material/Close';
import { closeSnackbar, useSnackbar } from 'notistack';
import { UserCategory } from '@/api/enum/userCategory.enum';
import {
	useSendFreeNoticeBatchMutation,
	useSendOrgNoticeBatchMutation,
} from '@/api/notice/noticeApiSlice';
import CloseIcon from '@mui/icons-material/Close';

interface SendNotificationModalProps {
	userCat: UserCategory;
	selectedUser: string[];
}

const SendNotificationModal = (props: SendNotificationModalProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const { userCat, selectedUser } = props;

	const [sendOrgNoticeBatch] = useSendOrgNoticeBatchMutation();
	const [sendFreeNoticeBatch] = useSendFreeNoticeBatchMutation();

	const defaultValues: DefaultValues<NoticeSendBatch> = {};

	const formSchema = Yup.object().shape({
		title: Yup.string().required(t('errorMessage.required')),
		content: Yup.string().required(t('errorMessage.required')),
	});

	const methods = useForm<NoticeSendBatch>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		if (selectedUser.length > 0) {
			methods.setValue('userIds', selectedUser);
		}
	}, [selectedUser]);

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit: SubmitHandler<NoticeSendBatch> = async (data) => {
		if (userCat === UserCategory.ORG) {
			const response = await sendOrgNoticeBatch(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.sendNotificationBatchSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				handleClose();
			}
		} else {
			const response = await sendFreeNoticeBatch(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.sendNotificationBatchSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				handleClose();
			}
		}
	};

	return (
		<>
			<Button
				variant='outlined'
				startIcon={<SendToMobileOutlinedIcon />}
				disabled={selectedUser.length === 0}
				onClick={() => setOpen(true)}
			>
				{t('button.sendNotification')}
				{selectedUser.length > 0 ? ` (${selectedUser.length})` : ''}
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle variant='h5'>
					{t('notification.sendNotificationBatch')}
				</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<Trans
						i18nKey={'notification.notificationMessage'}
						values={{ numOfUsers: selectedUser.length }}
						components={{
							Highlight: <Box display='inline-block' marginLeft='10px' />,
						}}
					/>
					<Divider style={{ marginTop: '10px' }} />
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12}>
									<AppTextField
										control={methods.control}
										name='title'
										label={t('notification.notificationTitle')}
										maxLength={50}
									/>
								</Grid>
								<Grid item xs={12}>
									<AppTextField
										control={methods.control}
										name='content'
										label={t('notification.notificationContent')}
										maxLength={255}
										multiline
										rows={4}
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.send')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SendNotificationModal;
