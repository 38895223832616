import { yupResolver } from '@hookform/resolvers/yup';
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppTextField from '../form-inputs/AppTextField';
import Close from '@mui/icons-material/Close';
import { NoticeSend } from '@/api/DTO/notice/notice.interface';
import { UserCategory } from '@/api/enum/userCategory.enum';
import {
	useSendFreeNoticeMutation,
	useSendOrgNoticeMutation,
} from '@/api/notice/noticeApiSlice';
import { closeSnackbar, useSnackbar } from 'notistack';
import { UserCatContext } from '@/context/userCategoryContext';
import CloseIcon from '@mui/icons-material/Close';

interface SendSingleNotificationModalProps {
	userId: string;
}

const SendSingleNotificationModal = (
	props: SendSingleNotificationModalProps
) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const { userId } = props;

	const { userCat } = useContext(UserCatContext);

	const [sendOrgNotice] = useSendOrgNoticeMutation();
	const [sendFreeNotice] = useSendFreeNoticeMutation();

	const defaultValues: DefaultValues<NoticeSend> = {};

	const formSchema = Yup.object().shape({
		title: Yup.string().required(t('errorMessage.required')),
		content: Yup.string().required(t('errorMessage.required')),
	});

	const methods = useForm<NoticeSend>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		methods.setValue('userId', userId);
	}, [userId]);

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit: SubmitHandler<NoticeSend> = async (data) => {
		if (userCat === UserCategory.ORG) {
			const response = await sendOrgNotice(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.sendNotificationSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				handleClose();
			}
		} else {
			const response = await sendFreeNotice(data);
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.sendNotificationSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				handleClose();
			}
		}
	};

	return (
		<>
			<Button
				variant='outlined'
				startIcon={<SendToMobileOutlinedIcon />}
				onClick={() => setOpen(true)}
			>
				{t('button.sendNotification')}
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle variant='h5'>
					{t('notification.sendNotification')}
				</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12}>
									<AppTextField
										control={methods.control}
										name='title'
										label={t('notification.notificationTitle')}
										maxLength={50}
									/>
								</Grid>
								<Grid item xs={12}>
									<AppTextField
										control={methods.control}
										name='content'
										label={t('notification.notificationContent')}
										maxLength={255}
										multiline
										rows={4}
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.send')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SendSingleNotificationModal;
