import { OtpNature } from '@/api/enum/otpNature.enum';
import { yupResolver } from '@hookform/resolvers/yup';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Button, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { Stack } from '@mui/system';
import React, { useEffect } from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
	useForgotPasswordVerifyOTPMutation,
	useSendOTPMutation,
} from '../../api/authApiSlice';
import { OtpVerificationReqDTO } from '../../api/DTO/auth.interface';
import AppOTPField from '../../common/components/form-inputs/AppOTPField';
import { VERIFICATION_CODE_PATTERN } from '../../common/constants/pattern';
import { selectOTPEmail, selectOTPToken, setCredentials } from './authSlice';
import AuthContainer from './components/AuthContainer';
import AuthCard from './components/AuthCard';

export const defaultValues: DefaultValues<OtpVerificationReqDTO> = {
	otp: '',
};

export default function EmailVerifyPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const OTPToken = useSelector(selectOTPToken);
	const OTPEmail = useSelector(selectOTPEmail);

	const formSchema = Yup.object().shape({
		otp: Yup.string()
			.required(t('errorMessage.required'))
			.matches(VERIFICATION_CODE_PATTERN),
	});

	const methods = useForm<OtpVerificationReqDTO>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	// verify code

	const [forgotPasswordVerifyOTP] = useForgotPasswordVerifyOTPMutation();

	useEffect(() => {
		if (!OTPToken) {
			navigate('/forgot-password');
		}
	}, [OTPToken]);

	// resend count down
	const [counter, setCounter] = React.useState(60);

	React.useEffect(() => {
		counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
	}, [counter]);

	const [sendOTP, { isSuccess: isSendOTPSuccess }] = useSendOTPMutation();

	function resendCode() {
		if (OTPEmail)
			sendOTP({
				nature: OtpNature.RESET_PASSWORD,
				loginID: OTPEmail,
			});
		else console.log('missing reset OTPEmail');
	}

	useEffect(() => {
		if (isSendOTPSuccess) {
			setCounter(60); // reset count down
		}
	}, [isSendOTPSuccess]);

	const onSubmit: SubmitHandler<OtpVerificationReqDTO> = async (data) => {
		if (OTPToken) {
			data.otpToken = OTPToken;
			try {
				const loginResponse = await forgotPasswordVerifyOTP(data).unwrap();
				dispatch(
					setCredentials({
						accessToken: loginResponse.data.accessToken,
					})
				);
				navigate('/forgot-password/reset');
			} catch (err) {
				console.log('error');
			}
		} else console.log('missing reset OTPToken');
	};

	return (
		<AuthContainer>
			<AuthCard
				title={t('forgotPassword.verifyTitle')}
				message={t('forgotPassword.verifyMesaage')}
			>
				<Link
					color='primary'
					component={RouterLink}
					to='/forgot-password'
					position='absolute'
					top='-50px'
					left='-20px'
					display='flex'
					alignItems='center'
					sx={{
						textDecoration: 'none',
					}}
				>
					<KeyboardArrowLeftIcon />
					{t('button.back')}
				</Link>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Stack
						width='382px'
						alignItems='center'
						spacing='24px'
						marginTop='40px'
					>
						<AppOTPField name='otp' control={methods.control} valueLength={4} />
						<Typography
							fontSize='14px'
							color='text.secondary'
							marginTop='40px'
							textAlign='center'
						>
							{counter === 0 && (
								<Link sx={{ cursor: 'pointer' }} onClick={resendCode}>
									{t('button.sendAgain')}
								</Link>
							)}

							{counter > 0 && (
								<Typography
									fontSize='14px'
									variant='bold'
									color='text.disabled'
								>
									{t('button.sendAgain')}({counter}
									{t('button.sec')})
								</Typography>
							)}
						</Typography>
					</Stack>

					<Button
						fullWidth
						type='submit'
						variant='contained'
						sx={{
							marginTop: '40px',
						}}
					>
						{t('button.confirm')}
					</Button>
				</form>
			</AuthCard>
		</AuthContainer>
	);
}
