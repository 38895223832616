import { yupResolver } from '@hookform/resolvers/yup';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppDropDownMenu from '../form-inputs/AppDropDownMenu';
import AppRadioGroup from '../form-inputs/AppRadioGroup';
import Close from '@mui/icons-material/Close';
import { Option } from '@/api/DTO/common.interface';
import { OrgUserOutCentre } from '@/api/DTO/user/user.interface';
import { closeSnackbar, useSnackbar } from 'notistack';
import { useFreeJoinCentreMutation } from '@/api/user/userApiSlice';
import { useNavigate } from 'react-router-dom';
import { centreOptions } from '@/api/enum/centre.enum';
import { useSelector } from 'react-redux';
import { selectMyInfo } from '@/pages/auth/authSlice';
import CloseIcon from '@mui/icons-material/Close';

interface JoinMembershipModalProps {
	userId: string;
	disabled?: boolean;
	joinDistrictsList: Option[];
}

const JoinMembershipModal = (props: JoinMembershipModalProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const myInfo = useSelector(selectMyInfo);

	const { userId, disabled, joinDistrictsList } = props;

	const [freeJoinCentre] = useFreeJoinCentreMutation();

	const defaultValues: DefaultValues<OrgUserOutCentre> = {
		centre: myInfo.centre,
	};

	const formSchema = Yup.object().shape({
		centre: Yup.string().required(t('errorMessage.pleaseSelect')),
		districtId: Yup.string().required(t('errorMessage.pleaseSelect')),
	});

	const methods = useForm<OrgUserOutCentre>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		methods.setValue('userId', userId);
	}, [userId]);

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit: SubmitHandler<OrgUserOutCentre> = async (data) => {
		const response = await freeJoinCentre(data);
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.joinCentreSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
			navigate(`/users/member-user/${userId}/info`);
		}
	};

	return (
		<>
			<MenuItem
				onClick={() => {
					setOpen(true);
				}}
				disabled={disabled}
			>
				<ListItemIcon>
					<PersonAddAltOutlinedIcon fontSize='small' />
				</ListItemIcon>
				<ListItemText>{t('button.joinMembership')}</ListItemText>
			</MenuItem>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='xs'
				fullWidth
			>
				<DialogTitle variant='h5'>{t('common.userJoinMembership')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0} marginBottom={2}>
								<Grid item xs={12}>
									<Typography variant='body2'>
										{t('common.transferInstitution')}
									</Typography>
									<AppRadioGroup
										control={methods.control}
										name='centre'
										row
										options={
											centreOptions.filter(
												(centre) => centre.id == myInfo.centre
											) ?? []
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<AppDropDownMenu
										control={methods.control}
										name='districtId'
										label={t('common.selectLocation')}
										options={joinDistrictsList}
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default JoinMembershipModal;
