import { yupResolver } from '@hookform/resolvers/yup';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
	ExportReport,
	ExportReportDto,
} from '../../../api/DTO/memberUser.interface';
import AppDatePicker from '../form-inputs/AppDatePicker';
import AppDropDownMenu from '../form-inputs/AppDropDownMenu';
import AppRadioGroup from '../form-inputs/AppRadioGroup';
import Close from '@mui/icons-material/Close';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import AppMonthDatePicker from '../form-inputs/AppMonthDatePicker';
import { ReportName } from '@/api/enum/report';
import { useGetReportMutation } from '@/api/report/reportApiSlice';
import { getDateMonth } from '@/utils/dateHelper';

interface ExportReportModalProps {
	userId: string;
}

const ExportReportModal = (props: ExportReportModalProps) => {
	const { userId } = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [getReport] = useGetReportMutation();

	const reportNameList = [
		// {
		// 	id: -1,
		// 	name: t('report.userCurrentData'),
		// },
		{
			id: -2,
			name: t('report.userData'),
		},
		{
			id: -3,
			name: t('report.userWalkTargetData'),
		},
		{
			id: -4,
			name: t('report.dailyUserUsageData'),
		},
		{
			id: -5,
			name: t('report.userEntityRewardsRedemptionData'),
		},
		{
			id: -6,
			name: t('report.userVirtualRewardRedemptionData'),
		},
	];

	const defaultValues: DefaultValues<ExportReport> = {};

	const formSchema = Yup.object().shape({
		reportType: Yup.string().required(t('errorMessage.pleaseSelect')),
		dataPeriodType: Yup.string().when('reportType', {
			is: (value: number) =>
				[
					ReportName.userData,
					ReportName.userWalkTargetData,
					ReportName.dailyUserUsageData,
					ReportName.userEntityRewardsRedemptionData,
					ReportName.userVirtualRewardRedemptionData,
				].includes(Number(value)),
			then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
			otherwise: (schema) => schema.notRequired(),
		}),
		month: Yup.string()
			.nullable()
			.when('dataPeriodType', {
				is: '2',
				then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
			}),
		period: Yup.object()
			.nullable()
			.when('dataPeriodType', {
				is: '3',
				then: (schema) =>
					schema.shape({
						start: Yup.string().required(t('errorMessage.pleaseSelect')),
						end: Yup.string().required(t('errorMessage.pleaseSelect')),
					}),
			}),
	});

	const methods = useForm<ExportReport>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		methods.setValue('userId', props.userId);
	}, [props.userId]);

	const handleClose = () => {
		setOpen(false);
	};

	const watchReportType = methods.watch('reportType');
	const watchDataPeriodType = methods.watch('dataPeriodType');
	const watchDatePeriodStart = methods.watch('period.start');
	const watchDatePeriodEnd = methods.watch('period.end');

	const onSubmit: SubmitHandler<ExportReport> = async (data) => {
		const obj: ExportReportDto = {
			reportCode: data.reportType,
			userId: userId,
		};

		if (data.dataPeriodType == '2') {
			const arr = getDateMonth(data.month);
			obj.start = arr[0];
			obj.end = arr[1];
		} else if (data.dataPeriodType == '3') {
			obj.start = data.period.start;
			obj.end = data.period.end;
		}

		const response = await getReport(obj);
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.startDownloadReport'), {
				variant: 'info',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <Close onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
		}
	};

	return (
		<>
			<Button
				variant='outlined'
				startIcon={<InsertChartOutlinedIcon />}
				onClick={() => {
					setOpen(true);
				}}
			>
				{t('button.exportReport')}
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='xs'
				fullWidth
			>
				<DialogTitle variant='h5'>{t('common.exportReport')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12}>
									<AppDropDownMenu
										control={methods.control}
										name='reportType'
										label={t('common.reportItems')}
										options={reportNameList}
										required
									/>
								</Grid>

								{[
									ReportName.userData,
									ReportName.userWalkTargetData,
									ReportName.dailyUserUsageData,
									ReportName.userEntityRewardsRedemptionData,
									ReportName.userVirtualRewardRedemptionData,
								].includes(Number(watchReportType)) && (
									<Grid item xs={12}>
										<Typography variant='body2' color='common.neutral40'>
											{t('common.dataPeriod')}
										</Typography>
										<AppRadioGroup
											control={methods.control}
											name='dataPeriodType'
											row
											options={
												[
													ReportName.userData,
													ReportName.userWalkTargetData,
													ReportName.dailyUserUsageData,
												].includes(Number(watchReportType))
													? [
															{ id: '2', name: t('common.month') },
															{ id: '3', name: t('common.customPeriod') },
													  ]
													: [
															{ id: '1', name: t('common.all') },
															{ id: '2', name: t('common.month') },
															{ id: '3', name: t('common.customPeriod') },
													  ]
											}
										/>
									</Grid>
								)}
								{watchDataPeriodType === '2' && (
									<Grid item xs={12}>
										<AppMonthDatePicker
											control={methods.control}
											name='month'
											label={t('common.selectMonth')}
											minDate={new Date('2024-10-01')}
											maxDate={new Date()}
											required
										/>
									</Grid>
								)}

								{watchDataPeriodType === '3' && (
									<>
										<Grid item xs={5.5}>
											<AppDatePicker
												control={methods.control}
												name='period.start'
												label={t('common.startDate')}
												maximumDate={watchDatePeriodEnd}
											/>
										</Grid>
										<Grid item xs={1}>
											<Typography
												textAlign='center'
												alignContent='center'
												height='100%'
											>
												—
											</Typography>
										</Grid>
										<Grid item xs={5.5}>
											<AppDatePicker
												control={methods.control}
												name='period.end'
												label={t('common.endDate')}
												minimumDate={watchDatePeriodStart}
											/>
										</Grid>
									</>
								)}
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.export')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ExportReportModal;
