import { VoucherRedeemStatus } from '@/api/enum/voucher.enum';
import { Chip } from '@mui/material';
import { TFunction } from 'i18next';

export default function getVoucherStatusChip(
	status: VoucherRedeemStatus,
	t: TFunction
): JSX.Element {
	switch (status) {
		case VoucherRedeemStatus.UNKNOWN:
			return <Chip label='-' color='default' variant='outlined' />;
		case VoucherRedeemStatus.PENDING:
			return (
				<Chip
					label={t('item.toBeRedeemed')}
					color='chipTertiary'
					variant='outlined'
				/>
			);
		case VoucherRedeemStatus.REDEEMED:
			return (
				<Chip
					label={t('item.redeemed')}
					color='chipLightGrey'
					variant='outlined'
				/>
			);
		case VoucherRedeemStatus.EXPIRED:
			return (
				<Chip
					label={t('item.expired')}
					color='chipLightGrey'
					variant='outlined'
				/>
			);
		default:
			return <Chip label='Unknown Status' color='default' variant='outlined' />;
	}
}
