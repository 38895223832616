import { yupResolver } from '@hookform/resolvers/yup';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import AppTextField from '@/common/components/form-inputs/AppTextField';
import { FormType } from '@/api/enum/formType.enum';
import { Dispatch, SetStateAction } from 'react';
import { SurveyUpdateReqDTO } from '@/api/DTO/survey/survey.interface';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utils/dateHelper';
import { useEditSurveyMutation } from '@/api/survey/surveyApiSlice';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';

interface SurveyFormProps {
	type: FormType;
	setType: Dispatch<SetStateAction<FormType>>;
	surveyId: string;
	title: string;
	link: string;
	createdAt: string;
}

export default function SurveyForm({
	type,
	setType,
	surveyId,
	title,
	link,
	createdAt,
}: SurveyFormProps) {
	const { t } = useTranslation();
	const showModal = useModal();

	const formSchema = Yup.object().shape({
		title: Yup.string().required(t('errorMessage.required')),
		link: Yup.string().required(t('errorMessage.required')),
	});

	const defaultValues: DefaultValues<SurveyUpdateReqDTO> = {
		id: surveyId,
		title: title,
		link: link,
	};

	const { handleSubmit, control } = useForm<SurveyUpdateReqDTO>({
		defaultValues: defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [editSurvey] = useEditSurveyMutation();

	const handleClose = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('common.giveUpEdit')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>{t('common.giveUpMsg')}</Typography>
					</>
				}
				cancellationText={t('button.giveUpFix')}
				confirmationText={t('button.saveAndLeave')}
				confirmationButtonProps={{ type: 'submit', variant: 'contained' }}
				onConfirm={() => handleSubmit(onSubmit)()}
				onCancel={() => setType(FormType.view)}
			/>
		));
	};

	const onSubmit: SubmitHandler<SurveyUpdateReqDTO> = async (data) => {
		const response = await editSurvey(data);
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.fixSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			setType(FormType.view);
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Typography variant='h5'>{t('survey.surveyInfo')}</Typography>
				<Grid container spacing={3} marginTop={0} marginBottom={3}>
					<Grid item xs={4}>
						<AppTextField
							control={control}
							name='title'
							label={t('common.name')}
							maxLength={50}
						/>
					</Grid>
					<Grid item xs={4}>
						<AppTextField
							control={control}
							name='link'
							label={t('survey.surveyLink')}
							maxLength={255}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							value={dayjs(createdAt).format(DISPLAY_DATE_FORMAT)}
							label={t('common.creationDate')}
							disabled
							fullWidth
						/>
					</Grid>
				</Grid>
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='primary'>
								{t('button.save')}
							</Button>
							<Button variant='text' color='info' onClick={handleClose}>
								{t('button.cancel')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			</form>
		</>
	);
}
