import { yupResolver } from '@hookform/resolvers/yup';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppTextField from '../form-inputs/AppTextField';
import Close from '@mui/icons-material/Close';
import { Tag } from '@/api/DTO/mentalHealthInfo/mentalHealthInfo.interface';
import { useSaveArticlesTagsMutation } from '@/api/mentalHealthInfo/mentalHealthApiSlice';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

interface AddCustomTagModalProps {
	open: boolean;
	onClose: (addedTag?: Tag) => void;
	refetchGetTags: () => void;
}

const AddCustomTagModal = (props: AddCustomTagModalProps) => {
	const { t } = useTranslation();
	const [saveTags] = useSaveArticlesTagsMutation();

	const defaultValues: DefaultValues<Tag> = {};

	const formSchema = Yup.object().shape({});

	const methods = useForm<Tag>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const { onClose, open, refetchGetTags } = props;

	const handleClose = () => {
		methods.reset();
		// if (addTagResponse) onClose(addTagResponse.data);
		// else onClose();
		onClose();
	};

	const watchName = methods.watch('name');

	const onSubmit = async (data: { name: string }) => {
		const response = await saveTags(data);
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.addTagsSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
			refetchGetTags();
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='xs'
				fullWidth
			>
				<DialogTitle variant='h6'>{t('common.addCustomTags')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12}>
									<AppTextField
										control={methods.control}
										name='name'
										label={t('common.tagText')}
										maxLength={10}
										showMaxLength
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
						disabled={!watchName}
					>
						{t('button.add')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AddCustomTagModal;
