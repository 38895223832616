import {
	UserListOfCashCouponFilter,
	UserListOfCashCouponSummaryItem,
} from '@/api/DTO/item.interface';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../../utils/qsHelper';
import UserListOfCashCouponSummeryFilterSection from './components/UserListOfCashCouponSummeryFilterSection';
import getVoucherStatusChip from '@/pages/app/components/VoucherStatusChip';
import { useFilterVouchersUsersItemsQuery } from '@/api/shop/shopApiSlice';
import { DISPLAY_DATE_FORMAT } from '@/utils/dateHelper';
import dayjs from 'dayjs';

export interface UserListOfCashCouponDataGrid {
	id: string;
	index: number;
	username: string;
	email?: string;
	phone?: string;
	district: string;
	serviceNumber: string;
	voucherName: string;
	staff: string;
	voucherRedeemStatus: number;
	redeemAt: string;
	expiredAt: string;
}

export default function UserListOfCashCouponSummaryPage() {
	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const defaultUserListOfCashCouponFilter: UserListOfCashCouponFilter = {
		pagination: initialPagination,
		sortings: [],
		searchKey: '',
		voucherRedeemStatuses: undefined,
	};

	const defaultValues: DefaultValues<UserListOfCashCouponFilter> =
		defaultUserListOfCashCouponFilter;

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const methods = useForm<UserListOfCashCouponFilter>({
		defaultValues,
	});

	const transformedSearchParams: UserListOfCashCouponFilter = {
		...defaultUserListOfCashCouponFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'searchKey',
			'voucherRedeemStatuses',
		]),
	};

	const { data, isSuccess: isGetPropertySummarySuccess } =
		useFilterVouchersUsersItemsQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
		});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const UserListOfCashCouponFilterFormResult =
				value as UserListOfCashCouponFilter;

			// append to url to reload page
			setSearchParams(qs.stringify(UserListOfCashCouponFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback(
		(response?: UserListOfCashCouponSummaryItem[]) => {
			if (response == null) return;
			const newResponse: UserListOfCashCouponDataGrid[] = [];
			response.forEach((item, index) => {
				newResponse.push({
					id: item.id,
					index: index,
					username: item.username,
					email: item.email,
					phone: item.phone,
					district: item.district,
					serviceNumber: item.serviceNumber,
					voucherName: item.voucherName,
					staff: item.staff,
					voucherRedeemStatus: item.voucherRedeemStatus,
					redeemAt: !item.redeemAt
						? '-'
						: dayjs(item.redeemAt)
								.locale('zh-hk-my-setting')
								.format(DISPLAY_DATE_FORMAT),
					expiredAt: !item.expiredAt
						? '-'
						: dayjs(item.expiredAt)
								.locale('zh-hk-my-setting')
								.format(DISPLAY_DATE_FORMAT),
				});
			});
			return newResponse;
		},
		[]
	);

	const columns: GridColDef[] = [
		{
			field: 'username',
			headerName: t('common.username'),
			flex: 27,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='body2'
						variant='regular'
					>
						{params.row.username}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.phone}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.email}
					</Typography>
				</Box>
			),
		},
		{
			field: 'district',
			headerName: t('common.belongedDistrict'),
			flex: 15,
		},
		{
			field: 'serviceNumber',
			headerName: t('common.serviceNumber'),
			flex: 16,
		},
		{
			field: 'voucherName',
			headerName: t('item.couponName'),
			flex: 16,
		},
		{
			field: 'staff',
			headerName: t('common.staffInCharge'),
			flex: 15,
		},
		{
			field: 'voucherRedeemStatus',
			headerName: t('item.redeemStatus'),
			flex: 11,
			renderCell: (params) => {
				return (
					<Box>{getVoucherStatusChip(params.row.voucherRedeemStatus, t)}</Box>
				);
			},
		},
		{
			field: 'expiredAt',
			headerName: t('item.redeemExpiredDate'),
			flex: 11,
		},
	];

	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>{t('item.userListOfCashCoupons')}</Typography>
			</Box>
			<Card sx={{ marginTop: 3 }}>
				<CardContent>
					<FormProvider {...methods}>
						<UserListOfCashCouponSummeryFilterSection />

						<Box marginTop={2}>
							<AppDataGrid
								loading={!isGetPropertySummarySuccess}
								rows={transformResponse(data?.data.items) ?? []}
								columns={columns}
								rowCount={data?.data.pagination.totalItems ?? 0}
								getRowId={(row) => row.index}
							/>
						</Box>
					</FormProvider>
				</CardContent>
			</Card>
		</>
	);
}
