import { Card, CardContent, Stack, Typography } from '@mui/material';

interface AuthCardProps {
	title: string;
	message: string;
}

export default function AuthCard(
	props: React.PropsWithChildren<AuthCardProps>
) {
	return (
		<>
			<Stack
				width='100%'
				maxWidth='450px'
				marginX='10px'
				alignItems='center'
				display='flex'
				position='relative'
			>
				<Card sx={{ width: '100%' }}>
					<CardContent>
						<Typography
							fontSize='24px'
							variant='bold'
							marginTop='22px'
							whiteSpace='nowrap'
						>
							{props.title}
						</Typography>
						<Typography
							fontSize='body2'
							marginTop='7px'
							color='common.neutral50'
						>
							{props.message}
						</Typography>
						{props.children}
					</CardContent>
				</Card>
			</Stack>
		</>
	);
}
