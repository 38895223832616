import { apiSlice } from '../apiSlice';
import { SuccessResponse } from '../DTO/common.interface';
import {
	AdjustUserCoinReqDTO,
	AdjustUserExpReqDTO,
} from '../DTO/mission/mission.interface';

export const missionApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		adjustUserCoin: builder.mutation<
			SuccessResponse<null>,
			AdjustUserCoinReqDTO
		>({
			query: (body) => ({
				url: '/web/mission/coin/adjust',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['UserStat'],
		}),

		adjustUserExp: builder.mutation<SuccessResponse<null>, AdjustUserExpReqDTO>(
			{
				query: (body) => ({
					url: '/web/mission/exp/adjust',
					method: 'POST',
					body,
				}),
				invalidatesTags: ['UserStat', 'UserStatExp'],
			}
		),
	}),
});

export const { useAdjustUserCoinMutation, useAdjustUserExpMutation } =
	missionApiSlice;
