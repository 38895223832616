import AppMultipleDropDownMenu from '@/common/components/form-inputs/AppMultipleDropDownMenu';
import AppSummarySearchBar from '@/common/components/form-inputs/AppSummarySearchBar';
import { Button, Grid, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import { FilterShopItemReqDTO } from '@/api/DTO/shop/shop.interface';
import { furnitureSubCategoryOptions } from '@/api/enum/shopSubCategory.enum';

export default function VirtualPropsRewardSummeryFilterSection() {
	const { t } = useTranslation();
	const { control, watch, resetField } = useFormContext<FilterShopItemReqDTO>();

	const resetFilterRHFState = () => {
		resetField('subcategoryIds');
		resetField('searchKey');
	};

	const clearAllFilter = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();
		resetFilterRHFState();
	};

	// reset button
	const watchSubcategoryIds = watch('subcategoryIds');
	const watchSearchKey = watch('searchKey');

	const isSubcategoryIdsDirty =
		watchSubcategoryIds && watchSubcategoryIds.length > 0;
	const isSearchKeyDirty =
		watchSearchKey !== undefined && watchSearchKey !== '';
	const isFilterDirty = isSubcategoryIdsDirty || isSearchKeyDirty;

	return (
		<Stack spacing={2}>
			<Grid container spacing={3}>
				<Grid item xs={4}>
					<AppSummarySearchBar
						name='searchKey'
						control={control}
						placeholder={t('common.pleaseEnterKeywordSearch')}
					/>
				</Grid>
				<Grid item xs={2.5}>
					<AppMultipleDropDownMenu
						control={control}
						name='subcategoryIds'
						label={t('common.classify')}
						options={furnitureSubCategoryOptions}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>
				<Grid item xs>
					<Button
						variant='text'
						startIcon={<RotateLeftOutlinedIcon />}
						disabled={!isFilterDirty}
						onClick={clearAllFilter}
					>
						{t('button.reset')}
					</Button>
				</Grid>
			</Grid>
		</Stack>
	);
}
