import { apiSlice } from '../apiSlice';
import { SuccessResponse } from '../DTO/common.interface';
import {
	CommunityMapResDTO,
	ExpResDTO,
	UserCheckInImageResDTO,
	UserStatisticsResDTO,
} from '../DTO/statistics/statistics.interface';

export const statApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getUserStatistics: builder.query<
			SuccessResponse<UserStatisticsResDTO>,
			string
		>({
			query: (userId) => ({
				url: `/web/statistics/users/${userId}`,
			}),
			providesTags: ['UserStat'],
		}),

		getUserCommunityMap: builder.query<
			SuccessResponse<CommunityMapResDTO[]>,
			string
		>({
			query: (userId) => ({
				url: `/web/statistics/users/${userId}/community-map`,
			}),
		}),

		getUserCommunityMapImages: builder.query<
			SuccessResponse<UserCheckInImageResDTO[]>,
			{ userId: string; communityEnum: number }
		>({
			query: ({ userId, communityEnum }) => ({
				url: `/web/statistics/users/${userId}/community-map/${communityEnum}/images`,
			}),
		}),

		getUserStatisticsExp: builder.query<SuccessResponse<ExpResDTO>, string>({
			query: (userId) => ({
				url: `/web/statistics/users/${userId}/exp`,
			}),
			providesTags: ['UserStatExp'],
		}),
	}),
});

export const {
	useGetUserStatisticsQuery,
	useGetUserCommunityMapQuery,
	useLazyGetUserCommunityMapImagesQuery,
	useGetUserStatisticsExpQuery,
} = statApiSlice;
