import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeSnackbar, useSnackbar } from 'notistack';
import {
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
	Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import {
	DefaultValues,
	FormProvider,
	useForm,
	SubmitHandler,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AppDropDownMenu from '@/common/components/form-inputs/AppDropDownMenu';
import AppRadioGroup from '@/common/components/form-inputs/AppRadioGroup';
import AppDatePicker from '@/common/components/form-inputs/AppDatePicker';
import { noMemberUserTypeList, userTypeList } from '@/api/enum/userType.enum';
import { Centre, CentreMap, centreList } from '@/api/enum/centre.enum';
import { useGetCentreDistrictsQuery } from '@/api/area/areaApiSlice';
import AppYearDatePicker from '@/common/components/form-inputs/AppYearDatePicker';
import AppMonthDatePicker from '@/common/components/form-inputs/AppMonthDatePicker';
import { ReportName } from '@/api/enum/report';
import { communityList } from '@/api/enum/community.enum';
import AppMultipleDropDownMenu from '@/common/components/form-inputs/AppMultipleDropDownMenu';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import {
	getCompletedQuartersForYear,
	getDateMonth,
	getDateQuarter,
} from '@/utils/dateHelper';
import { ReportDTO, ReportFormDTO } from '@/api/DTO/report/report.interface';
import { useGetReportMutation } from '@/api/report/reportApiSlice';

interface Props {
	rowId: number;
	rowName: string;
}

const ExportReportModal = (prop: Props) => {
	const { rowId, rowName } = prop;
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [getReport] = useGetReportMutation();
	const [forceUpdate, setForceUpdate] = useState(0);

	const { userInfo } = useGetUser<useGetUserResultType>();

	const [minYear, setMinYear] = useState<Date>();
	const [maxYear, setMaxYear] = useState<Date>();
	useEffect(() => {
		const now = new Date();
		const currentYear = now.getFullYear();
		const endYear = currentYear;

		setMinYear(new Date(2024, 1, 1));
		setMaxYear(new Date(endYear, 1, 1));
	}, []);

	const defaultValues: DefaultValues<any> = {};
	const getFormSchema = (rowId: number) => {
		const userTypeSchema = [
			ReportName.userDailyUsageDataList,
			ReportName.mentalHealthInfoReport,
			ReportName.regionalInfoReport,
			ReportName.communityResourceReport,
			ReportName.exchangeReport,
			ReportName.entityRewardReport,
			ReportName.userReport,
			ReportName.virtualRewardReport,
		].includes(rowId)
			? Yup.array()
					.nullable()
					.min(1, t('errorMessage.pleaseSelect'))
					.required(t('errorMessage.pleaseSelect'))
			: Yup.array().nullable();

		const districtSchema = [
			ReportName.memberApplicationDataReport,
			ReportName.memberDailyLoginTimeReport,
			ReportName.memberDailyLoginNumReport,
			ReportName.userDailyUsageDataList,
			ReportName.mentalHealthInfoReport,
			ReportName.exchangeReport,
			ReportName.entityRewardReport,
			ReportName.virtualRewardReport,
			ReportName.userReport,
			ReportName.inactiveUserReport,
		].includes(rowId)
			? Yup.array()
					.nullable()
					.min(1, t('errorMessage.pleaseSelect'))
					.required(t('errorMessage.pleaseSelect'))
			: Yup.array().nullable();

		const communityEnumSchema = [
			ReportName.regionalInfoReport,
			ReportName.communityResourceReport,
		].includes(rowId)
			? Yup.array()
					.nullable()
					.min(1, t('errorMessage.pleaseSelect'))
					.required(t('errorMessage.pleaseSelect'))
			: Yup.array().nullable();

		const dataPeriodTypeSchema = [
			ReportName.memberApplicationDataReport,
			ReportName.memberDailyLoginTimeReport,
			ReportName.memberDailyLoginNumReport,
			ReportName.userDailyUsageDataList,
			ReportName.mentalHealthInfoReport,
			ReportName.regionalInfoReport,
			ReportName.communityResourceReport,
			ReportName.exchangeReport,
		].includes(rowId)
			? Yup.string().required(t('errorMessage.pleaseSelect'))
			: Yup.string().nullable();

		return Yup.object().shape({
			centreType: Yup.string().required(t('errorMessage.pleaseSelect')),
			userType: userTypeSchema,
			district: districtSchema,
			communityEnum: communityEnumSchema,
			dataPeriodType: dataPeriodTypeSchema,
			year: Yup.string()
				.nullable()
				.when('dataPeriodType', {
					is: '1',
					then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
				}),
			yearQuarter: Yup.string()
				.nullable()
				.when('dataPeriodType', {
					is: '2',
					then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
				}),
			quarter: Yup.string()
				.nullable()
				.when('dataPeriodType', {
					is: '2',
					then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
				}),
			month: Yup.string()
				.nullable()
				.when('dataPeriodType', {
					is: '3',
					then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
				}),
			period: Yup.object()
				.nullable()
				.when('dataPeriodType', {
					is: '4',
					then: (schema) =>
						schema.shape({
							start: Yup.string().required(t('errorMessage.pleaseSelect')),
							end: Yup.string().required(t('errorMessage.pleaseSelect')),
						}),
				}),
		});
	};

	const methods = useForm<ReportFormDTO>({
		defaultValues,
		resolver: yupResolver(getFormSchema(rowId)),
	});

	const handleClose = () => {
		methods.reset();
		setOpen(false);
	};
	const handleOpen = () => {
		setOpen(true);
	};

	const watchCentreType = methods.watch('centreType');
	const watchDataPeriodType = methods.watch('dataPeriodType');
	const watchDatePeriodStart = methods.watch('period.start');
	const watchDatePeriodEnd = methods.watch('period.end');
	const watchYear = methods.watch('year');
	const watchYearQuarter = methods.watch('yearQuarter');

	const { data: centreDistricts, isSuccess: isGetCentreDistrictsSuccess } =
		useGetCentreDistrictsQuery(watchCentreType || '', {
			refetchOnMountOrArgChange: true,
			skip: !watchCentreType,
		});

	useEffect(() => {
		if (!watchCentreType) {
			methods.setValue('centreType', userInfo?.centre);
		}
	}, [userInfo]);

	useEffect(() => {
		if (
			[
				ReportName.userDailyUsageDataList,
				ReportName.mentalHealthInfoReport,
				ReportName.regionalInfoReport,
				ReportName.communityResourceReport,
				ReportName.exchangeReport,
				ReportName.entityRewardReport,
				ReportName.userReport,
				ReportName.virtualRewardReport,
			].includes(rowId)
		) {
			if (watchCentreType == Centre.SHARE) {
				methods.setValue('userType', [0]);
			} else {
				methods.setValue('userType', [1, 2]);
			}
		}
	}, [watchCentreType]);

	useEffect(() => {
		if (
			[
				ReportName.regionalInfoReport,
				ReportName.communityResourceReport,
			].includes(rowId)
		) {
			methods.setValue(
				'communityEnum',
				communityList.map((item) => item.id)
			);
		}
	}, [rowId]);

	useEffect(() => {
		if (
			[
				ReportName.memberApplicationDataReport,
				ReportName.memberDailyLoginTimeReport,
				ReportName.memberDailyLoginNumReport,
				ReportName.userDailyUsageDataList,
				ReportName.mentalHealthInfoReport,
				ReportName.exchangeReport,
				ReportName.entityRewardReport,
				ReportName.virtualRewardReport,
				ReportName.userReport,
				ReportName.inactiveUserReport,
			].includes(rowId)
		) {
			methods.setValue(
				'district',
				(centreDistricts?.data || []).map((item) => item.id)
			);
		}
	}, [centreDistricts, isGetCentreDistrictsSuccess]);

	useEffect(() => {
		methods.setValue('year', '');
		methods.setValue('yearQuarter', '');
		methods.setValue('quarter', '');
		methods.setValue('month', '');
		methods.setValue('period.start', '');
		methods.setValue('period.end', '');
	}, [watchDataPeriodType]);

	const onSubmit: SubmitHandler<ReportFormDTO> = async (data) => {
		const obj: ReportDTO = { reportCode: rowId, centreTypes: [] };
		if (data.centreType == Centre.SHARE) {
			if (rowId == ReportName.memberApplicationDataReport) {
				obj.reportCode = ReportName.noMemberApplicationDataReport;
			}
			if (rowId == ReportName.memberDailyLoginTimeReport) {
				obj.reportCode = ReportName.noMemberDailyLoginTimeReport;
			}
			if (rowId == ReportName.memberDailyLoginNumReport) {
				obj.reportCode = ReportName.noMemberDailyLoginNumReport;
			}
		}

		if (data.dataPeriodType == '1') {
			obj.start = `${watchYear}-09-01`;
			obj.end = `${Number(watchYear) + 1}-08-31`;
		} else if (data.dataPeriodType == '2') {
			const arr = getDateQuarter(data.quarter, Number(data.yearQuarter));
			obj.start = arr[0];
			obj.end = arr[1];
		} else if (data.dataPeriodType == '3') {
			const arr = getDateMonth(data.month);
			obj.start = arr[0];
			obj.end = arr[1];
		} else if (data.dataPeriodType == '4') {
			obj.start = data.period.start;
			obj.end = data.period.end;
		}

		if (data.centreType) {
			obj.centreTypes = [];
			obj.centreTypes.push(CentreMap[data.centreType]);
		}
		if (data.district) {
			obj.districts = data.district;
		}
		if (data.userType) {
			obj.userLevels = data.userType;
		}
		if (data.communityEnum) {
			obj.communityEnums = data.communityEnum;
		}

		const response = await getReport(obj);

		if ('data' in response) {
			enqueueSnackbar(t('snackbar.startDownloadReport'), {
				variant: 'info',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <Close onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
		}
	};

	return (
		<>
			<IconButton color='primary' onClick={() => handleOpen()}>
				<FileDownloadOutlinedIcon sx={{ cursor: 'pointer' }} />
			</IconButton>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='xs'
				fullWidth
			>
				<DialogTitle variant='h5'>{rowName}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent sx={{ paddingTop: 0 }}>
					<Typography variant='body1'>
						{t('report.selectReportRange')}
					</Typography>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12}>
									<AppDropDownMenu
										control={methods.control}
										name='centreType'
										label={t('common.institution')}
										options={centreList[userInfo?.centre as Centre]}
									/>
								</Grid>
								{/* 用戶類型 */}
								{[
									ReportName.userDailyUsageDataList,
									ReportName.mentalHealthInfoReport,
									ReportName.regionalInfoReport,
									ReportName.communityResourceReport,
									ReportName.exchangeReport,
									ReportName.entityRewardReport,
									ReportName.userReport,
									ReportName.virtualRewardReport,
								].includes(rowId) && (
									<Grid item xs={12}>
										<AppMultipleDropDownMenu
											control={methods.control}
											name='userType'
											label={t('common.userType')}
											options={
												watchCentreType == Centre.SHARE
													? noMemberUserTypeList
													: userTypeList
											}
											emptyArrayIfNoSelection
										/>
									</Grid>
								)}

								{/* 地區 */}
								{[
									ReportName.memberApplicationDataReport,
									ReportName.memberDailyLoginTimeReport,
									ReportName.memberDailyLoginNumReport,
									ReportName.userDailyUsageDataList,
									ReportName.mentalHealthInfoReport,
									ReportName.exchangeReport,
									ReportName.entityRewardReport,
									ReportName.virtualRewardReport,
									ReportName.userReport,
									ReportName.inactiveUserReport,
								].includes(rowId) &&
									watchCentreType && (
										<Grid item xs={12}>
											{/* <AppDropDownMenu
											control={methods.control}
											name='district'
											label={t('common.district')}
											options={centreDistricts?.data || []}
										/> */}

											<AppMultipleDropDownMenu
												control={methods.control}
												name='district'
												label={t('common.district')}
												options={centreDistricts?.data || []}
												selectAllOptions={{
													isSelectAll: true,
													text: t('common.allDistrict'),
												}}
												renderValue={t('common.allDistrict')}
												emptyArrayIfNoSelection
											/>
										</Grid>
									)}

								{/* 資訊地區 */}
								{[
									ReportName.regionalInfoReport,
									ReportName.communityResourceReport,
								].includes(rowId) && (
									<Grid item xs={12}>
										<AppMultipleDropDownMenu
											control={methods.control}
											name='communityEnum'
											label={t('common.infoDistrict')}
											options={communityList}
											selectAllOptions={{
												isSelectAll: true,
												text: t('common.allDistrict'),
											}}
											renderValue={t('common.allDistrict')}
											emptyArrayIfNoSelection
										/>
									</Grid>
								)}

								{/* 時間段 */}
								{[
									ReportName.memberApplicationDataReport,
									ReportName.memberDailyLoginTimeReport,
									ReportName.memberDailyLoginNumReport,
									ReportName.userDailyUsageDataList,
									ReportName.mentalHealthInfoReport,
									ReportName.regionalInfoReport,
									ReportName.communityResourceReport,
									ReportName.exchangeReport,
								].includes(rowId) && (
									<>
										<Grid item xs={12}>
											<Typography variant='body2' color='common.neutral40'>
												{t('common.dataPeriod')}
											</Typography>
											<AppRadioGroup
												control={methods.control}
												name='dataPeriodType'
												row
												options={[
													{ id: '1', name: t('common.year') },
													{ id: '2', name: t('common.quarter') },
													{ id: '3', name: t('common.month') },
													{ id: '4', name: t('common.customPeriod') },
												]}
											/>
										</Grid>
										{watchDataPeriodType == '1' && (
											<Grid item xs={12}>
												<AppYearDatePicker
													control={methods.control}
													name='year'
													label={t('common.selectYear')}
													minYear={minYear}
													maxYear={maxYear}
													required
												/>
												{watchYear && (
													<Typography variant='body2'>
														{`${t('report.yearRange')}：${watchYear}-09-01 ~ ${
															Number(watchYear) + 1
														}-08-31`}
													</Typography>
												)}
											</Grid>
										)}
										{watchDataPeriodType == '2' && (
											<>
												<Grid item xs={5.5}>
													<AppYearDatePicker
														control={methods.control}
														name='yearQuarter'
														label={t('common.selectParticularYear')}
														minYear={minYear}
														maxYear={maxYear}
														required
													/>
												</Grid>
												<Grid item xs={1}>
													<Typography
														textAlign='center'
														alignContent='center'
														height='100%'
													>
														—
													</Typography>
												</Grid>
												<Grid item xs={5.5}>
													<AppDropDownMenu
														control={methods.control}
														name='quarter'
														label={t('common.selectQuarter')}
														options={getCompletedQuartersForYear(
															Number(watchYearQuarter)
														)}
														required
													/>
												</Grid>
											</>
										)}

										{watchDataPeriodType == '3' && (
											<Grid item xs={12}>
												<AppMonthDatePicker
													control={methods.control}
													name='month'
													label={t('common.selectMonth')}
													minDate={new Date('2024-10-01')}
													maxDate={new Date()}
													required
												/>
											</Grid>
										)}

										{watchDataPeriodType == '4' && (
											<>
												<Grid item xs={5.5}>
													<AppDatePicker
														control={methods.control}
														name='period.start'
														label={t('common.startDate')}
														maximumDate={watchDatePeriodEnd}
													/>
												</Grid>
												<Grid item xs={1}>
													<Typography
														textAlign='center'
														alignContent='center'
														height='100%'
													>
														—
													</Typography>
												</Grid>
												<Grid item xs={5.5}>
													<AppDatePicker
														control={methods.control}
														name='period.end'
														label={t('common.endDate')}
														minimumDate={watchDatePeriodStart}
													/>
												</Grid>
											</>
										)}
									</>
								)}
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.download')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ExportReportModal;
