import { useTranslation } from 'react-i18next';

export function useMentalHealthOptions(options: any[]) {
	const { t } = useTranslation();
	const listMap: Record<string, any[]> = {
		statusList: [
			{ id: 5, name: t('mentalHealthInfo.isListed') },
			{ id: 6, name: t('mentalHealthInfo.isPulled') },
		],
		articleTypeList: [
			{ id: 0, name: t('mentalHealthInfo.plainText') },
			{ id: 1, name: t('mentalHealthInfo.moreImage') },
			{ id: 2, name: t('mentalHealthInfo.pdfFile') },
			{ id: 3, name: t('mentalHealthInfo.movie') },
			{ id: 4, name: t('mentalHealthInfo.audio') },
		],
		categoryList: [
			{ id: 1, name: t('mentalHealthInfo.latestNews') },
			{ id: 2, name: t('mentalHealthInfo.knowMentalHealth') },
			{ id: 3, name: t('mentalHealthInfo.lifeStorySharing') },
			{ id: 4, name: t('mentalHealthInfo.columnSharing') },
		],
		subCategoryList: [
			{ id: 1, name: t('mentalHealthInfo.schizophrenia') },
			{ id: 2, name: t('mentalHealthInfo.psychosis') },
			{ id: 3, name: t('mentalHealthInfo.tristimania') },
			{ id: 4, name: t('mentalHealthInfo.anxiety') },
			{ id: 5, name: t('mentalHealthInfo.bipolar') },
			{ id: 6, name: t('mentalHealthInfo.paranoic') },
			{ id: 7, name: t('mentalHealthInfo.compulsive') },
			{ id: 8, name: t('mentalHealthInfo.personalityDisorder') },
			{ id: 9, name: t('mentalHealthInfo.others') },
		],
	};
	return options.reduce((result: Record<string, any[]>, type) => {
		if (listMap[type]) {
			result[type] = listMap[type];
		}
		return result;
	}, {});
}
