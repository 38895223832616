import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import {
	Badge,
	BadgeProps,
	Button,
	Grid,
	Stack,
	Tab,
	Tabs,
	Typography,
	styled,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AppMultipleDropDownMenu from '@/common/components/form-inputs/AppMultipleDropDownMenu';
import AppSummarySearchBar from '@/common/components/form-inputs/AppSummarySearchBar';
import { useRouteMatch } from '@/utils/routeHelper';
import { Link, useLocation } from 'react-router-dom';
import {
	ArticlesCountRes,
	MentalHealthInfoFilter,
} from '@/api/DTO/mentalHealthInfo/mentalHealthInfo.interface';
import { ArticleMenu, ArticlestatusType } from '@/api/enum/common.enum';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		position: 'relative',
		top: 12,
		backgroundColor: '#FFDAD6',
		color: '#FF5449',
	},
	'&.badge-active .MuiBadge-badge': {
		backgroundColor: '#FF5449',
		color: '#fff',
	},
}));

interface FilterSectionProps {
	districtsOptions: any;
	articleTypeList: any;
	menuCode: string;
	setSelectedId: any;
	statusType: number | string;
	statusList: { id: number; name: string }[];
	pageCount: ArticlesCountRes;
}

export default function MentalHealthInfoSummaryFilterSection(
	prop: FilterSectionProps
) {
	const {
		districtsOptions,
		articleTypeList,
		menuCode,
		setSelectedId,
		pageCount,
		statusType,
		statusList,
	} = prop;
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const { control, watch, resetField } =
		useFormContext<MentalHealthInfoFilter>();

	// You need to provide the routes in descendant order.
	// This means that if you have nested routes like:
	// users, users/new, users/edit.
	// Then the order should be ['users/add', 'users/edit', 'users'].
	const routeMatch = useRouteMatch(pathname, [
		'/mental-health-info/latest-news/drafts',
		'/mental-health-info/latest-news/director-approval',
		'/mental-health-info/latest-news/supervisor-approval',
		'/mental-health-info/latest-news/awaiting-release',
		'/mental-health-info/latest-news/released',
		'/mental-health-info/latest-news/removed',

		'/mental-health-info/know-mental-health/drafts',
		'/mental-health-info/know-mental-health/director-approval',
		'/mental-health-info/know-mental-health/supervisor-approval',
		'/mental-health-info/know-mental-health/awaiting-release',
		'/mental-health-info/know-mental-health/released',
		'/mental-health-info/know-mental-health/removed',

		'/mental-health-info/story-share/drafts',
		'/mental-health-info/story-share/director-approval',
		'/mental-health-info/story-share/supervisor-approval',
		'/mental-health-info/story-share/awaiting-release',
		'/mental-health-info/story-share/released',
		'/mental-health-info/story-share/removed',

		'/mental-health-info/column-share/drafts',
		'/mental-health-info/column-share/director-approval',
		'/mental-health-info/column-share/supervisor-approval',
		'/mental-health-info/column-share/awaiting-release',
		'/mental-health-info/column-share/released',
		'/mental-health-info/column-share/removed',
	]);

	const currentTab = routeMatch?.pattern?.path;

	const resetFilterRHFState = () => {
		resetField('districts');
		resetField('types');
	};

	const clearAllFilter = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();
		resetFilterRHFState();
	};

	// reset button
	const watchDistrict = watch('districts');
	const watchType = watch('types');

	const isDistrictDirty =
		Array.isArray(watchDistrict) && watchDistrict.length !== 0;
	const isTypeDirty = Array.isArray(watchType) && watchType.length !== 0;
	const isFilterDirty = isDistrictDirty || isTypeDirty;

	const tabList = [
		{
			name: 'draft',
			tabText: t('tabs.draft'),
			tabValue: `/mental-health-info/${menuCode}/drafts`,
			tabTo: `/mental-health-info/${menuCode}/drafts`,
			countKey: 'draftCount',
		},
		{
			name: 'awaitingDirectorApproval',
			tabText: t('tabs.awaitingDirectorApproval'),
			tabValue: `/mental-health-info/${menuCode}/director-approval`,
			tabTo: `/mental-health-info/${menuCode}/director-approval`,
			countKey: 'secondDraftCount',
		},
		{
			name: 'awaitingSupervisorApproval',
			tabText: t('tabs.awaitingSupervisorApproval'),
			tabValue: `/mental-health-info/${menuCode}/supervisor-approval`,
			tabTo: `/mental-health-info/${menuCode}/supervisor-approval`,
			countKey: 'thirdDraftCount',
		},
		{
			name: 'awaitingRelease',
			tabText: t('tabs.awaitingRelease'),
			tabValue: `/mental-health-info/${menuCode}/awaiting-release`,
			tabTo: `/mental-health-info/${menuCode}/awaiting-release`,
			countKey: 'readyCount',
		},
		{
			name: 'released',
			tabText: t('tabs.released'),
			tabValue: `/mental-health-info/${menuCode}/released`,
			tabTo: `/mental-health-info/${menuCode}/released`,
			countKey: 'publishCount',
		},
		{
			name: 'removed',
			tabText: t('tabs.removed'),
			tabValue: `/mental-health-info/${menuCode}/removed`,
			tabTo: `/mental-health-info/${menuCode}/removed`,
			countKey: 'pullCount',
		},
	];

	const handleTabChange = () => {
		setSelectedId([]);
	};
	return (
		<Stack>
			<Tabs
				value={currentTab}
				sx={{ borderBottom: 1, borderColor: 'divider' }}
				onChange={handleTabChange}
			>
				{currentTab &&
					tabList.map((item) => (
						<Tab
							key={item.name}
							label={
								<>
									{Number(pageCount[item.countKey]) > 0 ? (
										<StyledBadge
											badgeContent={pageCount[item.countKey]}
											className={
												currentTab.indexOf(item.name) > -1 ? 'badge-active' : ''
											}
										>
											<Typography variant='body1'>
												&nbsp;{item.tabText}
											</Typography>
										</StyledBadge>
									) : (
										<Typography variant='body1'>{item.tabText}</Typography>
									)}
								</>
							}
							value={item.tabValue}
							to={item.tabTo}
							component={Link}
						/>
					))}
			</Tabs>

			<Grid container spacing={3} marginTop={0}>
				<Grid item xs={3}>
					<AppSummarySearchBar
						autoMinWidth={true}
						name='searchKey'
						control={control}
						placeholder={t('common.pleaseEnterKeywordSearch')}
					/>
				</Grid>
				{menuCode == ArticleMenu.latestNews && (
					<Grid item xs={2}>
						<AppMultipleDropDownMenu
							control={control}
							name='districts'
							label={t('common.district')}
							options={districtsOptions}
							size='small'
							emptyArrayIfNoSelection
						/>
					</Grid>
				)}
				<Grid item xs={2}>
					<AppMultipleDropDownMenu
						control={control}
						name='types'
						label={t('common.format')}
						options={articleTypeList}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>

				<Grid item xs>
					<Button
						variant='text'
						startIcon={<RotateLeftOutlinedIcon />}
						disabled={!isFilterDirty}
						onClick={clearAllFilter}
					>
						{t('button.reset')}
					</Button>
				</Grid>
			</Grid>
		</Stack>
	);
}
