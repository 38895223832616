/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Checkbox,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextFieldProps,
	Typography,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import {
	FieldPath,
	FieldValues,
	UseControllerProps,
	useController,
} from 'react-hook-form';
import { Option } from '../../../api/DTO/common.interface';

interface AppMultipleDropDownMenuProps {
	label?: string;
	options: Option[];
	infoPopUp?: ReactNode;
	emptyArrayIfNoSelection?: boolean;
	selectAllOptions?: {
		isSelectAll: boolean;
		text: string;
	};
	renderValue?: string;
}

export default function AppMultipleDropDownMenu<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	options,
	infoPopUp,
	disabled,
	placeholder,
	required,
	emptyArrayIfNoSelection,
	selectAllOptions,
	renderValue,
	...props
}: AppMultipleDropDownMenuProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;
	const [selectAll, setSelectAll] = useState(false);

	function handleSelectAll(checkedName: any) {
		if (checkedName.length == 1 && checkedName.includes(-1)) {
			setSelectAll(true);
			field.onChange(options.map((option) => option.id));
		} else if (checkedName.length > options.length) {
			setSelectAll(false);
			field.onChange([]);
		} else if (checkedName.length <= options.length && checkedName.length > 1) {
			setSelectAll(true);
			field.onChange(options.map((option) => option.id));
		} else if (!selectAll) {
			setSelectAll(true);
			field.onChange(options.map((option) => option.id));
		} else if (selectAll) {
			setSelectAll(false);
			field.onChange([]);
		}
	}
	function handleSelect(checkedName: any) {
		if (checkedName.includes(-1)) {
			handleSelectAll(checkedName);
		} else if (checkedName.length == 0) {
			emptyArrayIfNoSelection ? field.onChange([]) : field.onChange(null);
		} else {
			field.onChange(checkedName);
		}
	}

	return (
		<FormControl
			error={!!fieldState.error?.message}
			fullWidth
			size={props.size}
		>
			<InputLabel>{label}</InputLabel>
			<Select
				{...field}
				variant='outlined'
				label={label}
				multiple
				onChange={(e) => {
					handleSelect(e.target.value);
				}}
				value={!field.value ? [] : field.value} // to fix `value` prop on `input` should not be null
				disabled={disabled}
				renderValue={(selected) => {
					if (selected.length >= options.length && renderValue)
						return renderValue;
					const optionsMap = (options || []).reduce(
						(map: Record<string, any>, option) => {
							map[option.id] = option.name;
							return map;
						},
						{}
					);
					const arr = (selected || []).map(
						(item: any) => optionsMap[item] || ''
					);
					return arr.join(',');
				}}
				sx={{
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
				}}
			>
				{selectAllOptions?.isSelectAll && options.length > 0 && (
					<MenuItem key='selectAll' value={-1}>
						<Stack direction={'row'} alignItems={'center'}>
							<Checkbox
								checked={
									Array.isArray(field.value) &&
									field.value.length === options.length
								}
								indeterminate={
									!Array.isArray(field.value) ||
									(Array.isArray(field.value) &&
										field.value.length !== options.length &&
										field.value.includes(-1))
								}
							/>
							<Typography variant='body1' color='black'>
								{selectAllOptions.text}
							</Typography>
						</Stack>
					</MenuItem>
				)}

				{options.map((option) => (
					<MenuItem key={option.id} value={option.id}>
						<Stack direction={'row'} alignItems={'center'}>
							<Checkbox checked={field.value?.indexOf(option.id) > -1} />
							<Typography
								variant='body1'
								color={option.color ? option.color : 'black'}
							>
								{option.name}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{errorMessage}</FormHelperText>
		</FormControl>
	);
}
