import { apiSlice } from '../apiSlice';
import { SuccessResponse } from '../DTO/common.interface';
import { ReportDTO } from '../DTO/report/report.interface';

export const reportApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// getReport: builder.mutation<SuccessResponse<any>, ReportDTO>({
		// 	query: (body) => ({
		// 		url: `/web/reports/fetch`,
		// 		method: 'POST',
		// 		body,
		// 		// responseHandler: async (response) => {
		// 		// 	const blob = await response.blob();
		// 		// 	console.log(12313, blob, response);
		// 		// 	const filename = 'report.xlsx';
		// 		// 	return blob;
		// 		// },
		// 		// transformResponse: (response: any) => response,
		// 		// providesTags: () => [{}],
		// 		responseHandler: async (response) =>
		// 			window.location.assign(
		// 				window.URL.createObjectURL(await response.blob())
		// 			),
		// 		cache: 'no-cache',
		// 	}),
		// }),
		getReport: builder.mutation({
			queryFn: async (body, api, extraOptions, baseQuery) => {
				const result: any = await baseQuery({
					url: `/web/reports/fetch`,
					method: 'POST',
					body,
					responseHandler: (response) => {
						return response.blob();
					},
				});

				const disposition = result.meta.response.headers.get(
					'Content-Disposition'
				);
				const hiddenElement = document.createElement('a');
				const url = window.URL || window.webkitURL;
				const blobPDF = url.createObjectURL(result.data);
				hiddenElement.href = blobPDF;
				hiddenElement.target = '_blank';
				hiddenElement.download = decodeURIComponent(
					disposition.split('filename=')[1]
				);
				hiddenElement.click();
				return { data: true };
			},
		}),
	}),
});

export const { useGetReportMutation } = reportApiSlice;
