import {
	FilterShopItemReqDTO,
	FilterShopItemResDTO,
} from '@/api/DTO/shop/shop.interface';
import { useFilterShopItemsQuery } from '@/api/shop/shopApiSlice';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../../utils/qsHelper';
import VirtualPropsRewardSummeryFilterSection from './components/VirtualPropsRewardSummeryFilterSection';
import dayjs from 'dayjs';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '@/utils/dateHelper';

export interface VirtualPropsRewardDataGrid {
	id: string;
	name: string;
	coin: number;
	categoryName: string;
	publishedAt: string;
}

export default function VirtualPropsRewardSummaryPage() {
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			read: ['read', 'item-list'],
		},
	});

	const defaultVirtualPropsRewardFilter: FilterShopItemReqDTO = {
		pagination: initialPagination,
		sortings: [],
		searchKey: '',
		subcategoryIds: undefined,
	};

	const defaultValues: DefaultValues<FilterShopItemReqDTO> =
		defaultVirtualPropsRewardFilter;

	const methods = useForm<FilterShopItemReqDTO>({
		defaultValues,
	});

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const transformedSearchParams: FilterShopItemReqDTO = {
		...defaultVirtualPropsRewardFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'searchKey',
			'subcategoryIds',
		]),
	};

	const { data: shopItemsData, isSuccess: isGetShopItemsSuccess } =
		useFilterShopItemsQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
			skip: !userRoles.read,
		});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const virtualPropsRewardFilterFormResult = value as FilterShopItemReqDTO;

			// append to url to reload page
			setSearchParams(qs.stringify(virtualPropsRewardFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: FilterShopItemResDTO[]) => {
		if (response == null) return;
		const newResponse: VirtualPropsRewardDataGrid[] = [];
		response.forEach((item, index) => {
			newResponse.push({
				id: index.toString(),
				name: item.name,
				coin: item.coin,
				categoryName: item.categoryName,
				publishedAt: !item.publishedAt
					? '-'
					: dayjs(item.publishedAt)
							.locale('zh-hk-my-setting')
							.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: t('common.name'),
			flex: 25,
		},
		{
			field: 'coin',
			headerName: t('item.price(coin)'),
			flex: 25,
		},
		{
			field: 'categoryName',
			headerName: t('common.classify'),
			flex: 25,
		},
		{
			field: 'publishedAt',
			headerName: t('item.shelfTime'),
			flex: 25,
		},
	];

	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>{t('item.virtualPropsReward')}</Typography>
			</Box>
			<Card sx={{ marginTop: 3 }}>
				<CardContent>
					<FormProvider {...methods}>
						<VirtualPropsRewardSummeryFilterSection />

						<Box marginTop={2}>
							<AppDataGrid
								loading={!isGetShopItemsSuccess}
								rows={transformResponse(shopItemsData?.data.items) ?? []}
								columns={columns}
								rowCount={shopItemsData?.data.pagination.totalItems ?? 0}
								getRowId={(row) => row.id}
							/>
						</Box>
					</FormProvider>
				</CardContent>
			</Card>
		</>
	);
}
