import { Box, Card, CardContent, Chip, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StudyCourseForm from './components/StudyCourseForm';
import { FormType } from '@/api/enum/formType.enum';
import { useMemo, useState } from 'react';
import { useGetCategoriesQuery } from '@/api/studyCourse/studyCourseListApiSlice';
export default function CreateStudyCoursePage() {
	const { t } = useTranslation();
	const [type, setType] = useState<FormType>(FormType.create);

	const { data: categoriesData } = useGetCategoriesQuery(null);
	const typesList = useMemo(() => {
		return (categoriesData?.data || []).map(
			(item: { type: any; desc: any }) => {
				return {
					id: item.type,
					name: item.desc,
				};
			}
		);
	}, [categoriesData]);
	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Box>
					<Typography variant='h4'>
						{t('studyCourse.createStudyCourse')}
					</Typography>
					<Card sx={{ marginTop: 3 }}>
						<CardContent sx={{ padding: '24px', paddingTop: 0 }}>
							<StudyCourseForm
								type={type}
								setType={setType}
								typesList={typesList}
							/>
						</CardContent>
					</Card>
				</Box>
			</Box>
		</>
	);
}
