import { Card, CardContent, Chip, Grid, IconButton } from '@mui/material';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '@/utils/dateHelper';
import { filterObjectByKeys, parseSummarySearchParams } from '@/utils/qsHelper';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useGetPracticePageListQuery } from '@/api/practiceRecord/practiceRecordApiSlice';
import { PracticeFilter } from '@/api/DTO/practiceRecord/practiceRecord.interface';
import PracticeRecordFilter from './components/PracticeRecordFilter';
import { usePracticeRecordOptions } from '@/hooks/usePracticeRecordOptions';
import { showLabel } from '@/utils/helper';

export default function PracticeRecordPage() {
	const { t } = useTranslation();
	const { userId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const { statusList } = usePracticeRecordOptions(['statusList']);

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			send: ['send', 'user'],
		},
	});

	const defaultPageFilter: PracticeFilter = {
		pagination: initialPagination,
		sortings: [],
		statuses: [],
		userId: userId || '',
	};

	const defaultValues: DefaultValues<PracticeFilter> = defaultPageFilter;

	const methods = useForm<any>({
		defaultValues,
	});

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const transformedSearchParams: PracticeFilter = {
		...defaultPageFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'statuses',
			'userId',
		]),
	};

	const { data: pageSummary, isSuccess: isPageSummarySuccess } =
		useGetPracticePageListQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
			skip: !userId,
		});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const pageFilterFormResult = value as PracticeFilter;

			// validate form and continue if form is valid
			methods.handleSubmit(() => {
				// append to url to reload page
				setSearchParams(qs.stringify(pageFilterFormResult));
			})();
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: any[]) => {
		if (response == null) return;
		const newResponse: any[] = [];
		response.forEach((item, index) => {
			newResponse.push({
				...item,
				statusShow: showLabel(item.status, statusList),
				pushedAt: dayjs(item.pushedAt)
					.locale('zh-hk-my-setting')
					.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'pushedAt',
			headerName: t('practiceRecord.sendInfoTime'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'categoryShow',
			headerName: t('practiceRecord.exerciseCategory'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'title',
			headerName: t('practiceRecord.exerciseTitle'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'markMethodShow',
			headerName: t('practiceRecord.exerciseMethod'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'statusShow',
			headerName: t('common.status'),
			flex: 1,
			minWidth: 200,
			renderCell: (params) => (
				<Chip
					label={params.row.statusShow}
					color={
						params.row.status == 1
							? 'chipGrey'
							: params.row.status == 3
							? 'chipPrimary'
							: params.row.status == 4
							? 'chipRed'
							: params.row.status == 5
							? 'chipGreen'
							: params.row.status == 6
							? 'chipLightGrey'
							: 'chipGrey'
					}
					variant='outlined'
				/>
			),
		},
		{
			field: 'edit',
			headerName: '',
			width: 68,
			renderCell: (params) => (
				<Link
					to={`/users/member-user/${userId}/practice-record/practice-record-detail/${params.row.exerciseId}`}
					state={{
						orgUserPageSummarySearchParams: qs.stringify(
							transformedSearchParams
						),
					}}
				>
					<IconButton color='primary'>
						<VisibilityOutlinedIcon />
					</IconButton>
				</Link>
			),
		},
	];

	const pageContent = (
		<>
			{userRoles.send && (
				<Grid container alignItems='stretch' columnSpacing={3} marginTop={2}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<FormProvider {...methods}>
									<form>
										<Grid
											container
											rowSpacing={2}
											columnSpacing={1}
											marginBottom={'16px'}
										>
											<Grid item xs={12}>
												<PracticeRecordFilter statusList={statusList} />
											</Grid>
										</Grid>
										<AppDataGrid
											loading={!isPageSummarySuccess}
											rows={transformResponse(pageSummary?.data?.items) ?? []}
											columns={columns}
											rowCount={pageSummary?.data?.pagination.totalItems ?? 0}
											getRowId={(row) => row.exerciseId}
										/>
									</form>
								</FormProvider>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			)}
		</>
	);

	return <div>{pageContent}</div>;
}
