import { VoucherRedeemStatus } from '@/api/enum/voucher.enum';
import {
	useGetVoucherQuery,
	useRedeemVoucherMutation,
} from '@/api/shop/shopApiSlice';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import AuthCard from '../auth/components/AuthCard';
import AuthContainer from '../auth/components/AuthContainer';
import AppFieldView, {
	AppFormLabel,
} from '@/common/components/form-view/AppFieldView';
import FullScreenLoader from '@/common/components/FullScreenLoader';
import getVoucherStatusChip from './components/VoucherStatusChip';

function VouchersPage() {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const [redeemSuccess, setRedeemSuccess] = useState(false);

	const redeemCode = searchParams.get('redeem-code') ?? '';

	const { data: voucherData, isLoading } = useGetVoucherQuery(redeemCode, {
		refetchOnMountOrArgChange: true,
		skip: !redeemCode,
	});
	const [redeemVoucher] = useRedeemVoucherMutation();

	const handleRedeem = async () => {
		if (!redeemCode) return;
		const response = await redeemVoucher(redeemCode).unwrap();

		if ('data' in response) {
			setRedeemSuccess(true);
		}
	};

	return (
		<AuthContainer>
			<AuthCard title={t('voucher.redeemVoucher')} message={''}>
				<Stack spacing={2} sx={{ mt: 2 }}>
					{isLoading ? (
						<FullScreenLoader />
					) : voucherData ? (
						<>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12}>
									<Typography>{t('voucher.voucherDetail')}</Typography>
								</Grid>
								<Grid item xs={12}>
									<AppFieldView
										label={t('common.username')}
										value={voucherData.data.username}
									/>
								</Grid>
								<Grid item xs={12}>
									<AppFieldView
										label={t('common.nickname')}
										value={voucherData.data.nickname}
									/>
								</Grid>
								<Grid item xs={12}>
									<AppFieldView
										label={t('item.couponName')}
										value={voucherData.data.voucherName}
									/>
								</Grid>
								<Grid item xs={12}>
									<AppFormLabel>{t('common.status')}</AppFormLabel>

									<Box>{getVoucherStatusChip(voucherData.data.status, t)}</Box>
								</Grid>
							</Grid>
							{!redeemSuccess && !voucherData?.data.isValid && (
								<Alert severity='warning' sx={{ marginTop: 2 }}>
									<AlertTitle sx={{ margin: 0 }}>
										{t('voucher.voucherNotExist')}
									</AlertTitle>
								</Alert>
							)}
							{redeemSuccess && (
								<Alert severity='success' sx={{ marginTop: 2 }}>
									<AlertTitle sx={{ margin: 0 }}>
										{t('voucher.voucherRedeemed')}
									</AlertTitle>
								</Alert>
							)}

							<Button
								variant='contained'
								onClick={handleRedeem}
								disabled={!voucherData?.data.isValid}
							>
								{t('button.redeem')}
							</Button>
						</>
					) : (
						<Alert severity='warning' sx={{ marginTop: 2 }}>
							<AlertTitle sx={{ margin: 0 }}>
								{t('voucher.voucherNotExist')}
							</AlertTitle>
						</Alert>
					)}
				</Stack>
			</AuthCard>
		</AuthContainer>
	);
}

export default VouchersPage;
