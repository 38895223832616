import { UserListOfCashCouponFilter } from '@/api/DTO/item.interface';
import AppMultipleDropDownMenu from '@/common/components/form-inputs/AppMultipleDropDownMenu';
import AppSummarySearchBar from '@/common/components/form-inputs/AppSummarySearchBar';
import { Button, Grid, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';

export default function UserListOfCashCouponSummeryFilterSection() {
	const { t } = useTranslation();
	const { control, watch, resetField } =
		useFormContext<UserListOfCashCouponFilter>();

	const resetFilterRHFState = () => {
		resetField('voucherRedeemStatuses');
		resetField('searchKey');
	};

	const clearAllFilter = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();
		resetFilterRHFState();
	};

	// reset button
	const watchRedeemStatus = watch('voucherRedeemStatuses');
	const watchSearchKey = watch('searchKey');

	const isRedeemStatusDirty = watchRedeemStatus && watchRedeemStatus.length > 0;
	const isSearchKeyDirty =
		watchSearchKey !== undefined && watchSearchKey !== '';
	const isFilterDirty = isRedeemStatusDirty || isSearchKeyDirty;

	return (
		<Stack spacing={2}>
			<Grid container spacing={3}>
				<Grid item xs={4}>
					<AppSummarySearchBar
						name='searchKey'
						control={control}
						placeholder={t('common.pleaseEnterKeywordSearch')}
					/>
				</Grid>
				<Grid item xs={2.5}>
					<AppMultipleDropDownMenu
						control={control}
						name='voucherRedeemStatuses'
						label={t('item.redeemStatus')}
						options={[
							{ id: 1, name: t('item.toBeRedeemed') },
							{ id: 2, name: t('item.redeemed') },
							{ id: 3, name: t('item.expired') },
						]}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>
				<Grid item xs>
					<Button
						variant='text'
						startIcon={<RotateLeftOutlinedIcon />}
						disabled={!isFilterDirty}
						onClick={clearAllFilter}
					>
						{t('button.reset')}
					</Button>
				</Grid>
			</Grid>
		</Stack>
	);
}
