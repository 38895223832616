import { useTranslation } from 'react-i18next';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import LinearScaleOutlinedIcon from '@mui/icons-material/LinearScaleOutlined';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import { ChapterType } from '@/api/enum/studyCourse.enum';

export function useStudyCourseOptions(options: any[]) {
	const { t } = useTranslation();
	const listMap: Record<string, any> = {
		statusList: [
			{ id: 1, name: t('common.draft') },
			{ id: 2, name: t('common.directorApproval') },
			{ id: 3, name: t('common.supervisorApproval') },
			{ id: 4, name: t('common.toBeListed') },
			{ id: 5, name: t('common.isListed') },
			{ id: 6, name: t('common.isPulled') },
		],
		chapterTypeList: [
			{ id: ChapterType.KnowledgeTest, name: t('studyCourse.knowledgeTest') },
			{
				id: ChapterType.PerceptionAssessment,
				name: t('studyCourse.perceptionAssessment'),
			},
			{
				id: ChapterType.EmpiricalReflection,
				name: t('studyCourse.empiricalReflection'),
			},
		],
		questionTypeList: [
			{
				id: 1,
				name: t('studyCourse.radio'),
				icon: <RadioButtonCheckedIcon />,
			},
			{
				id: 2,
				name: t('studyCourse.multiple'),
				icon: <CheckBoxOutlinedIcon />,
			},
			{
				id: 3,
				name: t('studyCourse.linearScale'),
				icon: <LinearScaleOutlinedIcon />,
			},
			{
				id: 4,
				name: t('studyCourse.checkGrid'),
				icon: <Grid4x4Icon />,
			},
		],
		statusChipMap: {
			1: { color: 'chipGrey' },
			2: { color: 'chipGrey' },
			3: { color: 'chipPrimary' },
			4: { color: 'chipTertiary' },
			5: { color: 'chipGreen' },
			6: { color: 'chipGrey' },
		},
		typesChipMap: {
			1: { color: 'chipCloth' },
			2: { color: 'chipFood' },
			3: { color: 'chipLive' },
			4: { color: 'chipWalk' },
			5: { color: 'chipCommunityWelfareInfo' },
		},
	};
	return options.reduce((result: Record<string, any[]>, type) => {
		if (listMap[type]) {
			result[type] = listMap[type];
		}
		return result;
	}, {});
}
