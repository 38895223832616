import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { SubmitHandler, DefaultValues, useForm } from 'react-hook-form';
import AppPasswordTextField from '../../common/components/form-inputs/AppPasswordTextField';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import AuthContainer from './components/AuthContainer';
import { useLoginMutation } from '../../api/authApiSlice';
import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';
import { EmailLoginDTO } from '../../api/DTO/auth.interface';
import AppTextField from '../../common/components/form-inputs/AppTextField';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { Box, IconButton } from '@mui/material';
import AuthCard from './components/AuthCard';

export const defaultValues: DefaultValues<EmailLoginDTO> = {
	email: '',
	password: '',
};

export default function LoginPage() {
	const { t } = useTranslation();

	const formSchema = Yup.object().shape({
		email: Yup.string()
			.required(t('errorMessage.required'))
			.email(t('errorMessage.email')),
		password: Yup.string().required(t('errorMessage.required')),
	});

	const { handleSubmit, control } = useForm<EmailLoginDTO>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const navigate = useNavigate();
	const location = useLocation();
	const from = (location.state?.from.pathname as string) || '/';
	const fromSearchParams = (location.state?.from.search as string) || '';
	const [login] = useLoginMutation();
	const dispatch = useDispatch();

	const onSubmit: SubmitHandler<EmailLoginDTO> = async (formResult) => {
		try {
			const loginResponse = await login(formResult).unwrap();
			dispatch(
				setCredentials({
					accessToken: loginResponse.data.accessToken,
				})
			);
			navigate(from + fromSearchParams);
		} catch (err) {
			console.log('error');
		}
	};

	return (
		<AuthContainer>
			<AuthCard title={t('login.welcomeTitle')} message={''}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Stack alignItems='center' spacing={2} marginTop='40px'>
						<AppTextField
							control={control}
							name='email'
							label={t('common.email')}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											edge='end'
											sx={{ pointerEvents: 'none' }}
										>
											<EmailOutlinedIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>

						<AppPasswordTextField
							control={control}
							name='password'
							label={t('common.password')}
						/>
					</Stack>
					<Box sx={{ display: 'flex', justifyContent: 'end' }}>
						<Link
							fontSize='subtitle2'
							color='primary'
							marginTop={3}
							component={RouterLink}
							to='/forgot-password'
						>
							{t('button.forgotPassword')}
						</Link>
					</Box>
					<Button
						fullWidth
						type='submit'
						variant='contained'
						size='large'
						sx={{
							marginTop: 3,
						}}
					>
						{t('button.signIn')}
					</Button>
				</form>
			</AuthCard>
		</AuthContainer>
	);
}
