import { Box, CircularProgress } from '@mui/material';

export default function FullScreenLoader() {
	return (
		<Box
			sx={{
				position: 'fixed',
				zIndex: 2000,
				right: 0,
				bottom: 0,
				top: 0,
				left: 0,
				background: '#FFFFFFCC',
			}}
		>
			<Box
				display='flex'
				alignItems='center'
				justifyContent='center'
				sx={{ height: '100%' }}
			>
				<CircularProgress />
			</Box>
		</Box>
	);
}
