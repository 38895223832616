import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import { createRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface Props {
	image: {
		base64: any;
		name: string;
	} | null;
	isOpen: boolean;
	option?: any;
	handleCropper: any;
}

const ImageCropper = (props: Props) => {
	const { image, isOpen = false, option, handleCropper } = props;
	const { t } = useTranslation();
	const cropperRef = createRef<ReactCropperElement>();

	const onCrop = (type: string) => {
		if (type == 'submit') {
			if (typeof cropperRef.current?.cropper !== 'undefined') {
				cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob: any) => {
					const file = new File([blob], image?.name || '', { type: blob.type });
					handleCropper(file);
				});
			}
		} else {
			handleCropper();
		}
	};

	return (
		<Dialog
			open={isOpen}
			onClose={() => onCrop('cancel')}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			maxWidth='md'
			fullWidth
		>
			<DialogTitle variant='h6'>{t('common.cropper')}</DialogTitle>
			<IconButton
				aria-label='close'
				onClick={() => onCrop('cancel')}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<Close />
			</IconButton>
			<DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
				<Cropper
					ref={cropperRef}
					style={{ width: '100%', minHeight: '600px' }}
					src={image?.base64}
					{...option}
				/>
			</DialogContent>
			<DialogActions>
				<Button type='submit' onClick={() => onCrop('cancel')}>
					{t('button.cancel')}
				</Button>
				<Button
					type='submit'
					variant='contained'
					onClick={() => onCrop('submit')}
				>
					{t('button.confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ImageCropper;
