import {
	FieldValues,
	FieldPath,
	UseControllerProps,
	useController,
} from 'react-hook-form';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

interface AppSummarySearchBarProps {
	placeholder?: string;
	autoMinWidth?: boolean;
}

export default function AppSummarySearchBar<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	placeholder,
	autoMinWidth,
	...props
}: AppSummarySearchBarProps & UseControllerProps<TFieldValues, TName>) {
	const { field } = useController(props);
	const searchKey = field.value;
	const searchKeyOnChange = field.onChange;

	const [text, setText] = useState<string>(field.value);

	useEffect(() => {
		setText(searchKey);
	}, [searchKey]);

	const clearButtonOnClick = () => {
		setText('');
		searchKeyOnChange(undefined);
	};

	const searchButtonOnClick = () => {
		searchKeyOnChange(text);
	};
	return (
		<>
			<OutlinedInput
				sx={{ width: '100%', minWidth: !autoMinWidth ? '342px' : 'auto' }}
				onChange={(e) => {
					setText(e.target.value);
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						searchButtonOnClick();
					}
				}}
				value={text ?? ''}
				size='small'
				placeholder={placeholder}
				startAdornment={
					<InputAdornment position='start'>
						<SearchIcon />
					</InputAdornment>
				}
				endAdornment={
					<InputAdornment position='end'>
						{searchKey && (
							<IconButton type='button' onClick={clearButtonOnClick}>
								<CloseIcon />
							</IconButton>
						)}
					</InputAdornment>
				}
			/>
		</>
	);
}
