import { yupResolver } from '@hookform/resolvers/yup';
import Close from '@mui/icons-material/Close';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
} from '@mui/material';
import { useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppPasswordTextField from '../form-inputs/AppPasswordTextField';
import { ADMIN_PASSWORD_PATTERN } from '@/common/constants/pattern';
import { useChangePasswordMutation } from '@/api/authApiSlice';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
interface ChangePasswordModalProps {
	userId: string;
	disabled?: boolean;
}

const ChangePasswordModal = (props: ChangePasswordModalProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [changePassword] = useChangePasswordMutation();

	const defaultValues: DefaultValues<any> = {};

	const formSchema = Yup.object().shape({
		oldPassword: Yup.string().required(t('errorMessage.required')),
		newPassword: Yup.string()
			.required(t('errorMessage.required'))
			.matches(ADMIN_PASSWORD_PATTERN, t('errorMessage.passwordMin8')),
		password: Yup.string()
			.required(t('errorMessage.required'))
			.oneOf([Yup.ref('newPassword')], t('errorMessage.unlikePassword')),
	});

	const methods = useForm<any>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	// useEffect(() => {
	// 	methods.setValue('userId', props.userId);
	// }, [props.userId]);

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit: SubmitHandler<any> = async (data) => {
		const response = await changePassword(data);
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.changePasswordSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
		}
	};

	return (
		<>
			<MenuItem
				onClick={() => {
					setOpen(true);
				}}
				disabled={props.disabled}
			>
				<ListItemIcon>
					<VpnKeyOutlinedIcon fontSize='small' />
				</ListItemIcon>
				<ListItemText>{t('button.changePassword')}</ListItemText>
			</MenuItem>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='xs'
				fullWidth
				onKeyDown={(e) => {
					if (e.key === 'Tab') {
						e.stopPropagation();
					}
				}}
			>
				<DialogTitle variant='h5'>{t('common.changePassword')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0} marginBottom={2}>
								<Grid item xs={12}>
									<AppPasswordTextField
										control={methods.control}
										name='oldPassword'
										label={t('common.currentPassword')}
									/>
								</Grid>
								<Grid item xs={12}>
									<AppPasswordTextField
										control={methods.control}
										name='newPassword'
										label={t('common.entreYourNewPassword')}
									/>
								</Grid>
								<Grid item xs={12}>
									<AppPasswordTextField
										control={methods.control}
										name='password'
										label={t('common.confirmNewPassword')}
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.save')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ChangePasswordModal;
