import { UpdateUserType } from '@/api/DTO/user/user.interface';
import { UserType, userTypeOptions } from '@/api/enum/userType.enum';
import { useGetStaffListQuery } from '@/api/staff/staffApiSlice';
import {
	useEditUserTypeInfoMutation,
	useEditUserTypeMutation,
} from '@/api/user/userApiSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import Close from '@mui/icons-material/Close';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { closeSnackbar, useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppDropDownMenu from '../form-inputs/AppDropDownMenu';
import AppRadioGroup from '../form-inputs/AppRadioGroup';
import AppTextField from '../form-inputs/AppTextField';
import CloseIcon from '@mui/icons-material/Close';

interface EditUserTypeModalProps {
	userId: string;
	userType?: UserType;
	level?: number;
	serviceNumber?: string;
	staffId: string;
}

const EditUserTypeModal = (props: EditUserTypeModalProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const { data: getStaffList } = useGetStaffListQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});

	const { userId, userType, level, serviceNumber, staffId } = props;

	const [editUserType] = useEditUserTypeMutation();
	const [editUserTypeInfo] = useEditUserTypeInfoMutation();

	const defaultValues: DefaultValues<UpdateUserType> = {
		userType: userType ?? UserType.PROGRAM_USER,
		serviceNumber: serviceNumber ?? '',
		level: level,
		staffId: staffId,
	};

	const formSchema = Yup.object().shape({
		serviceNumber: Yup.string()
			.nullable()
			.when('userType', {
				is: UserType.PROGRAM_USER,
				then: (schema) => schema.required(t('errorMessage.required')),
			}),
		staffId: Yup.string()
			.nullable()
			.when('userType', {
				is: UserType.PROGRAM_USER,
				then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
			}),
		level: Yup.number()
			.nullable()
			.when('userType', {
				is: UserType.PROGRAM_USER,
				then: (schema) => schema.oneOf([1, 2], t('errorMessage.pleaseSelect')),
			}),
	});

	const methods = useForm<UpdateUserType>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		methods.setValue('userId', userId);
	}, [userId]);

	const handleClose = () => {
		setOpen(false);
	};

	const watchUserType = methods.watch('userType');

	useEffect(() => {
		if (watchUserType == '1') {
			methods.setValue('userType', 1);
			methods.setValue('level', undefined);
			methods.setValue('serviceNumber', undefined);
			methods.setValue('staffId', undefined);
		} else if (watchUserType == '2') {
			methods.setValue('userType', 2);
			methods.resetField('level');
			methods.resetField('serviceNumber');
			methods.resetField('staffId');
		}
	}, [watchUserType]);

	const onSubmit: SubmitHandler<UpdateUserType> = async (data) => {
		let response;
		if (userType == 2 && data.userType == 2) {
			response = await editUserTypeInfo(data);
		} else {
			response = await editUserType(data);
		}
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.editUserTypeSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
		}
	};

	return (
		<>
			<IconButton color='primary' onClick={() => setOpen(true)}>
				<CreateOutlinedIcon sx={{ cursor: 'pointer' }} />
			</IconButton>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='xs'
				fullWidth
			>
				<DialogTitle variant='h5'>{t('common.editUserCategory')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12}>
									<Typography variant='body2' color='common.neutral40'>
										{t('common.userCategory')}
									</Typography>
									<AppRadioGroup
										control={methods.control}
										name='userType'
										row
										options={userTypeOptions}
									/>
								</Grid>
								{watchUserType == UserType.PROGRAM_USER && (
									<>
										<Grid item xs={12}>
											<Typography variant='body1'>
												{t('common.fillInInfoMsg')}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<AppTextField
												control={methods.control}
												name='serviceNumber'
												label={t('common.serviceNumber')}
												maxLength={31}
											/>
										</Grid>
										<Grid item xs={12}>
											<Typography variant='body2' color='common.neutral40'>
												{t('common.level')}
											</Typography>
											<AppRadioGroup
												control={methods.control}
												name='level'
												row
												options={[
													{ id: 1, name: '1' },
													{ id: 2, name: '2' },
												]}
											/>
										</Grid>
										<Grid item xs={12}>
											<AppDropDownMenu
												control={methods.control}
												name='staffId'
												label={t('common.staffInCharge')}
												options={getStaffList?.data ?? []}
												required
											/>
										</Grid>
									</>
								)}
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EditUserTypeModal;
