import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Grid, ListItemText, Menu, MenuItem } from '@mui/material';
import AppMultipleDropDownMenu from '@/common/components/form-inputs/AppMultipleDropDownMenu';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPracticeRecordTemplateListQuery } from '@/api/practiceRecord/practiceRecordApiSlice';
import { PracticeFilter } from '@/api/DTO/practiceRecord/practiceRecord.interface';

interface Props {
	statusList: { id: string; name: string }[];
}

export default function PracticeRecordPageFilter(props: Props) {
	const { statusList } = props;
	const { t } = useTranslation();
	const { control, watch } = useFormContext<PracticeFilter>();
	const navigate = useNavigate();
	const { userId } = useParams();

	const [showSelect, setShowSelect] = useState<null | HTMLElement>(null);
	const open = Boolean(showSelect);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setShowSelect(event.currentTarget);
	};
	const handleClose = () => {
		setShowSelect(null);
	};

	const { data: practiceTemplateData } = useGetPracticeRecordTemplateListQuery(
		null,
		{ refetchOnMountOrArgChange: true }
	);

	const transformResponse = useCallback((response?: any[]) => {
		if (response == null) return;
		const newResponse: any[] = [
			{
				id: -1,
				name: t('practiceRecord.template'),
				disabled: true,
			},
		];
		response.forEach((item) => {
			newResponse.push({
				id: item.id,
				name: item.desc,
			});
		});
		newResponse.push({
			id: -2,
			name: t('common.other'),
			disabled: true,
		});
		newResponse.push({
			id: -3,
			name: t('practiceRecord.customExercise'),
		});

		return newResponse;
	}, []);

	const routeTo = (event: React.MouseEvent<any>) => {
		const id = event.currentTarget.getAttribute('data-id');
		navigate(
			`/users/member-user/${userId}/practice-record/practice-record-form/${id}`
		);
	};

	return (
		<Grid
			container
			rowSpacing={2}
			columnSpacing={1}
			justifyContent='space-between'
		>
			<Grid item xs={2}>
				<AppMultipleDropDownMenu
					control={control}
					name='statuses'
					label={t('common.status')}
					options={statusList}
					size='small'
					emptyArrayIfNoSelection
				/>
			</Grid>
			<Grid item xs={5} sx={{ textAlign: 'right' }}>
				<Button
					component='label'
					variant='text'
					startIcon={<AddIcon />}
					endIcon={<ArrowDropDownIcon />}
					style={{ alignItems: 'center' }}
					aria-controls={open ? 'more-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup='true'
					onClick={handleClick}
				>
					{t('button.distributionExercise')}
				</Button>

				<Menu
					id='more-menu'
					MenuListProps={{
						'aria-labelledby': 'more-button',
					}}
					anchorEl={showSelect}
					open={open}
					onClose={handleClose}
				>
					{(transformResponse(practiceTemplateData?.data) || []).map((item) => (
						<MenuItem
							onClick={routeTo}
							disabled={item.disabled}
							key={item.id}
							data-id={item.id}
						>
							<ListItemText>{item.name}</ListItemText>
						</MenuItem>
					))}
				</Menu>
			</Grid>
		</Grid>
	);
}
