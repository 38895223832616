import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { Fragment, ReactNode, useMemo, useState } from 'react';
import {
	ListItem,
	ListItemProps,
	Badge,
	styled,
	BadgeProps,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BadgeOutlined from '@mui/icons-material/BadgeOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useRouteMatch } from '../../utils/routeHelper';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { ReactComponent as DotIcon } from '@/assets/icon/dot.svg';
import { useGetUser } from '@/hooks/useGetUser';
import { menuPermission } from '@/casl/menuPermission';
import { useGetPageCount } from '@/hooks/useGetPageCount';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

interface ListItemLinkProps extends ListItemProps {
	icon: ReactNode;
	to: string;
	badgeContent?: number;
	permission?: boolean;
}

const listItemNameMap: { [key: string]: string } = {
	'/reports': 'drawerListItems.reportExport',
	'/reports/report-list/*': 'drawerListItems.reportList',
	'/users/member-user/*': 'drawerListItems.memberUser',
	'/users/non-member-user/*': 'drawerListItems.nonMemberUser',
	'/users': 'drawerListItems.userManagement',
	'/users/remove-user': 'drawerListItems.removeUserList',
	'/staffs': 'drawerListItems.staffManagement',
	'/staffs/staff-list': 'drawerListItems.staffList',
	'/items/member-reward/*': 'drawerListItems.programMemberReward',
	'/items/virtual-props-reward/*': 'drawerListItems.virtualPropsReward',
	'/items/user-coupon-list/*': 'drawerListItems.userListOfCashCoupons',
	'/items/user-mission-list/*': 'drawerListItems.userMissionList',
	'/items': 'drawerListItems.rewardManagement',
	'/users/application/*': 'drawerListItems.allApplications',
	'/surveys': 'drawerListItems.surveyManagement',
	'/surveys/survey-list/*': 'drawerListItems.surveyList',
	'/resources': 'drawerListItems.resourcesManagement',
	'/resources/resources-list/*': 'drawerListItems.resourcesList',
	'/study-course': 'drawerListItems.studyCourseManage',
	'/study-course/study-course-list/*': 'drawerListItems.studyCourseList',
	'/mental-health-info': 'drawerListItems.mentalHealthInfoManagement',
	'/mental-health-info/latest-news/*': 'drawerListItems.latestNews',
	'/mental-health-info/know-mental-health/*':
		'drawerListItems.knowMentalHealth',
	'/mental-health-info/story-share/*': 'drawerListItems.lifeStorySharing',
	'/mental-health-info/column-share/*': 'drawerListItems.columnSharing',
};
interface rootMenu {
	permission: boolean;
	icon: ReactNode;
	to: string;
	nestedCollapseList: NestedMenuItem[];
}
interface NestedMenuItem {
	permission: boolean;
	to: string;
	badgeContent?: number;
}

interface ListItemsProps {
	drawerOpen: boolean;
	handleDrawerOpen: () => void;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		position: 'relative',
		top: 10,
		backgroundColor: '#FFDAD6',
		color: '#FF5449',
	},
}));

export default function ListItems({
	drawerOpen,
	handleDrawerOpen,
}: ListItemsProps) {
	const { userInfo } = useGetUser();

	const { t } = useTranslation();
	const { pathname } = useLocation();

	const routeMatch = useRouteMatch(pathname, Object.keys(listItemNameMap));
	const currentTab = routeMatch?.pattern?.path;

	const [openIndex, setOpenIndex] = useState<number[]>([]);

	const {
		applicationPageCount,
		knowMentalHealthPageCount,
		storySharePageCount,
		columnSharePageCount,
		resourcesPageCount,
		vouchersPageCount,
		removeUserPageCount,
		studyCoursePageCount,
	} = useGetPageCount();

	const rootMenus: rootMenu[] = [
		{
			permission: true,
			icon: <InsertChartOutlinedIcon />,
			to: '/reports',
			nestedCollapseList: [
				{
					permission: true,
					to: '/reports/report-list',
				},
			],
		},
		{
			permission: true,
			icon: <AccountCircleOutlinedIcon />,
			to: '/users',
			nestedCollapseList: [
				{
					permission: true,
					to: '/users/member-user',
				},
				{
					permission: true,
					to: '/users/non-member-user',
				},
				{
					permission: true,
					to: '/users/application',
					badgeContent: applicationPageCount,
				},
				{
					permission: true,
					to: '/users/remove-user',
					badgeContent: removeUserPageCount,
				},
			],
		},
		{
			permission: true,
			icon: <RocketLaunchOutlinedIcon />,
			to: '/items',
			nestedCollapseList: [
				{
					permission: true,
					to: '/items/member-reward',
				},
				{
					permission: true,
					to: '/items/virtual-props-reward',
				},
				{
					permission: true,
					to: '/items/user-coupon-list',
					badgeContent: vouchersPageCount,
				},
				{
					permission: true,
					to: '/items/user-mission-list',
				},
			],
		},
		{
			permission: true,
			icon: <FeedOutlinedIcon />,
			to: '/mental-health-info',
			nestedCollapseList: [
				{
					permission: true,
					to: '/mental-health-info/latest-news',
				},
				{
					permission: true,
					to: '/mental-health-info/know-mental-health',
					badgeContent: knowMentalHealthPageCount,
				},
				{
					permission: true,
					to: '/mental-health-info/story-share',
					badgeContent: storySharePageCount,
				},
				{
					permission: true,
					to: '/mental-health-info/column-share',
					badgeContent: columnSharePageCount,
				},
			],
		},
		{
			permission: true,
			icon: <MapOutlinedIcon />,
			to: '/resources',
			nestedCollapseList: [
				{
					permission: true,
					to: '/resources/resources-list',
					badgeContent: resourcesPageCount,
				},
			],
		},
		{
			permission: true,
			icon: <SchoolOutlinedIcon />,
			to: '/study-course',
			nestedCollapseList: [
				{
					permission: true,
					to: '/study-course/study-course-list',
					badgeContent: studyCoursePageCount,
				},
			],
		},
		{
			permission: true,
			icon: <EventNoteOutlinedIcon />,
			to: '/surveys',
			nestedCollapseList: [
				{
					permission: true,
					to: '/surveys/survey-list',
				},
			],
		},
		{
			permission: true,
			icon: <BadgeOutlined />,
			to: '/staffs',
			nestedCollapseList: [
				{
					permission: true,
					to: '/staffs/staff-list',
				},
			],
		},
	];

	const handleOpenIndex = (index: number) => {
		handleDrawerOpen();
		if (drawerOpen && openIndex.includes(index)) {
			setOpenIndex((oldValues) => {
				return oldValues.filter((value) => value !== index);
			});
		} else if (drawerOpen) {
			setOpenIndex([...openIndex, index]);
		} else {
			setOpenIndex([index]);
		}
	};

	function SingleListItem(props: ListItemLinkProps) {
		const { icon, to, sx, badgeContent, permission } = props;
		const pathMatch =
			useRouteMatch(to, Object.keys(listItemNameMap))?.pattern.path ?? '';
		const primary = listItemNameMap[pathMatch];

		const listItemButtonProps = { component: Link, to: to };

		if (permission && menuPermission[userInfo?.role]) {
			const permission = menuPermission[userInfo?.role].find((item: string) => {
				if (
					item === to ||
					(item.endsWith('/*') && to.startsWith(item.slice(0, -2)))
				) {
					return true;
				} else {
					return false;
				}
			});
			if (!permission) return <></>;
		}

		return (
			<>
				<ListItem component='li' disablePadding>
					<ListItemButton
						{...listItemButtonProps}
						selected={currentTab == pathMatch}
						sx={{
							justifyContent: drawerOpen ? 'initial' : 'center',
							px: 2.5,
							...sx,
						}}
					>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: drawerOpen ? 3 : 'auto',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
								color:
									currentTab == pathMatch ? 'primary.main' : 'text.primary',
							}}
						>
							{icon}
							{!drawerOpen && (
								<Typography
									variant='subtitle1'
									style={{
										maxWidth: '60px',
										textAlign: 'center',
										whiteSpace: 'normal',
										display: '-webkit-box',
										WebkitLineClamp: 2,
										WebkitBoxOrient: 'vertical',
										overflow: 'hidden',
									}}
								>
									{t(primary)}
								</Typography>
							)}
						</ListItemIcon>
						<ListItemText
							primary={t(primary)}
							sx={{
								whiteSpace: 'normal',
								display: '-webkit-box',
								WebkitLineClamp: 2,
								WebkitBoxOrient: 'vertical',
								overflow: 'hidden',
								opacity: drawerOpen ? 1 : 0,
								color:
									currentTab == pathMatch ? 'primary.main' : 'text.primary',
							}}
						/>
						{badgeContent !== undefined && badgeContent > 0 && (
							<StyledBadge badgeContent={badgeContent}></StyledBadge>
						)}
					</ListItemButton>
				</ListItem>
			</>
		);
	}

	return (
		<List>
			{rootMenus.map((menu, index) => {
				const pathMatch =
					useRouteMatch(menu.to, Object.keys(listItemNameMap))?.pattern.path ??
					'';
				const primary = listItemNameMap[pathMatch];
				if (menu.permission && menuPermission[userInfo?.role]) {
					const permission = menuPermission[userInfo?.role].find(
						(item: string) => {
							if (
								item === menu.to ||
								(item.endsWith('/*') && menu.to.startsWith(item.slice(0, -2)))
							) {
								return true;
							} else {
								return false;
							}
						}
					);
					if (!permission) return;
				}

				return (
					<Fragment key={index}>
						<ListItem
							component='li'
							onClick={() => handleOpenIndex(index)}
							disablePadding
						>
							<ListItemButton
								sx={{
									justifyContent: drawerOpen ? 'initial' : 'center',
									px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: drawerOpen ? 3 : 'auto',
										justifyContent: 'center',
										alignItems: 'center',
										flexDirection: 'column',
										color: 'text.primary',
									}}
								>
									<Badge
										sx={{
											'& .MuiBadge-badge': {
												backgroundColor: '#FF5449',
											},
										}}
										variant='dot'
										invisible={
											!menu.nestedCollapseList.some(
												(value: { to: string; badgeContent?: number }) => {
													return (
														value.badgeContent !== undefined &&
														value.badgeContent > 0
													);
												}
											)
										}
									>
										{menu.icon}
									</Badge>
									{!drawerOpen && (
										<Typography
											variant='subtitle1'
											style={{
												maxWidth: '60px',
												textAlign: 'center',
												whiteSpace: 'normal',
												display: '-webkit-box',
												WebkitLineClamp: 2,
												WebkitBoxOrient: 'vertical',
												overflow: 'hidden',
											}}
										>
											{t(primary)}
										</Typography>
									)}
								</ListItemIcon>
								<ListItemText
									primary={t(primary)}
									sx={{
										opacity: drawerOpen ? 1 : 0,
										color: 'text.primary',
									}}
								/>
								{drawerOpen &&
									(openIndex.includes(index) ? <ExpandLess /> : <ExpandMore />)}
							</ListItemButton>
						</ListItem>
						{menu.nestedCollapseList && drawerOpen && (
							<Collapse
								component='li'
								in={openIndex.includes(index)}
								timeout='auto'
								unmountOnExit
							>
								<List disablePadding>
									{menu.nestedCollapseList.map((subMenu, subIndex) => (
										<SingleListItem
											key={subIndex}
											icon={<DotIcon />}
											to={subMenu.to}
											badgeContent={subMenu.badgeContent}
											permission={subMenu.permission}
											sx={{
												pointerEvents: pathname.includes(subMenu.to)
													? 'none'
													: 'auto',
											}}
										/>
									))}
								</List>
							</Collapse>
						)}
					</Fragment>
				);
			})}
		</List>
	);
}
