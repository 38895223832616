import { useLogoutMutation } from '@/api/authApiSlice';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
	Chip,
	Container,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ListItems from './ListItems';
import ChangePasswordModal from './modal/ChangePasswordModal';
import RouterBreadcrumbs from './RouterBreadcrumbs';
import Logo from '@/assets/images/Logo.png';
import { menuPermission } from '@/casl/menuPermission';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { logOut, setMyInfo } from '@/pages/auth/authSlice';
import { useDispatch } from 'react-redux';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `80px`,
	// width: `calc(${theme.spacing(7)} + 1px)`,
	// [theme.breakpoints.up('sm')]: {
	// 	width: `calc(${theme.spacing(8)} + 1px)`,
	// },
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	backgroundColor: '#fff',
	boxShadow: 'none',
	color: '#666',
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export default function MiniDrawer() {
	const { t } = useTranslation();
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { userInfo } = useGetUser<useGetUserResultType>();
	const location = useLocation();
	if (menuPermission[userInfo?.role]) {
		const permission = menuPermission[userInfo?.role].find((item: string) => {
			if (
				item === location.pathname ||
				(item.endsWith('/*') && location.pathname.startsWith(item.slice(0, -2)))
			) {
				return true;
			} else {
				return false;
			}
		});
		if (!permission) navigate('/404');
	}

	const [logoutApi, { isSuccess: isLogoutSuccess }] = useLogoutMutation();

	const handleLogout = async () => {
		const response = await logoutApi();
		if ('data' in response) {
			dispatch(setMyInfo({}));
			dispatch(logOut());
			navigate('/login');
		}
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	// more menu

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const openAn = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<AppBar position='fixed' open={open}>
					<Toolbar>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							onClick={handleDrawerOpen}
							edge='start'
							sx={{
								marginRight: '20px',
								marginLeft: '-3px',
								...(open && { display: 'none' }),
							}}
						>
							<MenuIcon />
						</IconButton>
						<img
							src={Logo}
							alt='logo'
							width='120px'
							style={{
								marginRight: '20px',
								...(open && { display: 'none' }),
							}}
						/>

						{process.env.REACT_APP_IS_TESTING_ENV && (
							<Chip
								sx={{ backgroundColor: '#FFDAD6', color: '#FF655B' }}
								icon={
									<FiberManualRecordIcon sx={{ color: '#FF655B!important' }} />
								}
								label={t('common.testing')}
							/>
						)}
						{process.env.REACT_APP_IS_UAT && (
							<Chip
								sx={{ backgroundColor: '#E9E1FF', color: '#7153C6' }}
								icon={
									<FiberManualRecordIcon sx={{ color: '#7153C6!important' }} />
								}
								label={t('common.uat')}
							/>
						)}

						<Typography
							component='h1'
							variant='h6'
							color='inherit'
							noWrap
							sx={{ flexGrow: 1 }}
						/>
						<Typography component='h1' variant='subtitle1' color='#666' noWrap>
							你好,
							<span style={{ color: '#2196F3' }}>
								{userInfo?.nickname}{' '}
								{userInfo?.centre && `(${userInfo?.centre})`}
							</span>
						</Typography>
						<IconButton color='inherit' onClick={handleClick}>
							<AccountCircleIcon />
						</IconButton>

						<Menu
							id='more-menu'
							MenuListProps={{
								'aria-labelledby': 'more-button',
							}}
							anchorEl={anchorEl}
							open={openAn}
							onClose={handleClose}
						>
							<MenuItem
								onClick={handleLogout}
								// disabled={!userRoles.editPassword}
							>
								<ListItemIcon>
									<LogoutIcon fontSize='small' />
								</ListItemIcon>
								<ListItemText>{t('button.logout')}</ListItemText>
							</MenuItem>
							<ChangePasswordModal userId='' />
						</Menu>
					</Toolbar>
				</AppBar>
				<Drawer variant='permanent' open={open}>
					<DrawerHeader>
						<img src={Logo} alt='logo' width='120px' />

						<IconButton onClick={handleDrawerClose}>
							{theme.direction === 'rtl' ? (
								<ChevronRightIcon />
							) : (
								<ChevronLeftIcon />
							)}
						</IconButton>
					</DrawerHeader>
					<Divider />
					<List>
						<ListItems drawerOpen={open} handleDrawerOpen={handleDrawerOpen} />
					</List>
				</Drawer>
				<Box
					component='main'
					sx={{
						flexGrow: 1,
						height: '100vh',
						overflow: 'auto',
					}}
				>
					<DrawerHeader />
					<Container maxWidth={false} sx={{ minWidth: 1152, py: 2, px: 3 }}>
						<RouterBreadcrumbs />
						<Outlet />
					</Container>
				</Box>
			</Box>
		</>
	);
}
