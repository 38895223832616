import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import qs from 'qs';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../../utils/qsHelper';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ResourcesSummaryFilterSection from './StudyCourseSummaryFilter';
import { ResourcesInfoItem } from '@/api/DTO/resources/resourcesList.interface';
import { showLabel } from '@/utils/helper';
import { useStudyCourseOptions } from '@/hooks/useStudyCourseOptions';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '@/utils/dateHelper';
import dayjs from 'dayjs';
import SettingStatusModal from './components/SetStatusDialog';
import { StudyCourseFilter } from '@/api/DTO/studyCourse/studyCourseList.interface';
import {
	useGetCategoriesQuery,
	useGetStudyCourseCountQuery,
	useGetStudyCourseListQuery,
	useGetStudyCourseStatusListQuery,
} from '@/api/studyCourse/studyCourseListApiSlice';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';

export interface ResourcesInfoDataGrid {}

interface StudyCourseSummaryPageProps {
	statusType: string | number;
}

export default function StudyCourseSummaryPage(
	prop: StudyCourseSummaryPageProps
) {
	const { statusType } = prop;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);
	const [selectedId, setSelectedId] = useState<string[]>([]);
	const { statusList, statusChipMap } = useStudyCourseOptions([
		'statusList',
		'statusChipMap',
	]);

	const { userRoles } = useGetUser<useGetUserResultType>({
		roleObj: {
			studyCourseCreate: ['studyCourseCreate', 'studyCourse'],
			studyCourseDetail: ['studyCourseDetail', 'studyCourse'],
		},
	});

	const { data: studyCourseStatusListData } =
		useGetStudyCourseStatusListQuery(statusType);
	const studyCourseStatusList = useMemo(() => {
		return (studyCourseStatusListData?.data || []).map(
			(item: { status: any; desc: any }) => {
				return {
					id: item.status,
					name: item.desc,
				};
			}
		);
	}, [studyCourseStatusListData]);

	const { data: categoriesData } = useGetCategoriesQuery(null);
	const typesList = useMemo(() => {
		return (categoriesData?.data || []).map(
			(item: { type: any; desc: any }) => {
				return {
					id: item.type,
					name: item.desc,
				};
			}
		);
	}, [categoriesData]);

	const { data: pageCount } = useGetStudyCourseCountQuery(null, {
		refetchOnMountOrArgChange: true,
	});

	const defaultStudyCourseFilter: StudyCourseFilter = {
		status: statusType,
		pagination: initialPagination,
		sortings: [],
		categories: [],
	};

	const defaultValues: DefaultValues<StudyCourseFilter> =
		defaultStudyCourseFilter;
	const methods = useForm<StudyCourseFilter>({
		defaultValues,
	});

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const defaultStudyCourseFilterFormResult = value as StudyCourseFilter;
			setSearchParams(qs.stringify(defaultStudyCourseFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformedSearchParams: StudyCourseFilter = {
		...defaultStudyCourseFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'categories',
		]),
	};

	const {
		data: propertySummary,
		isSuccess: isGetPropertySummarySuccess,
		isLoading: isGetPropertyLoading,
	} = useGetStudyCourseListQuery(transformedSearchParams, {
		refetchOnMountOrArgChange: true,
	});

	useEffect(() => {
		if (!isGetPropertyLoading) {
			setSelectedId([]);
		}
	}, [propertySummary]);

	const transformResponse = useCallback((response?: ResourcesInfoItem[]) => {
		if (response == null) return;
		const newResponse: ResourcesInfoDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				...item,
				updatedAt: !item.updatedAt
					? '-'
					: dayjs(item.updatedAt)
							.locale('zh-hk-my-setting')
							.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: t('studyCourse.courseName'),
			flex: 1,
		},
		{
			field: 'chapterTotal',
			headerName: t('studyCourse.chapterNum'),
			flex: 1,
		},
		{
			field: 'categories',
			headerName: t('common.classify'),
			flex: 1,
			renderCell: (params) => params.row.categories.join(', '),
		},
		{
			field: 'status',
			headerName: t('common.status'),
			flex: 1,
			renderCell: (params) => (
				<Chip
					variant='outlined'
					label={showLabel(params.row.status, statusList)}
					color={
						(statusChipMap[params.row.status || '1']?.color as 'chipGrey') ||
						'chipGrey'
					}
				/>
			),
		},
		{
			field: 'edit',
			headerName: '',
			width: 68,
			renderCell: (params) =>
				userRoles.studyCourseDetail && (
					<Link
						to={`/study-course/study-course-list/${params.id}/info`}
						state={{
							propertySummarySearchParams: qs.stringify(
								transformedSearchParams
							),
						}}
					>
						<IconButton color='primary'>
							<VisibilityOutlinedIcon />
						</IconButton>
					</Link>
				),
		},
	];

	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>{t('studyCourse.studyCourseList')}</Typography>

				<Stack direction={'row'} alignItems={'center'} spacing={3}>
					<SettingStatusModal
						currentStatus={statusType}
						selectedId={selectedId}
						setSelectedId={setSelectedId}
						studyCourseStatusList={studyCourseStatusList}
					/>
					{userRoles.studyCourseCreate && (
						<Button
							variant='contained'
							startIcon={<AddIcon />}
							onClick={() =>
								navigate(`/study-course/study-course-list/add`, {
									state: {
										propertySummarySearchParams: qs.stringify(
											transformedSearchParams
										),
									},
								})
							}
						>
							{t('button.createStudyCourse')}
						</Button>
					)}
				</Stack>
			</Box>
			<Card sx={{ marginTop: 3 }}>
				<CardContent>
					<FormProvider {...methods}>
						<ResourcesSummaryFilterSection
							setSelectedId={setSelectedId}
							typesList={typesList}
							methods={methods}
							pageCount={pageCount?.data || {}}
						/>

						<Box marginTop={2}>
							<AppDataGrid
								loading={!isGetPropertySummarySuccess}
								rows={transformResponse(propertySummary?.data?.items) ?? []}
								columns={columns}
								rowCount={propertySummary?.data?.pagination.totalItems ?? 0}
								getRowId={(row) => row.id}
								checkboxSelection
								selectionModel={selectedId}
								onSelectionModelChange={(ids) => {
									setSelectedId(ids.map((id) => String(id)));
								}}
							/>
						</Box>
					</FormProvider>
				</CardContent>
			</Card>
		</>
	);
}
