import { createTheme } from '@mui/material';
import {} from '@mui/material/colors';
import './App.css';

declare module '@mui/material/styles' {
	interface TypographyVariants {
		regular: React.CSSProperties;
		medium: React.CSSProperties;
		bold: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		regular?: React.CSSProperties;
		medium?: React.CSSProperties;
		bold?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		regular: true;
		medium: true;
		bold: true;
	}
}

declare module '@mui/material/Chip' {
	interface ChipPropsColorOverrides {
		chipTertiary: true;
		chipPrimary: true;
		chipGreen: true;
		chipRed: true;
		chipGrey: true;
		chipLightGrey: true;
		chipFilledTertiary: true;
		chipPIRUser: true;
		chipCloth: true;
		chipFood: true;
		chipLive: true;
		chipWalk: true;
		chipCommunityWelfareInfo: true;
	}
}

declare module '@mui/material/styles/createPalette' {
	interface CommonColors {
		tertiary70: string;
		tertiary90: string;
		neutral40: string;
		neutral50: string;
		neutral70: string;
		neutral90: string;
	}

	interface Palette {
		chipPrimary: Palette['primary'];
		chipTertiary: Palette['primary'];
		chipGreen: Palette['primary'];
		chipRed: Palette['primary'];
		chipGrey: Palette['primary'];
		chipLightGrey: Palette['primary'];
		chipFilledTertiary: Palette['primary'];
		chipPIRUser: Palette['primary'];
		chipCloth: Palette['primary'];
		chipFood: Palette['primary'];
		chipLive: Palette['primary'];
		chipWalk: Palette['primary'];
		chipCommunityWelfareInfo: Palette['primary'];
	}

	interface PaletteOptions {
		chipPrimary?: PaletteOptions['primary'];
		chipTertiary?: PaletteOptions['primary'];
		chipGreen?: PaletteOptions['primary'];
		chipRed?: PaletteOptions['primary'];
		chipGrey?: PaletteOptions['primary'];
		chipLightGrey?: PaletteOptions['primary'];
		chipFilledTertiary?: PaletteOptions['primary'];
		chipPIRUser?: PaletteOptions['primary'];
		chipCloth?: PaletteOptions['primary'];
		chipFood?: PaletteOptions['primary'];
		chipLive?: PaletteOptions['primary'];
		chipWalk?: PaletteOptions['primary'];
		chipCommunityWelfareInfo?: PaletteOptions['primary'];
	}
}

export const mainTheme = createTheme({
	palette: {
		background: {
			default: '#F9F9FC',
		},
		common: {
			tertiary70: '#E09E36',
			tertiary90: '#FFDDB3',
			neutral40: '#5D5E61',
			neutral50: '#76777A',
			neutral70: '#AAABAE',
			neutral90: '#E2E2E5',
		},
		secondary: {
			main: '#FFDDB3',
		},

		// for Chip
		chipPrimary: {
			main: '#2B7DB2',
			light: '#2B7DB2',
			dark: '#2B7DB2',
			contrastText: '#2B7DB2',
		},
		chipTertiary: {
			main: '#E09E36',
			light: '#E09E36',
			dark: '#E09E36',
			contrastText: '#E09E36',
		},
		chipGreen: {
			main: '#2E7D32',
			light: '#2E7D32',
			dark: '#2E7D32',
			contrastText: '#2E7D32',
		},
		chipRed: {
			main: '#DA342E',
			light: '#DA342E',
			dark: '#DA342E',
			contrastText: '#DA342E',
		},
		chipGrey: {
			main: '#72787E',
			light: '#72787E',
			dark: '#72787E',
			contrastText: '#72787E',
		},
		chipLightGrey: {
			main: '#C1C7CE',
			light: '#C1C7CE',
			dark: '#C1C7CE',
			contrastText: '#C1C7CE',
		},
		chipFilledTertiary: {
			main: '#FFDF9C',
			light: '#FFDF9C',
			dark: '#FFDF9C',
			contrastText: '#775A0B',
		},
		chipPIRUser: {
			main: '#CBE6FF',
			light: '#CBE6FF',
			dark: '#CBE6FF',
			contrastText: '#001E31',
		},
		chipCloth: {
			main: '#EBCBFF',
			light: '#EBCBFF',
			dark: '#EBCBFF',
			contrastText: '#4F4356',
		},
		chipFood: {
			main: '#FFD990',
			light: '#FFD990',
			dark: '#FFD990',
			contrastText: '#6D6045',
		},
		chipLive: {
			main: '#A4E8B7',
			light: '#A4E8B7',
			dark: '#A4E8B7',
			contrastText: '#2F4234',
		},
		chipWalk: {
			main: '#CBE6FF',
			light: '#CBE6FF',
			dark: '#CBE6FF',
			contrastText: '#001E31',
		},
		chipCommunityWelfareInfo: {
			main: '#FFDBD6',
			light: '#FFDBD6',
			dark: '#FFDBD6',
			contrastText: '#534442',
		},
	},
	components: {
		MuiChip: {
			defaultProps: {
				size: 'small',
			},
		},
	},
});

export default mainTheme;
