import {
	Badge,
	BadgeProps,
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Divider,
	Grid,
	Tab,
	Tabs,
	Typography,
	styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormType } from '@/api/enum/formType.enum';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import AppFieldView, {
	AppFormLabel,
} from '@/common/components/form-view/AppFieldView';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import ResourcesForm from './components/resourcesForm';
import AppImageView from '@/common/components/form-view/AppImageView';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { GridColDef } from '@mui/x-data-grid';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import {
	useDeleteResourcesMutation,
	useGetDetailQuery,
	useGetLogsListQuery,
	useGetTypesQuery,
	useResourceStatusQuery,
	useResourcesLogsCountQuery,
} from '@/api/communityResources/resourcesListApiSlice';
import {
	ResourcesLogsFilter,
	ResourcesLogsRes,
} from '@/api/DTO/resources/resourcesList.interface';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '@/utils/dateHelper';
import dayjs from 'dayjs';
import { showLabel } from '@/utils/helper';
import { useResourcesListOptions } from '@/hooks/useResourcesListOptions';
import SettingStatusModal from './components/setStatusDialog';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import AppElementView from '@/common/components/form-view/AppElementView';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		position: 'relative',
		top: 12,
		backgroundColor: '#FFDAD6',
		color: '#FF5449',
	},
	'&.badge-active .MuiBadge-badge': {
		backgroundColor: '#FF5449',
		color: '#fff',
	},
}));

export default function ResourcesInfoPage() {
	const { t } = useTranslation();
	const [type, setType] = useState<FormType>(FormType.view);
	const { id } = useParams();
	const navigate = useNavigate();
	const [currentTab, setCurrentTab] = useState('allInfo');
	const { statusList, statusChipMap, typesChipMap } = useResourcesListOptions([
		'statusList',
		'statusChipMap',
		'typesChipMap',
	]);
	const showModal = useModal();
	const [deleteResources] = useDeleteResourcesMutation();
	const defaultPropertyFilter: ResourcesLogsFilter = {
		pagination: initialPagination,
		sortings: [
			// {
			// 	property: 'communityResourceUpdatedAt',
			// 	direction: SortDirection.DESCENDING,
			// },
		],
		id,
	};
	const [transformedSearchParams, setTransformedSearchParams] =
		useState<ResourcesLogsFilter>(defaultPropertyFilter);

	const tabList = [
		{
			name: 'allInfo',
			tabText: t('communityResources.allInfo'),
			countKey: '',
		},
		{
			name: 'statusRecord',
			tabText: t('communityResources.statusRecord'),
			countKey: 'logCount',
		},
	];

	const { data: resourcesInfo } = useGetDetailQuery(id, {
		refetchOnMountOrArgChange: true,
	});

	const { userRoles } = useGetUser<useGetUserResultType>({
		roleObj: {
			delete: [
				'delete',
				'communityResources',
				String(resourcesInfo?.data.status),
			],
			edit: ['edit', 'communityResources', String(resourcesInfo?.data.status)],
		},
	});

	const { data: logsCount } = useResourcesLogsCountQuery(id, {
		refetchOnMountOrArgChange: true,
	});

	// 当前角色可操作的状态
	const { data: resourcesStatus } = useResourceStatusQuery(
		resourcesInfo?.data.status,
		{
			refetchOnMountOrArgChange: true,
			skip: !resourcesInfo?.data.status,
		}
	);
	const resourcesStatusList = useMemo(() => {
		return (resourcesStatus?.data || []).map(
			(item: { status: any; desc: string }) => {
				return {
					id: item.status,
					name: item.desc,
				};
			}
		);
	}, [resourcesStatus]);

	const { data: typesData } = useGetTypesQuery(null);
	const { typesList } = useMemo(() => {
		const typesList = (typesData?.data || []).map((item: any) => {
			return {
				id: String(item.type),
				name: item.typeDesc,
			};
		});
		return { typesList };
	}, [typesData]);

	const handleDelete = () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('communityResources.deleteCommunityResources')}
				titleProps={{ variant: 'h6' }}
				description={
					<Typography variant='body1'>
						{t('communityResources.isDeleteCommunityResources')}
					</Typography>
				}
				cancellationText=''
				confirmationText={t('button.delete')}
				confirmationButtonProps={{ color: 'error', variant: 'outlined' }}
				onConfirm={async () => {
					const response = await deleteResources(id || '');
					if ('data' in response) {
						enqueueSnackbar(t('snackbar.deletedCommunityResources'), {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						navigate(-1);
					}
				}}
			/>
		));
	};
	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setCurrentTab(newValue);
	};

	const { data: propertySummary, isSuccess: isGetPropertySummarySuccess } =
		useGetLogsListQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
		});
	const transformResponse = useCallback((response?: ResourcesLogsRes[]) => {
		if (response == null) return;
		const newResponse: ResourcesLogsRes[] = [];
		response.forEach((item, index) => {
			newResponse.push({
				...item,
				index: index,
				updatedAt: !item.updatedAt
					? '-'
					: dayjs(item.updatedAt)
							.locale('zh-hk-my-setting')
							.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
			});
		});
		return newResponse;
	}, []);
	const defaultValues: DefaultValues<ResourcesLogsFilter> =
		defaultPropertyFilter;
	const methods = useForm<ResourcesLogsFilter>({
		defaultValues,
	});

	useEffect(() => {
		const subscription = methods.watch((value) => {
			setTransformedSearchParams(value as ResourcesLogsFilter);
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const columns: GridColDef[] = [
		{
			field: 'updatedAt',
			headerName: t('common.updatedDate'),
			flex: 1,
		},
		{
			field: 'content',
			headerName: t('common.content'),
			flex: 1,
		},
		{
			field: 'mark',
			headerName: t('common.remark'),
			flex: 1,
		},
	];

	return (
		<Box>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>
					{t('communityResources.communityResourcesDetail')}
				</Typography>
				{userRoles.delete && (
					<Button
						startIcon={<DeleteOutlined />}
						onClick={handleDelete}
						color='error'
						variant='outlined'
					>
						{t('button.delete')}
					</Button>
				)}
			</Box>
			<Tabs
				value={currentTab}
				sx={{ marginTop: '24px' }}
				onChange={handleTabChange}
			>
				{tabList.map((item) => (
					<Tab
						value={item.name}
						key={item.name}
						label={
							<>
								{Number(logsCount?.data[item.countKey]) > 0 ? (
									<StyledBadge
										badgeContent={logsCount?.data[item.countKey]}
										className={
											currentTab.indexOf(item.name) > -1 ? 'badge-active' : ''
										}
									>
										<Typography variant='body1'>
											&nbsp;{item.tabText}
										</Typography>
									</StyledBadge>
								) : (
									<Typography variant='body1'>{item.tabText}</Typography>
								)}
							</>
						}
					/>
				))}
			</Tabs>
			{currentTab == 'allInfo' && (
				<Card sx={{ marginTop: '10px' }}>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant='h6'>
									{t('communityResources.setting')}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<AppFieldView
									label={t('common.creationTime')}
									value={
										!resourcesInfo?.data.createdAt
											? '-'
											: dayjs(resourcesInfo?.data.createdAt)
													.locale('zh-hk-my-setting')
													.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<AppFormLabel>{t('common.status')}</AppFormLabel>
								<Box display='flex' justifyContent='space-between'>
									<Chip
										variant='outlined'
										label={showLabel(resourcesInfo?.data.status, statusList)}
										color={
											(statusChipMap[resourcesInfo?.data.status || '1']
												?.color as 'default') || 'default'
										}
									/>
									{type == FormType.view && (
										<SettingStatusModal
											setStatusButton={true}
											selectedId={[id || '']}
											resourcesStatusList={resourcesStatusList}
											currentStatus={resourcesInfo?.data.status}
										/>
									)}
								</Box>
							</Grid>
						</Grid>

						<Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />

						<Box display='flex' justifyContent='space-between'>
							{type == FormType.view ? (
								<>
									<Typography variant='h6'>{t('common.content')}</Typography>
									{userRoles.edit && (
										<Button
											startIcon={<CreateOutlinedIcon />}
											onClick={() => setType(FormType.edit)}
										>
											{t('button.edit')}
										</Button>
									)}
								</>
							) : type == FormType.edit ? (
								<Typography variant='h6' sx={{ marginBottom: '24px' }}>
									{t('communityResources.details')}
								</Typography>
							) : (
								''
							)}
						</Box>
						{(() => {
							switch (type) {
								case FormType.view:
									return (
										<>
											<Grid
												container
												spacing={3}
												marginTop={0}
												marginBottom={3}
											>
												<Grid item xs={6}>
													<AppElementView
														label={t('common.classify')}
														value={
															<Chip
																variant='filled'
																label={
																	showLabel(
																		String(resourcesInfo?.data.type),
																		typesList
																	) || '-'
																}
																color={
																	(typesChipMap[
																		Number(resourcesInfo?.data.type) || '1'
																	]?.color as 'default') || 'default'
																}
															/>
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<AppFieldView
														label={t('common.subclassify')}
														value={resourcesInfo?.data.subType}
													/>
												</Grid>
												<Grid item xs={6}>
													<AppFieldView
														label={t('common.name')}
														value={resourcesInfo?.data.name}
													/>
												</Grid>
												<Grid item xs={6}>
													<AppFieldView
														label={t('common.district')}
														value={resourcesInfo?.data.communityDistrict}
													/>
												</Grid>
												<Grid item xs={6}>
													<AppFieldView
														label={t('common.contactNumber')}
														value={resourcesInfo?.data.phone}
													/>
												</Grid>
												<Grid item xs={6}>
													<AppFieldView
														label={t('common.address')}
														value={resourcesInfo?.data.address}
													/>
												</Grid>
												<Grid item xs={6}>
													<AppImageView
														label={t('common.picture')}
														imageElement={
															<img
																src={resourcesInfo?.data.pic?.url}
																style={{ width: '300px' }}
															/>
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<AppFieldView
														label={t('communityResources.introduce')}
														value={resourcesInfo?.data.introduce}
													/>
												</Grid>
											</Grid>
										</>
									);
								case FormType.edit:
									return (
										<ResourcesForm
											type={type}
											setType={setType}
											resourcesInfo={resourcesInfo?.data}
										/>
									);
							}
						})()}
					</CardContent>
				</Card>
			)}

			{currentTab == 'statusRecord' && (
				<Card sx={{ marginTop: '10px' }}>
					<CardContent>
						<FormProvider {...methods}>
							<Box marginTop={2}>
								<AppDataGrid
									loading={!isGetPropertySummarySuccess}
									rows={transformResponse(propertySummary?.data?.items) ?? []}
									columns={columns}
									rowCount={propertySummary?.data?.pagination.totalItems ?? 0}
									getRowId={(row) => row.index}
								/>
							</Box>
						</FormProvider>
					</CardContent>
				</Card>
			)}
		</Box>
	);
}
