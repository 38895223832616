import { SuccessResponse, rolesListRes } from './DTO/common.interface';
import { ArticlesDistrictsRes } from './DTO/mentalHealthInfo/mentalHealthInfo.interface';
import { apiSlice } from './apiSlice';

export const commonApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getRoles: builder.query<SuccessResponse<rolesListRes[]>, null>({
			query: () => ({
				url: `/web/roles/staff`,
			}),
		}),

		getArticlesDistricts: builder.query<
			SuccessResponse<ArticlesDistrictsRes[]>,
			null
		>({
			query: () => ({ url: `/web/articles/districts` }),
		}),

		uploadMedia: builder.mutation<
			SuccessResponse<{ fileKey: string; url: string }[]>,
			FormData
		>({
			query: (body) => ({ url: `/web/media/upload`, method: 'POST', body }),
		}),
	}),
});

export const {
	useGetRolesQuery,
	useGetArticlesDistrictsQuery,
	useUploadMediaMutation,
} = commonApiSlice;
