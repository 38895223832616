import image1 from '@/assets/images/404-1.png';
import image2 from '@/assets/images/404-2.png';
import image3 from '@/assets/images/404-3.png';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Page404 = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleToIndex = () => {
		navigate('/', { replace: true });
	};

	return (
		<Box
			style={{
				textAlign: 'center',
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%,-50%)',
			}}
		>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='flex-end'
			>
				<img src={image1} />
				<img src={image2} style={{ margin: '0 18px' }} />
				<img src={image3} />
			</Grid>
			<Box sx={{ margin: '80px 0 40px' }}>
				<Typography variant='h5' color={'#242628'} marginBottom={'24px'}>
					{t('errorMessage.page404')}
				</Typography>
				<Typography variant='body1' color={'#76777A'}>
					{t('errorMessage.notFind')}
				</Typography>
				<Typography variant='body1' color={'#76777A'}>
					{t('errorMessage.toIndex')}
				</Typography>
			</Box>
			<Button variant='contained' onClick={handleToIndex}>
				{t('button.toIndex')}
			</Button>
		</Box>
	);
};

export default Page404;
