import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import AppRadioGroup from '@/common/components/form-inputs/AppRadioGroup';
import AppTextField from '@/common/components/form-inputs/AppTextField';
import AppDropDownMenu from '@/common/components/form-inputs/AppDropDownMenu';
import * as Yup from 'yup';
import { useGetStaffListQuery } from '@/api/staff/staffApiSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { applyJoinMemberDTO } from '@/api/DTO/user/application.interface';
import { useEffect } from 'react';

interface JoinMemberConfirmationFormModalProps {
	serviceNumber?: string;
	open: boolean;
	onClose: () => void;
	onConfirm: (formResult: applyJoinMemberDTO) => void;
}

const JoinMemberConfirmationFormModal = ({
	serviceNumber,
	open,
	onClose,
	onConfirm,
}: JoinMemberConfirmationFormModalProps) => {
	const { t } = useTranslation();

	const { data: getStaffList } = useGetStaffListQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});

	const defaultValues: DefaultValues<applyJoinMemberDTO> = {
		serviceNumber: '',
		level: 0,
		staffId: '',
	};

	const formSchema = Yup.object().shape({
		serviceNumber: Yup.string().nullable().required(t('errorMessage.required')),
		staffId: Yup.string().nullable().required(t('errorMessage.pleaseSelect')),
		level: Yup.number()
			.nullable()
			.oneOf([1, 2], t('errorMessage.pleaseSelect')),
	});

	const methods = useForm<applyJoinMemberDTO>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const onSubmit: SubmitHandler<applyJoinMemberDTO> = async (data) => {
		onConfirm(data);
		onClose();
	};

	useEffect(() => {
		methods.reset();
		if (open) {
			methods.setValue('serviceNumber', serviceNumber); // prefill serviceNumber
		}
	}, [open]);

	return (
		<Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
			<DialogTitle variant='h5'>{t('application.setUserCategory')}</DialogTitle>

			<IconButton
				aria-label='close'
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Grid container spacing={2} marginTop={0}>
							<Grid item xs={12}>
								<Typography variant='body1'>
									{t('common.fillInInfoMsg')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<AppTextField
									control={methods.control}
									name='serviceNumber'
									label={t('common.serviceNumber')}
									maxLength={31}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant='body2' color='common.neutral40'>
									{t('common.level')}
								</Typography>
								<AppRadioGroup
									control={methods.control}
									name='level'
									row
									options={[
										{ id: 1, name: '1' },
										{ id: 2, name: '2' },
									]}
								/>
							</Grid>
							<Grid item xs={12}>
								<AppDropDownMenu
									control={methods.control}
									name='staffId'
									label={t('common.staffInCharge')}
									options={getStaffList?.data ?? []}
									required
								/>
							</Grid>
						</Grid>
					</form>
				</FormProvider>
			</DialogContent>
			<DialogActions>
				<Button
					type='submit'
					variant='contained'
					onClick={methods.handleSubmit(onSubmit)}
				>
					{t('button.confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default JoinMemberConfirmationFormModal;
