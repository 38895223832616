import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { JoinMemberFilter } from '@/api/DTO/user/application.interface';
import AppSummarySearchBar from '@/common/components/form-inputs/AppSummarySearchBar';
import AppMultipleDropDownMenu from '@/common/components/form-inputs/AppMultipleDropDownMenu';

interface FilterProps {
	statusInput?: boolean;
	statusList?: any;
}

export default function applicationFilterSection(prop: FilterProps) {
	const { statusInput, statusList } = prop;
	const { t } = useTranslation();
	const { control } = useFormContext<JoinMemberFilter>();

	return (
		<Grid container rowSpacing={2} columnSpacing={1} sx={{ marginTop: 0 }}>
			<Grid item xs={2.5}>
				<AppSummarySearchBar
					autoMinWidth={true}
					name='loginID'
					control={control}
					placeholder={t('common.pleaseEnterKeywordSearch')}
				/>
			</Grid>
			{statusInput && (
				<Grid item xs={2}>
					<AppMultipleDropDownMenu
						control={control}
						name='statusList'
						label={t('common.outCentreStatus')}
						options={statusList}
						size='small'
						emptyArrayIfNoSelection
					/>
				</Grid>
			)}
		</Grid>
	);
}
