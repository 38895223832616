import { useTranslation } from 'react-i18next';
import { Box, IconButton, Grid, Typography } from '@mui/material';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import {
	OutMemberFilter,
	applicationListItem,
} from '@/api/DTO/user/application.interface';
import FilterSection from './components/filterSection';
import { useSearchParams } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { selectMyInfo } from '@/pages/auth/authSlice';
import { useSelector } from 'react-redux';
import { useGetApplicationListQuery } from '@/api/user/applicationApiSlice';
import { filterObjectByKeys, parseSummarySearchParams } from '@/utils/qsHelper';
import qs from 'qs';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utils/dateHelper';
import DetailDialog from './components/detailDialog';
import { ApplyEvent } from '@/api/enum/common.enum';

export interface JoinCentreList {
	id: number;
	name: string;
	applyAt: string;
	fromCentre: string;
}

export default function JoinCentrePage() {
	const { t } = useTranslation();
	const myInfo = useSelector(selectMyInfo);
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);
	const [showDetailDialog, setShowDetailDialog] = useState(false);
	const [detailObj, setDetailObj] = useState({});

	const handleDetail = (obj: { transferId: any; status?: any }) => {
		setShowDetailDialog(true);
		setDetailObj({
			transferId: obj.transferId,
			status: obj.status,
		});
	};

	const defaultPropertyFilter: OutMemberFilter = {
		pagination: initialPagination,
		loginID: '',
		sortings: [],
		event: ApplyEvent.outMember,
		centreEnum: myInfo?.centre || '',
	};
	const transformedSearchParams: OutMemberFilter = {
		...defaultPropertyFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'loginID',
			'event',
			'centreEnum',
		]),
	};
	const defaultValues: DefaultValues<OutMemberFilter> = defaultPropertyFilter;
	const methods = useForm<OutMemberFilter>({
		defaultValues,
	});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const propertyFilterFormResult = value as OutMemberFilter;
			// append to url to reload page
			setSearchParams(qs.stringify(propertyFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const { data: propertySummary, isSuccess: isGetPropertySummarySuccess } =
		useGetApplicationListQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
			skip: !myInfo?.centre,
		});

	const transformResponse = useCallback((response?: applicationListItem[]) => {
		if (response == null) return;
		const newResponse: JoinCentreList[] = [];
		response.forEach((item) => {
			newResponse.push({
				...item,
				fromCentre: !item.fromCentre ? '-' : item.fromCentre,
				applyAt: !item.applyAt
					? '-'
					: dayjs(item.applyAt)
							.locale('zh-hk-my-setting')
							.format(DISPLAY_DATE_FORMAT),
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'username',
			headerName: t('common.username'),
			flex: 27,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='body2'
						variant='regular'
					>
						{params.row.username}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.phone}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.email}
					</Typography>
				</Box>
			),
		},
		{
			field: 'fromDistrict',
			headerName: t('application.district'),
			flex: 35,
		},
		{
			field: 'serviceNumber',
			headerName: t('common.serviceNumber'),
			flex: 35,
		},
		{
			field: 'applyAt',
			headerName: t('common.applyTime'),
			flex: 11,
		},
		{
			field: 'edit',
			headerName: '',
			width: 68,
			renderCell: (params) => (
				<div
					onClick={() =>
						handleDetail({
							transferId: params.row.transferId,
							status: params.row.status,
						})
					}
				>
					<IconButton color='primary'>
						<VisibilityOutlinedIcon />
					</IconButton>
				</div>
			),
		},
	];

	return (
		<>
			<FormProvider {...methods}>
				<FilterSection></FilterSection>

				<Box marginTop={2}>
					<AppDataGrid
						loading={!isGetPropertySummarySuccess}
						rows={transformResponse(propertySummary?.data?.items) ?? []}
						columns={columns}
						rowCount={propertySummary?.data?.pagination.totalItems ?? 0}
						getRowId={(row) => row.transferId}
					/>
				</Box>
			</FormProvider>

			{showDetailDialog && (
				<DetailDialog
					detailObj={detailObj}
					isOpen={showDetailDialog}
					setIsOpen={setShowDetailDialog}
					type={ApplyEvent.outMember}
				/>
			)}
		</>
	);
}
