import qs from 'qs';

export function parseSummarySearchParams(urlSearchParams: URLSearchParams): {
	[key: string]: unknown;
} {
	return qs.parse(urlSearchParams.toString(), {
		decoder(str, decoder, charset) {
			const strWithoutPlus = str.replace(/\+/g, ' ');
			if (charset === 'iso-8859-1') {
				// unescape never throws, no try...catch needed:
				return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
			}

			if (/^(\d+|\d*\.\d+)$/.test(str)) {
				return parseFloat(str);
			}

			const keywords = {
				true: true,
				false: false,
				null: null,
				undefined: undefined,
			};
			if (str in keywords) {
				return keywords[str as keyof typeof keywords];
			}

			// utf-8
			try {
				return decodeURIComponent(strWithoutPlus);
			} catch (e) {
				return strWithoutPlus;
			}
		},
	});
}

export function filterObjectByKeys(raw: object, allowed: string[]): object {
	return Object.fromEntries(
		Object.entries(raw).filter(([key]) => allowed.includes(key))
	);
}
