import { styled } from '@mui/system';
import {
	DataGrid,
	GridRowIdGetter,
	GridToolbar,
	GridValidRowModel,
	GridColumns,
	GridSelectionModel,
	GridCallbackDetails,
	zhTW,
} from '@mui/x-data-grid';
import { useController, useFormContext } from 'react-hook-form';
import { Pagination } from '../../../api/DTO/common.interface';

export const initialPagination: Pagination = {
	page: 1,
	size: 10,
};

interface AppDataGridProps {
	loading: boolean;
	rows: GridValidRowModel[];
	columns: GridColumns<GridValidRowModel>;
	rowCount: number;
	getRowId: GridRowIdGetter<GridValidRowModel> | undefined;
	autoHeight?: boolean;
	checkboxSelection?: boolean;
	selectionModel?: GridSelectionModel;
	onSelectionModelChange?:
		| ((
				selectionModel: GridSelectionModel,
				details: GridCallbackDetails<any>
		  ) => void)
		| undefined;
	columnHeadersBackgroundColor?: string;
}

/**
 * Custom Data Grid with React Hook Form controlled pagination
 */
export default function AppDataGrid({
	loading,
	rows,
	columns,
	rowCount,
	getRowId,
	autoHeight,
	checkboxSelection,
	selectionModel,
	onSelectionModelChange,
	columnHeadersBackgroundColor,
}: AppDataGridProps) {
	const { control } = useFormContext();
	const { field } = useController({ control: control, name: 'pagination' });
	const pagination = field.value as Pagination;
	const paginationOnChange = field.onChange;

	return (
		<StyledDataGrid
			loading={loading}
			rows={rows}
			columns={columns}
			getRowId={getRowId}
			rowCount={rowCount}
			paginationMode='server'
			page={pagination.page - 1} // -1 because MUI Data Grid page is start from 0
			onPageChange={(page: number) => {
				paginationOnChange({ ...pagination, page: page + 1 }); // +1 because MUI Data Grid page is start from 0
			}}
			pageSize={pagination.size}
			onPageSizeChange={(pageSize: number) => {
				paginationOnChange({ page: 1, size: pageSize });
			}}
			pagination={true}
			rowsPerPageOptions={[10, 20, 50, 100]}
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableDensitySelector
			disableColumnMenu
			sortingMode='server'
			autoHeight={autoHeight ?? true}
			checkboxSelection={checkboxSelection ?? false}
			selectionModel={selectionModel}
			onSelectionModelChange={onSelectionModelChange}
			keepNonExistentRowsSelected
			headerHeight={56}
			getRowHeight={() => 'auto'}
			components={{
				Toolbar: GridToolbar,
			}}
			componentsProps={{
				toolbar: {
					csvOptions: { disableToolbarButton: true },
					printOptions: { disableToolbarButton: true },
					quickFilterProps: { debounceMs: 250 },
				},
			}}
			localeText={zhTW.components.MuiDataGrid.defaultProps.localeText}
			columnHeadersBackgroundColor={columnHeadersBackgroundColor}
		/>
	);
}

const StyledDataGrid = styled(DataGrid, {
	shouldForwardProp: (prop) => prop !== 'columnHeadersBackgroundColor',
})<{
	columnHeadersBackgroundColor?: string;
}>(({ columnHeadersBackgroundColor }) => ({
	border: 0,
	'& .MuiDataGrid-columnHeaderTitle': {
		fontWeight: 'normal',
	},
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: columnHeadersBackgroundColor
			? columnHeadersBackgroundColor
			: '#EEF1F6',
		fontWeight: 'normal',
	},
	'& .MuiDataGrid-columnSeparator': {
		visibility: 'hidden',
	},
	'& .MuiDataGrid-iconButtonContainer button': {
		display: 'none',
	},
	'.MuiDataGrid-cellContent': {
		wordWrap: 'break-word',
		whiteSpace: 'normal',
		padding: '15px 0',
	},
}));
