import { useTranslation } from 'react-i18next';
import {
	Typography,
	Card,
	CardContent,
	Stack,
	Button,
	Box,
	IconButton,
} from '@mui/material';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import {
	StaffFilter,
	StaffListSummaryItem,
} from '@/api/DTO/staff/staffList.interface';
import StaffListSummaryFilterSection from './components/staffListSummaryFilterSection';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import qs from 'qs';
import { useGetStaffPageQuery } from '@/api/staff/staffApiSlice';
import { filterObjectByKeys, parseSummarySearchParams } from '@/utils/qsHelper';
import { DISPLAY_CHINESE_DATE_TIME_FORMAT } from '@/utils/dateHelper';
import dayjs from 'dayjs';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';

export interface staffListDataGrid {
	lastLogin: string;
	createdAt: string;
}

export default function StaffListSummaryPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	// useGetUser返回 userInfo 和 userRoles
	// 接收一个对象
	// 第一个入参shouldRefetch：是否再次调用获取用户信息api
	// 第二个入参roleObj：传入需要判断的权限返回权限对象boolean
	// roleObj的key自定义，值为需要判断的权限，返回自定义key和对应的boolean
	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			create: ['create', 'staffList'],
		},
	});

	const defaultPropertyFilter: StaffFilter = {
		pagination: initialPagination,
		loginID: '',
		sortings: [],
	};
	const defaultValues: DefaultValues<StaffFilter> = defaultPropertyFilter;
	const methods = useForm<StaffFilter>({
		defaultValues,
	});
	const transformedSearchParams: StaffFilter = {
		...defaultPropertyFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'loginID',
		]),
	};

	const { data: propertySummary, isSuccess: isGetPropertySummarySuccess } =
		useGetStaffPageQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
		});

	const transformResponse = useCallback((response?: StaffListSummaryItem[]) => {
		if (response == null) return;
		const newResponse: staffListDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				...item,
				lastLogin: !item.lastLogin
					? '-'
					: dayjs(item.lastLogin)
							.locale('zh-hk-my-setting')
							.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
				createdAt: !item.createdAt
					? '-'
					: dayjs(item.createdAt)
							.locale('zh-hk-my-setting')
							.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
			});
		});
		return newResponse;
	}, []);

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const propertyFilterFormResult = value as StaffFilter;

			// append to url to reload page
			setSearchParams(qs.stringify(propertyFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: t('common.staffName'),
			flex: 21,
		},
		{
			field: 'role',
			headerName: t('common.position'),
			flex: 15,
		},
		{
			field: 'email',
			headerName: t('common.email'),
			flex: 21,
		},
		{
			field: 'createdAt',
			headerName: t('common.creationTime'),
			flex: 21,
		},
		{
			field: 'lastLogin',
			headerName: t('common.lastLoginInTime'),
			flex: 21,
		},
		{
			field: 'edit',
			headerName: '',
			width: 68,
			renderCell: (params) => (
				<Link
					to={`/staffs/staff-list/info/${params.id}`}
					state={{
						propertySummarySearchParams: qs.stringify(transformedSearchParams),
					}}
				>
					<IconButton color='primary'>
						<VisibilityOutlinedIcon />
					</IconButton>
				</Link>
			),
		},
	];

	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Typography variant='h4'>{t('common.staffList')}</Typography>
				<Stack direction={'row'} alignItems={'center'} spacing={3}>
					{userRoles.create && (
						<Button
							variant='contained'
							startIcon={<AddIcon />}
							onClick={() =>
								navigate(`/staffs/staff-list/add`, {
									state: {
										propertySummarySearchParams: qs.stringify(
											transformedSearchParams
										),
									},
								})
							}
							style={{ alignItems: 'center' }}
						>
							{t('button.addStaffAccount')}
						</Button>
					)}
				</Stack>
			</Box>

			<Card sx={{ marginTop: 3 }}>
				<CardContent>
					<FormProvider {...methods}>
						<StaffListSummaryFilterSection></StaffListSummaryFilterSection>

						<Box marginTop={2}>
							<AppDataGrid
								loading={!isGetPropertySummarySuccess}
								rows={transformResponse(propertySummary?.data?.items) ?? []}
								rowCount={propertySummary?.data?.pagination.totalItems ?? 0}
								columns={columns}
								getRowId={(row) => row.userId}
							/>
						</Box>
					</FormProvider>
				</CardContent>
			</Card>
		</>
	);
}
