import { yupResolver } from '@hookform/resolvers/yup';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Button } from '@mui/material';
import Link from '@mui/material/Link';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useForgotResetPasswordMutation } from '../../api/authApiSlice';
import AppPasswordTextField from '../../common/components/form-inputs/AppPasswordTextField';
import { ADMIN_PASSWORD_PATTERN } from '../../common/constants/pattern';
import { selectCurrentToken } from './authSlice';
import AuthCard from './components/AuthCard';
import AuthContainer from './components/AuthContainer';
import { closeSnackbar, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

export type FormValues = {
	newPassword: string;
	confirmPassword: string;
};

export const defaultValues: DefaultValues<FormValues> = {
	newPassword: '',
	confirmPassword: '',
};

export default function ResetPasswordPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const token = useSelector(selectCurrentToken);
	const { enqueueSnackbar } = useSnackbar();

	const formSchema = Yup.object().shape({
		newPassword: Yup.string()
			.required(t('errorMessage.required'))
			.matches(ADMIN_PASSWORD_PATTERN, t('errorMessage.passwordMin8')),
		confirmPassword: Yup.string()
			.required(t('errorMessage.required'))
			.oneOf([Yup.ref('newPassword')], t('errorMessage.password')),
	});

	const { handleSubmit, control } = useForm<FormValues>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [forgotResetPassword] = useForgotResetPasswordMutation();

	const onSubmit: SubmitHandler<FormValues> = async (formResult) => {
		if (token) {
			const response = await forgotResetPassword({
				password: formResult.newPassword,
			});
			if ('data' in response) {
				enqueueSnackbar(t('snackbar.resetPasswordSuccess'), {
					variant: 'success',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
					autoHideDuration: 3000,
					action: (key) => {
						return <CloseIcon onClick={() => closeSnackbar(key)} />;
					},
				});
				navigate('/login');
			}
		} else console.log('missing reset accessToken');
	};

	useEffect(() => {
		if (!token) {
			navigate('/forgot-password');
		}
	}, [token]);

	return (
		<AuthContainer>
			<AuthCard title={t('forgotPassword.resetPasswordTitle')} message={''}>
				<Link
					color='primary'
					component={RouterLink}
					to='/forgot-password/verify'
					position='absolute'
					top='-50px'
					left='-20px'
					display='flex'
					alignItems='center'
				>
					<KeyboardArrowLeftIcon />
					{t('button.back')}
				</Link>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Stack
						width='382px'
						alignItems='center'
						spacing='24px'
						marginTop='40px'
					>
						<AppPasswordTextField
							name='newPassword'
							control={control}
							label={t('common.enterYourPassword')}
							helperText={t('forgotPassword.newPasswordHelperText')}
						/>

						<AppPasswordTextField
							name='confirmPassword'
							control={control}
							label={t('common.enterYourPasswordAgain')}
						/>
					</Stack>

					<Button
						fullWidth
						type='submit'
						variant='contained'
						sx={{
							marginTop: '40px',
						}}
					>
						{t('button.submit')}
					</Button>
				</form>
			</AuthCard>
		</AuthContainer>
	);
}
