import { useContext, useEffect, useState } from 'react';
import { UserContext } from '@/context/userContext';
import defineAbilityFor from '@/casl/defineAbility';
import { useSelector } from 'react-redux';
import { selectMyInfo } from '@/pages/auth/authSlice';

interface Props {
	shouldRefetch?: boolean;
	roleObj?: { [key: string]: [string, string, string?] };
}
export type useGetUserResultType = {
	userInfo: any;
	userRoles: { [key: string]: boolean };
};
export function useGetUser<
	T extends useGetUserResultType = useGetUserResultType
>(props: Props = {}): T {
	const [userRoles, setUserRoles] = useState({});
	const { shouldRefetch = false, roleObj = {} } = props;
	const { isLoading, isSuccess, userInfoRefetch } = useContext(UserContext);
	const userInfo = useSelector(selectMyInfo);

	// shouldRefetch 判断是否需要重新调用接口
	useEffect(() => {
		if (!isLoading && isSuccess && shouldRefetch) {
			userInfoRefetch();
		}
	}, [shouldRefetch]);

	useEffect(() => {
		if (
			userInfo?.role &&
			roleObj &&
			typeof roleObj === 'object' &&
			!Array.isArray(roleObj)
		) {
			const newRoles: any = {};
			const ability = defineAbilityFor(userInfo.role);
			Object.keys(roleObj).forEach((key) => {
				newRoles[key] = ability.can(
					roleObj[key][0],
					roleObj[key][1],
					roleObj[key][2]
				);
			});
			if (JSON.stringify(newRoles) !== JSON.stringify(userRoles)) {
				setUserRoles(newRoles);
			}
		}
	}, [userInfo, roleObj]);

	return { userInfo, userRoles } as T;
}
