import { useSelector } from 'react-redux';
import {
	selectServiceWorkerRegistration,
	selectServiceWorkerUpdated,
} from '../../../serviceWorkerSlice';
import { Button, Snackbar } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const VersionUpdateAlertSnackbarRoot = () => {
	const { t } = useTranslation();

	const isServiceWorkerUpdated = useSelector(selectServiceWorkerUpdated);
	const serviceWorkerRegistration = useSelector(
		selectServiceWorkerRegistration
	);

	const updateServiceWorker = () => {
		if (serviceWorkerRegistration) {
			const registrationWaiting = serviceWorkerRegistration.waiting;

			if (registrationWaiting) {
				registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

				window.location.reload();
			} else {
				window.location.reload();
			}
		} else {
			window.location.reload();
		}
	};

	useEffect(() => {
		if (isServiceWorkerUpdated) {
			if (serviceWorkerRegistration) {
				const registrationWaiting = serviceWorkerRegistration.waiting;

				if (registrationWaiting) {
					registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
				}
			}
		}
	}, [isServiceWorkerUpdated]);

	return (
		<>
			{isServiceWorkerUpdated && (
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={true}
					message={t('snackbar.FoundNewVersionMessage')}
					action={
						<Button onClick={updateServiceWorker}>{t('button.refresh')}</Button>
					}
				/>
			)}
		</>
	);
};

export default VersionUpdateAlertSnackbarRoot;
